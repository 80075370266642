import React, { useMemo } from 'react';
import { offerPalette, OfferState } from 'services/offerService';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Legend = () => {
  const { t } = useTranslation();

  const filteredOfferPalette = useMemo(() =>
    Object.entries(offerPalette)
      .filter(([ key ]) => ![OfferState.ACTIVE, OfferState.INACTIVE].includes(key as OfferState))
      .map(([ key, palette ]) => ({ legendColor: palette.highlighted, label: key }))
  , []);

  const legends = useMemo(() => filteredOfferPalette.map((palette, index) => (
    <StatusContainer key={`legend-${palette.label}-${index}`}>
      <StatusBall color={palette.legendColor}/>
      <TextWrapper>{t(`schema:offer.state.${palette.label}`)}</TextWrapper>
    </StatusContainer>
  )), [filteredOfferPalette, t]);

  return (
    <Container>
      {legends}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing.l}px;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing.s}px;
`;

interface IStatusBallProps {
  color: string;
}

const StatusBall = styled.span<IStatusBallProps>`
  height: 10px;
  width: 10px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.spacing.xxs}px;
`;

const TextWrapper = styled.span`
  font-size: ${({ theme }) => theme.typography.size.XS}px;
  color: ${({ theme }) => theme.color.grey[6]};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: lighter;
`;

export default Legend;
