import React, { ReactElement } from 'react';
import { withTheme, DefaultTheme } from 'styled-components';

interface IProps {
    children: ReactElement | ReactElement[] | string;
    theme: DefaultTheme;
    hasError?: boolean;
    onClick?: () => void;
}
const TableRow = ({ children, theme, hasError, onClick }: IProps) => {
    return (
        <tr
            onClick={onClick}
            style={{ backgroundColor: hasError && theme.color.common.lightRed, color: hasError && theme.color.grey[4] }}
        >
            {children}
        </tr>
    );
};

export default withTheme(TableRow);
