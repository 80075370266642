import i18n from './i18n';

export const langs = [
    { id: 'fr', label: 'Français' },
    { id: 'en', label: 'Anglais' },
];

export function fixedTranslation(lang: string, translationPath: string): string {
    const language = i18n.getFixedT(lang);
    return language(translationPath);
}
