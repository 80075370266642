import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import { AddButton, DeleteButton } from '../Button';
import { BaseInput, IInputProps } from './common';

const STEP = 1;

interface INumberInput extends IInputProps<number | string> {
    minValue?: number;
    maxValue?: number;
    step?: number;
    onChange: (v: number | string) => void;
    testID?: string;
    validateOnChange?: boolean;
    width?: number;
    showButtons?: boolean;
}

enum Action {
    ADD = 'ADD',
    SUBSTRACT = 'SUBSTRACT',
}

function NumberInput({
    value = 0,
    onChange,
    name,
    className,
    hasError,
    onBlur,
    disabled,
    minValue,
    maxValue,
    step,
    testID,
    width = 75,
    validateOnChange = false,
    showButtons = true
}: INumberInput) {
    function onNumberInputChange(event: ChangeEvent<HTMLInputElement>) {
        const { value } = event.currentTarget;
        let newValue;

        //Maintains the numbers and the '-' symbol and replaces the other symbols with an empty string
        const parsedValue = parseInt(value.replace(/[^\d-]/g, ''));

        if (!value || (value !== '-' && isNaN(parsedValue))) {
            newValue = '';
        }

        else {
            newValue = isNaN(parsedValue) ? '-' : parsedValue;

        }

        if (minValue && newValue < minValue) {
            newValue = minValue;
        } else if (maxValue && newValue > maxValue) {
            newValue = maxValue;
        }
        onChange(newValue);
    }

    const onButtonClick = (action: Action, step: number | undefined) => () => {
        const stepValue = step ? step : STEP;
        const newValue = typeof value === 'string' ? 0 : value + (action === Action.ADD ? stepValue : -stepValue);
        onChange(newValue);
    };

    return (
        <Wrapper data-test={testID} className={className}>
            {showButtons && <StyledSubtractButton
                disabled={disabled || value === minValue}
                onClick={onButtonClick(Action.SUBSTRACT, step)}
            />}
            <Input
                width={width}
                disabled={disabled}
                hasError={hasError}
                name={name}
                id={name}
                value={value && value.toString()}
                type="text"
                onChange={onNumberInputChange}
                onBlur={onBlur}
                validateOnChange={validateOnChange}
            />
            {showButtons && <StyledAddButton
                disabled={disabled || value === maxValue}
                onClick={onButtonClick(Action.ADD, step)}
            />}
        </Wrapper>
    );
}

const Wrapper = styled.span`
    display: flex;
`;

const Input = styled(BaseInput)`
    width: ${({ width }) => width}px;
    font-family: ${({ theme }) => theme.typography.fontFamilySecondary};
    font-weight: ${({ theme }) => theme.typography.fontWeight.light};
`;

const StyledAddButton = styled(AddButton)`
    margin-left: 2px;
`;

const StyledSubtractButton = styled(DeleteButton)`
    margin-right: 2px;
`;

export default NumberInput;
