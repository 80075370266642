import { RoutablePanel } from 'components';
import { IRenderProps as IPanelRenderProps } from 'components/Panel';
import ArticleConceptList from 'components/Query/ArticleConceptList';
import { loader } from 'graphql.macro';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import AddOrEditArticleForm from './AddOrEditForm';

const CREATE_ARTICLE_MUTATION = loader('./query/createArticle.gql');

interface IPropsPanel extends RouteComponentProps<{ idArticle: string }> {
    beforeClose: () => void;
    parentPage: string;
}

const AddGlobalArticlePanel = ({ history: { push }, location: { search }, beforeClose, parentPage }: IPropsPanel) => {
    const [t] = useTranslation();
    const onClose = () => {
        beforeClose();
        push(`${routes.globalCatalog.list()}${search}`);
    };

    return (
        <RoutablePanel
          title={t(`page:globalCatalog.add.title`)}
          onClose={onClose}
          {...(parentPage && {testID:`${parentPage}-add-article`})}
        >
            {({ onClose }: IPanelRenderProps) => (
                <ArticleConceptList>
                    {({ concepts }) => (
                        <Mutation mutation={CREATE_ARTICLE_MUTATION}>
                            {(createArticle: (param: Record<'variables', any>) => Promise<any>) => (
                                <AddOrEditArticleForm
                                    {...{
                                        concepts,
                                        updateArticle: createArticle,
                                        onSuccess: onClose,
                                    }}
                                    {...(parentPage && {testID:`${parentPage}-add-article`})}
                                />
                            )}
                        </Mutation>
                    )}
                </ArticleConceptList>
            )}
        </RoutablePanel>
    );
};

export default withRouter(AddGlobalArticlePanel);
