import AddMarketingCard from './AddMarketingCard';
import React from 'react';
import { Route } from 'react-router-dom';
import appRoutes from 'Router/routes';
import CommunicationPage from './CommunicationPage';
import PublishMarketingCard from './PublishMarketingCard';

function routingCommunicationsPage() {
    return (
        <>
            <Route exact path={appRoutes.communication.list()} component={CommunicationPage} />
            <Route exact path={appRoutes.communication.add()} component={AddMarketingCard} />
            <Route path={appRoutes.communication.edit()} component={AddMarketingCard} />
            <Route exact path={appRoutes.communication.added()} component={PublishMarketingCard} />
        </>
    );
}

export default routingCommunicationsPage;
