import styled from 'styled-components';

import {Warning, WarningFilled} from '../Icons';

export const Wrapper = styled.div`
    width: 100%;
    padding: ${({ theme }) => theme.spacing.s}px;
    margin-top: ${({ theme }) => theme.spacing.s}px;
    background-color: ${({ theme }) => theme.color.grey[0]};
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    color: ${({ theme }) => theme.color.common.darkRed};
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
`;

export const WrapperLighter = styled.div`
    display: flex;
    width: 100%;
    font-weight: lighter;
    padding-top: 0;
    padding-left: 0; 
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    color: ${({ theme }) => theme.color.common.red};
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
`;

export const WrapperWarning = styled.div`
    display: flex;
    width: 100%;
    font-weight: lighter;
    padding-top: 0;
    padding-left: 0; 
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    color: ${({ theme }) => theme.color.common.navy};
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
`;

export const StyledIcon = styled(Warning)`
    position: relative;
    top: 4px;
    margin-right: 6px;
`;

export const StyledIconLighter = styled(Warning)`
    margin-right: 6px;
`;

export const StyledIconWarning= styled(WarningFilled)`
    margin-right: 6px;
`;

export const Text = styled.span`
    line-height: 1.4;
`;

export const TextLighter = styled.div`
    line-height: 1.4;
    margin-bottom: ${({ theme }) => theme.spacing.xxs}px;
`;

export const MultiErrorsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const IconWrapper = styled.div`
    display: inline-flex;
`;