import React, { useState, ReactNode } from 'react';
import { Struct } from 'components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface IDayProps {
    displayText: string;
    checked: boolean;
    disabled?: boolean;
    onClick?: (e) => void;
}

export function DayItem({ displayText, checked, onClick, disabled = false }: IDayProps) {
    return (
        <Day checked={checked} onClick={onClick} disabled={disabled}>
            {displayText}
        </Day>
    );
}

interface IProps {
    activeDays: Array<number>;
    inactiveDays?: Array<number>;
    children?: string | ReactNode;
    multiSelect?: boolean;
    dayValues?: number[];
    onChange: (value: any) => void;
    className?: string;
}

const DayPicker = ({
    multiSelect,
    activeDays,
    inactiveDays = [],
    children,
    onChange,
    className,
    dayValues = [0, 1, 2, 3, 4, 5, 6],
}: IProps) => {
    const [selected, setSelected] = useState(new Set(activeDays));
    const handleSelectChange = (e, value: number) => {
        e.preventDefault();
        if (multiSelect) {
            if (selected.has(value)) {
                selected.delete(value);
            } else {
                selected.add(value);
            }
        } else {
            selected.clear();
            selected.add(value);
        }
        setSelected(selected);
        onChange(Array.from(selected));
    };
    const [t] = useTranslation();
    const days = dayValues.map((d, i) => ({ key: t(`app:brefWeekDays.${i}`), value: d }));
    return (
        <Wrapper className={className}>
            <Struct.Top>
                <DayWrapper>
                    {days.map(day => (
                        <DayItem
                            key={day.key}
                            displayText={day.key}
                            disabled={inactiveDays.includes(day.value)}
                            onClick={(e) => {
                                handleSelectChange(e, day.value);
                            }}
                            checked={selected.has(day.value)}
                        />
                    ))}
                </DayWrapper>
            </Struct.Top>
            {children}
        </Wrapper>
    );
};

export default DayPicker;

const Wrapper = styled.div`
    flex-grow: 1;
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    background-color: ${({ theme }) => theme.color.common.white};
`;

const DayWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing.xs}px;
`;

interface ICheckProps {
    disabled: boolean;
    checked: boolean | null | undefined;
}

const Day = styled.button<ICheckProps>`
    height: ${({ theme }) => theme.dimension.height.element}px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 ${({ theme }) => theme.spacing.s}px;
    margin: ${({ theme }) => theme.spacing.xxs}px;
    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    width: 10px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    background-color: ${({ theme, checked }) => (checked ? theme.color.common.blue : theme.color.grey[0])};
    color: ${({ theme, checked }) => (checked ? theme.color.common.white : theme.color.grey[6])};
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    box-shadow: ${({ theme, checked }) => (checked ? theme.boxShadow[0] : 'none')};
    opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
`;
