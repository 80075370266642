import { Component } from 'react';
import Auth from 'services/auth0';

class LoginComponent extends Component<{}> {
    // Remove this to stop redirecting to authentication directly
    async componentDidMount() {
        Auth.signIn();
    }

    render() {
        return null;
    }
}

export default LoginComponent;
