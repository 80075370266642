import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Table, Icon } from 'components';
import { OfferItem } from './OfferItemTable';
import { toast } from 'components/Toast';
import Info, { InfoType } from 'components/Info';
import { OfferItemFormulaToSell } from 'types/globalTypes';

export const formulasKey = 'FORMULA';

const UPDATE_FORMULA_ITEM_MUTATION = loader('../query/updateOfferItemFormula.gql');

const FormulaLines = ({
    item,
    toggleCatalogModal,
    formulaArticles,
    onUpdate = (value?: any, isDelete?: boolean) => {},
    hasContainerColumn,
    valuesAtZero,
    disabledFormulaItemsStock,
    updateOfferItem,
}) => {
    const [updateOfferItemFormula] = useMutation(UPDATE_FORMULA_ITEM_MUTATION)
    const { t } = useTranslation();
    const [isStepsSectionOpen, setIsStepsSectionOpen] = useState(true);


    const divideItemsBySteps = (items, stepsLength) => items.reduce(function (acc, curr) {
        const items = acc?.[curr?.stepNumber]?.length ? [...acc[curr.stepNumber], curr] : [curr]
        !!acc?.[curr?.stepNumber] && (acc[curr.stepNumber] = items);
        return acc;
      }, new Array(stepsLength).fill([]));


    const formulaItemsByStep = useMemo(
        () => !!formulaArticles?.length && !!item?.steps?.length ? divideItemsBySteps(formulaArticles, item.steps.length)  : [], [formulaArticles]
    );

    const handleFormulaItemStock = async (id: string, quantityOverall: number, isStockShared?: boolean) => {
        const res = await updateOfferItemFormula({variables: { id, quantityOverall}})
        isStockShared && toast({ toastMessage: 'UPDATE_COMMON_STOCK', type: 'info' });
        if (!!res?.data?.updateOfferItemFormula?.id) {
            onUpdate(id, true)
        }
    }

    const handleFormulaItemsStockInputDisable = (item) => disabledFormulaItemsStock.some((addedItem) =>
            addedItem.idLocalArticle === item.localArticle.id && item.id !== addedItem.id
        )

    const itemsTotalQuantityByStep = !!formulaItemsByStep.length
        ? formulaItemsByStep.map((step) => step?.reduce((acc, curr) => acc + curr?.quantityRemaining, 0))
        : [0];
    const isFormulaIncomplete = item.quantityRemaining === 0 || itemsTotalQuantityByStep.some((quantity) => quantity <= 0);


    const tags = [
        isStepsSectionOpen
            ? <CollapseIconWrapper onClick={() => setIsStepsSectionOpen(false)}><Icon.ArrowUp /></CollapseIconWrapper>
            : <CollapseIconWrapper onClick={() => setIsStepsSectionOpen(true)}><Icon.ArrowDown /></CollapseIconWrapper>,
        isFormulaIncomplete && (
        <IncompleteTagWrapper><Info display={InfoType.INCOMPLETE}>
            <>{t('page:clickcollect.daily-offers.formulaIncompleteProductTag')}</>
        </Info></IncompleteTagWrapper>
        )
    ]

    const onToSellUpdate = (id: string, toSell: OfferItemFormulaToSell) => {
        updateOfferItemFormula({
            variables: {
                id,
                quantityOverall: item.quantityOverall,
                toSell
            },
        });
    };

    return (
        <>
            <FormulaTitleContainer isIncomplete={isFormulaIncomplete}>
                <OfferItem
                    boldLabel
                    item={{
                        inheritedLabel: item.formulaTemplate?.name,
                        quantityRemaining: item.quantityRemaining,
                        quantityOverall: item.quantityOverall,
                        id: item.id,
                        // @ts-ignore
                        localArticle: { container: item.container },
                        isFormulaItem: true,
                        toSell: item.toSell
                    }}
                    onChange={(item) => {onUpdate(item)}}
                    onSubmit={({ id, quantityOverall, value }) => {
                        handleFormulaItemStock(id, quantityOverall + Number(value));
                    }}
                    valuesAtZero={valuesAtZero}
                    hasContainerColumn={hasContainerColumn}
                    tags={tags}
                    OfferItemToSell={onToSellUpdate}
                />
            </FormulaTitleContainer>
            {isStepsSectionOpen &&
                <tr>
                    <StepsContainer colSpan={100}>
                        {item.steps?.map(({ name, order, maxProducts }) => {
                            const stepName = t('page:clickcollect.formula.stepName', {
                                stepOrder: order + 1,
                                stepName: name,
                            });

                            const stepMaxProducts = t('page:clickcollect.formula.stepMaxProducts', {
                                stepMaxProducts: maxProducts,
                            });

                            const stepArticles = formulaItemsByStep?.[order] || [];

                            return (
                                <>
                                    <FormulaStep key={`formula_step_${name}_${order}`}>
                                        {stepName}
                                        {stepMaxProducts}
                                        <StepAddProduct onClick={() => toggleCatalogModal({idOfferItemFormula: item.id, stepNumber: order, stepArticles })}>
                                            {t('page:clickcollect.formula.addProducts')}
                                        </StepAddProduct>
                                    </FormulaStep>
                                    {!!stepArticles.length && <SeparatorLine />}
                                    <Table
                                        renderLine={(item : any) =>
                                            <tr>
                                                <OfferItem
                                                  // @ts-ignore
                                                    item={{
                                                        inheritedLabel: item.localArticle.label,
                                                        localArticle: item.localArticle,
                                                        quantityRemaining: item.quantityRemaining,
                                                        quantityOverall: item.quantityOverall,
                                                        id: item.id,
                                                    }}
                                                    onChange={(data) => {
                                                        onUpdate({
                                                            ...data,
                                                            idLocalArticle: item.localArticle.id,
                                                            idOfferItem: item?.idOfferItem,
                                                            isStockShared: item?.isStockShared
                                                        })
                                                    }}
                                                    onSubmit={({ id, quantityOverall, value }) => {
                                                        if(!!item?.idOfferItem) {
                                                            updateOfferItem({
                                                                variables: {
                                                                    id: item?.idOfferItem,
                                                                    quantityOverall: quantityOverall + Number(value),
                                                                }
                                                            });
                                                            onUpdate(id, true);
                                                            toast({ toastMessage: 'UPDATE_COMMON_STOCK', type: 'info' });
                                                            return;
                                                        }
                                                        handleFormulaItemStock(id, quantityOverall + Number(value), item?.isStockShared);
                                                    }}
                                                    valuesAtZero={valuesAtZero}
                                                    hasContainerColumn={hasContainerColumn}
                                                    disableStockInput={handleFormulaItemsStockInputDisable(item)}
                                                    disableToogleToSell={true}
                                                />
                                            </tr>
                                        }
                                        data={stepArticles}
                                    />
                                </>
                            );
                        })}
                    </StepsContainer>
                </tr>
            }
        </>
    );
};

export const FormulasTable = ({
    isLoading,
    formulasList,
    toggleCatalogModal,
    formulasArticlesList,
    onUpdate,
    hasContainerColumn,
    valuesAtZero,
    disabledFormulaItemsStock,
    updateOfferItemMutation
}) => {
    const { t } = useTranslation();

    const filterFormulaArticlesByParent = (idOfferItemFormulaParent) =>
        formulasArticlesList.filter((formulaArticle) => formulaArticle.idOfferItemFormulaParent === idOfferItemFormulaParent);

    return !isLoading && !!formulasList?.length ? (
        <Table
            groupBy={{
                key: 'family',
                options: [
                    {
                        id: formulasKey,
                        label: t('page:clickcollect.formula.title'),
                        greyVariation: true
                    },
                ],
            }}
            renderLine={(item) => (
                <FormulaLines
                    item={item}
                    // @ts-ignore
                    formulaArticles={filterFormulaArticlesByParent(item.numericId)}
                    toggleCatalogModal={toggleCatalogModal}
                    hasContainerColumn={hasContainerColumn}
                    valuesAtZero={valuesAtZero}
                    onUpdate={onUpdate}
                    disabledFormulaItemsStock={disabledFormulaItemsStock}
                    updateOfferItem={updateOfferItemMutation}
                />
            )}
            data={formulasList}
        />
    ) : null;
};

type FormulaTitleContainerProps = {
    isIncomplete?: boolean
}

const FormulaTitleContainer = styled.tr<FormulaTitleContainerProps>`
    height: 60px !important; // this is being set by the main table style so we need to overwrite it
    background-color: ${({ isIncomplete, theme }) => isIncomplete ? theme.color.common.lightRed : ''}
`;

const StepsContainer = styled.td`
    padding-left: 0 !important;
    border-top: none !important; // this is being set by the main table style so we need to overwrite it

    & > div:not(:first-child) {
        border-top: 1px solid ${(props) => props.theme.color.grey[2]};
    }
`;
const FormulaStep = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 20px;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-weight: ${({ theme }) => theme.typography.fontWeight.bolder};
`;

const StepAddProduct = styled.span`
    color: #2196f3;
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    line-height: 13px;
    margin-left: 10px;
    cursor: pointer;
`;

const SeparatorLine = styled.div`
    border-top: 1px solid ${({ theme }) => theme.color.grey[6]};
`;

const IncompleteTagWrapper = styled.span`
    margin-left: 10px;
`;

const CollapseIconWrapper = styled.span`
    margin-top: 10px;
    cursor: pointer;
    vertical-align: middle;
`;
