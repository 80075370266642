import { Icon } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme, DefaultTheme } from 'styled-components';

import { PageWithTabs } from 'components/Layout';
import appRoutes from 'Router/routes';
import GlobalCatalogPage from './GlobalCatalogPage';

interface IProps {
    theme: DefaultTheme;
}

const getRoutes = (t: any) => [
    {
        tabKey: 'catalog',
        tabLabel: t('page:globalCatalog.tab'),
        path: appRoutes.globalCatalog.list,
        component: GlobalCatalogPage,
    },
];

const GlobalCatalog = ({ theme }: IProps) => {
    const { t } = useTranslation();

    return (
        <PageWithTabs
            routes={getRoutes(t)}
            indexRoute={appRoutes.globalCatalog.index()}
            title={t(`app:app.globalCatalog`)}
            Icon={<Icon.GlobalCatalog color={theme.color.grey[6]} />}
        />
    );
};

export default withTheme(GlobalCatalog);
