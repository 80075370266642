import { loader } from 'graphql.macro';
import React from 'react';
import { client } from 'services/apollo/client';
import styled from 'styled-components';

const UPDATE_MARKETING_CARD_STATE = loader('./query/updateMarketingCardState.gql');

interface ITabsProps {
    id: string;
    checked: boolean;
    onChange: Function;
    className?: string;
    disabled?: boolean;
}

export default function ToggleSwitchMarketingCard({ id, checked, onChange, className, disabled }: ITabsProps) {
    async function onClick() {
        await client.mutate({
            mutation: UPDATE_MARKETING_CARD_STATE,
            variables: { id, isActive: !checked },
        });
        !disabled && onChange();
    }
    return (
        <Switch aria-label="Toggle" className={className}>
            <Checkbox disabled={disabled} checked={checked} onClick={onClick} onChange={() => {}} type="checkbox" />
            <Slider />
        </Switch>
    );
}

const width = 40;
const height = 22;
const sliderSize = 16;
const borderWidth = 1;
const padding = (height - sliderSize - 2 * borderWidth) / 2;

const Switch = styled.label`
    position: relative;
    width: ${width}px;
    height: ${height}px;
    display: inline-block;
`;
const Slider = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.grey[1]};
    border: ${borderWidth}px solid ${({ theme }) => theme.color.grey[2]};
    border-radius: ${({ theme }) => theme.borderRadius.l}px;
    transition: background-color ${({ theme }) => theme.transition.duration[1]}ms;
    &:before {
        position: absolute;
        content: '';
        height: ${sliderSize}px;
        width: ${sliderSize}px;
        left: ${padding}px;
        bottom: ${padding}px;
        background-color: ${({ theme }) => theme.color.grey[5]};
        border-radius: 50%;
        transition: transform ${({ theme }) => theme.transition.duration[1]}ms,
            background-color ${({ theme }) => theme.transition.duration[1]}ms;
    }
`;

// @ts-ignore
const Checkbox = styled.input`
    width: 0;
    height: 0;
    opacity: 0;
    &:checked + ${Slider} {
        background-color: ${({ theme }) => theme.color.common.blue};
    }
    &:checked + ${Slider}:before {
        transform: translateX(${sliderSize}px);
        background-color: ${({ theme }) => theme.color.common.white};
    }
    &:disabled + ${Slider} {
        cursor: not-allowed;
        opacity: ${({ theme }) => theme.opacity.disabled};
    }
`;
