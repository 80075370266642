import { PosSortingStrategy } from "types/globalTypes";


const i18nKeyMapper: { [key in PosSortingStrategy]: string } = {
    [PosSortingStrategy.Default]: 'page:holding.preferences.posSortingStrategy.default',
    [PosSortingStrategy.Alphabetical]: 'page:holding.preferences.posSortingStrategy.alphabetical',
    [PosSortingStrategy.Customized]: 'page:holding.preferences.posSortingStrategy.customized',
}
export const posSortingStrategyMapper = (sortingStrategy: PosSortingStrategy): string =>
    i18nKeyMapper[sortingStrategy];


export default posSortingStrategyMapper;