import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme, DefaultTheme } from 'styled-components';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Input } from './TextInput';
import { Icon } from 'components';

export type IProps = {
    disabled: boolean;
    onSelect: Function;
    className?: string;
    theme: DefaultTheme;
};

function getElement(addressComponents: any[], element: string) {
    const details = addressComponents.find((component: any) => component.types.includes(element));
    return details ? details.long_name : '';
}

const LocationSearchInput = ({ onSelect, disabled, className, theme }: IProps) => {
    const { t } = useTranslation();
    const [address, setAddress] = useState('');

    if (disabled && address !== '') {
        setAddress('');
    }

    const handleChange = (address: string) => {
        setAddress(address);
    };

    const handleSelect = async (address: string) => {
        setAddress('');
        try {
            const results = await geocodeByAddress(address);
            const addressComponents = results[0].address_components;
            const streetNumber = getElement(addressComponents, 'street_number');
            const streetName = getElement(addressComponents, 'route');
            const address1 = streetNumber ? `${streetNumber} ${streetName}` : streetName;
            const city = getElement(addressComponents, 'locality');
            const zip = getElement(addressComponents, 'postal_code');

            const { lat: latitude, lng: longitude } = await getLatLng(results[0]);
            // needs street name for valid addresses
            streetName &&
                onSelect({
                    address1,
                    address2: '',
                    city,
                    zip,
                    latitude,
                    longitude,
                });
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <PlacesAutocomplete
            searchOptions={{ componentRestrictions: { country: 'fr' } }}
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                return (
                    <Wrapper>
                        {
                          // @ts-ignore
                          <Input
                            {...getInputProps({
                                placeholder: t('component:locationSearchInput.placeholder'),
                                className,
                            })}
                            disabled={disabled}
                          />
                        }
                        <AutocompleteContainer>
                            {loading && <Icon.Spinner />}
                            {suggestions.map((suggestion) => {
                                return (
                                    <Suggestion
                                        {...(getSuggestionItemProps(suggestion, {
                                            style: {
                                                backgroundColor: suggestion.active
                                                    ? theme.color.input.backgroundColorSelected
                                                    : theme.color.input.backgroundColor,
                                                color: suggestion.active
                                                    ? theme.color.common.white
                                                    : theme.color.input.textColor,
                                            },
                                        }) as any)}
                                    >
                                        <span>{suggestion.description}</span>
                                    </Suggestion>
                                );
                            })}
                        </AutocompleteContainer>
                    </Wrapper>
                );
            }}
        </PlacesAutocomplete>
    );
};

const Wrapper = styled.div`
    position: relative;
`;

const AutocompleteContainer = styled.div`
    position: absolute;
    background-color: ${({ theme }) => theme.color.input.backgroundColor};
    z-index: 1;
    width: 100%;
`;

const Suggestion = styled.div`
    cursor: pointer;
    padding: ${({ theme }) => theme.spacing.xs}px;
`;

export default withTheme(LocationSearchInput);
