import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Section, Card, Header } from './Struct';

interface IProps {
    children?: ReactElement | ReactElement[];
    mode: 'H1' | 'H2' | 'H3' | 'H4';
    value: string | ReactElement | null;
    subTitle?: string | ReactElement | null;
    icon?: ReactElement;
    grow?: boolean;
    className?: string;
    type?: string;
    testID?: string;
}

export default function Title(props: IProps) {
    const Title = { H1: H1, H2: H2, H3: H3, H4: H4 }[props.mode];
    return (
        <TitleGroup haveIcon={!!props.icon} className={props.className} id='titleGroup'>
            {!!props.icon && <Icon>{props.icon}</Icon>}
            <Title data-test={props.testID} type={props.type}>{props.value}</Title>
            {!!props.subTitle || props.grow ? <SubTitle>{props.subTitle}</SubTitle> : null}
            {props.children}
        </TitleGroup>
    );
}

interface ITitleGroupProps {
    haveIcon: boolean;
}
const TitleGroup = styled.div<ITitleGroupProps>`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
    margin-bottom: ${({ theme }) => theme.spacing.s}px;
    & > * {
        margin-right: ${({ theme }) => theme.spacing.s}px;
    }
    & > *:last-child {
        margin-right: 0px;
    }
    flex-wrap: wrap;
    ${Section} > &:first-child {
        margin-left: ${({ haveIcon }) => (haveIcon ? '-29px' : '-20px')};
    }

    ${Card} > & {
        & > *:first-child {
            margin-right: ${({ haveIcon }) => (haveIcon ? '10px' : '0')};
        }
    }
    ${Header} > & {
        margin-top: ${({ theme }) => theme.spacing.s}px;
    }
`;

const SubTitle = styled.div`
    color: ${(props) => props.theme.color.grey[6]};
    flex-grow: 1;
    text-align: center;
    font-size: 20px;
`;

interface TitlePropsProps {
    inactive?: boolean;
    type?: string;
}
const Icon = styled.span`
    flex-shrink: 0;
    margin-right: 10px !important;
`;
export const H1 = styled.h1<TitlePropsProps>`
    flex-shrink: 0;
    height: ${({ theme }) => theme.dimension.height.element}px;
    color: ${({ theme, inactive }) => (inactive ? theme.color.grey[4] : theme.color.common.navy)};
    font-family: ${({ theme }) => theme.typography.fontFamilySecondary};
    font-size: ${({ theme }) => theme.typography.fontSizeXXL}px;
`;

export const H2 = styled.h2<TitlePropsProps>`
    flex-shrink: 0;
    color: ${({ theme, inactive }) => (inactive ? theme.color.grey[4] : theme.color.common.navy)};
    font-family: ${({ theme }) => theme.typography.fontFamilySecondary};
    font-size: ${({ theme }) => theme.typography.fontSizeXL}px;
`;

export const H3 = styled.h3<TitlePropsProps>`
    flex-shrink: 0;
    color: ${({ theme, inactive, type }) => (inactive ? theme.color.grey[4] : type === 'warning' ? theme.color.common.red : theme.color.common.navy)};
    font-family: ${({ theme }) => theme.typography.fontFamilySecondary};
    font-size: ${({ theme }) => theme.typography.fontSizeL}px;
`;

export const H4 = styled.h4<TitlePropsProps>`
    flex-shrink: 0;
    color: ${({ theme, inactive, type }) => (inactive ? theme.color.grey[4] : type === 'warning' ? theme.color.common.red :  theme.color.common.navy)};
    font-family: ${({ theme }) => theme.typography.fontFamilySecondary};
    font-size: ${({ theme }) => theme.typography.fontSizeM}px;
    max-width: 850px;
`;
