import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { appTheme } from 'theme';

export interface IToggleProps {
    onChange: (event: MouseEvent) => void;
    className?: string;
    checked: boolean;
    onColor?: any;
    offColor?: any;
    onLabel: string;
    offLabel: string;
    disabled?: boolean;
    width?: number;
    testID?: string;
}

interface IStateProps {
    color: any;
}

function Toggle({
    checked,
    onColor = appTheme.color.common.green,
    offColor = appTheme.color.common.red,
    onLabel,
    offLabel,
    onChange,
    className,
    disabled,
    width,
    testID
}: IToggleProps) {

    return (
        <Switch {...(testID && {'data-test':`${testID}-toggle`})} disabled={!!disabled} aria-label="Toggle" className={className} width={width}>
            <Checkbox disabled={!!disabled} checked={checked} onClick={onChange} onChange={() => {}} type="checkbox" />
            <Off color={offColor}>{offLabel}</Off>
            <On color={onColor}>{onLabel}</On>
        </Switch>
    );
}

const height = 40;
const switchWidth = 160;

interface ISwitchProps {
    disabled: boolean;
    width?: number;
}

const Switch = styled.label<ISwitchProps>`
    display: flex;
    width: ${({ width = switchWidth }) => width}px;
    height: ${height}px;
    background-color: ${({ theme }) => theme.color.common.white};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ disabled, theme }) => (disabled ? theme.opacity.disabled : 1)};
`;

export const State = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    color: ${({ theme }) => theme.color.grey[4]};
    text-transform: capitalize;
    transition: background-color ${({ theme }) => theme.transition.duration[0]}ms;
`;

const Checkbox = styled.input`
    display: none;
    margin: 0;
    width: 0;
    height: 0;
    opacity: 0;
`;

const On = styled(State as any)<IStateProps>`
        ${Checkbox}:checked ~ & {
            background-color: ${({ color }) => color};
            color: ${({ theme }) => theme.color.common.white};
            font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
        }
    `;
const Off = styled(State as any)<IStateProps>`
        ${Checkbox}:not(:checked) ~ & {
            background-color: ${({ color }) => color};
            color: ${({ theme }) => theme.color.common.white};
            font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
        }
    `;

export default Toggle;
