import { loader } from 'graphql.macro';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import { transformMealHeartQuantityRule } from 'services/mealHeartService';
import { convertObjectToISO } from 'services/dateService';
import { createOfferTemplateVariables } from 'types/createOfferTemplate';
import AddOrEditOfferTemplateForm, { formData } from './AddOrEditForm';
import { getPaymentTypes } from 'services/offerTemplateService';
import { OfferTemplateWithdrawalType } from 'types/globalTypes';

interface IPropsPanel extends RouteComponentProps<{ idHolding: string; idPos: string }> {
    beforeClose: () => void;
}

const AddOfferTemplatePage = ({
    history: { push },
    location: { search },
    match: {
        params: { idHolding, idPos },
    },
}: IPropsPanel) => {
    const onClose = () => {
        push(`${routes.cnc.offers.list(idHolding, idPos)}${search}`);
    };
    return (
        <Mutation mutation={loader('./query/createOfferTemplate.gql')}>
            {(createTemplate: (param: Record<'variables', createOfferTemplateVariables>) => Promise<any>) => (
                <AddOrEditOfferTemplateForm
                    offerTemplate={null}
                    isModeEdit={false}
                    idHolding={idHolding}
                    idPos={idPos}
                    onSubmit={(values: formData) => {
                        const {
                            pos,
                            name,
                            description,
                            fullDescription,
                            salesType,
                            withdrawalType,
                            period,
                            orderStartDefaultDaily,
                            orderEndDefaultDaily,
                            withdrawStartDefault,
                            withdrawEndDefault,
                            image,
                            withdrawSlotDuration,
                            minCancellationDelay,
                            minPreparationDelay,
                            published,
                            enableComment,
                            enableWithdrawalTypeSelection,
                            isRoomService,
                            translations,
                            languages,
                            mealHeartRule,
                            mealHeartQuantityRule,
                            orderPerTimeSlot,
                            daysInAdvance,
                            pickupPoints,
                            // payment methodes
                            badge,
                            cc,
                            edenred,
                            onsite,
                            admission,
                            fee,
                        } = values;

                        const validatedTranslations = translations.filter((onlineContent) =>
                            languages.includes(onlineContent.language)
                        );

                        const quantityRules = mealHeartQuantityRule
                            ? transformMealHeartQuantityRule(mealHeartQuantityRule)
                            : [];

                        const useIsInAdvanceComponent = !(
                            withdrawalType === OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT ||
                            withdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE ||
                            withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE);

                        if (withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE) {
                            createTemplate({
                                variables: {
                                    idPos: pos,
                                    name,
                                    description,
                                    fullDescription,
                                    salesType,
                                    withdrawalType,
                                    period,
                                    orderStartDefault: orderStartDefaultDaily,
                                    orderEndDefault: orderEndDefaultDaily,
                                    withdrawStartDefault: orderStartDefaultDaily,
                                    withdrawEndDefault: orderEndDefaultDaily,
                                    maxOrdersPerSlotDefault: orderPerTimeSlot ? orderPerTimeSlot.max : 1,
                                    idImage: image.id,
                                    timezone: 'Europe/Paris',
                                    withdrawSlotDuration: withdrawSlotDuration
                                        ? convertObjectToISO(withdrawSlotDuration)
                                        : 'PT5M',
                                    minCancellationDelay: minCancellationDelay
                                        ? convertObjectToISO(minCancellationDelay)
                                        : 'PT5M',
                                    minPreparationDelay: minPreparationDelay
                                        ? convertObjectToISO(minPreparationDelay)
                                        : 'PT5M',
                                    published: published,
                                    enableComment,
                                    enableWithdrawalTypeSelection,
                                    isRoomService,
                                    translations: validatedTranslations,
                                    selectedFamilies: mealHeartRule.selectedFamilies,
                                    quantityRules,
                                    daysInAdvanceStart: daysInAdvance.min,
                                    daysInAdvanceEnd: daysInAdvance.max,
                                    paymentTypes: getPaymentTypes(badge,cc,edenred,onsite),
                                    pickupPoints,
                                    admission,
                                    fee,
                                },
                            });
                        } else {
                            createTemplate({
                                variables: {
                                    idPos: pos,
                                    name,
                                    description,
                                    fullDescription,
                                    salesType,
                                    withdrawalType,
                                    period,
                                    orderStartDefault: useIsInAdvanceComponent ? daysInAdvance.orderStartDefaultDaily : orderStartDefaultDaily,
                                    orderEndDefault: useIsInAdvanceComponent ? daysInAdvance.orderEndDefaultDaily : orderEndDefaultDaily,
                                    withdrawStartDefault: withdrawalType !== OfferTemplateWithdrawalType.CLICK_AND_PICK_UP ? withdrawStartDefault : orderStartDefaultDaily,
                                    withdrawEndDefault: withdrawalType !== OfferTemplateWithdrawalType.CLICK_AND_PICK_UP ? withdrawEndDefault : orderEndDefaultDaily,
                                    maxOrdersPerSlotDefault: orderPerTimeSlot.max,
                                    idImage: image.id,
                                    timezone: 'Europe/Paris',
                                    withdrawSlotDuration: withdrawSlotDuration
                                        ? convertObjectToISO(withdrawSlotDuration)
                                        : 'PT5M',
                                    minCancellationDelay: minCancellationDelay
                                        ? convertObjectToISO(minCancellationDelay)
                                        : 'PT5M',
                                    minPreparationDelay: minPreparationDelay
                                        ? convertObjectToISO(minPreparationDelay)
                                        : 'PT5M',
                                    published: published,
                                    enableComment,
                                    enableWithdrawalTypeSelection,
                                    isRoomService,
                                    translations: validatedTranslations,
                                    selectedFamilies: mealHeartRule.selectedFamilies,
                                    quantityRules,
                                    daysInAdvanceStart: daysInAdvance.min,
                                    daysInAdvanceEnd: daysInAdvance.max,
                                    paymentTypes: getPaymentTypes(badge,cc,edenred,onsite),
                                    pickupPoints,
                                    admission,
                                    fee,
                                },
                            });
                        }
                        onClose();
                    }}
                />
            )}
        </Mutation>
    );
};

export default withRouter(AddOfferTemplatePage);
