import { MealHeartQuantityRule } from "pages/ClickCollect/ClickCollectPage/AddOrEditForm";
import { QuantityRuleType } from "../types/globalTypes";

/**
 Serializing array of objects, into and a parent object, with properties as name, and array with min/max value inside:

    [
        {
            "property":"mealQuantity",
            "min":1,
            "max":10
        },
        ...
    ]

    INTO
    {
        mealQuantity: { min: 1, max: 10 },
        ...
    }
*/

interface IMealHeartRule {
    property: QuantityRuleType;
    min: number;
    max: number;
}
export function serializeIQuantityRulesIntoFormValue(mealHeartRules : IMealHeartRule []): Partial<MealHeartQuantityRule> {
    const quantityRuleObjectSerialized = {};

    mealHeartRules.forEach(rule => {
            quantityRuleObjectSerialized[rule.property] = { min: rule.min, max: rule.max }
        })
    
    return quantityRuleObjectSerialized;
}

export function transformMealHeartQuantityRule(mealHeartQuantityRule: MealHeartQuantityRule) {
    return Object.keys(mealHeartQuantityRule).map((key) => ({
        property: key,
        max: mealHeartQuantityRule[key].max,
        min: mealHeartQuantityRule[key].min,
    }));
}
