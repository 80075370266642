import { DateTime, Interval } from 'luxon';

const DEFAULT_NUMB_OF_DAYS_DISPLAYED = 5;
// we don't count the 1st day of the carousel for this
const NUMB_OF_DAYS_TIL_CAROUSEL_LIMIT = 4;


export const getStartDateFromInterval = (range, inJSDate = false) => {
  const startDate = Interval.fromISO(range).start;
  return inJSDate ? new Date(startDate.toISO()) : startDate;
}

export const getCarouselFirstDay = (offersToCheck) => {
  if(offersToCheck === undefined) return new Date();

  const todayDate = DateTime.now().startOf('day');
  const futureDate = DateTime.now().plus({days: 5}).startOf('day');
  const offerStartingDate = getStartDateFromInterval(offersToCheck[0].withdrawRange) as DateTime;
  const offerExistInInterval = Interval.fromDateTimes(todayDate, futureDate).contains(offerStartingDate);

  return offerExistInInterval ? new Date() : new Date(offerStartingDate.toISO())
};

export const getDatesIntervalISO = (startingDate = new Date(), pageNumber = 0) => {
  const newCarouselLimitStart = new Date();
  const daysToIncrease = DEFAULT_NUMB_OF_DAYS_DISPLAYED * pageNumber;
  newCarouselLimitStart.setDate(startingDate.getDate() + daysToIncrease);
  newCarouselLimitStart.setUTCHours(0, 0, 0, 0);

  const newCarouselLimitEnd = new Date(newCarouselLimitStart);
  newCarouselLimitEnd.setDate(newCarouselLimitStart.getDate() + NUMB_OF_DAYS_TIL_CAROUSEL_LIMIT);
  newCarouselLimitEnd.setUTCHours(23, 59, 59, 59);

  return Interval.fromDateTimes(newCarouselLimitStart, newCarouselLimitEnd).toISO();
};
