import React from 'react';
import styled from 'styled-components';
import TimePicker from 'react-time-picker';
import { appTheme } from 'theme';
import { Icon } from 'components';
import { useTranslation } from 'react-i18next';

interface IProps {
    startTime?: string | undefined;
    endTime?: string | undefined;
    onChangeStartTime?: (value) => void;
    onChangeEndTime?: (value) => void;
    name?: string;
    values?: any; 
}


const TimePickerInput = ({ startTime, endTime, onChangeStartTime, onChangeEndTime , values}: IProps) => {

    const { t } = useTranslation();

    return (
        <>
            <TimePickerLabel>
                <StyledLabel> de </StyledLabel>
                <StyledTimePicker startTime={startTime} endTime={endTime}
                    name="startTime"
                    value={startTime}
                    onChange={onChangeStartTime}
                    clearIcon={true}
                    clockIcon={null}
                    disableClock={true}
                />
                <StyledLabel> à </StyledLabel>
                <StyledTimePicker startTime={startTime} endTime={endTime}
                    name='endTime'
                    value={endTime}
                    onChange={onChangeEndTime}
                    clearIcon={true}
                    clockIcon={null}
                    disableClock={true}
                />
            </TimePickerLabel>

            {(!startTime || !endTime) && (
                <ErrorWrapper>
                    <IconWrapper>
                        <Icon.Warning color={appTheme.color.common.red} />
                    </IconWrapper>
                    <ErrorText>{t('page:admin.cnc.required')}</ErrorText>
                </ErrorWrapper>
            )}

            {(startTime && endTime && startTime >= endTime) && (
                <ErrorWrapper>
                    <IconWrapper>
                        <Icon.Warning color={appTheme.color.common.red} />
                    </IconWrapper>
                    <ErrorText>{t('page:admin.cnc.form.error.invalidHour')}</ErrorText>
                </ErrorWrapper>
            )}
        </>
    )
};


interface IProps {
    startTime?: string;
    endTime?: string;
};

const TimePickerLabel = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: '#800080';
`;

const StyledLabel = styled.div`
    margin-left: 15px;
    margin-right: 15px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-weight: normal;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    letter-spacing: 0;
    line-height: ${({ theme }) => theme.typography.size.S}px;
`;

const StyledTimePicker = styled(TimePicker) <IProps>`
    display: flex;
    height: 40px;
    width: 89px;
    border: 1.2px solid ${({ startTime, endTime, theme }) => (startTime && endTime && startTime < endTime) ? theme.color.grey[2] : theme.color.common.red};
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    color: ${({ theme }) => theme.color.grey[6]};
    text-align: center;
    input {
        color: ${({ theme }) => theme.color.grey[6]};
    }
    background-color: ${({ startTime, endTime, theme }) => (startTime && endTime && startTime < endTime) ? 'none' : theme.color.common.backgroundRed};
`;

const ErrorWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    margin-top: 20px;
    background-color: ${({ theme }) => theme.color.grey[0]};
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
`;

const ErrorText = styled.span`
    font-size: ${({ theme }) => theme.typography.size.S}px;
    letter-spacing: 0;
    text-align: left;
    color: ${({ theme }) => theme.color.common.red};
    font-family: inherit;
    margin-left: ${({ theme }) => theme.spacing.xs}px;
`;

const IconWrapper = styled.div`
    margin: ${({ theme }) => `0 ${theme.spacing.xs}`};
    display: flex;
    align-content: center;
`;

export default TimePickerInput;