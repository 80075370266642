import React from 'react';
import styled, { withTheme, DefaultTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MaintenanceDefault } from 'resources/img/MaintenanceDefault';
import logo from "resources/img/logo.png";

interface IProps {
    theme: DefaultTheme;
}

const Maintenance = ({ theme }: IProps) => {
    const { t } = useTranslation();
    return (
        <>
        <Header>
            <img src={logo} width={114} alt="Foodi Services" />
        </Header>
        <Container>
            <ContainerText>
                <Title>
                    {t(`error:maintenance.title`)}
                </Title>
                <Text>
                    {t(`error:maintenance.mainMessage`)}
                </Text>
                <Text>
                    {t(`error:maintenance.backSoon`)}
                </Text>
            </ContainerText>
            <MaintenanceDefault />
        </Container>
        </>
    );
};

export default withTheme(Maintenance);

const Header = styled.div`
    padding: ${({ theme }) => theme.spacing.m}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }) => theme.color.common.white};
    background-color: ${({ theme }) => theme.color.common.navy};
`;

const Title = styled.div`
    color: ${({ theme }) => theme.color.common.navy};
    font-size: 30px;
    margin-top: 72px;
    margin-bottom: 2vh;
    line-height: 60px;
    font-weight: 600;
`;

const Text = styled.div`
    color: ${({ theme }) => theme.color.common.navy};
    font-size: 22px;
    margin-bottom: 8px;
`;

const Container = styled.div`
    margin-left: 150px;
`;

const ContainerText = styled.div`
    align-items: flex-start;
`;