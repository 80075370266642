import {
    Column,
    Icon,
    IconButton,
    Text,
    ImageInputField,
    QueryLoader,
    ErrorMessageLighter,
    InputLabel,
} from 'components';
import React, { useEffect, useState, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { loader } from 'graphql.macro';
import { Field } from 'formik';
import { appTheme } from 'theme';
import LanguageTextInput from 'components/LanguageTextInput';
import * as Yup from 'yup';
import { MarketingTypeNew, QuerySearchInput } from 'types/globalTypes';
import { QueryResult } from 'localTypes';
import { BadgeText, FlagSelector, ImageContainer, ImageWrapper, Preview, StyledBadge } from './commonStyles';
import { fixedTranslation } from 'services/translationService';
import { dataURItoFile } from '../../../utils';
import importantMessageBgEurest from '../../../resources/img/eurest.png';
import importantMessageBgExalt from '../../../resources/img/exalt.png';
import importantMessageBgFoodi from '../../../resources/img/foodi.png';
import importantMessageBgMedirest from '../../../resources/img/medirest.png';
import importantMessageBgPopote from '../../../resources/img/popote.png';
import importantMessageBgScolarest from '../../../resources/img/scolarest.png';
import { uploadImage, uploadImageVariables } from 'types/uploadImage';
import { useMutation } from '@apollo/client';

const PreviewImage = loader('../query/getImageForPreview.gql');

export const AVAILABLE_BACKGROUNDS = [
    importantMessageBgEurest,
    importantMessageBgExalt,
    importantMessageBgFoodi,
    importantMessageBgMedirest,
    importantMessageBgPopote,
    importantMessageBgScolarest
]

interface IImage {
    created: string;
    hash: string;
    id: string;
    path: string;
    __typename: string;
}
interface IImagePreviewProps {
    imageList: IImage[];
    importantMessage?: boolean;
    children: React.ReactNode;
}

const ImagePreview = ({ imageList, importantMessage, children }: IImagePreviewProps) => {
    return (
        <ImageContainer importantMessage={importantMessage}>
            <>
                {imageList.length > 0 ? (
                    <>
                        <QueryLoader
                            variables={{
                                querySearch: {
                                    key: 'id',
                                    value: imageList.map((idImage) => idImage.id).join(','),
                                    operator: '*',
                                } as QuerySearchInput,
                            }}
                            query={PreviewImage}
                        >
                            {({ data: { image } }: QueryResult<any>) =>
                                image.edges.map((element) => (
                                    <ImageWrapper
                                        key={element.node.path}
                                        src={`${window.config.IMAGE_BASE_URL}/resize/414x303/${element.node.path}`}
                                    />
                                ))
                            }
                        </QueryLoader>
                        { children ? <ImageChildWrapper>{children}</ImageChildWrapper> : null }
                    </>
                ) : (
                    !importantMessage && <Icon.ImagePlaceholder color="#2D586B" />
                )}
            </>
        </ImageContainer>
    );
};

interface IIMBackgroundPickerProps {
    availableBackgrounds: string[];
    setIdImageList: Function;
    setFieldValue?: Function;
    value: string;
    fieldName?: string;
    isModeEdit?: boolean;
}
export function IMBackgroundPicker({
    availableBackgrounds,
    setIdImageList,
    setFieldValue,
    value,
    fieldName = 'image',
    isModeEdit = false,
}: IIMBackgroundPickerProps): JSX.Element {
    const [uploadImage] = useMutation<uploadImage, uploadImageVariables>(loader('../../../query/uploadImage.gql'));
    const [imagesIds, setImagesIds] = useState<any[]>([]);
    const [selectedImageId, setSelectedImageId] = useState<any>('');

    useEffect(() => {
        if(!isModeEdit){
            handleBackgroundImages();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(isModeEdit && value && !selectedImageId) {
            handleBackgroundImages();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    //this component The very first time it runs uploads the availableBackgrounds to the database,
    //and after that, since the images already exists on the DB it only serves as a GET.
    //FIXME: In the future, maybe have the images only being set up on the database, and from there,
    //we display the images available in the Back Office
    const handleBackgroundImages = async () => {
        //upload the availableBackgrounds to the DB
        const temp = await Promise.all(
            availableBackgrounds.map(async (image) => {
                //convert the base64 Image to a blob and upload the file
                const file = dataURItoFile(image);
                const {data} = await uploadImage({ variables: { file } });
                return data && data.upload && data.upload.id;
            })
        );
        //add the returned ids to state
        setImagesIds(temp);
        if(!value){
             //if doesn't have value set the default image id to the form, the Preview and selectedBackground of the picker
            setFieldValue && temp.length > 0 && setFieldValue(fieldName, [{id: temp[0]}]);
            setIdImageList([{id: temp[0]}]);
            setSelectedImageId(temp[0])
        } else {
            //if has value the keep everything and set the selectedBackground for the picker
            setSelectedImageId(value)
        }
    };
    return (
        <>
            <Text size={'S'} color={appTheme.color.grey[6]}>
                {
                    <Trans
                        i18nKey="page:communication.addMarketingCard.ImportantMessageBackgroundColor"
                        components={[<strong />]}
                    />
                }
            </Text>
            <Row>
                {availableBackgrounds.map((background, i) => (
                    <ColorBox
                        key={`${i}`}
                        bgImage={background}
                        onClick={() => {
                            setFieldValue && setFieldValue(fieldName, [{id: imagesIds[i]}]);
                            setIdImageList([{id: imagesIds[i]}])
                            setSelectedImageId(imagesIds[i])
                        }}
                    >
                        {/* Check imageId from Form against the id from the selectedBackground image */}
                        {imagesIds[i] === selectedImageId && (
                            <Icon.Check
                                color={appTheme.color.common.white}
                                width={appTheme.dimension.width.small}
                                height={appTheme.dimension.height.small}
                            />
                        )}
                    </ColorBox>
                ))}
            </Row>
        </>
    );
}
interface IStepProps {
    values?: any;
    errors?: any;
    setErrors?;
    setFromValue?: Function;
    validate?: Function;
    validationSchema?: Yup.ObjectSchema<any>;
    setStepSchema: Function;
    setFieldValue?: Function;
    validateField?: Function;
    isSubmitting?: boolean;
}

const StepTwo = ({
    values,
    validationSchema,
    setStepSchema,
    setFieldValue,
    validateField,
    errors,
    setErrors,
    isSubmitting,
}: IStepProps) => {
    const { t } = useTranslation();
    const [idImageList, setIdImageList] = useState<any[]>([]);
    const [language, setLanguage] = useState(values.languages.includes('fr') ? 'fr' : values.languages[0]);
    const [typeTimeout, setTypeTimeout] = useState<number | null>(null);

    const isLinkClickable = !errors.linkToExternalPage && values.linkToExternalPage;
    // set a lower bottom margin to show the error message close to the link input
    const bottomMarginLinkInput = errors.linkToExternalPage ? 'xxs' : undefined;

    const validateLink = useCallback((value) => {

        if (validationSchema) {
            const isLinkToExternalPageValid = Yup
                .reach(validationSchema, 'linkToExternalPage')
                .isValidSync(value);

            // returns truthy value when there is an error since that's what formik expects
            return !isLinkToExternalPageValid;
        }
    }, [validationSchema]);

    useEffect(() => {
        let mainObject: any = {};
        values.languages.forEach((lang: string) => {
            const importantResume = values.type === MarketingTypeNew.IMPORTANT_MESSAGE;

            if (importantResume) {
                mainObject = {
                    ...mainObject,
                    [`${lang}ArticleTitle`]: Yup.string().required(t('app:error.required') || undefined),
                    [`${lang}ArticleResume`]: Yup.string().required(t('app:error.required') || undefined),
                };
            } else {
                mainObject = { ...mainObject, [`${lang}ArticleTitle`]: Yup.string().required(t('app:error.required') || undefined) };
            }
        });

        setStepSchema(
            Yup.object().shape({
                ...mainObject,
                image: Yup.array(
                    Yup.object({
                        id: Yup.string().required(t('app:error.required') || undefined),
                    })
                ),
                linkToExternalPage: Yup.string().url(t('page:communication.addMarketingCard.linkError') || undefined).nullable()
            })
        );
    }, [setStepSchema, values.languages, t, values.type]);

    useEffect(() => {
        if (values.image.length && values.image[0].id) {
            setIdImageList([values.image[0]]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // trigger validation of link when validation schema is available or isSubmitting draft state changes
        if (values.linkToExternalPage) {
            // set error manually to trigger a re-render
            setErrors({ linkToExternalPage: validateLink(values.linkToExternalPage)});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationSchema, isSubmitting, setErrors, validateLink]);

    const selector = (flag: string) => {
        setLanguage(flag);
    };

    const onChangeLinkInput = (value) => {
        if(value === '') {
            setFieldValue && setFieldValue('linkToExternalPage', null);
        }

        if (typeTimeout !== null) {
            // clear previous timer
            clearTimeout(typeTimeout);
            setTypeTimeout(null);
        }

        // clear link previous error while the user is typing
        setErrors({ linkToExternalPage: false });

        // trigger field validation half a second after the user stops writing
        setTypeTimeout(setTimeout(() => {
            if (validateField) {
                validateField('linkToExternalPage');
            }
        }, 500));
    };

    const renderLinkErrorMessage = () => {

        return (
            errors.linkToExternalPage ? (
                <ErrorMessageWrapper>
                    <ErrorMessageLighter>{t('page:communication.addMarketingCard.linkError')}</ErrorMessageLighter>
                </ErrorMessageWrapper>
            ) : null
        );
    }

    return (
        <Wrapper>
            <MainSubWrapper>
                <Text bold size={'L'} color={appTheme.color.common.black}>
                    {t(
                        `page:communication.addMarketingCard.${
                            values.type === MarketingTypeNew.ARTICLE
                                ? 'creationArticleTitle'
                                : 'creationImportantMessageTitle'
                        }`
                    )}
                </Text>
                <LabelFieldWrapper>
                    <InputLabel
                        inline
                        name={
                            <Trans
                                i18nKey="page:communication.addMarketingCard.articleTitlePart"
                                components={[<strong />, <i />]}
                            />
                        }
                        showRequiredAsterisk
                    />
                </LabelFieldWrapper>
                {values.languages.map((lang) => (
                    <LanguageTextInput
                        key={`${lang}ArticleTitle`}
                        locale={lang}
                        name={`${lang}ArticleTitle`}
                        maxLength={80}
                        multiline
                        placeholder={fixedTranslation(
                            lang,
                            `page:communication.addMarketingCard.articleTitlePlaceholder`
                        )}
                        value={values[`${lang}ArticleTitle`]}
                    />
                ))}
                <LabelFieldWrapper>
                    <InputLabel
                        inline
                        name={
                            <Trans
                                i18nKey={
                                    values.type === MarketingTypeNew.ARTICLE
                                        ? 'page:communication.addMarketingCard.articleResumePart'
                                        : 'page:communication.addMarketingCard.importantMessageResume'
                                }
                                components={[<strong />, <i />]}
                            />
                        }
                        showRequiredAsterisk={values.type === MarketingTypeNew.IMPORTANT_MESSAGE}
                    />
                </LabelFieldWrapper>
                {values.languages.map((lang) => (
                    <LanguageTextInput
                        key={`${lang}ArticleResume`}
                        locale={lang}
                        name={`${lang}ArticleResume`}
                        maxLength={140}
                        multiline
                        placeholder={fixedTranslation(
                            lang,
                            `page:communication.addMarketingCard.articleResumePlaceholder`
                        )}
                        value={values[`${lang}ArticleResume`]}
                    />
                ))}
                {values.type === MarketingTypeNew.ARTICLE ? (
                    <Field
                        id="imageInputField"
                        label={<Trans i18nKey="page:communication.addMarketingCard.image" components={[<strong />]} />}
                        secondaryLabel={t('page:communication.addMarketingCard.imageHint')}
                        name="image"
                        component={ImageInputField}
                        hasId={(idList: any[]) => setIdImageList(idList)}
                        initialImages={idImageList}
                        overrideShowRequiredAsterisk
                    />
                ) : (
                    <>
                        <LanguageTextInput
                            bottomSize={bottomMarginLinkInput}
                            useDefaultErrorMessage={false}
                            useDefaultErrorColoring={false}
                            validate={validateLink}
                            onChangeField={onChangeLinkInput}
                            multiline={true}
                            placeholder={t(`page:communication.addMarketingCard.linkPlaceholder`)}
                            label={
                                <Trans
                                    i18nKey="page:communication.addMarketingCard.linkOptional"
                                    components={[<strong />, <i />]}
                                />
                            }
                            name="linkToExternalPage"
                            value={values.linkToExternalPage}
                        />
                        {renderLinkErrorMessage()}
                        <IMBackgroundPicker
                            availableBackgrounds={AVAILABLE_BACKGROUNDS}
                            setIdImageList={setIdImageList}
                            setFieldValue={setFieldValue}
                            value={values.image[0].id}
                        />
                    </>
                )}
            </MainSubWrapper>

            <VerticalLine />

            <SecondSubWrapper>
                <Text bold size={'L'} color={appTheme.color.common.black}>
                    {t('page:communication.addMarketingCard.prevArticle')}
                </Text>

                <FlagSelector>
                    {values.languages.includes('fr') && (
                        <IconButton onClick={() => selector('fr')}>
                            {language === 'fr' ? <Icon.FlagFr /> : <Icon.FlagFrDisabled />}
                        </IconButton>
                    )}
                    {values.languages.includes('en') && (
                        <>
                            &nbsp; &nbsp;
                            <IconButton onClick={() => selector('en')}>
                                {language === 'en' ? <Icon.FlagUK /> : <Icon.FlagUKDisabled />}
                            </IconButton>
                        </>
                    )}
                </FlagSelector>

                <a style= {{ width: "inherit"}}
                   target={ isLinkClickable ? '_blank' : undefined }
                   href={ isLinkClickable ? values.linkToExternalPage : undefined }
                   rel="noreferrer"
                >
                    <Preview>
                        <StyledBadge
                            backgroundColor={
                                values.type === MarketingTypeNew.IMPORTANT_MESSAGE && appTheme.color.common.lightGrey
                            }
                        >
                            {values.type === MarketingTypeNew.ARTICLE ? (
                                <>
                                    <Icon.Hot />
                                    <BadgeText>{t('page:communication.communicationList.article')}</BadgeText>
                                </>
                            ) : (
                                <>
                                    <Icon.WarningSmall />
                                    <BadgeText>{t('page:communication.communicationList.infoNewsTitle')}</BadgeText>
                                </>
                            )}
                        </StyledBadge>
                        <ImagePreview
                            imageList={idImageList}
                            importantMessage={values.type === MarketingTypeNew.IMPORTANT_MESSAGE}
                        >
                            {values.type === MarketingTypeNew.IMPORTANT_MESSAGE && (
                                <>
                                    <IMTitle>{values[`${language}ArticleTitle`]}</IMTitle>
                                    <IMContent>{values[`${language}ArticleResume`]}</IMContent>
                                    { isLinkClickable ? <IMSeeMore>{`${fixedTranslation(
                                        language,
                                        'page:communication.addMarketingCard.component.infoNewsSeeMore'
                                    )} >`}</IMSeeMore> : null }
                                </>
                            )}
                        </ImagePreview>
                        {values.type === MarketingTypeNew.ARTICLE ? (
                            <>
                                <Text
                                    bold
                                    size={'L'}
                                    color={appTheme.color.common.deepGrey}
                                    wordBreak={'break-word'}
                                    lineHeight={'M'}
                                    paddingTop={14}
                                >
                                    {values[`${language}ArticleTitle`]}
                                </Text>

                                <Text size={'XS'} color={appTheme.color.grey[8]} wordBreak={'break-word'} paddingTop={8}>
                                    {values[`${language}ArticleResume`]}
                                    {values.publicationStartDate && (
                                        <>
                                            <span> </span>
                                            <Text size={'XS'} color={appTheme.color.grey[4]} overflow={'hidden'}>
                                                {fixedTranslation(
                                                    language,
                                                    `page:communication.addMarketingCard.publishedDate`
                                                )}
                                                {values.publicationStartDate.toLocaleDateString()}
                                            </Text>
                                        </>
                                    )}
                                </Text>
                            </>
                        ) : (
                            <></>
                        )}
                    </Preview>
                </a>
            </SecondSubWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    align-items: flex-start;
    padding: ${({ theme }) => theme.spacing.s}px;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    background-color: ${({ theme }) => theme.color.common.white};
    flex-grow: 0;
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 940px;
`;

const LabelFieldWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.xs}px;
    padding-bottom: ${({ theme }) => theme.spacing.xs}px;
    background-color: ${({ theme }) => theme.color.common.white};
`;

const MainSubWrapper = styled(Column)`
    margin: 15px;
    align-items: flex-start;
    flex: 5;
`;
const SecondSubWrapper = styled(Column)`
    margin: 15px;
    align-items: flex-start;
    flex: 4;
`;

const VerticalLine = styled.div`
    border-left: 2px solid ${({ theme }) => theme.color.grey[0]};
    height: calc(100% - 40px);
    position: flex;
    left: 50%;
    margin-left: -3px;
    top: 0;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

interface IColorBoxProps {
    bgImage?: string;
}
const ColorBox = styled.span<IColorBoxProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(${({ bgImage }) => bgImage});
    background-position: center;
    object-fit: cover;
    height: 60px;
    width: 60px;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    margin: ${({ theme }) => `${theme.spacing.xs}px ${theme.spacing.xs}px 0 0`};
    cursor: pointer;
`;

const IMTitle = styled.p`
    color: ${({ theme }) => theme.color.common.white};
    font-size: ${({ theme }) => theme.typography.fontSizeL}px;
    font-family: ${({ theme }) => theme.typography.fontFamilyKelson};
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing.xxs}px;
`;

const IMContent = styled.p`
    color: ${({ theme }) => theme.color.common.white};
    font-size: ${({ theme }) => theme.typography.fontSizeL}px;
    margin-top: 0;
    font-family: ${({ theme }) => theme.typography.fontFamilyKelson};
`;

const IMSeeMore = styled.p`
    color: ${({ theme }) => theme.color.common.white};
    font-size: ${({ theme }) => theme.typography.fontSizeL}px;
    align-self: flex-end;
    font-family: ${({ theme }) => theme.typography.fontFamilyKelson};
    position: absolute;
    bottom: 25px;
`;

const ImageChildWrapper = styled.div`
    position: absolute;
    top: ${({ theme }) => theme.spacing.s + 5}px;
    left: ${({ theme }) => theme.spacing.m}px;
    width: 86%;
    height: 100%;
`;

export const ErrorMessageWrapper = styled.div`
    margin-bottom: 30px;
`;

export default StepTwo;
