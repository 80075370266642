import { Icon, Tooltip } from 'components';
import React from 'react';
import styled from 'styled-components';

interface IProps {
    image: {
        id: string;
        path: string;
        filename: string;
        width: number;
        height: number;
    } | null;
    iconWidth?: number;
    iconColor?: string;
    renderToolTipContent?: any;
    id?: string;
}
const ImageIconWithTooltip = ({ iconColor, iconWidth = 18, image, renderToolTipContent, id }: IProps) => {
    if (!image) return <StyledNoImage width={iconWidth} />;
    const { width, height, filename, path } = image;
    return (
        <StyledTooltip
            id={id}
            iconWidth={iconWidth}
            toolTipContent={
                !!renderToolTipContent ? (
                    renderToolTipContent
                ) : (
                    <>
                        <Header>
                            <Icon.Image width={iconWidth} color={iconColor} />
                        </Header>
                        <Image src={`${window.config.IMAGE_BASE_URL}/resize/400x400/${path}`} />
                        <Footer>
                            {filename}
                            <Size>
                                {width} × {height}
                            </Size>
                        </Footer>
                    </>
                )
            }
        >
            <Icon.Image width={iconWidth} color={iconColor} />
        </StyledTooltip>
    );
};

// @ts-ignore
const StyledTooltip = styled(props => <Tooltip {...props} />)`
    top: ${({ theme }) => -theme.spacing.xs}px;
    right: calc(100% - ${({ theme, iconWidth }) => theme.spacing.xs + iconWidth}px);
`;

const StyledNoImage = styled(Icon.NoImage)`
    cursor: not-allowed;
`;

const Image = styled.img`
    height: 180px;
    width: 300px;
    object-fit: cover;
`;

const Header = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: ${({ theme }) => theme.spacing.xs}px;
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing.xs}px;
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    color: ${({ theme }) => theme.color.grey[6]};
`;

const Size = styled.span`
    display: inline-block;
    flex-shrink: 0;
    margin-left: ${({ theme }) => theme.spacing.s}px;
`;

export default ImageIconWithTooltip;
