import React from 'react';
import { Button, Row, TextInputField, ToggleSwitchField } from 'components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { ButtonType } from 'components/Button';
import Select from 'react-select';
import { ExternalLink } from 'types/holdingPos';
import { Bin } from "../../../components/Icons";
import { appTheme } from "../../../theme";
import { getArticleFamilyOptions } from "../../../services/articleService";
import I18n from "../../../services/i18n";
import { PrintingPriority } from "../../../types/globalTypes";

interface IProps {
    arrayHelpers: any;
    values: any;
}

const customStyles = {
    control: (base) => ({
        ...base,
        border: `1px solid ${appTheme.color.grey[2]}`,
    }),
    clearIndicator: (prevStyle) => ({
        ...prevStyle,
        color: appTheme.color.common.red,
        ':hover': { color: appTheme.color.common.red },
        backgroundColor: appTheme.color.grey[0],
    }),
    multiValue: (base) => {
        return {
            ...base,
            backgroundColor: appTheme.color.grey[0],
        };
    },
    multiValueRemove: (base) => {
        return {
            ...base,
            backgroundColor: false,
            color: appTheme.color.common.blue,
            ':hover': { color: appTheme.color.common.blue },
            order: 1,
        };
    },
    multiValueLabel: (base) => {
        return {
            ...base,
            backgroundColor: false,
            order: 2,
        };
    },
    option: (base) => ({
        ...base,
        ':hover': {
            color: appTheme.color.common.blue,
            fontWeight: appTheme.typography.fontWeight.bold,
            backgroundColor: appTheme.color.grey[0]
        },
        backgroundColor: false,
    }),
    placeholder: (base) => ({
        ...base,
        color: appTheme.color.grey[5],
        fontSize: appTheme.typography.fontSizeS,
        fontWeight: appTheme.typography.fontWeight.bold,
    }),
    indicatorSeparator: (styles) => ({ display: 'none' }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
        color: appTheme.color.common.navy
    })
};

const ScopesInput = ({ field, form }: { field: any; form: any }) => (
    <Select
        isMulti
        styles={customStyles}
        options={[{ value: 'all', label: I18n.t('schema:article.familyOption.ALL') }]
            .concat(getArticleFamilyOptions(false)
            .map(({ id, label }) => ({ value: id, label })))
        }
        value={field.value?.map((item: any) => ({
            value: item,
            label: I18n.t(`schema:article.familyOption.${item}`)
        })) || []}
        menuPlacement="auto"
        onChange={(value) => {
            if (!value) {
                form.setFieldValue(field.name, []);
                return form.setFieldTouched(field.name, true);
            }
            if(value.some((item: any) => item.value === 'all')) {
                form.setFieldValue(field.name, getArticleFamilyOptions(false).map((item: any) => item.id));
                return form.setFieldTouched(field.name, true);
            }else {
                form.setFieldValue(field.name, value.map((item: any) => item.value));
            }
            form.setFieldTouched(field.name, true);
        }}
    />
);

// @ts-ignore
const PrintersEdit: React.FC<IProps> = React.memo(({ values, arrayHelpers, setFieldValue, field, ...rest }) => {
    const [t] = useTranslation();
    const onAdd = () => {
        arrayHelpers.push({ scopes: [] });
    };


    return (
        <Wrapper>
            {values.posPrinters?.map((link: ExternalLink, index: number) => (
                <PrinterContainer key={index}>
                    <Title>
                        {`${t('schema:posHub.printer.title')} ${index + 1}`}
                        <IconButton onClick={(e) => arrayHelpers.remove(index)}>
                            <Bin color={appTheme.color.grey[6]} height={24} width={24}/>
                        </IconButton>
                    </Title>
                    <Field
                        name={`posPrinters.${index}.master`}
                        component={ToggleSwitchField}
                        sideLabel={true}
                        onLabel={t('schema:posHub.printer.master')}
                        offLabel={t('schema:posHub.printer.master')}
                        handleChange={() => {
                            setFieldValue('posPrinters', values.posPrinters.map((item: any, i: number) => {
                                if (i === index) {
                                    return { ...item, master: !values.posPrinters[index].master };
                                }
                                return { ...item, master: false };
                            }));
                        }}
                    />
                    {values.printingPriority == PrintingPriority.mealHeart && <Field
                        name={`posPrinters.${index}.default`}
                        component={ToggleSwitchField}
                        sideLabel={true}
                        onLabel={t('schema:posHub.printer.default')}
                        offLabel={t('schema:posHub.printer.default')}
                        handleChange={() => {
                            setFieldValue('posPrinters', values.posPrinters.map((item: any, i: number) => {
                                if (i === index) {
                                    return { ...item, default: !values.posPrinters[index].default };
                                }
                                return { ...item, default: false };
                            }));
                        }}
                    />}
                    <Field
                        label={t('schema:posHub.printer.name')}
                        name={`posPrinters.${index}.printer.name`}
                        component={TextInputField}
                    />
                    <Field
                        label={t('schema:posHub.printer.device')}
                        name={`posPrinters.${index}.printer.deviceId`}
                        component={TextInputField}
                    />
                    <Field
                        label={t('schema:posHub.printer.articleFamilies')}
                        component={ScopesInput}
                        name={`posPrinters.${index}.scopes`}
                    />
                </PrinterContainer>
            ))}
            <Row>
                <Button
                    type="button"
                    display={ButtonType.SECONDARY}
                    onClick={onAdd}
                    inline
                >
                    {t('schema:posHub.printer.add')}
                </Button>
            </Row>
        </Wrapper>
    );
});

const Wrapper = styled.div`
  width: 100%;
`;
const PrinterContainer = styled.div<{ asRow?: boolean }>`
  margin-bottom: ${({ asRow }) => asRow ? -20 : 30}px;
`;
export const Title = styled.h2`
  flex-shrink: 0;
  color: ${({ theme }) => (theme.color.grey[6])};
  font-family: ${({ theme }) => theme.typography.fontFamilySecondary};
  font-size: ${({ theme }) => theme.typography.fontSizeXL}px;
  margin-top: 56px;
  margin-bottom: 24px;
  display: flex;
`;

const IconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing.xs}px;
  border-radius: ${({ theme }) => theme.borderRadius.xs}px;
  width: 28px;
  height: 28px;
  background-color: transparent;

  &:disabled {
    opacity: 0.3;
  }
`;


export default PrintersEdit;
