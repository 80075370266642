import React, { Fragment, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { loader } from 'graphql.macro';
import { Icon, QueryLoader, Row, Title } from 'components';
import { Reload as ReloadIcon } from 'components/Icons';
import { delay } from 'utils';
import { IconButton } from 'components/Button';
import { QueryResult } from 'localTypes';
import { useTranslation } from 'react-i18next';
import { countOrders, countOrders_offer_Offer } from 'types/countOrders';
import { getDates, getDisplayTime } from 'services/dateService';
import Chart, { IChartValue } from 'components/Chart';
import { DateTime } from 'luxon';
import { appTheme } from 'theme';

const COUNT_QUERY = loader('../query/countOrders.gql');

interface IProps {
    idOffer?: string;
    testID?: string;
}

const ACTIVE_LABEL_COLOR = appTheme.color.common.blue;
const INACTIVE_LABEL_COLOR = appTheme.color.grey[6];

const ACTIVE_BAR_COLOR = appTheme.color.common.green;
const INACTIVE_BAR_COLOR = '#DFE2E6';

const CANCELATION_COLOR = appTheme.color.common.red;

//Auxiliar functions for charts
const colorPickerXAxisLabel = (value: string) => {
    var now = DateTime.local();
    const labelBeginSlotDate = DateTime.fromFormat(value, 'HH:mm');

    const labelEndSlotDate = labelBeginSlotDate.plus({ minutes: slotDuration });

    if (now.day === offerDay.day && now >= labelBeginSlotDate && now < labelEndSlotDate) return ACTIVE_LABEL_COLOR;

    return INACTIVE_LABEL_COLOR;
};

const barColorPicker = (value: string) => {
    var now = DateTime.local();
    const labelBeginSlotDate = DateTime.fromFormat(value, 'HH:mm');

    const labelEndSlotDate = labelBeginSlotDate.plus({ minutes: slotDuration });

    if (now.day < offerDay.day || now < labelEndSlotDate) return ACTIVE_BAR_COLOR;

    return INACTIVE_BAR_COLOR;
};

const secondaryColorPicker = (value: number) => {
    if (value === 0) {
        return INACTIVE_LABEL_COLOR;
    }
    return CANCELATION_COLOR;
};

function getSlotDuration(withdrawRange: string): number {
    const [startRange, endRange] = getDates(withdrawRange);

    const startDate = DateTime.fromISO(startRange);
    const endDate = DateTime.fromISO(endRange);

    return endDate.diff(startDate, 'minutes').minutes;
}

var slotDuration = 15;
var offerDay: DateTime;

export default function ReservationsCountInfo({ idOffer, testID }: IProps) {
    const [t] = useTranslation();
    const [fetching, setFetching] = useState(false);

    return (
        <QueryLoader fetchPolicy={'network-only'} variables={{ idOffer: idOffer }} query={COUNT_QUERY}>
            {({ data, refetch }: QueryResult<countOrders>) => {
                const offer = data.offer as countOrders_offer_Offer;

                if (offer && offer.slots) {
                    //setting slotDuration, for colorPickers
                    slotDuration = getSlotDuration(offer.slots[0].withdrawRange);
                    offerDay = DateTime.fromISO(getDates(offer.slots[0].withdrawRange)[0]);

                    const acceptedOrdersData: IChartValue[] = offer.slots.map((slot) => {
                        return {
                            label: getDisplayTime(getDates(slot.withdrawRange)[0]),
                            value: slot.acceptedOrdersCount,
                        };
                    });

                    const canceledOrdersData: IChartValue[] = offer.slots.map((slot) => {
                        return {
                            label: getDisplayTime(getDates(slot.withdrawRange)[0]),
                            value: slot.canceledOrdersCount,
                        };
                    });

                    return (
                        <Fragment>
                            <Title
                                testID={testID}
                                mode={'H3'}
                                value={t('app:reservation.dayReservation')}
                                grow
                                icon={<Icon.Booking color={appTheme.color.grey[6]} />}
                            >
                                <IconButton
                                    {...(testID && {'data-test':`${testID}-refresh`})}
                                    onClick={async () => {
                                        setFetching(true);
                                        await delay(refetch);
                                        setFetching(false);
                                    }}
                                >
                                    <Reload className={fetching ? 'active' : ''} />
                                </IconButton>
                            </Title>

                            <CountRowTitle justify={'center'}>
                                <Label>
                                    <div style={{ marginRight: 15, display: 'inline-block' }}>
                                        {t('app:reservation.reservations')}:{' '}
                                        <InlineLabelBold color={ACTIVE_BAR_COLOR}>
                                            {offer.acceptedOrdersCount}
                                        </InlineLabelBold>{' '}
                                    </div>
                                    <div style={{ display: 'inline-block' }}>
                                        {t('app:reservation.placesPerSlot')}:{' '}
                                        <InlineLabelBold color={INACTIVE_LABEL_COLOR}>
                                            {offer.maxOrdersPerSlot ? offer.maxOrdersPerSlot : ''}
                                        </InlineLabelBold>
                                    </div>
                                </Label>
                            </CountRowTitle>

                            <Chart
                                testID={testID}
                                data={acceptedOrdersData}
                                boxedSecondaryData={canceledOrdersData}
                                secondaryColorPicker={secondaryColorPicker}
                                xAxisColorPicker={colorPickerXAxisLabel}
                                barColorPicker={barColorPicker}
                                maxValue={offer.maxOrdersPerSlot}
                            />

                            <CountRowTitle justify={'center'}>
                                <Label>
                                    <div style={{ display: 'inline-block' }}>
                                        {t('app:reservation.cancellations')}:{' '}
                                        <InlineLabelBold color={CANCELATION_COLOR}>
                                            {' '}
                                            {offer.canceledOrdersCount}
                                        </InlineLabelBold>
                                    </div>
                                </Label>
                            </CountRowTitle>
                        </Fragment>
                    );
                }

                return null;
            }}
        </QueryLoader>
    );
}

const spin = keyframes`
  from {
    transform:rotate(360deg);
  }
  to {
    transform:rotate(0deg);
  }
`;

interface InlineLabelProps {
    color: string;
}

const Label = styled.div`
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.fontSizeM}px;
`;

const InlineLabelBold = styled.p<InlineLabelProps>`
    color: ${({ color }) => color};
    font-weight: bold;
    display: inline;
`;

const CountRowTitle = styled(Row)`
    margin-bottom: ${({ theme }) => theme.spacing.s}px;
    margin-top: ${({ theme }) => theme.spacing.s}px;
`;

export const RefreshButton = styled.div`
    margin: 0.25em;
    text-align: center;
    font-size: 42px;
    background: transparent;
    border: none !important;
`;

export const Reload = styled(ReloadIcon)`
    &.active {
        animation-name: ${spin};
        animation-duration: 500ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
`;
