import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getDates, getDisplayDate } from 'services/dateService';
import routes from 'Router/routes';

import { TableWithOptions, Icon, IconButton, Tooltip, Button } from 'components';
import { getSingleOption, getTypeOptions } from 'components/Tag';
import Badge from 'components/Block/Badge';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import {
    listMarketingCardNew,
    listMarketingCardNew_list_edges_node_MarketingCardNew,
    listMarketingCardNewVariables,
} from 'types/listMarketingCardNew';

import { appTheme } from 'theme';
import { MarketingTypeNew } from 'types/globalTypes';
import { Mutation } from '@apollo/client/react/components';
import { deleteMarketingCardVariables } from 'types/deleteMarketingCard';
import { resetPagination } from 'components/Table/Paginator';
import * as qs from 'qs';
import ToggleSwitchMarketingCard from './ToggleSwitchMarketingCard';

interface IProps {
    loadTotalCount: (value: number) => void;
}

export enum FilterKey {
    DRAFT = 'isDraft',
    TYPE = 'type',
}

const MARKETING_CARD_LIST_QUERY = loader('./query/listMarketingCard.gql');
const DELETE_MARKETING_CARD = loader('./query/deleteMarketingCard.gql');

function DeleteMarketingCardButton({
    id,
    queryVariables,
    onChange,
    history,
}: {
    id: string;
    queryVariables: any;
    onChange: any;
    history: any;
}) {
    const { t } = useTranslation();
    const [status, setStatus] = useState<boolean>();

    return (
        <Mutation
            // remove marketing card from list query cache
            update={(cache: any, response) => {
                const cachedGlobalData = cache.readQuery({
                    query: MARKETING_CARD_LIST_QUERY,
                    variables: queryVariables,
                });

                const updatedEdges = cachedGlobalData.list.edges.filter((edge) => edge.node.id !== id);

                cache.writeQuery({
                    query: MARKETING_CARD_LIST_QUERY,
                    variables: queryVariables,
                    data: {
                        ...cachedGlobalData,
                        list: {
                            ...cachedGlobalData.list,
                            edges: updatedEdges,
                            totalCount: cachedGlobalData.list.totalCount - 1,
                        },
                    },
                });

                setStatus(updatedEdges.length === 0);
            }}
            onCompleted={(data) => {
                if (status) {
                    history && history.push({ search: qs.stringify({ ...resetPagination }) });
                }
            }}
            refetchQueries={() => {
                return [
                    {
                        query: MARKETING_CARD_LIST_QUERY,
                        variables: queryVariables,
                    },
                ];
            }}
            mutation={DELETE_MARKETING_CARD}
        >
            {(deleteMarketingCard: (param: Record<'variables', deleteMarketingCardVariables>) => Promise<any>) => {
                const onDeleteClick = () => {
                    deleteMarketingCard({
                        variables: { id },
                    });
                };
                return (
                    <StyledButton onMouseDown={onDeleteClick}>
                        {t(`page:communication.addMarketingCard.component.removeComponent`)}
                    </StyledButton>
                );
            }}
        </Mutation>
    );
}

const MarketingCard = ({
    item: { id, title, publicationRange, type, created, active, isDraft, holdingCount },
    onChange,
    queryVariables,
    history,
}: {
    item: listMarketingCardNew_list_edges_node_MarketingCardNew;
    onChange?: any;
    queryVariables?: any;
    history?: any;
}) => {
    const { t } = useTranslation();
    const [activeStatus, setActiveStatus] = useState(active);
    const todayMidnight = new Date().setHours(0, 0, 0, 0);
    const [publicationStartDate, publicationEndDate] = publicationRange ? getDates(publicationRange) : ['', ''];

    const onChangeSwitch = () => {
        setActiveStatus(!activeStatus);
    };

    const onEdit = useCallback(
        (idCommunication: string) => () => {
            history.push(routes.communication.edit(idCommunication));
        },
        [history]
    );

    const BinTooltip = ({ id, queryVariables, onChange }) => {
        const [activeTooltip, setActiveTooltip] = useState(false);
        const [activeTooltipText, setActiveTooltipText] = useState(true);

        return (
            <div onBlur={(e) => { setActiveTooltip(false); setActiveTooltipText(true) }}>
                <StyledTooltipModal
                    clickedToolTip={activeTooltip}
                    toolTipContent={
                        <>
                            <Header>
                                <ModalTitle>{t(`page:communication.communicationList.deleteModalTitle`)}</ModalTitle>
                                <Icon.Delete />
                            </Header>

                            <FlexRow>
                                <AnnulerButton onClick={() => { setActiveTooltip(false) }}>
                                    {t(`page:communication.addMarketingCard.component.tooltipCancel`)}
                                </AnnulerButton>

                                <DeleteMarketingCardButton
                                    id={id}
                                    queryVariables={queryVariables}
                                    onChange={onChange}
                                    history={history}
                                />
                            </FlexRow>
                        </>
                    }
                >
                    <StyledTooltip toolTipContent={activeTooltipText ? t('page:communication.communicationList.delete') : ''}>
                        <IconButton onClick={() => { setActiveTooltip(true); setActiveTooltipText(false) }} >
                            <Icon.Delete />
                        </IconButton>
                    </StyledTooltip>
                </StyledTooltipModal>
            </div>
        );
    };

    return (
        <>
            <StyledTD style={{ width: '30%' }}>
                <TextGrid>{title}</TextGrid>
            </StyledTD>
            <StyledTD style={{ width: '10%' }}>
                <Badge
                    pill
                    small
                    color={appTheme.color.common.blue}
                    fontColor={appTheme.color.common.white}
                    content={`${holdingCount}`}
                />
            </StyledTD>
            <StyledTD style={{ width: '13%' }}>
                {type === MarketingTypeNew.ARTICLE ? (
                    <Badge
                        pill
                        small
                        color={appTheme.color.common.yellow}
                        fontColor={appTheme.color.grey[7]}
                        content={t('page:communication.communicationList.article')}
                    >
                        <Icon.Hot />
                    </Badge>
                ) : (
                        <Badge
                            pill
                            small
                            color={appTheme.color.common.lightGrey}
                            fontColor={appTheme.color.grey[7]}
                            content={
                                type === MarketingTypeNew.IMPORTANT_MESSAGE
                                    ? t('page:communication.communicationList.infoNewsTitle')
                                    : ''
                            }
                        >
                            <Icon.WarningSmall />
                        </Badge>
                    )}
            </StyledTD>
            <StyledTD style={{ width: '15%' }}>
                <TextGrid>{`${getDisplayDate(created)}`}</TextGrid>
            </StyledTD>
            <StyledTD style={{ width: '10%' }}>
                <ActionButtonsContainer>
                    <div style={{ paddingRight: '20px' }}>
                        <StyledTooltip toolTipContent={t('page:communication.communicationList.edit')}>
                            <IconButton onClick={onEdit(id)}>
                                <Icon.Edit />
                            </IconButton>
                        </StyledTooltip>
                    </div>
                    <div>
                        <BinTooltip id={id} queryVariables={queryVariables} onChange={onChange} />
                    </div>
                </ActionButtonsContainer>
            </StyledTD>
            <StyledTD style={{ width: '10%', textAlign: 'right' }}>
                {isDraft ? (
                    <div style={{ textAlign: 'right' }}>
                        <Badge
                            small
                            color={appTheme.color.common.lightYellow}
                            fontColor={appTheme.color.common.orange}
                            content={t('page:communication.communicationList.isDraft')}
                        />
                    </div>
                ) : (
                        <ToggleSwitchMarketingCard
                            onChange={onChangeSwitch}
                            checked={
                                activeStatus &&
                                new Date(publicationStartDate).getTime() <= todayMidnight &&
                                new Date(publicationEndDate).getTime() >= todayMidnight
                            }
                            id={id}
                        />
                    )}
            </StyledTD>
        </>
    );
};

const CommunicationListTable = ({ history, location, loadTotalCount }: IProps & RouteComponentProps) => {
    const { t } = useTranslation();

    const optionDraft = getSingleOption(t('page:communication.communicationList.isDraft'));

    const optionsType = getTypeOptions(
        t('page:communication.communicationList.article'),
        MarketingTypeNew.ARTICLE,
        t('page:communication.communicationList.infoNewsTitle'),
        MarketingTypeNew.IMPORTANT_MESSAGE
    );

    const renderLine =
        (queryVariables: listMarketingCardNewVariables) =>
            (item: listMarketingCardNew_list_edges_node_MarketingCardNew) => {
                return (
                    <StyledTR key={item.id}>
                        <MarketingCard item={item} queryVariables={queryVariables} history={history} />
                    </StyledTR>
                );
            };

    return (
        <>
            <TableWithOptions
                renderLine={renderLine}
                headers={getHeaders(t)}
                withQueryVariables
                fetchPolicy={'network-only'}
                query={MARKETING_CARD_LIST_QUERY}
                searchPlaceholder={t('app:placeholder.search.communication')}
                tags={{
                    [FilterKey.TYPE]: optionsType,
                    [FilterKey.DRAFT]: optionDraft,
                }}
                headersWithoutPadding={true}
                initialBlankPage={() => {
                    loadTotalCount(0);
                    return (
                        <BlankPageContainer>
                            <Icon.BlankPage />
                            <BlankPageContent>
                                {t('page:communication.communicationList.blankPageContent')}
                            </BlankPageContent>
                        </BlankPageContainer>
                    );
                }}
            >
                {({ data }: QueryResult<listMarketingCardNew>) => {
                    if (data) {
                        loadTotalCount(data.list.totalCount);
                    }
                }}
            </TableWithOptions>
        </>
    );
};

const getHeaders = (t: any) => [
    {
        key: 'label',
        displayName: t('schema:communication.title'),
    },
    {
        key: 'holding',
        displayName: t('schema:communication.holdings'),
    },
    {
        key: 'type',
        displayName: t('schema:communication.type'),
    },
    {
        key: 'creation',
        displayName: t('schema:communication.creation'),
    },
    {
        key: 'actions',
        displayName: t('schema:communication.actions'),
    },
    {
        key: 'active',
        displayName: t('schema:communication.active'),
    },
];

interface IFlexRow {
    justifyContent?: string;
    marginBottom?: number;
}

const StyledTR = styled.tr`
    cursor: default;
`;

const StyledTD = styled.td`
    padding-left: 0px !important;
`;

const BlankPageContainer = styled.div`
    height: 100%;
    min-height: 400px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

const ModalTitle = styled.div`
    display: flex;
    font-size: 12px;
    max-width: 250px;
`;

const BlankPageContent = styled.span`
    font-size: ${({ theme }) => theme.typography.size.M}px;
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    margin-top: ${({ theme }) => theme.spacing.s}px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: Helvetica;
    letter-spacing: 0;
    text-align: center;
`;

const TextGrid = styled.span`
    font-size: ${({ theme }) => theme.typography.size.S}px;
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    margin-top: ${({ theme }) => theme.spacing.s}px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    letter-spacing: 0;
    font-weight: lighter;
`;

const ActionButtonsContainer = styled.div`
    display: flex;
    justify-content: start;
    margin: auto;
`;

export const StyledTooltip = styled((props) => <Tooltip styledTooltip={true} {...props} />)`
    top: ${({ theme, style }) => style?.top ? style.top : theme.spacing.s}px;
    padding: 5px 0px;
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    width: ${({ style }) => style?.width ? style.width : '70'}px;
    left: 50%;
    margin-left: ${({ style }) => style?.transform ? '0' : '-35'}px;
    text-align: center;
    transform: ${({ style }) => style?.transform ? style.transform : 'none'};
`;

const StyledTooltipModal = styled((props) => <Tooltip {...props} />)`
    top: -10px;
    right: -10px;
    width: 330px;
    padding: ${({ theme }) => theme.spacing.xs}px;
    z-index: 2;
`;

const FlexRow = styled.div<IFlexRow>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)}px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
`;

const StyledButton = styled(Button)`
    width: 130px;
    min-width: 130px;
    height: 30px;
    cursor: pointer;
`;

const AnnulerButton = styled.span`
    font-size: ${({ theme }) => theme.typography.size.S}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bolder};
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    color: ${({ theme }) => theme.color.common.blue};
    font-family: Open Sans;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;
    padding: 0 40px;
    display: flex;
    align-self: center;
    cursor: pointer;
`;

export default withRouter(CommunicationListTable);
