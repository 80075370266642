import { appTheme } from 'theme';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { getNodes } from 'services/queryService';
import {
    searchHolding_tokenOwner_Employee as Employee,
    searchHolding_tokenOwner_Employee_choices_edges_node as Holding,
} from 'types/searchHolding';
import Select, { Option } from 'react-select';
import FieldContainer from '../components/Inputs/FormikFields/FieldContainer';
import { FieldProps } from 'formik';
import styled from 'styled-components';

interface IProps {
    isMultiSelect?: boolean | false;
    isDisabled?: boolean | false;
    onChange: (value: any) => void;
    initialValues? : Option[]
}

/**
 * 
 * @param props Dropdown that allows to select multiple Holdings at the same time
 * @returns 
 */
const DropdownHolding: FunctionComponent<FieldProps & IProps> = (props) => {
    const {  onChange, isMultiSelect, initialValues, isDisabled } = props;
    const [holdingValues, setHoldingValues] = useState(initialValues);
    const { t } = useTranslation();

    useEffect(()=>{
        isDisabled && setHoldingValues([{label:t('page:admin.employees.allHoldings').toUpperCase(), value: "0"}]);
        !isDisabled && setHoldingValues(initialValues);
    }, [isDisabled])

    const customStyles = {
        clearIndicator: (prevStyle) => ({
            ...prevStyle,
            color: appTheme.color.common.red,
            ':hover': { color: appTheme.color.common.red },
            backgroundColor: appTheme.color.grey[0],
        }),
        multiValue: (base) => {
            return {
                ...base,
                backgroundColor: appTheme.color.grey[0],
            };
        },
        multiValueRemove: (base) => {
            return {
                ...base,
                backgroundColor: false,
                color: appTheme.color.common.blue,
                ':hover': { color: appTheme.color.common.blue },
                order: 1,
            };
        },
        multiValueLabel: (base) => {
            return {
                ...base,
                backgroundColor: false,
                order: 2,
            };
        },
        option: (base) => ({
            ...base,
            ':hover': { color: appTheme.color.common.blue, fontWeight: appTheme.typography.fontWeight.bold, backgroundColor : appTheme.color.grey[0] },
            backgroundColor: false,
        }),
        placeholder: (base) => ({
            ...base,
            color: appTheme.color.grey[5],
            fontSize: appTheme.typography.fontSizeS,
            fontWeight: appTheme.typography.fontWeight.bold,
        }),
        control: (base) => ({
            ...base,
            minHeight: 52,
        }),
        indicatorSeparator: (styles) => ({display:'none'}),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
            color : appTheme.color.common.navy
        })
    };

    let selectedOptions: Option[] = [];

    const {  data, refetch } = useQuery(loader('../query/searchHolding.gql'), {
      variables: { search: '%', id: '', useId: false },
      context: { debounceKey: 'holding' },
      errorPolicy: "all"
    });

    let choices: Holding[] = [];

    try {
        const tokenOwner = data.tokenOwner as Employee;
        choices = getNodes(tokenOwner.choices);

        selectedOptions = choices.map((holding) => {
            return {
                value: holding.id,
                label: holding.label,
            };
        });
    } catch (e) {}

    return (
      <FieldContainer {...props}>
        <FieldWrapper>
            <Select
                onChange={(value) => {
                  setHoldingValues(value);
                  onChange(value);
              }}
                isMulti={isMultiSelect}
                minMenuHeight={52}
                options={selectedOptions}
                onInputChange={(inputValue: string) => {
                    refetch({ search: `%${inputValue}%` });
                }}
                defaultValue={initialValues}
                placeholder={t('page:communication.addMarketingCard.holdingsPlaceholder')}
                closeMenuOnSelect={!isMultiSelect}
                styles={customStyles}
                value={holdingValues}
                isDisabled={isDisabled}
            />
        </FieldWrapper>
      </FieldContainer>
    );
};

const FieldWrapper = styled.div`
    width: inherit;
`;
export default DropdownHolding;
