import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import { loader } from 'graphql.macro';
import { TableWithOptions } from 'components';
import { QueryResult } from 'localTypes';
import { TransitionGroup } from 'react-transition-group';
import CheckListUser from './CheckListUser';
import EditCheckListUserPanel from './AddOrEditCheckListUser/EditCheckListUserPanel';
import AddCheckListUserPanel from './AddOrEditCheckListUser/AddCheckListUserPanel';
import { checklistUser, listChecklistUsers } from 'types/listChecklistUsers';

const CHECKLISTUSERS_LIST_QUERY = loader('./query/getEPointageUsers.gql');

interface IProps extends RouteComponentProps<{ siteId: string }> {} 

const getHeaders = (t: any) =>
    ['lastName','firstName', 'email', 'schools', 'role', 'actions'].map(key => ({ key, displayName: t(`schema:checkListUser.admin.${key}`) }));

const CheckListUsersTable = ({ 
    match: {
        params: { siteId },
    },
}: IProps) => {
    const { t } = useTranslation();
    const [refetchAfterDelete, setRefetchAfterDelete] = React.useState(false);

    const renderLine = (item: checklistUser) => {
        return <CheckListUser 
            key={item.id}
            item={item}
            refetchAfterDelete={() => setRefetchAfterDelete(true)}
        />;}

    return (
        <TableWithOptions
            headers={getHeaders(t)}
            renderLine={renderLine}
            query={CHECKLISTUSERS_LIST_QUERY}
            variables={{ siteId }}
            searchPlaceholder={t('app:placeholder.search.user')}
        >
        {({ refetch }: QueryResult<listChecklistUsers>) => {
                        if (refetchAfterDelete) {
                            refetch();
                            setRefetchAfterDelete(false);
                        }
                        return (
                            <TransitionGroup>
                                <Route
                                    path={routes.checkList.admin.checkListUser.edit()}
                                    render={() => <EditCheckListUserPanel beforeClose={refetch} />}
                                />
                                <Route
                                    path={routes.checkList.admin.checkListUser.add()} 
                                    render={() => <AddCheckListUserPanel beforeClose={refetch} />}
                                />
                            </TransitionGroup>
                        );
                    }}
        </TableWithOptions>
    );
};

export default withRouter(CheckListUsersTable);
