import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import get from 'lodash/get';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TableWithOptions, Icon } from 'components';
import { StyledTooltip } from 'pages/Communication/CommunicationPage/CommunicationListTable';
import { appTheme } from 'theme';
import { QueryResult } from 'localTypes';
import { listLocalArticles_list_edges_node_LocalArticle } from 'types/listLocalArticles';
import PickUpPointTablePanel from 'components/Layout/PickUpPointTablePanel';

// we will be using the containers query while the BE is being made
const LIST_PICK_UP_POINTS = loader('../../query/listAllPickUpPoints.gql');

const ICON_SIZE = 20;
const ICON_COLOR = appTheme.color.grey[6];
interface IProps {
    idHolding: string;
    togglePickUpPointsModal: () => void;
    isPickUpPointsPanelOpen: boolean;
}

const TableRow = (pickUpPoint, index, onClickEditBtn, editLabel) => (
    <tr key={`${pickUpPoint.id}-${index}`} >
      <TextContainer>
            <TextWrapper>{pickUpPoint.name}</TextWrapper>
        </TextContainer>
        <TextContainer>
          <TextWrapper>{pickUpPoint.withdrawalSchedule}</TextWrapper>
        </TextContainer>
        <td>
          <TextWrapper>{pickUpPoint.description}</TextWrapper>
        </td>
        <td>
          <TextWrapper>{pickUpPoint.address}</TextWrapper>
        </td>
        <ButtonsContainer>
          <StyledTooltip toolTipContent={editLabel}>
            <span id={`edit-button_row-${index}`} onClick={() => onClickEditBtn(index)}>
                <Icon.Edit width={ICON_SIZE} height={ICON_SIZE} color={ICON_COLOR} />
            </span>
          </StyledTooltip>
        </ButtonsContainer>
    </tr>
);

const PickUpPointsTable = ({ idHolding, togglePickUpPointsModal, isPickUpPointsPanelOpen }: IProps) => {
    const { t } = useTranslation();
    const editLabel = t('app:button.containerEdit');
    const [ isEditMode, setIsEditMode ] = useState(false);
    const [ indexEditPickUpPoint, setIndexEditPickUpPoint ] = useState(0);

    const defaultQueryVariables = {
        querySearch: [{ key: 'idHolding', operator: '=', value: idHolding }],
    };

    const onClickEditBtn = (index) => {
      setIndexEditPickUpPoint(index);
      setIsEditMode(true);
      togglePickUpPointsModal();
    }

    return (
        <TableWithOptions
            renderLine={(item, index: number) => TableRow(item, index, onClickEditBtn, editLabel)}
            headers={getHeaders(t)}
            fetchPolicy="cache-and-network"
            query={LIST_PICK_UP_POINTS}
            variables={defaultQueryVariables}
            withPagination={false}
        >
            {({ data, refetch }: QueryResult<listLocalArticles_list_edges_node_LocalArticle>) => {
                const pickUpPoint = isEditMode ? get(data, `list.edges[${indexEditPickUpPoint}].node`, null) : null;

                const closeModal = ({shouldRefetch = false}) => {
                    if(shouldRefetch){
                      refetch();
                    }

                    setIsEditMode(false);
                    setIndexEditPickUpPoint(0);
                    togglePickUpPointsModal();
                };
                return (
                    <PickUpPointTablePanel
                        closeModal={closeModal}
                        isOpen={isPickUpPointsPanelOpen}
                        pickUpPoint={pickUpPoint}
                        isEditMode={isEditMode}
                    />
                );
            }}
        </TableWithOptions>
    );
};

const getHeaders = (t: any) => [
    {
        key: 'name',
        displayName: t('schema:pickUpPoints.name'),
    },
    {
        key: 'deliveryTimes',
        displayName: t('schema:pickUpPoints.deliveryTimes'),
    },
    {
        key: 'description',
        displayName: t('schema:pickUpPoints.description'),
    },
    {
        key: 'address',
        displayName: t('schema:pickUpPoints.address'),
    },
    {
        key: 'action',
        displayName: '',
    },
];


const TextContainer = styled.td`
  width: 25%;
`;

const TextWrapper = styled.span`
    font-size: ${({ theme }) => theme.typography.size.S}px;
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    margin-top: ${({ theme }) => theme.spacing.s}px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    letter-spacing: 0;
    font-weight: lighter;
    overflow-wrap: anywhere;
`;

const ButtonsContainer = styled.td`
  width: 1%;
`;

export default PickUpPointsTable;
