import get from 'lodash/get';
import React, { FunctionComponent } from 'react';
import { FieldProps } from 'formik';

import { NumberInput } from 'components';
import FieldContainer from './FieldContainer';

interface IProps {
    className?: string;
    label?: string;
    disabled?: boolean;
    value?: number;
    minValue?: number;
    maxValue?: number;
    step?: number;
    onChangeSlotDuration?: (value: number | string) => void;
    testID?: string;
}

const NumberInputField: FunctionComponent<FieldProps & IProps> = (props) => {
    const { field, form, disabled, minValue, maxValue, step, onChangeSlotDuration, testID } = props;
    const onChange = (value: number | string) => {
        form.setFieldValue(field.name, value);
        form.setFieldTouched(field.name);
    };
    return (
        <FieldContainer {...props}>
            <NumberInput
                {...(testID && {testID:`${testID}-number-input`})}
                {...field}
                minValue={minValue}
                maxValue={maxValue}
                step={step}
                onChange={onChangeSlotDuration ? onChangeSlotDuration : onChange}
                hasError={!!get(form.errors, field.name) && !!get(form.touched, field.name)}
                disabled={disabled}
            />
        </FieldContainer>
    );
};

export default NumberInputField;
