import styled from 'styled-components';

export const Header = styled.header`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing.s}px ${({ theme }) => theme.spacing.m}px;
    padding-bottom: 0px;
    background-color: ${({ theme }) => theme.color.common.white};
`;

export const Top = styled.div`
    height: ${({ theme }) => theme.dimension.height.element}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const PageContent = styled.div`
    padding: 0 ${({ theme }) => theme.spacing.m}px;
    padding-top: ${({ theme }) => theme.spacing.l}px;
`;

export const Breadcrumb = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    color: ${props => props.theme.color.common.blue};
    font-weight: ${({ theme }) => theme.typography.fontWeight.lighter};
    text-align: left;
    & > * {
        margin-right: ${({ theme }) => theme.spacing.s}px;
    }
`;

export const Section = styled.section`
    max-width: ${({ theme }) => theme.dimension.width.mainContent}px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
`;

export const Card = styled.article`
    flex-grow: 1;
    width: 100%;
    padding: ${({ theme }) => theme.spacing.s}px;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    background-color: ${({ theme }) => theme.color.common.white};
`;
