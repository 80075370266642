import React from 'react';
import styled from 'styled-components';

import { Info, InfoType, InfoSize } from 'components';

interface IProps {
    name: string;
    nameItalic?: string | null;
    children: string | number;
    testID?: string;
}

export function ReservationsInfo({ children, name, nameItalic, testID }: IProps) {
    return (
        <Container data-test={testID}>
            <Name>{name}</Name>
            {nameItalic ? <NameItalic>{nameItalic}</NameItalic> : ''}
            <Info size={InfoSize.TALL} display={InfoType.INFO}>
                {children}
            </Info>
        </Container>
    );
}

interface IInfoTagProps {}
const Container = styled.div<IInfoTagProps>`
    display: flex;
    align-items: center;
`;
const Name = styled.div`
    margin-right: ${(props) => props.theme.spacing.xxs / 2}px;
    color: ${(props) => props.theme.color.grey[6]};
    font-size: ${(props) => props.theme.typography.fontSizeS}px;
`;

const NameItalic = styled.div`
    margin-right: ${(props) => props.theme.spacing.xxs / 2}px;
    color: ${(props) => props.theme.color.grey[6]};
    font-size: ${(props) => props.theme.typography.fontSizeS}px;
    font-style: italic;
`;

export default ReservationsInfo;
