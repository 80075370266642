import { useState } from 'react';
import { QuerySearchInput } from 'types/globalTypes';
import { PaginationOptions } from 'localTypes';
import { resetPagination, DEFAULT_ROW_PER_PAGE } from 'components/Table/Paginator';

export default function useTableOptions<T extends string>(): {
    queryParams: { [key in T]?: QuerySearchInput };
    onSearchValueChange: (params: { [key in T]?: QuerySearchInput }) => void;
    querySearch: QuerySearchInput[];
    onPaginationChange: (paginationOptions: PaginationOptions) => void;
    paginationOptions: PaginationOptions;
} {
    const [queryParams, setQueryParams] = useState({
        first: DEFAULT_ROW_PER_PAGE,
        after: undefined,
        last: undefined,
        before: undefined,
    } as { [key in T]?: QuerySearchInput } & PaginationOptions);
    const { first, after, last, before, ...querySearchValues } = queryParams;
    const querySearch = Object.values(querySearchValues).filter(Boolean) as QuerySearchInput[];

    const onSearchValueChange = (newParams: { [key in T]?: QuerySearchInput }) => {
        const newPaginationOptions = after || last || before ? { ...resetPagination, first: first || last } : {};
        setQueryParams({ ...queryParams, ...newParams, ...newPaginationOptions });
    };

    const onPaginationChange = (paginationOptions: PaginationOptions) => {
        setQueryParams({ ...queryParams, ...paginationOptions });
    };

    return {
        queryParams,
        onSearchValueChange,
        querySearch,
        onPaginationChange,
        paginationOptions: {
            first: !first && !last ? DEFAULT_ROW_PER_PAGE : parseInt(first as any, 10),
            after,
            last: last && parseInt(last as any, 10),
            before,
        },
    };
}
