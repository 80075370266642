import { Icon } from 'components';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import routes from 'Router/routes';
import styled from 'styled-components';
import { MarketingTypeNew } from 'types/globalTypes';

const PublishMarketingCard = (props: any) => {
    const { t } = useTranslation();
    const type = (props.location && props.location.state.values.type) || '';
    const publicationStartDate = (props.location && props.location.state.values.publicationStartDate) || undefined;
    return (
        <PublishPageContainer>
            <Icon.PublishPage />
            {publicationStartDate && new Date(publicationStartDate).getTime() > new Date().getTime() ? (
                <FuturePublishPageTitle>
                    <Trans
                        i18nKey="page:communication.communicationList.futurePublishPageTitle"
                        values={{ date: publicationStartDate.toLocaleDateString() }}
                        components={[<strong></strong>]}
                    />
                </FuturePublishPageTitle>
            ) : (
                <>
                    <PublishPageTitle>{t('page:communication.communicationList.publishPageTitle')}</PublishPageTitle>
                    {type === MarketingTypeNew.ARTICLE ? (
                        <PublishPageContent>
                            {t('page:communication.communicationList.publishPageContentArticle')}
                        </PublishPageContent>
                    ) : (
                        <PublishPageContent>
                            {t('page:communication.communicationList.publishPageContentNews')}
                        </PublishPageContent>
                    )}
                </>
            )}
            <Link to={routes.communication.list()}>
                <PublishPageButton onClick={() => {}}>
                    {t('page:communication.communicationList.publishPageButton')}
                </PublishPageButton>
            </Link>
        </PublishPageContainer>
    );
};

const PublishPageContainer = styled.div`
    height: 100%;
    min-height: 400px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

const PublishPageTitle = styled.span`
    font-size: ${({ theme }) => theme.typography.size.M}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bolder};
    line-height: ${({ theme }) => theme.typography.lineHeight.M}px;
    margin-top: 40px;
    color: ${({ theme }) => theme.color.common.navy};
    font-family: Open Sans;
    letter-spacing: 0;
    text-align: center;
`;

const FuturePublishPageTitle = styled.span`
    font-size: ${({ theme }) => theme.typography.size.M}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bolder};
    line-height: ${({ theme }) => theme.typography.lineHeight.M}px;
    margin-top: 40px;
    margin-bottom: ${({ theme }) => theme.spacing.l}px;
    color: ${({ theme }) => theme.color.common.navy};
    font-family: Open Sans;
    letter-spacing: 0;
    text-align: center;
`;

const PublishPageContent = styled.span`
    font-size: ${({ theme }) => theme.typography.size.M}px;
    line-height: ${({ theme }) => theme.typography.lineHeight.M}px;
    margin-top: ${({ theme }) => theme.spacing.xxs}px;
    margin-bottom: ${({ theme }) => theme.spacing.l}px;
    color: ${({ theme }) => theme.color.common.navy};
    font-family: Open Sans;
    letter-spacing: 0;
    text-align: center;
`;

const PublishPageButton = styled.span`
    font-size: ${({ theme }) => theme.typography.size.S}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bolder};
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    color: ${({ theme }) => theme.color.common.blue};
    font-family: Open Sans;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;
`;

export default withRouter(PublishMarketingCard);
