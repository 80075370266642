import { QueryLoader } from 'components';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import AddOrEditServiceForm from './AddOrEditServiceForm';
import { getChecklistService } from 'types/listChecklistServices';

const SERVICE_MUTATION = loader('../query/updateEPointageService.gql');
const SERVICE_QUERY = loader('../query/getEPointageService.gql');

interface IPropsPanel extends RouteComponentProps<{ siteId: string, idService: string }> {
    beforeClose: () => void;
}

const EditServicePage = ({
    match: {
        params: { idService, siteId },
    },
    history: { push },
    beforeClose,
}: IPropsPanel) => {
    const [t] = useTranslation();
    const onClose = () => {
        beforeClose();
        push(`${routes.checkList.services.list(siteId)}`)
    };

    return (
        <QueryLoader
            hasData={(data: getChecklistService): boolean => !!(data && data.service)}
            variables={{ id: parseInt(idService) }}
            fetchPolicy='network-only'
            query={SERVICE_QUERY}
        >
            {({ data: { service } }: QueryResult<getChecklistService>) => {
                return (
                    <Mutation
                        mutation={SERVICE_MUTATION}
                        update={(cache: any, { data: { updateEPointageService } }: any) => {
                            const cachedData = cache.readQuery({
                                query: SERVICE_QUERY,
                                variables: {
                                    id: parseInt(idService),
                                },
                            });
                            cache.writeQuery({
                                query: SERVICE_QUERY,
                                variables: {
                                    id: parseInt(idService),
                                },
                                data: { ...cachedData, service: updateEPointageService },
                            });
                        }}
                    >
                        {(updateEPointageService: (param: Record<'variables', any>) => Promise<any>) => (
                            <AddOrEditServiceForm
                                {...{
                                    onSuccess: onClose,
                                    service,
                                    updateEPointageService,
                                    siteId
                                }}
                            />
                        )}
                    </Mutation>
                );
            }}
        </QueryLoader>
    );
};

export default withRouter(EditServicePage);
