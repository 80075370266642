import React, { FunctionComponent } from 'react';
import { FieldProps } from 'formik';

import Toggle, { IToggleProps } from '../Toggle';
import FieldContainer from './FieldContainer';

const ToggleField: FunctionComponent<FieldProps & IToggleProps> = props => {
    const { field, form, ...rest } = props;
    const onChange = () => {
        form.setFieldValue(field.name, !field.value);
        form.setFieldTouched(field.name);
    };

    return (
        <FieldContainer {...props}>
            <Toggle {...rest} {...field} onChange={onChange} checked={field.value === true} />
        </FieldContainer>
    );
};

export default ToggleField;
