import React, { useState, useRef, ReactElement } from 'react';
import { appTheme } from 'theme';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MutationOptions } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { QueryResult } from 'localTypes';
import gql from 'graphql-tag';
import { uploadFile, uploadFileVariables } from 'types/uploadFile';
import { Icon, Text, Button, QueryLoader, Row } from 'components';
import { ButtonType } from 'components/Button';
import { IInputProps } from './common';
import Loader from 'components/Loader';

interface IImageDetailsProps {
    width: number;
    height: number;
    filename: string;
    button: ReactElement;
}
const ImageDetails = ({ width, height, filename, button }: IImageDetailsProps) => (
    <TextWrapper>
        <Text overflow="hidden" bold>
            {filename}
        </Text>
        <Text>
            {width} × {height}{' '}
        </Text>
        <Text italic>Conseillé : 300 × 300</Text>
        {button}
    </TextWrapper>
);

interface IImageCDNPreviewProps {
    path: string;
}

const ImageCDNPreview = ({ width, height, filename, button, path }: IImageDetailsProps & IImageCDNPreviewProps) => (
    <Wrapper>
        <ImageWrapper src={`${window.config.IMAGE_BASE_URL}/resize/400x400/${path}`} />
        <ImageDetails {...{ width, height, filename, button }} />
    </Wrapper>
);

interface IImage extends Blob {
    name: string;
    path: string;
    size: number;
}
interface IImageProps {
    image: IImage;
    button: ReactElement;
}

const reader = new FileReader();
const ImageFilePreview = ({ image, button }: IImageProps) => {
    const [dimensions, setDimensions] = useState({ w: 0, h: 0 });
    const { name } = image;
    const { w, h } = dimensions;
    const imgRef = useRef<HTMLImageElement>(null);
    reader.onload = (e: any) => {
        if (imgRef && imgRef.current) {
            imgRef.current.src = e.target.result;
            setDimensions({
                w: imgRef.current.naturalWidth,
                h: imgRef.current.naturalHeight,
            });
        }
    };
    if (!w && !h) {
        reader.readAsDataURL(image);
    }
    return (
        <Wrapper>
            <ImageWrapper ref={imgRef} />
            {!!w && <ImageDetails {...{ width: w, height: h, filename: name, button }} />}
        </Wrapper>
    );
};

export const DropZoneFile = ({
    setSelectedImage,
    hasError,
    accept,
}: {
    setSelectedImage: Function;
    hasError?: boolean;
    accept: string;
}) => {
    const [t] = useTranslation();
    const [error, setError] = useState('');

    return (
        <Dropzone
            onDrop={files => {
                if (files[0] && files[0].type.includes('image')) {
                    setSelectedImage(files as []);
                } else {
                    setError(t('component:fileInput.formatNotAllowed') || '');
                }
            }}
        >
            {({ getRootProps, getInputProps }) => (
                <InputWrapper {...getRootProps()} hasError={hasError}>
                    <Row justify="center">
                        <IconWrapper>
                            <Icon.Attach />
                        </IconWrapper>
                        <Text bold color={appTheme.color.common.blue}>
                            {t('component:fileInput.uploadFile1')}
                        </Text>
                        &nbsp;
                        <Text>{t('component:fileInput.uploadFile2')}</Text>
                        <input {...getInputProps()} accept={accept} />
                    </Row>
                    {!!error && (
                        <Text align="center" color={appTheme.color.common.red}>
                            {error}
                        </Text>
                    )}
                </InputWrapper>
            )}
        </Dropzone>
    );
};

interface IProps extends IInputProps<any> {
    inline?: boolean;
    accept?: string;
}
export const UploadFile = ({
    children,
}: {
    children: (mutate: MutationOptions<uploadFile, uploadFileVariables>, loading: boolean) => ReactElement;
}) => {
    return (
        <Mutation<uploadFile, uploadFileVariables>
            mutation={gql`
                mutation uploadFile($file: Upload) {
                    upload(file: $file) {
                        id
                        hash
                        path
                        created
                    }
                }
            `}
        >
            {(mutate, { loading }: { loading: boolean }) => 
              // @ts-ignore
              children(mutate, loading)
            }
        </Mutation>
    );
};

export default function FileInput({
    value,
    onChange,
    disabled,
    hasError,
    accept = 'image/png, image/jpeg',
    testID,
    ...props
}: IProps) {
    const [selectedImage, setSelectedImage] = useState([]);
    const [t] = useTranslation();
    const id = value ? value.id : null;
    return (
        //@ts-ignore
        <FieldWrapper {...props} data-test={testID}>
            {id && (
                <QueryLoader
                    // @ts-ignore
                    variables={{ id }}
                    query={gql`
                        query getFile($id: ID!) {
                            image: get(id: $id) {
                                ... on Image {
                                    id
                                    path
                                    width
                                    height
                                    filename
                                }
                            }
                        }
                    `}
                >
                    {({ data: { image } }: QueryResult<any>) => {
                        return (
                            <ImageCDNPreview
                                {...image}
                                button={
                                    <Button
                                        disabled={disabled}
                                        onClick={() => {
                                            setSelectedImage([]);
                                            onChange && onChange({ id: null });
                                        }}
                                        display={ButtonType.SECONDARY}
                                    >
                                        {t('component:fileInput.replace')}
                                    </Button>
                                }
                            />
                        );
                    }}
                </QueryLoader>
            )}
            {selectedImage.map((image: IImage) => (
                <UploadFile key={image.name}>
                    {(mutate, loading) => (
                        <ImageFilePreview
                            {...{ image }}
                            button={
                                <Button
                                    disabled={loading}
                                    onClick={async () => {
                                        // @ts-ignore
                                        const res = await mutate({ variables: { file: image } });
                                        if (res && res.data) {
                                            setSelectedImage([]);
                                            onChange && onChange(res.data.upload);
                                        }
                                    }}
                                    display={ButtonType.SECONDARY}
                                >
                                    {loading ? <Loader /> : t('component:fileInput.upload')}
                                </Button>
                            }
                        />
                    )}
                </UploadFile>
            ))}
            {selectedImage.length === 0 && !id && (
                <DropZoneFile setSelectedImage={setSelectedImage} hasError={hasError} accept={accept} />
            )}
        </FieldWrapper>
    );
}

const FieldWrapper = styled.div<IInputWrapperProps>`
    width: 100%;
    ${({ hasError, theme }) => `border: 1px solid ${hasError ? theme.color.input.borderColorError : 'none'};`};
`;

const IconWrapper = styled.div`
    margin: 0 10px;
    display: flex;
    align-content: center;
`;
interface IInputWrapperProps {
    hasError?: boolean;
}
const InputWrapper = styled.div<IInputWrapperProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 157px;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    background-color: ${({ theme }) => theme.color.input.backgroundColor};
    cursor: pointer;
    ${({ hasError, theme }) =>
        `border: 1px solid ${hasError ? theme.color.input.borderColorError : theme.color.input.borderColor};`}
`;

const Wrapper = styled.div`
    display: flex;
    height: 157px;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 25px;
    overflow: hidden;
`;

const ImageWrapper = styled.img`
    flex-shrink: 0;
    height: 146px;
    width: 146px;
    border-radius: 8px;
    display: flex;
    object-fit: cover;
`;
