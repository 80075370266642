import React, { ComponentType, ReactElement, useEffect } from 'react';
import { Route, Redirect, Switch, withRouter, RouteComponentProps } from 'react-router-dom';

import { Struct, Tab, Tabs, Title } from 'components';
import useTabIndexSelector from 'components/Tabs/useTabIndexSelector';
import { IRouteFunction } from 'Router/utils';

export interface IRoute {
    // url param of the tab
    tabKey: string;
    tabLabel: string;
    path: IRouteFunction;
    component: ComponentType<any>;
    props?: Object;
    hideSelectLayoutPOS?: boolean;
}

interface IProps {
    indexRoute: string;
    HeaderComponent?: ComponentType<any>;
    title?: string | null;
    Icon?: ReactElement;
    routes: IRoute[];
    onChangeTab?: (tabIndex: number) => void;
}

const PageWithTabs = ({
    routes,
    HeaderComponent,
    indexRoute,
    title = '',
    Icon,
    onChangeTab,
    match: {
        params: { tab, idHolding, idPos },
    },
}: IProps & RouteComponentProps<{ tab: string; idHolding?: string; idPos?: string }>) => {
    const [selectedTabIndex, setSelectedTabIndex] = useTabIndexSelector(routes, tab);

    useEffect(() => {
        onChangeTab && onChangeTab(selectedTabIndex)
    }, [onChangeTab, selectedTabIndex]);

    const TabsComponent = (
        <Tabs value={selectedTabIndex} onChange={setSelectedTabIndex}>
            {routes.map(({ tabKey, path, tabLabel }) => (
                <Tab 
                  id={`tab-${tabKey}`} 
                  key={tabKey} 
                  linkTo={path(idHolding, idPos)} 
                  label={tabLabel} 
                  testID={`tab-${title}-${tabKey}`}
                />
            ))}
        </Tabs>
    );

    return (
        <>
            {HeaderComponent && <HeaderComponent />}
            <>
                <Struct.Header>
                    {!HeaderComponent && <Title testID={`page-header-${title}`} mode="H1" value={title} icon={Icon} />}
                    {TabsComponent}
                </Struct.Header>
            </>
            <Struct.PageContent>
                <Switch>
                    <Route exact path={indexRoute}>
                        <Redirect to={routes[0].path(idHolding, idPos)} />
                    </Route>
                    {routes.map(({ tabKey, path, component: Component, props = {} }) => (
                        <Route
                            key={tabKey}
                            path={path()}
                            render={routeProps => <Component {...props} {...routeProps} />}
                        />
                    ))}
                </Switch>
            </Struct.PageContent>
        </>
    );
};

export default withRouter(PageWithTabs);
