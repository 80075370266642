import React from 'react';
import { useTranslation } from 'react-i18next';
import { loader } from 'graphql.macro';
import { Mutation } from '@apollo/client/react/components';
import { StyledButton } from './EmployeesPage.style';

const DELETE_EMPLOYEE = loader('./query/deleteEmployee.gql');

const DeleteEmployeeButton = ({ 
    id,
    onChange,
}: {
    id: string;
    onChange: () => void;
}) => {
    const { t } = useTranslation();

    return (
        <Mutation mutation={DELETE_EMPLOYEE}>
            {(deleteEmployee: (param: Record<'variables', any>) => Promise<any>) => {
                const onDeleteClick = async () => {
                    await deleteEmployee({
                        variables: { id },
                    });
                    onChange();
                };

                return (
                    <StyledButton onMouseDown={onDeleteClick}>
                        {t(`app:button.delete`)}
                    </StyledButton>
                );
            }}
        </Mutation>
    );
}

export default (DeleteEmployeeButton);
