import { QueryLoader, RoutablePanel, Struct, Title, ToggleSwitch, InputLabel } from 'components';
import { IRenderProps as IPanelRenderProps } from 'components/Panel';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import React, { useState } from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import appRoutes from 'Router/routes';
import {
    holdingPos,
    holdingPos_concepts_edges_node_Concept,
    holdingPos_holding_Holding,
    holdingPos_zones_edges_node_Zone,
    holdingPos_posTypes_edges_node_PosTypeModel,
    holdingPos_pos_Pos,
} from 'types/holdingPos';
import { getNodes } from 'services/queryService';
import EditPos from './EditPos';
import EditZone from './EditZone';
import Zone from './Zone';
import { PosSortingStrategy } from 'types/globalTypes';

interface IProps extends RouteComponentProps<{ idHolding: string; idPos?: string }> {}

const GET_HOLDING_POS_QUERY = loader('../query/holdingPos.gql');

const Pos = ({
    match: {
        params: { idHolding },
    },
}: IProps) => {
    const [showEmpty, setShowEmpty] = useState(false);
    const [t] = useTranslation();
    return (
        <QueryLoader
            hasData={(data: holdingPos): boolean => !!(data && data.holding)}
            query={GET_HOLDING_POS_QUERY}
            variables={{ idHolding, idPos: '', getPos: false, getTakenPositions: false }}
        >
            {({ data }: QueryResult<holdingPos>) => {
                const holding = data.holding! as holdingPos_holding_Holding;
                return (
                    <>
                        <Struct.Section>
                            <Title grow mode="H2" value={holding.label}>
                                <div>
                                    <InputLabel inline name={t('page:holding.pos.label.toggleClosedPOS')}></InputLabel>
                                    <ToggleSwitch checked={showEmpty} onChange={() => setShowEmpty(!showEmpty)} />
                                </div>
                            </Title>
                        </Struct.Section>
                        {getNodes(data.zones).map((zone, index) => (
                            <Zone
                                zone={zone as holdingPos_zones_edges_node_Zone}
                                hideEmpty={!showEmpty}
                                key={`zone-${index}`}
                            />
                        ))}
                        <TransitionGroup>
                            <Route exact path={appRoutes.holding.pos.edit()} component={EditPosPanel} />
                            <Route exact path={appRoutes.holding.pos.zoneEdit()} component={EditZonePanel} />
                        </TransitionGroup>
                    </>
                );
            }}
        </QueryLoader>
    );
};

interface IPropsEditPosPanel extends RouteComponentProps<{ idHolding: string; idPos?: string }> {}

const EditPosPanel = withRouter(
    ({
        history: { push },
        match: {
            params: { idHolding, idPos },
        },
    }: IPropsEditPosPanel) => {
        const onClose = () => {
            push(appRoutes.holding.pos.index(idHolding));
        };
        return (
            <RoutablePanel title="Edition d'un point de vente" open={!!idPos} onClose={onClose}>
                {({ onClose }: IPanelRenderProps) => {
                    return !idPos ? null : (
                        <QueryLoader
                            query={GET_HOLDING_POS_QUERY}
                            variables={{ idHolding, idPos, getPos: true, getTakenPositions: true }}
                            hasData={(data: holdingPos): boolean => !!(data && data.concepts)}
                            fetchPolicy={'network-only'}
                        >
                            {({ data }: QueryResult<holdingPos>) => {

                                const takenPositions = getNodes(data.takenPositions)
                                    .map(({ positionNumber }) => positionNumber);

                                return (
                                    <EditPos
                                        onSuccess={onClose}
                                        pos={data.pos as holdingPos_pos_Pos}
                                        holding={data.holding as holdingPos_holding_Holding}
                                        takenPositions={takenPositions}
                                        showPositionNumber={data.holdingSetting?.posSortingStrategy === PosSortingStrategy.Customized}
                                        concepts={getNodes(data.concepts) as holdingPos_concepts_edges_node_Concept[]}
                                        posTypes={
                                            getNodes(data.posTypes) as holdingPos_posTypes_edges_node_PosTypeModel[]
                                        }
                                        zones={getNodes(data.zones) as holdingPos_zones_edges_node_Zone[]}
                                    />
                                );
                            }}
                        </QueryLoader>
                    );
                }}
            </RoutablePanel>
        );
    }
);

interface IPropsEditZonePanel extends RouteComponentProps<{ idHolding: string; idZone?: string }> {}

const EditZonePanel = withRouter(
    ({
        history: { push },
        match: {
            params: { idHolding, idZone },
        },
    }: IPropsEditZonePanel) => {
        const onClose = () => {
            push(appRoutes.holding.pos.index(idHolding));
        };
        return (
            <RoutablePanel title="Edition d'une Zone" open={!!idZone} onClose={onClose}>
                {({ onClose }: IPanelRenderProps) => {
                    return !idZone ? null : (
                        <QueryLoader
                            query={GET_HOLDING_POS_QUERY}
                            variables={{ idHolding, idPos: '', getPos: false, getTakenPositions: false }}
                            hasData={(data: holdingPos): boolean => !!(data && data.zones)}
                        >
                            {({ data }: QueryResult<holdingPos>) => {
                                const zones = getNodes(data.zones) as holdingPos_zones_edges_node_Zone[];
                                const zone = zones.find((zone) => zone.id === idZone);
                                return zone ? <EditZone zone={zone} onSuccess={onClose} /> : null;
                            }}
                        </QueryLoader>
                    );
                }}
            </RoutablePanel>
        );
    }
);

export default withRouter(Pos);
