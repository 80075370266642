import I18n from 'services/i18n';
import { IPaymentTypes } from 'types/createOfferTemplate';

export class OfferTemplateTranslation {
    language: string;
    name: string;
    description: string;
    fullDescription: string | null;
    onlineHelp: string | null;

    private constructor(
        language: string,
        name: string,
        description: string,
        fullDescription: string | null,
        onlineHelp: string | null
    ) {
        this.language = language;
        this.name = name;
        this.description = description;
        this.fullDescription = fullDescription;
        this.onlineHelp = onlineHelp;
    }

    public static createOnlineHelpTranslation(
        language: string,
        name: string,
        description: string,
        fullDescription: string | null,
        onlineHelp: string | null
    ): OfferTemplateTranslation {
        return new OfferTemplateTranslation(language, name, description, fullDescription, onlineHelp);
    }
}

export const getProductFamilyOptions = (allowedFamilies: string[]) =>
    allowedFamilies.map((family: string) => ({
        id: family,
        label: I18n.t(`schema:article.familyOption.${family}`),
    }));

export const getPaymentTypes = (
    badge: boolean,
    cc: boolean,
    edenred: boolean,
    onsite: boolean,
) => [
    badge && IPaymentTypes.Badge,
    cc && IPaymentTypes.CreditCard,
    edenred && IPaymentTypes.Edenred,
    onsite && IPaymentTypes.OnSite,
].filter(type => !!type) as IPaymentTypes[];
