import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { loader } from 'graphql.macro';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { fixedTranslation } from 'services/translationService';
import LanguageTextInput from 'components/LanguageTextInput';
import { ErrorMessageLighter, InputLabel } from 'components';
import { appTheme } from 'theme';
import {
    AVAILABLE_BACKGROUNDS as availableBackgrounds,
    IMBackgroundPicker as BackgroundPicker,
    ErrorMessageWrapper,
} from '../StepTwo';
import { isValidURL } from '../../../../utils';

const PreviewImage = loader('../../query/getImageForPreview.gql');

interface IInfoNewsProps {
    values: any;
    rank: number;
    onChange: (value: string, language: string) => void;
}
//Render a Info News
const InfoNews = ({ values, rank, onChange }: IInfoNewsProps) => {
    const { t } = useTranslation();
    const availableLanguages = values.languages;
    const langsObj = availableLanguages.reduce((acc, curr) => {
        acc[curr] = '';
        return acc;
    }, {}); // {fr:'', en:''}

    const htmlTitleParagraphStart = `<p id="Title" style="margin: 0; color: #FFFFFF; font-family:${appTheme.typography.fontFamily}; font-size: 18px; font-weight: ${appTheme.typography.fontWeight.bold}; letter-spacing: 0; line-height: 22px;">`;
    const htmlContentParagraphStart = `<p id="Content" style="margin: 0; color: #FFFFFF; font-family:${appTheme.typography.fontFamily}; font-size: 16px; letter-spacing: 0; line-height: 20px;">`;
    const htmlParagraphsEnd = '</p>';

    const [backgroundId, setBackgroundId] = useState('');
    const [title, setTitle] = useState(langsObj);
    const [content, setContent] = useState(langsObj);
    const [link, setLink] = useState('');
    const [linkValid, setLinkValid] = useState(true);
    const [typeTimeout, setTypeTimeout] = useState<number | null>(null);
    const [hasValue, setHasValue] = useState(false);

    const { loading, data } = useQuery(PreviewImage, {
        variables: {
            querySearch: {
                key: 'id',
                value: backgroundId,
                operator: '*',
            },
        },
        skip: !!!backgroundId, // doesn't do the query if this value is undefined; (skip: boolean)
    });

    const handlePreviousValue = useCallback(() => {
        if (!hasValue) {
            const temp = availableLanguages.map(
                (lang: string) => !!values[`content${lang.toUpperCase()}`][rank].content
            ); // check if already has value by language
            const hasPreviousValue = temp.some((value: boolean) => value); //if any element of the previous array is true, then hasPreviousValue will be true
            setHasValue(hasPreviousValue);
        }
    }, [availableLanguages, hasValue, rank, values]);

    useEffect(() => {
        handlePreviousValue();
        // this is needed because values.content is always true since the card is 
        // created as soon as you select infoNews, but only on isModeEdit it will actually 
        // have real content, or when you go back and forward on the steps
    }, [values, hasValue, rank, handlePreviousValue]);

    useEffect(() => {
        handleEditMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasValue]);

    useEffect(() => {
        if (!loading) {
            createPreview();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, content, backgroundId, link, loading]);

    const handleEditMode = () => {
        //get the InfoNews content from the html string
        if (values && !hasValue) {
            const tempTitle = { ...title };
            const tempContent = { ...content };
            availableLanguages.forEach((lang: string) => {
                const _content = values[`content${lang.toUpperCase()}`][rank];

                if (_content.key === 'infoNews' && _content.content.length > 0) {
                    let infoNewsBackground = '';
                    const rawContent = _content.content;
                    const infoNewsParagraphs = rawContent
                        ? rawContent.split(htmlTitleParagraphStart)[1].split(htmlParagraphsEnd)
                        : '';
                    if (rawContent.split('Image:')[1]) {
                        //for local
                        infoNewsBackground = `Image:${rawContent.split('Image:')[1].charAt(0)}`;
                    } else {
                        //for Prod
                        //in Prod we use "Image:{id}" string encoded in base64
                        infoNewsBackground = rawContent.split('<img alt="')[1].split('"')[0];
                    }
                    const infoNewsTitle = infoNewsParagraphs ? infoNewsParagraphs[0] : '';
                    const infoNewsContent = infoNewsParagraphs
                        ? infoNewsParagraphs[1].split(htmlContentParagraphStart)[1]
                        : '';

                    if (infoNewsBackground) {
                        setBackgroundId(infoNewsBackground);
                    }
                    if (infoNewsTitle) {
                        tempTitle[lang] = infoNewsTitle;
                    }
                    if (infoNewsContent) {
                        tempContent[lang] = infoNewsContent;
                    }

                    const infoNewsLink = rawContent ?
                        rawContent.split('<a href="')[1] && rawContent.split('<a href="')[1].split('"')[0] : '';
                    if (infoNewsLink) {
                        setLink(infoNewsLink);
                    }
                }
            });
            setTitle(tempTitle);
            setContent(tempContent);
        }
    };

    interface IInfoNewsValue {
        value: string;
        fieldName: string;
    }
    const handleOnChange = ({ value, fieldName }: IInfoNewsValue, language: string) => {
        if (fieldName === 'infoNewsTitle') {
            setTitle({ ...title, [language]: value });
        } else {
            setContent({ ...content, [language]: value });
        }
    };

    const onChangeLinkInput = (value: string) => {
        setLink(value);
        if (typeTimeout !== null) {
            // clear previous timer
            clearTimeout(typeTimeout);
            setTypeTimeout(null);
        }

        // clear link previous error while the user is typing
        setLinkValid(true);

        // trigger field validation half a second after the user stops writing
        setTypeTimeout(
            setTimeout(() => {
                setLinkValid(!value ? true : isValidURL(value));
            }, 500)
        );
    };

    const createPreview = () => {
        //this creates a html string of the whole infoNews card and sends it through the onChange callback
        availableLanguages.forEach(async (language: string) => {
            if (backgroundId) {
                const isValidLink = !link ? true : isValidURL(link); //the link is optional
                const isValidTitle = availableLanguages.map((lang) => !!title[lang].length).every(Boolean); // returns false if there is at least one title without length by language
                const isValidContent = availableLanguages.map((lang) => !!content[lang].length).every(Boolean); //checks for content length by language
                const isValidInfoNews = isValidTitle && isValidContent && isValidLink ? 'isValid' : 'notValid';
                const firstHTMLPart =
                    data && data.image
                        ? `<div id="${isValidInfoNews}" style="position: relative; width: 100%; border-radius: 8px; margin-top: 20px;">
                            <img alt="${backgroundId}" src="${window.config.IMAGE_BASE_URL}/resize/${data.image.edges[0].node.height}x${data.image.edges[0].node.width}/${data.image.edges[0].node.path}" style="width: 100%; position: relative; border-radius: 8px;"/>`
                        : '<div id="notValid" style="position: relative; width: 100%; background-color: #cccccc; border-radius: 8px;">';

                const cardContent = `
                        <div style="position: absolute; top: 0px; margin: 20px; display: flex; flex-direction: column; justify-content: space-between; height: 85%;">
                            <div>
                                ${htmlTitleParagraphStart}${title[language]}${htmlParagraphsEnd}
                                <br/>
                                ${htmlContentParagraphStart}${content[language]}${htmlParagraphsEnd}
                            </div>
                            ${
                                link && isValidLink
                                    ? `<span style="color: #FFFFFF; font-family:${
                                          appTheme.typography.fontFamily
                                      }; font-size: 18px; margin-bottom: 10px;">${fixedTranslation(
                                          language,
                                          'page:communication.addMarketingCard.component.infoNewsSeeMore'
                                      )} > </span>`
                                    : ''
                            }
                        </div>
                    </div>`;

                //the anchor is not clickable if the link is empty
                const finalHTML = `<a ${
                    link && isValidLink ? `href="${link}"` : ''
                } ${
                    link && isValidLink ? `target="_blank"` : ''
                } style="width: 100%;">${firstHTMLPart}${cardContent}</a>`;

                onChange(finalHTML, language);
            }
        });
    };

    const backgroundPickerRender = () => {
        const picker = (edit: boolean) => (
            <BackgroundPicker
                availableBackgrounds={availableBackgrounds}
                setIdImageList={(image) => setBackgroundId(image[0].id)}
                value={backgroundId}
                isModeEdit={edit}
            />
        );
        for (const lang of availableLanguages) {
            if (!values[`content${lang.toUpperCase()}`][rank].content && !hasValue) {
                //if the user selects a "new" infoNews when editing an existing Article
                return picker(false);
            } else if (backgroundId !== '') return picker(true); //if is editing an existing infoNews, waits for the background before render the picker
        }
        return null;
    };

    const renderLinkErrorMessage = () => {
        return !linkValid ? (
            <ErrorMessageWrapper>
                <ErrorMessageLighter>{t('page:communication.addMarketingCard.linkError')}</ErrorMessageLighter>
            </ErrorMessageWrapper>
        ) : null;
    };

    return (
        <InfoNewsWrapper>
            <LabelFieldWrapper>
                <InputLabel
                    inline
                    name={
                        <Trans
                            i18nKey="page:communication.addMarketingCard.articleTitlePart"
                            components={[<strong />, <i />]}
                        />
                    }
                    showRequiredAsterisk
                />
            </LabelFieldWrapper>
            {availableLanguages.map((language) => (
                <LanguageTextInput
                    key={`${language}InfoNewsTitle`}
                    locale={language}
                    name={'infoNewsTitle'}
                    maxLength={80}
                    multiline
                    placeholder={fixedTranslation(
                        language,
                        `page:communication.addMarketingCard.articleTitlePlaceholder`
                    )}
                    value={title[language]}
                    onChange={(value) => handleOnChange(value, language)}
                />
            ))}
            <LabelFieldWrapper>
                <InputLabel
                    inline
                    name={
                        <Trans
                            i18nKey="page:communication.addMarketingCard.importantMessageResume"
                            components={[<strong />, <i />]}
                        />
                    }
                    showRequiredAsterisk
                />
            </LabelFieldWrapper>
            {availableLanguages.map((language) => (
                <LanguageTextInput
                    key={`${language}InfoNewsContent`}
                    locale={language}
                    name={'infoNewsContent'}
                    maxLength={140}
                    multiline
                    placeholder={fixedTranslation(
                        language,
                        `page:communication.addMarketingCard.articleResumePlaceholder`
                    )}
                    value={content[language]}
                    onChange={(value) => handleOnChange(value, language)}
                />
            ))}

            <LanguageTextInput
                bottomSize={!linkValid ? 'xxs' : undefined}
                useDefaultErrorMessage={false}
                useDefaultErrorColoring={false}
                onChangeField={onChangeLinkInput}
                multiline={true}
                placeholder={t(`page:communication.addMarketingCard.linkPlaceholder`)}
                label={
                    <Trans
                        i18nKey="page:communication.addMarketingCard.linkOptional"
                        components={[<strong />, <i />]}
                    />
                }
                name="InfoNewsLink"
                value={link}
            />
            {renderLinkErrorMessage()}
            {backgroundPickerRender()}
        </InfoNewsWrapper>
    );
};

const InfoNewsWrapper = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.s}px;
`;

const LabelFieldWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.xs}px;
    padding-bottom: ${({ theme }) => theme.spacing.xs}px;
    background-color: ${({ theme }) => theme.color.common.white};
`;


export default InfoNews;
