import Loader from 'components/Loader';
import React, { PureComponent, KeyboardEvent, ReactNode } from 'react';
import onClickOutside from 'react-onclickoutside';
import styled from 'styled-components';
import { appTheme } from 'theme';

export interface ISelectOption {
    id: string;
    label: string;
    code?: string;
}

interface IProps {
    id?: string;
    disabled?: boolean;
    loading?: boolean;
    onSearch?: (search?: string) => void;
    selected?: ISelectOption | null;
    placeholder: string;
    resultSize: number;
    noResultMessage: string;
    renderItem: (item: ISelectOption) => ReactNode;
    className?: string;
    data: ISelectOption[];
    onChange: (selected: ISelectOption) => void;
    hasError?: boolean;
    ghost?: boolean;
    fullWidth?: boolean;
    newStyle?: boolean;
    width?: number;
    color?: string;
    backgroundColor?: string;
    testID?: string;
}

interface IState {
    search: string;
    backgroundColor?: string;
    selected: ISelectOption | null;
    softSelectIndex: number;
    open: boolean;
}

const DEFAULT_SIZE = 4;
const BASE_HEIGHT = 40;

class Select extends PureComponent<IProps, IState> {
    public static defaultProps = {
        id:'',
        placeholder: '',
        noResultMessage: 'No results',
        resultSize: DEFAULT_SIZE,
        renderItem: (item: ISelectOption) => item.label + (item.code !== undefined ? " - " + item.code : ""),
        zIndex: 0,
        loading: false,
        disabled: false,
        ghost: false,
        newStyle: false,
        width: 0,
        color: ''
    };
    searchField: HTMLInputElement | null = null;
    resultsWrapper: HTMLDivElement | null = null;
    state: IState = { open: false, selected: null, search: '', softSelectIndex: -1 };

    constructor(props: IProps) {
        super(props);
        this.state.selected = props.selected || null;
    }
    componentWillReceiveProps(newProps: IProps) {
        if (newProps.selected !== this.state.selected) {
            this.setState({ selected: newProps.selected || null });
        }
    }

    handleClickOutside = () => {
        this.setState({ search: '', open: false, softSelectIndex: -1 });
    };

    handleSearch = (search: string) => {
        if (!this.props.onSearch) return;
        this.setState({ search }, () => {
            this.props.onSearch!(this.state.search);
            this.setState({ open: true, softSelectIndex: -1 });
        });
    };
    handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        const current = this.state.softSelectIndex;
        const max = this.props.data.length;

        switch (e.keyCode) {
            case 27: //escape
                if (this.searchField) this.searchField.blur();
                break;
            case 40: //down
                this.handleSoftSelect(1);
                break;
            case 38: //up
                this.handleSoftSelect(-1);
                break;
            case 39: //right
                if (this.state.search === '' && this.state.selected) this.handleSearch(this.state.selected.label);
                break;
            case 13: //enter
                if (current === -1 && max === 1) {
                    this.handleSelect(this.props.data[0]);
                } else if (current !== -1) {
                    this.handleSelect(this.props.data[current]);
                }
                break;
        }
    };
    handleSoftSelect = (delta: number) => {
        if (!this.resultsWrapper) return;
        const resultSize = this.props.resultSize;
        const top = this.resultsWrapper.scrollTop;
        const current = this.state.softSelectIndex;
        const max = this.props.data.length;

        let softSelectIndex = current + delta;
        if (softSelectIndex < 0) {
            softSelectIndex = max - 1;
            this.resultsWrapper.scrollTop = max * BASE_HEIGHT - resultSize * BASE_HEIGHT;
        } else if (softSelectIndex === max) {
            softSelectIndex = 0;
            this.resultsWrapper.scrollTop = 0;
        } else if (delta > 0) {
            this.resultsWrapper.scrollTop = (softSelectIndex - resultSize + 1) * BASE_HEIGHT;
        } else if (delta < 0 && top > softSelectIndex * BASE_HEIGHT) {
            this.resultsWrapper.scrollTop = (softSelectIndex - resultSize + 1) * BASE_HEIGHT;
        }

        this.setState({ softSelectIndex });
    };

    handleSelect = (selected: ISelectOption, e?: KeyboardEvent<HTMLInputElement>) => {
        if (!this.state.selected) {
            this.props.onChange(selected);
        } else if (this.state.selected.id !== selected.id) {
            this.props.onChange(selected);
        }
        this.setState({ selected, open: false, search: '' });
        if (e) e.stopPropagation();
        if (this.searchField) this.searchField.blur();
    };
    render() {
        const { selected, search, open } = this.state;
        const {
            id,
            placeholder,
            noResultMessage,
            resultSize,
            renderItem,
            className,
            loading,
            data,
            disabled,
            hasError,
            ghost,
            fullWidth,
            newStyle,
            width,
            color,
            testID,
            backgroundColor
        } = this.props;
        let displayValue = selected ? selected.label : placeholder;
        return (
            <Wrapper
              id={id}
              className={className}
              fullWidth={fullWidth}
              width={width}
              backgroundColor={backgroundColor}
              {...(testID && {'data-test':`${testID}-select`})}
            >
                <Icon
                    id={`${id}-dropdown-button`}
                    disabled={!!disabled}
                    ghost={ghost}
                    open={open}
                    onClick={() => this.searchField && this.searchField.focus()}
                    hasError={hasError}
                    newStyle={newStyle}
                >
                    <svg width="16" height="9" viewBox="0 0 16 9" xmlns="http://www.w3.org/2000/svg">
                        <polygon
                            fill={appTheme.color.grey[6]}
                            points="1.390625 0.296875 8 6.94140625 14.609375 0.296875 15.453125 1.140625 8.421875 8.171875 8 8.5234375 7.578125 8.171875 0.546875 1.140625"
                        />
                    </svg>
                </Icon>
                <SearchField
                    id={`${id}-dropdown-search-field`}
                    data-test={testID}
                    disabled={!!disabled}
                    ref={ref => (this.searchField = ref)}
                    open={open}
                    type="text"
                    value={search}
                    onKeyDown={this.handleKeyDown}
                    onFocus={() => this.setState({ open: true })}
                    onChange={e => this.handleSearch(e.currentTarget.value)}
                    hasError={hasError}
                />
                <PlaceHolder disabled={!!disabled} ghost={ghost} open={open} empty={!selected} hasError={hasError} color={color}>
                    {search === '' ? displayValue : ''}
                </PlaceHolder>

                {open ? (
                    <ResultWrapper ghost={ghost} open={open} ref={ref => (this.resultsWrapper = ref)} size={resultSize}>
                        { // @ts-ignore
                          loading && <Loader />
                        }
                        {!loading && data.map((item, index) => (
                            <ResultItem
                                id={`${id}-dropdown-item_${item.id}`}
                                key={item.id}
                                softSelected={index === this.state.softSelectIndex}
                                selected={!!selected && item.id === selected.id}
                                onMouseDown={() => this.handleSelect(item)}
                                {...(testID && {'data-test':`${testID}-${item.id}`})}
                            >
                                {renderItem(item)}
                            </ResultItem>
                        ))}
                        {!loading && data.length === 0 ? <NoResults>{noResultMessage}</NoResults> : null}
                    </ResultWrapper>
                ) : null}
            </Wrapper>
        );
    }
}

interface SelectProps {
    readonly open: boolean;
    readonly hasError?: boolean;
    readonly ghost?: boolean;
    readonly disabled?: boolean;
    readonly empty?: boolean;
    readonly newStyle?: boolean;
    readonly color?: string;
}

interface ResultItemProps {
    readonly softSelected: boolean;
    readonly selected?: boolean;
}

interface WrapperProps {
    readonly fullWidth?: boolean;
    readonly width?: number;
    readonly backgroundColor?: string;
}

const Wrapper = styled.div<WrapperProps>`
    position: relative;
    width: ${({ width, fullWidth }) => (width ? `${width}px` : fullWidth ? '100%' : '260px')};
    background-color: ${({ backgroundColor }) => (backgroundColor)};
`;

const fontStyle = `
    font-size: 14px;
    color: ${appTheme.color.input.textColor};
    text-align: left;
    font-weight: ${appTheme.typography.fontWeight.bolder};
    padding: 10px;
`;
const SearchField = styled.input<SelectProps>`
    border: 1px solid transparent;
    background-color: transparent;
    position: absolute;
    border-radius: 4px 0 0 4px;
    top: 1px;
    height: 38px;
    ${fontStyle}
    right: 40px;
    left: 1px;
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    margin-right: 40px;
    &:focus {
        outline: none;
    }
`;

const Icon = styled.div<SelectProps>`
    height: ${({ newStyle }) => newStyle ? '35px' : '40px'};
    width: 40px;
    background-color: ${({ hasError, theme, disabled }) =>
        hasError
            ? theme.color.input.backgroundColorError
            : disabled
            ? theme.color.input.backgroundColorDisabled
            : theme.color.input.backgroundColor};
    float: right;
    border: 1px solid
        ${({ open, theme, hasError, ghost }) => {
            if (ghost) return theme.color.common.transparent;
            if (open) return theme.color.input.borderColorFocus;
            return hasError ? theme.color.input.borderColorError : theme.color.input.borderColor;
        }};
    border-left-width: 0;
    border-radius: ${({ open }) => (open ? '0 4px 0 0' : '0 4px 4px 0')};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')} ${({ ghost }) => ghost && 'border: none'};
`;
const PlaceHolder = styled.div<SelectProps>`
    ${fontStyle}
    height: 40px;
    border: 1px solid
        ${({ open, theme, hasError }) =>
            open
                ? theme.color.input.borderColorFocus
                : hasError
                ? theme.color.input.borderColorError
                : theme.color.input.borderColor};
    border-radius: ${({ open }) => (open ? '4px 4px 0 0' : '4px 0')};
    color: ${({ theme, empty, color }) => (color? color : empty ? theme.color.input.placeholderColor : theme.color.input.textColor)};
    background-color: ${({ hasError, theme, disabled }) =>
        hasError
            ? theme.color.input.backgroundColorError
            : disabled
            ? theme.color.input.backgroundColorDisabled
            : 'transparent'};
    white-space: nowrap;
    text-overflow: ellipsis;
    border-right: none;
    overflow: hidden;
    ${({ ghost }) => ghost && 'border: none'};
`;
const ResultWrapper = styled.div<SelectProps & { size: number }>`
    margin-top: -1px;
    border-top: 1px solid ${({ theme }) => theme.color.input.borderColor};
    border-top-color: ${({ open, theme }) =>
        open ? theme.color.input.borderColorFocus : theme.color.input.borderColor};
    background-color: white;
    position: absolute;
    width: 100%;
    border-radius: ${({ open }) => (open ? '0 0 4px 4px' : '4px')};
    max-height: ${({ size }) => `${size * BASE_HEIGHT}px`};
    overflow-y: auto;
    z-index: ${({ open }) => (open ? 10 : 1)};
    color: ${({ theme }) => theme.color.input.textColor};
    box-shadow: ${({ theme }) => theme.boxShadow[0]};
    ${({ ghost }) => ghost && 'border: none'};
`;

const NoResults = styled.div`
    ${fontStyle}
    width: 100%;
    background-color: ${({ theme }) => theme.color.common.white};
    height: ${BASE_HEIGHT}px;
`;

// @ts-ignore
const ResultItem = styled(NoResults)<ResultItemProps>`
    background-color: ${({ softSelected, theme }) =>
        softSelected ? theme.color.input.backgroundColorSelected : 'transparent'};
    color: ${({ softSelected, theme }) => (softSelected ? theme.color.common.white : 'inherit')};
    &:hover {
        background-color: ${({ theme }) => theme.color.input.backgroundColorSelected};
        color: ${({ theme }) => theme.color.common.white};
    }
    ${({ selected, theme }) =>
        selected
            ? `
    background-color: ${theme.color.common.blue};
    color: ${theme.color.common.white};`
            : ''};
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

// @ts-ignore
export default onClickOutside(Select);
