import { loader } from 'graphql.macro';
import React, { ReactElement } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { QueryResult, OfferTemplateType } from 'localTypes';
import { setItem, getItem, LocalStorageKey, removeItem } from 'services/persistentData';
import { QueryLoader } from 'components';
import { getNodes } from 'services/queryService';

import {
    getPOSWithOfferTemplate,
    getPOSWithOfferTemplate_get_Pos,
    getPOSWithOfferTemplate_list_edges_node_Pos,
} from 'types/getPOSWithOfferTemplate';
import { searchPOS, searchPOS_get_Pos, searchPOS_list_pointOfSales_pos_Pos } from 'types/searchPOS';

const GET_POS_QUERY = loader('../query/getPOSWithOfferTemplate.gql');
const SELECT_POS = loader('../feature/SelectPOS/searchPOS.gql');

interface IProps {
    children: (
        loading: boolean,
        authorizedPos?: getPOSWithOfferTemplate_list_edges_node_Pos | getPOSWithOfferTemplate_list_edges_node_Pos
    ) => ReactElement;
    offerTemplateType: OfferTemplateType;
}

const PosIdCheck = ({
    match: {
        params: { idPos: paramsIdPos, idHolding },
    },
    children,
    offerTemplateType,
}: IProps & RouteComponentProps<{ idHolding: string; idPos: string }>) => {
    const persistedIdPos = getItem(LocalStorageKey.POS_ID);
    let currentIdPos = paramsIdPos || persistedIdPos || '';

     if (offerTemplateType === OfferTemplateType.BookingService) {
         return (
            <QueryLoader
                displayErrorMessage={false}
                context={{
                    debounceKey: 'pos',
                }}
                query={SELECT_POS}
                variables={{
                    search: '%',
                    idHolding,
                    idPos: currentIdPos,
                    useId: true,
                }}
            >
                {({ data, loading }: QueryResult<searchPOS>) => {
                    const pos = data.get as searchPOS_get_Pos;
                    const posList = data.list.pointOfSales.map((d) => d.pos as searchPOS_list_pointOfSales_pos_Pos);
                    const authorizedPos = pos && posList.map(({ id }) => id).includes(pos.id) ? pos : posList[0];
                    if (!authorizedPos) {
                        removeItem(LocalStorageKey.POS_ID);
                        return children(loading);
                    }
                    setItem(LocalStorageKey.POS_ID, authorizedPos.id);
                    return children(loading, authorizedPos);
                }}
            </QueryLoader>
        );
     }
     else if (offerTemplateType === OfferTemplateType.ClickCollect) {
        return (
            <QueryLoader
                displayErrorMessage={false}
                query={SELECT_POS}
                variables={{
                    search: '%',
                    idHolding,
                    idPos: currentIdPos,
                    useId: true,
                }}
            >
                {({ data, loading }: QueryResult<searchPOS>) => {
                    const pos = data.get as searchPOS_get_Pos;
                    const posList = data.list.pointOfSales.map((d) => d.pos as searchPOS_list_pointOfSales_pos_Pos);
                    const authorizedPos = pos && posList.map(({ id }) => id).includes(pos.id) ? pos : posList[0];
                    if (!authorizedPos) {
                        removeItem(LocalStorageKey.POS_ID);
                        return children(loading);
                    }
                    setItem(LocalStorageKey.POS_ID, authorizedPos.id);
                    return children(loading, authorizedPos);
                }}
            </QueryLoader>
        );
    }
     else {
        return (
            <QueryLoader
                variables={{
                    search: '%',
                    idHolding,
                    idPos: currentIdPos,
                    useId: !!currentIdPos,
                    offerTemplateType: offerTemplateType,
                }}
                query={GET_POS_QUERY}
            >
                {({ data: { get, list }, loading }: QueryResult<getPOSWithOfferTemplate>) => {
                    const pos = get as getPOSWithOfferTemplate_get_Pos;
                    const posList = getNodes(list) as getPOSWithOfferTemplate_list_edges_node_Pos[];
                    const authorizedPos =
                        pos && pos.zone.holding.id === idHolding && posList.map(({ id }) => id).includes(pos.id)
                            ? pos
                            : posList[0];
                    if (!authorizedPos) {
                        removeItem(LocalStorageKey.POS_ID);
                        return children(loading);
                    }
                    if (authorizedPos.id !== persistedIdPos) {
                        setItem(LocalStorageKey.POS_ID, authorizedPos.id);
                    }
                    return children(loading, authorizedPos);
                }}
            </QueryLoader>
        );
    }
    
};

export default withRouter(PosIdCheck);
