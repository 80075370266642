import { RoutablePanel } from 'components';
import { IRenderProps as IPanelRenderProps } from 'components/Panel';
import { loader } from 'graphql.macro';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import AddOrEditForm from './AddOrEditForm';

const CREATE_EMPLOYEE_MUTATION = loader('../query/createEmployee.gql');

interface IPropsPanel extends RouteComponentProps {
    beforeClose: () => void;
}

const AddEmployeePanel = ({ history: { push }, location: { search }, beforeClose }: IPropsPanel) => {
    const [t] = useTranslation();
    const onClose = () => {
        beforeClose();
        push(`${routes.admin.employees.list()}${search}`);
    };

    return (
        <RoutablePanel 
          title={t(`page:admin.employees.creation.title`)} 
          onClose={onClose}
        >
            {({ onClose }: IPanelRenderProps) => (
                <Mutation mutation={CREATE_EMPLOYEE_MUTATION}>
                    {(createEmployee: (param: Record<'variables', any>) => Promise<any>) => (
                        <AddOrEditForm
                            {...{
                                onSuccess: onClose,
                                updateEmployee: createEmployee,
                                isCreation: true,
                            }}
                        />
                    )}
                </Mutation>
            )}
        </RoutablePanel>
    );
};

export default withRouter(AddEmployeePanel);
