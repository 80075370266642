import React from 'react';
import { withRouter } from "react-router-dom";
import { Button, Icon, QueryLoader, Struct, Title } from 'components';
import appRoutes from "../../../Router/routes";
import { loader } from "graphql.macro";
import { QueryResult } from "../../../localTypes";
import { IList } from "../../../components/Table/TableWithOptions";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import HubTable from "./HubTable";

const GET_POS_HUBS = loader('../query/listPosHubs.gql');

interface IPropsEmptyStatePage {
    message?: string;
}

function EmptyStatePage({ message }: IPropsEmptyStatePage) {
    const { t } = useTranslation();
    const defaultMessage = t('page:holding.printing.emptyStateMessage')
    return (
        <EmptyStateWrapper>
            <Icon.BlankPage/>
            <MessageWrapper>{message || defaultMessage}</MessageWrapper>
        </EmptyStateWrapper>
    )
}

function ListPrinting({
                      match: {
                          params: { idHolding },
                      },
                      history
                  }) {
    const { t } = useTranslation();
    return (
        <QueryLoader
            hasData={(data: IList): boolean => !!(data && data.list)}
            query={GET_POS_HUBS}
            variables={{ querySearch: [{ key: "idHolding", operator: "=", value: idHolding }] }}
            fetchPolicy={"cache-and-network"}
        >
            {({ data }: QueryResult<IList>) => {
                return (
                    <Struct.Section>
                        <Title grow mode="H2" value={t('page:holding.printing.title')}>
                            <Button
                                onClick={() => history.push(`${appRoutes.holding.printing.add(idHolding)}`)}>{t('app:button.add-hub')}</Button>
                        </Title>
                        {data.list.edges.length > 0 ?
                            (<Struct.Card>
                                <HubTable 
                                  idHolding={idHolding} 
                                  history={history} 
                                  // @ts-ignore
                                  data={data.list.edges}
                                />
                            </Struct.Card>) :
                            (<EmptyStatePage/>)
                        }
                    </Struct.Section>
                )
            }}
        </QueryLoader>)
};

const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 600px;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.color.common.white};
`;

const MessageWrapper = styled.div`
  font-size: ${({ theme }) => theme.typography.size.M}px;
  line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
  margin-top: ${({ theme }) => theme.spacing.s}px;
  color: ${({ theme }) => theme.color.grey[6]};
  font-family: Helvetica;
  letter-spacing: 0;
  text-align: center;
`;

export default withRouter(ListPrinting);
