import React from 'react';
import styled from 'styled-components';
import { BarChart, XAxis, YAxis, Bar, Cell, ResponsiveContainer } from 'recharts';
import { appTheme } from 'theme';

export interface IChartValue {
    label: string;
    value: number;
}

interface IProps {
    data: IChartValue[];
    boxedSecondaryData?: IChartValue[] | undefined;
    secondaryColorPicker? : (value: number) => string;
    xAxisColorPicker: (value: string) => string;
    barColorPicker: (value: string) => string;
    maxValue: number | null;
    testID?: string;
}

const DEFAULT_COLOR = '#5F7081'

const CustomizedAxisTick = (props: any) => {
    const { y, payload, colorPicker } = props;
    const { value, tickCoord } = payload;

    const color = colorPicker(value);

    return (
        <g>
            <text x={tickCoord} y={y} dy={16} fill={color} textAnchor={'middle'}>
                {value}
            </text>
            ;
        </g>
    );
};

const TopLabel = (props: any) => {
    const { x, y, value, viewBox } = props;

    const middleOfBar = viewBox.width / 2;
    return (
        <text
            x={x}
            y={y}
            dx={middleOfBar}
            dy={-10}
            fontSize={appTheme.typography.fontSizeM}
            fontWeight={'bold'}
            fill={DEFAULT_COLOR}
            textAnchor={'middle'}
        >
            {value}
        </text>
    );
};

const InsideLabel = (props: any) => {
    const { x, value, viewBox, offset, colorPicker } = props;

    const middleOfBox = viewBox.width / 2;
    const verticalMiddleOfBox = viewBox.height / 2;
    const startYAvoidMargin = 1;

    return (
        <text
            x={x}
            y={offset+startYAvoidMargin}
            dx={middleOfBox}
            dy={verticalMiddleOfBox}
            fontSize={appTheme.typography.fontSizeM}
            fontWeight={'bold'}
            fill={colorPicker ? colorPicker(value) : DEFAULT_COLOR}
            textAnchor={'middle'}
        >
            {value}
        </text>
    );
};

const CustomBox = (props: any) => {
    const { stroke, fill, x, width, height } = props;

    const startYAvoidMargin = 1;
    return (
        <rect x={x} y={startYAvoidMargin} rx={'5'} ry={'5'} width={width} height={height} stroke={stroke} fill={fill} />
    );
};

/**
 * Bar Chart, that expects an array of objects, containing label and value
 * This can be customizable, passing colors for the bars and the x axis labels
 * Optionally, you can pass secondaryData, to show boxed data, below each label of the x axis
 * @param props
 */
const Chart = (props: IProps) => {
    const { data, xAxisColorPicker, barColorPicker, maxValue, boxedSecondaryData, secondaryColorPicker, testID } = props;

    const barWidth = 48;
    const barChartWidth = data.length * barWidth * 1.5;

    let maxBarValue = maxValue;
    if (maxBarValue === null) {
        maxBarValue = Math.max.apply(
            Math,
            data.map((element) => element.value)
        );
    }

    return (
        <GraphWrapper>
            <ResponsiveContainer minWidth={barChartWidth} height={250}>
                <BarChart data={data} margin={{ top: 30, right: 10, bottom: 10, left: 10 }}>
                    <XAxis
                        axisLine={false}
                        dataKey={'label'}
                        tickLine={false}
                        tick={<CustomizedAxisTick colorPicker={xAxisColorPicker} />}
                    />
                    <YAxis type={'number'} ticks={[0, maxBarValue]} domain={[0, maxBarValue]} axisLine={false} />
                    <Bar
                        isAnimationActive={false}
                        dataKey={'value'}
                        background={{ fill: '#eee' }}
                        barSize={barWidth}
                        label={<TopLabel />}
                    >
                        {data.map((entry, index) => (
                            <Cell {...(testID && {'data-test':`${testID}-cell-${index}`})} key={`cell-${index}`} fill={barColorPicker(entry.label)} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>

            {boxedSecondaryData ? (
                <ResponsiveContainer minWidth={barChartWidth} height={50}>
                    <BarChart data={boxedSecondaryData} margin={{ right: 10, bottom: 5, left: 10 }}>
                        <XAxis dataKey={'label'} axisLine={false} tick={false} tickLine={false} />
                        <YAxis type={'number'} dataKey={'value'} axisLine={false} tick={false} tickLine={false} />
                        <Bar
                            isAnimationActive={false}
                            dataKey={'value'}
                            shape={<CustomBox />}
                            fill={'#ffffff'}
                            barSize={barWidth}
                            label={<InsideLabel colorPicker={secondaryColorPicker} />}
                        >
                            {data.map((entry, index) => (
                                <Cell {...(testID && {'data-test':`${testID}-cell-secondary-${index}`})} key={`cell-${index}`} height={30} stroke={DEFAULT_COLOR} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            ) : null}
        </GraphWrapper>
    );
};

const GraphWrapper = styled.div`
    overflow-x: auto;
    overflow-y: hidden;
`;

export default Chart;
