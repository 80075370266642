import React from 'react';
import { Route } from 'react-router-dom';
import appRoutes from 'Router/routes';
import EditOfferTemplate from './ClickCollectPage/EditOfferTemplate';
import AddOfferTemplate from './ClickCollectPage/AddOfferTemplate';
import OfferDetailsPage from './DailyOffersPage/OfferDetailsPage';
import ClickCollectPage from './ClickCollectPage';

function RoutingClickAndCollectPage() {
    return (
        <>
            <Route exact path={appRoutes.cnc.offers.list()} component={ClickCollectPage} />
            <Route exact path={appRoutes.cnc.offers.offerTemplate.edit()} component={EditOfferTemplate} />
            <Route exact path={appRoutes.cnc.offers.offerTemplate.add()} component={AddOfferTemplate} />
            <Route exact path={appRoutes.cnc.offers.listOffer()} component={AddOfferTemplate} />
            <Route path={appRoutes.cnc.offers.getOffer()} component={OfferDetailsPage} />
        </>
    );
}

export default RoutingClickAndCollectPage;
