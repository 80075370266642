import { Duration } from 'luxon';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import { loader } from 'graphql.macro';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Field, Form, Formik, FieldArray } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { updateOfferVariables } from 'types/updateOffer';
import { getOffer_offer_Offer, getOffer_offer_Offer_offerTemplate } from 'types/getOffer';
import {
    InfoLabel,
    NumberInput,
    NumberInputField,
    Panel,
    SubmitButton,
    TimeRangeInputField
} from 'components';
import { PanelContent, PanelFooter } from 'components/Panel';
import { areRangesValid, isRangeStartBeforeEnd } from 'services/form/offerValidator';
import { areRangesEqual } from 'services/validationService';
import { OfferTemplateWithdrawalType } from '../../../../types/globalTypes';
import { updateOfferTemplateVariables } from "../../../../types/updateOfferTemplate";
import LanguageTextInput from "../../../../components/LanguageTextInput";

const UPDATE_OFFER_MUTATION = loader('../query/updateOffer.gql');
const UPDATE_OFFER_TEMPLATE_DESCRIPTION_MUTATION = loader('../query/updateOfferTemplateDescription.gql');

const FORM_ID = 'offer_edit_form';

const DEFAULT_DURATION = 5;

interface IProps {
    isOpen: boolean;
    closeModal: () => void;
    offer: getOffer_offer_Offer;
    match: {
        params: { idOffer: string };
    };
}

interface IFormValues {
    maxOrdersPerSlot: number | null;
    orderRange: string;
    withdrawRange: string;
    offerTemplate: getOffer_offer_Offer_offerTemplate;
}

function OfferParameterFormPanel({
    isOpen,
    closeModal,
    offer: { maxOrdersPerSlot, orderRange, withdrawRange, offerTemplate, withdrawSlotDuration },
    match: {
        params: { idOffer },
    },
}: IProps & RouteComponentProps) {
    const { t } = useTranslation();
    const validate = (duration: number, withdrawalType: OfferTemplateWithdrawalType | null) => ({
        orderRange,
        withdrawRange,
    }: IFormValues) => {
        const errors = {} as any;
        const clickServeOrTableServiceRule =
            withdrawalType &&
            (
                withdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE ||
                withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE ||
                withdrawalType === OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT ||
                withdrawalType === OfferTemplateWithdrawalType.CLICK_AND_PICK_UP
            );

        if (clickServeOrTableServiceRule) {
            return errors;
        }

        if (!areRangesValid(orderRange, withdrawRange, duration)) {
            errors.orderRange = t('page:clickcollect.daily-offers.form.error.orderRange');
            errors.withdrawRange = t('page:clickcollect.daily-offers.form.error.withdrawRange');
        }

        return errors;
    };

    const duration = withdrawSlotDuration
        ? Duration.fromISO(withdrawSlotDuration).as('minutes')
        : Duration.fromISO(offerTemplate.withdrawSlotDuration).as('minutes')
        || DEFAULT_DURATION;

    const displayOfferParameters = (values) => {
      const isClickAndPickUp = offerTemplate.withdrawalType === OfferTemplateWithdrawalType.CLICK_AND_PICK_UP;

        if (
            offerTemplate.withdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE ||
            offerTemplate.withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE ||
            isClickAndPickUp
        ) {
            return offerParametersSeatClickServeView(isClickAndPickUp, values);
        }

        if (offerTemplate.withdrawalType === OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT) {
            return offerParametersInstantClickAndCollectView(values);
        }

        return offerParametersDetailsDefaultView(values);
    };

    const offerParametersSeatClickServeView = (isClickAndPickUp: boolean = false, values) => {
      const fieldLabel = isClickAndPickUp ?
        t('page:clickcollect.daily-offers.label.orderRangeClickAndPickup') :
        t('page:clickcollect.daily-offers.label.orderRangeSeatClickAndServe');

        return (
            <>
                <Field
                    label={fieldLabel}
                    name="orderRange"
                    component={TimeRangeInputField}
                    step={duration}
                />
                <InfoLabel fontWeight="bold" name={t(`page:admin.cnc.description`)} paddingRight={4} />
                {/* There is no limit on the description in the backend, if added re-nable this and update translations */}
                <InfoLabel fontStyle="italic" name={t(`page:admin.cnc.maximumChracters`, { max: 90 })} />
                <FieldArray
                    name="offerTemplate.translations"
                    render={() => <div>
                        {values.offerTemplate.translations?.map((translation, index) =>
                                <LanguageTextInput
                                    id={`offer-description-input-${translation.language}`}
                                    key={`description${translation.language}`}
                                    locale={translation.language}
                                    name={`offerTemplate.translations[${index}].description`}
                                    multiline
                                    maxLength={90}
                                    value={translation.description}
                                />
                        )}
                    </div>
                    }
                />
            </>
        );
    };

    const offerParametersInstantClickAndCollectView = (values) => {
        return (
            <>
                <Field
                    label={t('page:clickcollect.daily-offers.label.opening')}
                    name="orderRange"
                    component={TimeRangeInputField}
                    step={duration}
                />
                <InfoLabel fontWeight="bold" name={t(`page:admin.cnc.description`)} paddingRight={4} />
                {/* There is no limit on the description in the backend, if added re-nable this and update translations */}
                <InfoLabel fontStyle="italic" name={t(`page:admin.cnc.maximumChracters`, { max: 90 })} />
                <FieldArray
                    name="offerTemplate.translations"
                    render={() => <div>
                        {values.offerTemplate.translations?.map((translation, index) =>
                            <LanguageTextInput
                                id={`offer-description-input-${translation.language}`}
                                key={`description${translation.language}`}
                                locale={translation.language}
                                name={`offerTemplate.translations[${index}].description`}
                                multiline
                                maxLength={90}
                                value={translation.description}
                            />
                        )}
                    </div>
                    }
                />
            </>
        );
    };

    const offerParametersDetailsDefaultView = (values) => {
        return (
            <>
                <InfoLabel name={t('schema:offer.withdrawSlotDuration')} />
                <StyledNumberInput disabled onChange={() => {}} value={duration} />
                <Field
                    label={t('schema:offer.maxOrdersPerSlot')}
                    name="maxOrdersPerSlot"
                    component={NumberInputField}
                />
                <Field
                    label={t('schema:offer.orderRange')}
                    name="orderRange"
                    component={TimeRangeInputField}
                    step={duration}
                />
                <Field
                    label={t('schema:offer.withdrawRange')}
                    name="withdrawRange"
                    component={TimeRangeInputField}
                    step={duration}
                />
                <InfoLabel fontWeight="bold" name={t(`page:admin.cnc.description`)} paddingRight={4} />
                {/* There is no limit on the description in the backend, if added re-nable this and update translations */}
                <InfoLabel fontStyle="italic" name={t(`page:admin.cnc.maximumChracters`, { max: 90 })} />
                <FieldArray
                    name="offerTemplate.translations"
                    render={() => <div>
                            {values.offerTemplate.translations?.map((translation, index) =>
                                    <LanguageTextInput
                                        id={`offer-description-input-${translation.language}`}
                                        key={`description${translation.language}`}
                                        locale={translation.language}
                                        name={`offerTemplate.translations[${index}].description`}
                                        multiline
                                        maxLength={90}
                                        value={translation.description}
                                    />
                            )}
                        </div>
                    }
                />
            </>
        );
    };
    return (
        <Panel onClose={closeModal} open={isOpen} title={t('page:clickcollect.daily-offers.title.offerParameterPanel')}>
            <Mutation mutation={UPDATE_OFFER_TEMPLATE_DESCRIPTION_MUTATION}>
                {(updateOfferTemplateDescription: (param: Record<'variables', updateOfferTemplateVariables>) => Promise<any>) => {
                    return (
                        <Mutation mutation={UPDATE_OFFER_MUTATION}>
                            {(updateOffer: (param: Record<'variables', updateOfferVariables>) => Promise<any>) => {
                                const initialValues = {
                                    maxOrdersPerSlot,
                                    orderRange,
                                    withdrawRange,
                                    offerTemplate,
                                };
                                return (
                                    <Formik
                                        validate={validate(duration, offerTemplate.withdrawalType)}
                                        validationSchema={getOfferSchema(t, offerTemplate.withdrawalType)}
                                        initialValues={initialValues}
                                        onSubmit={({ offerTemplate, ...values }, { setSubmitting, resetForm }) => {
                                            if(offerTemplate.translations && !isEqual(offerTemplate.translations, initialValues.offerTemplate.translations)){
                                                updateOfferTemplateDescription({
                                                    variables: {
                                                        offerTemplate: {
                                                            id: offerTemplate.id,
                                                            description: offerTemplate.translations[0].description,
                                                            translations: offerTemplate.translations?.map(({__typename, ...translation}) => (translation))
                                                        }
                                                    }});
                                            }
                                            updateOffer({
                                                variables: {
                                                    ...values,
                                                    id: idOffer,
                                                    maxOrdersPerSlot: values.maxOrdersPerSlot || 1,
                                                },
                                            })
                                                .then(
                                                    ({
                                                        errors,
                                                        data: {
                                                            updateOffer: { maxOrdersPerSlot, orderRange, withdrawRange },
                                                        },
                                                    }) => {
                                                        if (errors) {
                                                            throw errors;
                                                        }
                                                        setSubmitting(false);
                                                        // @ts-ignore
                                                        resetForm({ maxOrdersPerSlot, orderRange, withdrawRange, offerTemplate });
                                                        closeModal();
                                                    }
                                                )
                                                .catch((error: any) => {
                                                    setSubmitting(false);
                                                });
                                        }}
                                    >
                                        {({ errors, isSubmitting, values, initialValues }) => {
                                            const formHasNoChange =
                                                values.maxOrdersPerSlot === initialValues.maxOrdersPerSlot &&
                                                isEqual(values.offerTemplate.translations, initialValues.offerTemplate.translations) &&
                                                areRangesEqual(values.orderRange, initialValues.orderRange) &&
                                                areRangesEqual(values.withdrawRange, initialValues.withdrawRange);
                                            return (
                                                <>
                                                    <PanelContent>
                                                        {
                                                          // @ts-ignore
                                                          <Form id={FORM_ID}>{displayOfferParameters(values)}</Form>
                                                        }
                                                    </PanelContent>
                                                    <PanelFooter>
                                                        <SubmitButton
                                                            form={FORM_ID}
                                                            formHasNoChange={formHasNoChange}
                                                            disabled={
                                                                formHasNoChange ||
                                                                Object.entries(errors).length !== 0 ||
                                                                isSubmitting
                                                            }
                                                        />
                                                    </PanelFooter>
                                                </>
                                            );
                                        }}
                                    </Formik>
                                );
                            }}
                        </Mutation>
                    );
                }}
            </Mutation>
        </Panel>
    );
}

const StyledNumberInput = styled(NumberInput)`
    margin-bottom: ${({ theme }) => theme.spacing.m}px;
`;

const getOfferSchema = (t: any, withdrawalType) =>
    Yup.object().shape({
        maxOrdersPerSlot: withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE
            ? Yup.number().nullable(true)
            : Yup.number().min(0, t('app:error.invalid')).required(t('app:error.required')),
        orderRange: Yup.string()
            .required(t('app:error.required'))
            .test(
                'is start before end',
                t('page:clickcollect.daily-offers.form.error.orderRange'),
                // @ts-ignore
                isRangeStartBeforeEnd
            ),
        withdrawRange: Yup.string()
            .required(t('app:error.required'))
            .test(
                'is start before end',
                t('page:clickcollect.daily-offers.form.error.withdrawRange'),
                // @ts-ignore
                isRangeStartBeforeEnd
            ),
        offerTemplate:  Yup.object().shape({
            translations: Yup.array()
                .of(Yup.object().shape({
                    description: Yup.string().max(90, t('page:admin.cnc.maximumCharacters', { max: 90 }))
                }))
        })
    });

export default withRouter(OfferParameterFormPanel);
