import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Tooltip, Icon } from 'components';
import {
    ActionButtonsWrapper,
    EditIcon,
    StyledTD,
    TextWithEllipsis,
    IconButton,
    StatusText
} from './ServicesPage.style';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Bin } from 'components/Icons';
import { appTheme } from 'theme';
import { Mutation } from '@apollo/client/react/components';
import { loader } from 'graphql.macro';

const ICON_WIDTH = 20;
const DELETE_SERVICE = loader('./query/removeEPointageService.gql');

interface IProps extends RouteComponentProps<{ siteId: string }> {
    item: any;
    index: number;
    history: any;
    location: any;
    refetchAfterDelete: () => void;
    onClickEdit: () => void;
};

const Service = ({ 
    item,
    index,
    history,
    location,
    match: {
        params: { siteId },
    },
    refetchAfterDelete,
    onClickEdit
}: IProps ) => {
    const { id, name, published } = item;
    const { t } = useTranslation();

    const statusLabel = published ? t('page:checkList.services.published') : t('page:checkList.services.unpublished');
    

    return (
        <Mutation mutation={DELETE_SERVICE}>
            {(deleteService: (param: Record<'variables', any>) => Promise<any>) => {
                const onDeleteClick = async () => {
                    await deleteService({
                        variables: { serviceId: id },
                    });
                    refetchAfterDelete();
                };

                return (
                    <tr key={`${item.id}-${index}`}>
                        <StyledTD width={'60%'} >
                            <TextWithEllipsis maxWidth={500} withEllipsis >
                                {name}
                            </TextWithEllipsis>
                        </StyledTD>
                        <StyledTD width={'30%'} >
                            <StatusText published={published}>
                                {statusLabel}
                            </StatusText>
                        </StyledTD> 
                        <StyledTD width={'10%'} >
                            <ActionButtonsWrapper>
                                <StyledTooltip toolTipContent={t('app:button.edit')}> 
                                    <EditIcon id={`edit-button_row-${id}`} onClick={onClickEdit}>
                                        <Icon.Edit width={ICON_WIDTH} height={ICON_WIDTH} />
                                    </EditIcon>
                                </StyledTooltip>
                                <StyledTooltip toolTipContent={t('app:button.delete')}>
                                    <IconButton onClick={onDeleteClick} greyIndexColor={2} >
                                        <Bin color={appTheme.color.common.red} height={14} width={13} />
                                    </IconButton>
                            </StyledTooltip>
                            </ActionButtonsWrapper>
                        </StyledTD>
                    </tr>
                );
            }}
        </Mutation>
        
    );
};

const StyledTooltip = styled((props) => <Tooltip styledTooltip={true} {...props} />)`
    padding: 5px 0px;
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    width: ${({ style }) => style?.width ? style.width : '70'}px;
    left: 50%;
    margin-left: ${({ style }) => style?.transform ? '0' : '-35'}px;
    text-align: center;
    transform: ${({ style }) => style?.transform ? style.transform : 'none'};
`;

export default withRouter(Service);
