import React, { ReactNode } from 'react';
import styled from 'styled-components';

import SelectLayoutHolding from './SelectLayoutHolding';
import IdiomSelector from '../IdiomSelector';
import { isTestingEnvironment } from '../../utils';

interface IProps {
    children?: ReactNode;
}

const HoldingPageContainer = ({ children }: IProps) => {
    return (
        <>
            {isTestingEnvironment() ? (
                <IdiomSelectorWrapper>
                    <IdiomSelector />
                </IdiomSelectorWrapper>
            ) : null}
            <SelectorWrapper>
                <SelectLayoutHolding />
            </SelectorWrapper>
            {children}
        </>
    );
};

const SelectorWrapper = styled.div`
    position: absolute;
    top: ${({ theme }) => theme.spacing.s}px;
    right: ${({ theme }) => theme.spacing.m}px;
`;

const IdiomSelectorWrapper = styled.div`
    position: absolute;
    top: ${({ theme }) => theme.spacing.s}px;
    right: 300px;
`;

export default HoldingPageContainer;
