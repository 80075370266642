import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import CheckBox from '../components/CheckBox';
import { HelpTooltip } from 'components';

interface ICheckBoxWithLabel {
    label: string;
    secondaryLabel?: string | null;
    tertiaryLabel?: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    hasHelpTooltip?: boolean;
    contentTooltip?: string;
    fontWeight?: string;
    fontSize?: string;
    lineHeight?: string;
    disabled?: boolean;
    id?: string;
}

const CheckBoxWithLabel: FunctionComponent<ICheckBoxWithLabel> = ({
    checked,
    onChange,
    label,
    secondaryLabel,
    tertiaryLabel,
    hasHelpTooltip,
    contentTooltip,
    fontWeight, 
    fontSize, 
    lineHeight,
    disabled = false,
    id
}) => (
    <>
        <CheckBoxAndLabel disabled={disabled}>
            <CheckBox id={id} checked={checked} onChange={onChange} disabled={disabled}/>
            <LabelWrapper fontWeight={fontWeight} fontSize={fontSize} lineHeight={lineHeight} disabled={disabled}>{label}
            <HelpToolTipWrapper>
                {hasHelpTooltip && (
                    <HelpTooltip
                        tooltipContent={contentTooltip}
                    />     
                )}
            </HelpToolTipWrapper>
            </LabelWrapper>

        </CheckBoxAndLabel>
        {secondaryLabel && <LabelWrapperSecondary>{secondaryLabel}</LabelWrapperSecondary>}

        {tertiaryLabel && checked && (
            <>
                <br />
                <LabelWrapperSecondary>{tertiaryLabel}</LabelWrapperSecondary>
                <br />
            </>
        )}
    </>
);

interface IProps {
    fontWeight?: string;
    fontSize?: string;
    lineHeight?: string;
};

const CheckBoxAndLabel = styled.div<{disabled?: boolean}>`
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing.xs}px;
    cursor: ${({disabled}) => disabled? 'initial' : 'pointer'};
`;

const LabelWrapper = styled.label<IProps & {disabled?: boolean}>`
    padding-left: ${({ theme }) => theme.spacing.xs}px;
    color: ${({ theme, disabled }) => disabled? theme.color.grey[5] : theme.color.grey[6]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-weight: ${({ theme, fontWeight }) => fontWeight? fontWeight : 'bold'};
    font-size: ${({ theme, fontSize }) => fontSize ? fontSize : theme.typography.fontSizeM}px;
    letter-spacing: 0;
    line-height: ${({ theme, lineHeight }) => lineHeight ? lineHeight : theme.typography.size.S}px;
`;

const LabelWrapperSecondary = styled.label`
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-weight: normal;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    letter-spacing: 0;
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    padding-bottom: ${({ theme }) => theme.spacing.s}px;
`;

const HelpToolTipWrapper = styled.div`
    display: inline-block;
    align-self: flex-end;
    padding-left: 5px;
`;

export default CheckBoxWithLabel;
