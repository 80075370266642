import React from 'react';
import styled from 'styled-components';

interface IBloc {
    size: number;
    direction: 'column' | 'row';
    justify?: string;
    align?: string;
    wrapped?: boolean;
    auto?: boolean;
}
const Bloc = styled.div<IBloc>`
    max-width: calc(((${({ size = '12' }) => size}% / 12) * 10e7) / 10e5);
    flex-grow: 0;
    justify-content: ${({ justify = 'flex-start' }) => justify};
    align-items: ${({ align = 'center' }) => align};
    display: flex;
    width: ${({ auto = false }) => (auto ? 'auto' : '100%')};
    flex-direction: ${({ direction = 'row' }) => direction};
    flex-wrap: ${({ wrapped = false }) => (wrapped ? 'wrap' : 'nowrap')};
`;

export const Row = (props: any) => <Bloc {...props} />;
export const Column = (props: any) => <Bloc direction="column" {...props} />;

export default Bloc;
