import { useState, useEffect } from 'react';

export default function useTabIndexSelector(
    routes: { tabKey: string }[],
    tab?: string
): [number, (index: number) => void] {
    const [selectedTabIndex, setSelectedTabIndex] = useState(tab ? routes.findIndex(route => route.tabKey === tab) : 0);

    useEffect(() => {
        if (tab) setSelectedTabIndex(routes.findIndex(route => route.tabKey === tab));
    }, [routes, tab]);
    return [selectedTabIndex, setSelectedTabIndex];
}
