import React, { FunctionComponent } from 'react';
import { FieldProps } from 'formik';

import { ToggleSwitch } from 'components';
import FieldContainer from './FieldContainer';
import styled from 'styled-components';

interface IProps {
    disabled?: boolean;
    className?: string;
    label?: string;
    sideLabel?: boolean;
    onLabel: string;
    offLabel: string;
    inline?: boolean;
    handleChange?: any;
    testID?: string;
}

const ToggleSwitchField: FunctionComponent<FieldProps & IProps> = (props) => {
    const { field, form, disabled, sideLabel, onLabel, offLabel, inline, handleChange, testID } = props;
    const onChange = () => {
        form.setFieldValue(field.name, !field.value);
        form.setFieldTouched(field.name);
    };

    return (
        <FieldContainer {...props} inline={inline}>
            <WrapperToggleChildren 
              sideLabel={sideLabel}
              {...(testID && {'data-test':`${testID}-toggle`})}
            >
                <ToggleSwitch
                    disabled={disabled}
                    {...field}
                    onChange={handleChange ? handleChange : onChange}
                    checked={field.value}
                />
                {sideLabel ? (
                    field.value ? (
                        <To>
                            <Label> {onLabel}</Label>
                        </To>
                    ) : (
                        <To>
                            <Label> {offLabel}</Label>
                        </To>
                    )
                ) : null}
            </WrapperToggleChildren>
        </FieldContainer>
    );
};

export default ToggleSwitchField;

interface IWrapper {
    sideLabel?: boolean;
}

const WrapperToggleChildren = styled.div<IWrapper>`
    display: flex;
    flex-direction: ${({ sideLabel }) => (sideLabel ? 'row' : 'column')};
    flex: 1;
`;

const To = styled.div`
    margin: 0 ${({ theme }) => theme.spacing.s}px;
`;

const Label = styled.label`
    color: ${({ theme }) => theme.color.grey[6]};
    font-size: ${({ theme }) => theme.typography.fontSizeM}px;
`;
