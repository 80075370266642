import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface IProps {
    name: ReactElement | ReactElement[] | string;
    children: ReactElement | ReactElement[] | string;
}

export default function Label({ children, name }: IProps) {
    return (
        <LabelTag>
            <Name>{name}</Name>
            {children}
        </LabelTag>
    );
}

interface IInfoTagProps {}
const LabelTag = styled.span<IInfoTagProps>``;
const Name = styled.div`
    font-size: 12px;
    color: ${props => props.theme.color.grey[4]};
    padding: 20px 0 10px 0;
`;
