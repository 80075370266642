import { ErrorMessageLighter, InputLabel } from 'components';
import { FieldProps, FormikErrors } from 'formik';
import get from 'lodash/get';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BaseInputSecondary, InputState } from '../common';
import FieldContainer from './FieldContainer';

enum Item {
    START = 'START',
    END = 'END',
}

interface IProps {
    readonly className?: string;
    readonly label?: string;
    readonly disabled?: boolean;
    readonly labelStart?: string;
    readonly labelEnd: string;
    readonly width?: number;
    readonly validateFormOnChange?: boolean;
    readonly useDefaultErrorMessage?: boolean;
    readonly labelEndInline?: string;
    readonly labelsFontSize?: string;
    readonly handleInputChange?: Function;
}

const RangeInputField = (props: FieldProps & IProps) => {
    const { field, form, disabled, labelStart, labelEnd, width, validateFormOnChange, useDefaultErrorMessage = true, labelEndInline, labelsFontSize = "L", handleInputChange } = props;
    const [startValue, setStartValue] = useState(field.value?.min || 0);
    const [endValue, setEndValue] = useState(field.value?.max || 0);
    const nameStart = useMemo(() => `${field.name}.min`, [field.name]);
    const nameEnd = useMemo(() => `${field.name}.max`, [field.name]);

    const handleChange = useCallback((item: Item) => ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (item === Item.START) {
            setStartValue(value)
            if (!nameStart) {
                return;
            }
            form.setFieldValue(nameStart, `${value}`.length > 0 ? Number(value) : null);
            form.setFieldTouched(nameStart);
            handleInputChange && handleInputChange(Number(value))
        }

        if (item === Item.END) {
            setEndValue(value)
            form.setFieldValue(nameEnd, `${value}`.length > 0 ? Number(value) : null);
            form.setFieldTouched(nameEnd);
        }

        if (validateFormOnChange) {
            setTimeout(() => {
                form.validateForm();
            }, 0);
        }
    }, [validateFormOnChange, nameStart, form, handleInputChange, nameEnd]);

    useEffect(() => {
        setStartValue(isNaN(field.value?.min) || field.value?.min === null ? '' : field.value.min);
        setEndValue(isNaN(field.value?.max) || field.value?.max === null ? '' : field.value.max);
    }, [field.value]);

    const renderError = (error?: string | string[] | FormikErrors<any> | FormikErrors<any>[], inline?: boolean) => {
        if (!error) {
            return null;
        }
        // When is object, avoid render when all values are undefined
        if (typeof error === 'object' && Object.values(error).findIndex(e => !!e) === -1) {
            return null;
        }

        return (
            <ErrorWrapper inline={inline}>
                <ErrorMessageLighter>{error}</ErrorMessageLighter>
            </ErrorWrapper>
        );
    };

    return (
        <FieldContainer {...props}>
            <Wrapper>
                <Vertical>
                    <AllInputsWrapper>
                        {labelStart &&
                            <VerticalWithMargin marginLeft={!labelEndInline}>
                                <InputWrapper>
                                  <InputLabel
                                      name={labelStart}
                                      htmlFor={nameStart}
                                      fontSize={labelsFontSize}
                                      inline
                                      noMarginRight
                                  />
                                  <Input
                                      {...field}
                                      name={nameStart}
                                      width={width}
                                      disabled={disabled}
                                      onChange={handleChange(Item.START)}
                                      state={!!get(form.errors, nameStart) && !!get(form.touched, nameStart) ? InputState.INVALID : InputState.VALID}
                                      value={startValue}
                                      min="0"
                                      step="1"
                                  />
                                </InputWrapper>
                            </VerticalWithMargin>
                        }
                        <Vertical>
                            <InputWrapper>
                                <InputLabel
                                    name={labelEnd}
                                    htmlFor={nameEnd}
                                    inline
                                    noMarginRight
                                    fontSize={labelsFontSize}
                                />
                                <Input
                                    {...field}
                                    name={nameEnd}
                                    width={width}
                                    disabled={disabled}
                                    onChange={handleChange(Item.END)}
                                    state={!!get(form.errors, nameEnd) && !!get(form.touched, nameEnd) ? InputState.INVALID : InputState.VALID}
                                    value={endValue}
                                    min="0"
                                    step="1"
                                />
                                {labelEndInline &&
                                  <InputLabel
                                    name={labelEndInline}
                                    htmlFor={nameEnd}
                                    inline
                                    noMarginRight
                                    fontSize={labelsFontSize}
                                  />
                                }
                                {!useDefaultErrorMessage && !labelStart && !labelEndInline && renderError(get(form.errors, nameEnd))}
                            </InputWrapper>
                        </Vertical>
                    </AllInputsWrapper>
                    {!useDefaultErrorMessage && (labelStart || labelEndInline) && renderError({ start: get(form.errors, nameStart), end: get(form.errors, nameEnd) }, true)}
                </Vertical>
            </Wrapper>
        </FieldContainer>
    );
};

const Wrapper = styled.div`
    display: flex;
`;

const Vertical = styled.div`
    flex-direction: row;
`;

// @ts-ignore
const VerticalWithMargin = styled(Vertical)<{marginLeft?: boolean}>`
    &:not(:last-child) {
        margin-right: ${({ marginLeft }) => marginLeft? '38' : '0' }px;
    }
`;

const Input = styled(BaseInputSecondary)<Pick<IProps, 'width'>>`
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    text-align: left;
    font-size: ${({ theme }) => theme.typography.fontSizeL}px;
    margin: 0 ${({ theme }) => theme.spacing.xs}px;

    &::placeholder {
        color: ${({ theme }) => theme.color.grey[6]};
        font-weight: 200;
    }
`;

const InputWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const AllInputsWrapper = styled.div`
    display: flex;
`;

const ErrorWrapper = styled.div<{ inline?: boolean }>`
    margin-left: ${({ theme, inline }) => inline ? 0 : theme.spacing.xs}px;
    margin-top: ${({ theme, inline }) => inline ? theme.spacing.s : 0}px;
    max-height: ${({ theme }) => theme.dimension.height.element}px;
`;

export default RangeInputField;
