import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import get from 'lodash/get';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TableWithOptions, Icon } from 'components';
import { StyledTooltip } from 'pages/Communication/CommunicationPage/CommunicationListTable';
import { appTheme } from 'theme';
import { QueryResult } from 'localTypes';
import { listLocalArticles_list_edges_node_LocalArticle } from 'types/listLocalArticles';
import FormulasTablePanel from 'components/Layout/FormulasTablePanel';

// we will be using the local catalog query while the BE is being made
const LIST_FORMULAS = loader('../../query/listAllFormulas.gql');
const ICON_SIZE = 20;
const ICON_COLOR = appTheme.color.grey[6];
interface IProps {
    idHolding: string;
    toggleFormulasModal: () => void;
    isFormulasPanelOpen: boolean;
}

const TableRow = (formula, index, onClickEditBtn, editLabel) => (
    <tr key={`${formula.id}-${index}`}>
        <TextContainer>
            <TextWrapper>{formula.name}</TextWrapper>
        </TextContainer>
        <td>
            <TextWrapper>{formula.description}</TextWrapper>
        </td>
        <ButtonsContainer>
            <StyledTooltip toolTipContent={editLabel}>
                <span id={`edit-button_row-${index}`} onClick={() => onClickEditBtn(index)}>
                    <Icon.Edit width={ICON_SIZE} height={ICON_SIZE} color={ICON_COLOR} />
                </span>
            </StyledTooltip>
        </ButtonsContainer>
    </tr>
);

const FormulasTable = ({ idHolding, toggleFormulasModal, isFormulasPanelOpen }: IProps) => {
    const { t } = useTranslation();
    const editLabel = t('app:button.containerEdit');
    const [isEditMode, setIsEditMode] = useState(false);
    const [indexEditFormulas, setIndexEditFormulas] = useState(0);

    const defaultQueryVariables = {
        querySearch: [
            {
                key: 'idHolding',
                operator: '=',
                value: idHolding,
            },
        ],
    };

    const onClickEditBtn = (index) => {
        setIndexEditFormulas(index);
        setIsEditMode(true);
        toggleFormulasModal();
    };
    return (
        <TableWithOptions
            renderLine={(item, index: number) => TableRow(item, index, onClickEditBtn, editLabel)}
            headers={getHeaders(t)}
            fetchPolicy="cache-and-network"
            query={LIST_FORMULAS}
            variables={defaultQueryVariables}
            withSearchBar={false}
            withPagination={false}
        >
            {({ data, refetch }: QueryResult<listLocalArticles_list_edges_node_LocalArticle>) => {
                const formula = isEditMode ? get(data, `list.edges[${indexEditFormulas}].node`, null) : null;
                const closeModal = ({ shouldRefetch = false }) => {
                    if (shouldRefetch) {
                        refetch();
                    }
                    setIsEditMode(false);
                    setIndexEditFormulas(0);
                    toggleFormulasModal();
                };

                return (
                    <FormulasTablePanel
                        closeModal={closeModal}
                        isOpen={isFormulasPanelOpen}
                        formula={formula}
                        isEditMode={isEditMode}
                    />
                );
            }}
        </TableWithOptions>
    );
};

const getHeaders = (t: any) => [
    {
        key: 'name',
        displayName: t('schema:formula.name'),
    },
    {
        key: 'description',
        displayName: t('schema:formula.description'),
    },
    {
        key: 'action',
        displayName: ' ',
    },
];

const TextContainer = styled.td`
    width: 25%;
`;

const TextWrapper = styled.span`
    font-size: ${({ theme }) => theme.typography.size.S}px;
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    margin-top: ${({ theme }) => theme.spacing.s}px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    letter-spacing: 0;
    font-weight: lighter;
    overflow-wrap: anywhere;
`;
const ButtonsContainer = styled.td`
    width: 1%;
`;

export default FormulasTable;
