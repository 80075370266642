import React from 'react';
import { Tags } from 'components';
import { ITagOption } from 'components/Tag';
import { FieldProps } from 'formik';

import FieldContainer from './FieldContainer';

interface IProps {
    options: ITagOption[];
    testID?: string;
}

const FormikTag = ({ field, form, options, testID, ...rest }: FieldProps & IProps) => (
    <FieldContainer field={field} form={form} {...rest} testID={testID}>
        <Tags
            {...field}
            onChange={value => {
                form.setFieldValue(field.name, value);
                form.setFieldTouched(field.name);
            }}
            options={options}
            wrapped={true}
            {...(testID && {testID:`${testID}-tags`})}
        />
    </FieldContainer>
);

export default FormikTag;
