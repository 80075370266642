import _ from "lodash";
import { CashSystem } from "types/cashSystem";

const cashSystemMapper = (result: any): CashSystem[] => {
    const list = _.get(result, "list.edges");
    return list?.map(({ node }: any) => (
        { ...node, label: node.name }
    ));
};

export default cashSystemMapper;
