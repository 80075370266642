import { Button, Struct, Title } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Container, TextButton } from './AdminPage.style';
import routes from 'Router/routes';
import CheckListUsersTable from './CheckListUsersTable';

interface IProps extends RouteComponentProps<{ siteId: string }> {};

const AdminPage = ({
    location,
    history,
    match: {
        params: { siteId }
    },
    }: IProps) => {
    const { t } = useTranslation();
    const redirectToCheckListUsersCreatePage = () => {
        history.push(`${routes.checkList.admin.checkListUser.add(siteId)}${location.search}`);
    };

    return (
        <Struct.Section>
            <Container id='title_section'>
                <Title grow mode="H2" value={t('page:checkList.adminList.title')} /> 
                <Button onClick={redirectToCheckListUsersCreatePage}>
                    <TextButton>{t('app:button.add-checklistUser')}</TextButton>
                </Button>
            </Container>
            <Struct.Card>
                <CheckListUsersTable />
            </Struct.Card>
        </Struct.Section>
    );
};

export default withRouter(AdminPage);
