import { DefaultTheme, createGlobalStyle } from 'styled-components';
import { rgba } from 'polished';
import KelsonSansRegular from './resources/fonts/Kelson-Regular.woff';
import KelsonSansLight from './resources/fonts/Kelson-Light.woff';
import KelsonSansMedium from './resources/fonts/Kelson-Medium.woff';

//                0          1          2          3          4          5          6          7          8
const grey = ['#F7F7F7', '#F2F4F6', '#E8ECEF', '#D7D7D7', '#B9B9B9', '#98A9BC', '#5F7081', '#383838', '#6B6B6B'];
const common = {
  transparent: 'transparent',
  white: '#FFF',
  black: '#000',
  lightGrey: '#EFEFEF',
  darkGrey: '#36434C',
  deepGrey: '#232323',

  lightBlue: '#E9F5FE',
  blue: '#2196F3',
  darkBlue: '#187BCA',
  navyBlue: '#0B5E9E',

  navy: '#1D2C39',

  lightGreen: '#E5F9F6',
  green: '#00BFA5',
  brightGreen: '#87D215',
  darkGreen: '#01A790',
  mediumGreen:'#4EAC63',

  lightOrange: '#FFF3E5',
  orange: '#FF8000',
  mediumOrange: '#FFB800',
  mediumLightOrange: '#F4B83A',

  lightRed: '#FFF9F9',
  lightMediumRed: '#FFE9E9',
  mediumRed: '#FFE0E0',
  red: '#F76262',
  darkRed: '#F13F3D',
  backgroundRed: 'rgba(247,98,98,0.1)',

  pink: '#FF62A5',
  pinkVivid: '#FE4D97',

  yellow: '#FFDB00',
  lightYellow: '#FFFCE5',
};

export const appTheme: DefaultTheme = {
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    // used for titles and number inputs
    fontFamilySecondary: "'Rubic', sans-serif",
    fontFamilyKelson: "'Kelson', sans-serif",
    fontSizeXXS: 10,
    fontSizeXS: 12,
    fontSizeS: 14,
    fontSizeM: 16,
    fontSizeL: 18,
    fontSizeXL: 24,
    fontSizeXXL: 30,
    inputFontSize: 18,
    size: {
      XXS: 10,
      XS: 12,
      S: 14,
      M: 16,
      L: 18,
      XL: 24,
      XXL: 30,
    },
    lineHeight: {
      XXS: 12,
      XS: 14,
      S: 19,
      M: 21,
      L: 33,
      XL: 41,
      XXL: 60,
    },
    fontWeight: {
      lighter: 300,
      light: 400,
      bold: 600,
      bolder: 700,
    },
  },
  color: {
    grey,
    common,
    input: {
      borderColor: grey[2],
      borderColorError: common.darkRed,
      borderColorFocus: common.blue,
      borderColorValid: common.darkGreen,
      borderColorSecondary: grey[6],
      backgroundColor: common.white,
      backgroundColorError: rgba(common.red, 0.1),
      backgroundColorDisabled: grey[0],
      backgroundColorSelected: rgba(common.blue, 0.5),
      textColor: common.navy,
      textColorSecondary: grey[6],
      placeholderColor: grey[5],
      textColorError: common.darkRed,
    },
  },
  spacing: {
    xxs: 5,
    xs: 10,
    s: 20,
    m: 30,
    l: 50,
  },
  dimension: {
    height: {
      header: 185,
      small: 23,
      inline: 30,
      element: 40,
      tr: 70,
    },
    width: {
      small: 27,
      sideNav: 330,
      mainContent: 940,
    },
  },
  borderRadius: {
    xs: 2,
    s: 4,
    m: 8,
    l: 14,
    xl: 30,
    xxl: 50,
  },
  boxShadow: ['0 2px 4px 0 rgba(0, 0, 0, 0.17)', '0 -4px 7px -7px rgba(0, 0, 0, 0.5)'],
  transition: {
    duration: [150, 300, 500],
  },
  opacity: {
    disabled: 0.75,
  },
};

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Rubik:300,400,600&display=swap');
  
  @font-face {
  font-family: 'Kelson';
    src: url(${KelsonSansRegular});
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
  font-family: 'Kelson';
    src: url(${KelsonSansMedium});
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
  font-family: 'Kelson';
    src: url(${KelsonSansLight});
    font-weight: 300;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
  }
  body {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    background-color: ${({ theme }) => theme.color.grey[0]};
    margin :0;
    padding: 0;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  input[type=number] {
      -moz-appearance:textfield; /* Firefox */
  }

  :focus {
    outline: none;
  }

  input:focus {
    outline: 1px solid ${({ theme }) => theme.color.common.blue};
  }

  button:disabled {
    cursor: not-allowed;
    opacity: ${({ theme }) => theme.opacity.disabled};
  }

  textarea:disabled,
  input:disabled {
    cursor: not-allowed;
  }

  button {
    border: none;
    cursor: pointer;
  }

  button:active {
    border-style: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  h1, h2, h3, h4 {
    font-weight: normal;
    margin: 0px;
  }

  .app-toast-container {
    padding: 0px !important;
  }

  .app-toast {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px !important;
    background-color: ${({ theme }) => theme.color.common.white} !important;
    color: ${({ theme }) => theme.color.grey[6]} !important;
  }
  .app-toast-error {
    border-left: 8px solid ${({ theme }) => theme.color.common.darkRed};
  }
  .app-toast-info {
    border-left: 8px solid ${({ theme }) => theme.color.common.blue};
  }
`;
