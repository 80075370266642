import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import _ from "lodash";
import { TextInput, NumberInput, Button, Icon, IconButton } from 'components';
import { ButtonType } from 'components/Button'
import { appTheme } from 'theme';
import { ArticleFamily } from 'types/globalTypes'

type Step = {
  name: string;
  maxProducts: number;
};

type FormulaStepCreationProps = {
  onChangeSteps: (steps: Step[]) => void;
  steps?: Step[];
  errors?: {
    name: string;
    maxProducts: string;
  };
};

const MAX_ARTICLES_MIN_VALUE = 1;
const DEFAULT_STEP = [{ name: '', maxProducts: MAX_ARTICLES_MIN_VALUE }];

const FormulaStepCreation: React.FC<FormulaStepCreationProps> = ({
  onChangeSteps = () => { },
  steps = DEFAULT_STEP,
  errors = {}
}) => {
  const { t } = useTranslation();
  const [memoSteps, setMemoSteps] = useState(steps);

  const handleAddOnClick = () => {
    const newSteps = [...memoSteps, ...DEFAULT_STEP];

    setMemoSteps(newSteps);
    onChangeSteps(newSteps);
  };

  const handleRemoveOnClick = (index: number) => {
    if (memoSteps.length === 1) {
      setMemoSteps(DEFAULT_STEP);
      return onChangeSteps(DEFAULT_STEP);
    }

    const newSteps = [...memoSteps]
    newSteps.splice(index, 1);

    setMemoSteps(newSteps);
    onChangeSteps(newSteps);
  };

  const handleInputOnChange = (value: string | number, index: number, field: string) => {
    const newSteps = [...memoSteps];
    newSteps[index] = {
      ...newSteps[index],
      [`${field}`]: value
    };

    setMemoSteps(newSteps);
    onChangeSteps(newSteps);
  };
  const handleInputOnBlur = (value: string, index: number) => {
    const newSteps = [...memoSteps];
    newSteps[index] = {
      ...newSteps[index],
      name: value.trim()
    };

    setMemoSteps(newSteps);
    onChangeSteps(newSteps);
  };

  const handleStepNamePlaceholder = (index: number): string => {
    const stepNameDefaultPlaceHolder = [
      `${ArticleFamily.STARTER}`,
      `${ArticleFamily.DISH}`,
      `${ArticleFamily.DESSERT}`,
      `${ArticleFamily.MISCELLANEOUS}`
    ];
    const placeHolderIndex = index <= stepNameDefaultPlaceHolder.length - 2 ? index : 3

    return `${t('component:formulaStepCreation.exampleStep')}${t(`schema:article.familyOption.${stepNameDefaultPlaceHolder[placeHolderIndex]}`)}`
  };

  const handleStepRender = (step: Step, i: number) => (
    <div key={`key-${i}`}>
      <div>
        <RowContainer>
          <FieldContainer>
            <StepTitle>
              {`${t('component:formulaStepCreation.step')} ${i + 1}`}
            </StepTitle>
            <TextInput
              width={250}
              value={step.name}
              onChange={(value) => handleInputOnChange(value, i, 'name')}
              placeholder={handleStepNamePlaceholder(i)}
              hasError={!!errors?.[i]?.name}
              onBlur={(event) =>handleInputOnBlur(event.target.value, i)}
            />
          </FieldContainer>
          <FieldContainer>
            <StepTitle>
              {t('component:formulaStepCreation.maxProducts')}
            </StepTitle>
            <RowContainer>
              <FieldContainer>
                <NumberInput
                  hasError={step.maxProducts < MAX_ARTICLES_MIN_VALUE}
                  showButtons={false}
                  value={step.maxProducts}
                  validateOnChange
                  onChange={(value) => handleInputOnChange(value, i, 'maxProducts')}
                />
              </FieldContainer>
              <IconButton
                onClick={() => handleRemoveOnClick(i)}
                disabled={memoSteps.length === 1 && _.isEqual(memoSteps, DEFAULT_STEP)}
              >
                <Icon.Bin
                  width={18}
                  height={20}
                  color={appTheme.color.grey[6]}
                />
              </IconButton>
            </RowContainer>
          </FieldContainer>
        </RowContainer>
      </div>
      <br />
    </div>
  )

  return (
    <>
      {memoSteps.map(handleStepRender)}
      <Button display={ButtonType.SECONDARY} onClick={handleAddOnClick}>{t('component:formulaStepCreation.addStep')}</Button>
    </>
  );
};

const StepTitle = styled.div`
  line-height: 17px;
  color: ${({ theme }) => theme.color.grey[6]};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: normal;
  font-size: ${({ theme }) => theme.typography.fontSizeM}px;
  margin-bottom: 6px;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FieldContainer = styled.div`
  margin-right: 20px;
`;

export default FormulaStepCreation;
