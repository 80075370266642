import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import { BaseInput, IInputProps } from './common';

interface IProps extends IInputProps<string> {
    id?: string;
    inline?: boolean;
    width?: number;
    multiline?: boolean;
    autoFocus?: boolean;
    maxLength?: number;
    style?: object;
    placeholder?: string;
    useDefaultErrorColoring?: boolean;
    color?: string;
    isPlaceholderBold?: boolean;
    testID?: string;
    }

export default function TextInput({
    id,
    value,
    onChange,
    onBlur,
    name,
    className,
    hasError,
    inline,
    width,
    disabled,
    multiline,
    autoFocus,
    maxLength,
    style,
    placeholder,
    useDefaultErrorColoring = true,
    color,
    isPlaceholderBold,
    testID
}: IProps) {
    function onValueChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const { value } = event.currentTarget;
        onChange(value);
    }
    const TagName = multiline ? Textarea : Input;
    return (
        <TagName
            ref={(el: HTMLElement | null) => (autoFocus ? setTimeout(() => el && el.focus()) : null)}
            placeholder={placeholder}
            hasError={hasError}
            useDefaultErrorColoring={useDefaultErrorColoring}
            className={className}
            value={value}
            type="text"
            name={name}
            id={id ? id : name}
            onChange={onValueChange}
            onBlur={onBlur}
            width={width ? width : inline ? 120 : undefined}
            disabled={disabled}
            maxLength={maxLength}
            style={style}
            color={color}
            isPlaceholderBold={isPlaceholderBold}
            data-test={testID}
        />
    );
}

interface InputProps {
    readonly width?: Number;
    onKeyDown?: any;
    readonly color?: string;
    readonly isPlaceholderBold?: boolean;
}

const Input = styled(BaseInput)<InputProps>`
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    text-align: left;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    color: ${({ color, theme }) => (color ? color : theme.color.common.nav)};
    &::placeholder {
        color: ${({ isPlaceholderBold, theme }) => isPlaceholderBold ? theme.color.grey[4] : theme.color.grey[6] };
        font-weight:${({ isPlaceholderBold, theme}) => isPlaceholderBold ?  theme.typography.fontWeight.bold : 200};
    }
    &:not(:focus) {
        color: ${({ hasError, useDefaultErrorColoring = true, theme, color }) => (useDefaultErrorColoring ?
            hasError ? theme.color.input.textColorError : color ? color : theme.color.input.textColor
            : color ? color : theme.color.input.textColor )};
    }
`;

const Textarea = styled(Input).attrs({
    as: 'textarea',
})`
    height: 100px;
    color: ${({ color, theme }) => (color ? color : theme.color.common.nav)};
    &::placeholder {
        color: ${({ isPlaceholderBold, theme }) => isPlaceholderBold ? theme.color.grey[4] : theme.color.grey[6] };
        font-weight:${({ isPlaceholderBold, theme}) => isPlaceholderBold ?  theme.typography.fontWeight.bold : 200};
    }
    &:not(:focus) {
        color: ${({ hasError, useDefaultErrorColoring = true, theme, color }) => (useDefaultErrorColoring ?
            hasError ? theme.color.input.textColorError : color ? color : theme.color.input.textColor
            : color ? color : theme.color.input.textColor )};
    }
`;

export { Input };
