import I18n from 'services/i18n';
import { DateTime, Duration } from 'luxon';
const locale_fr = 'fr-FR';

export function getDayFullName(date: string, locale: string = locale_fr): string {
    const today = new Date();
    const requestedDate = new Date(date);
    const dayFullName =
        today.getDay() === requestedDate.getDay()
            ? I18n.t('app:today')
            : new Intl.DateTimeFormat(locale, { weekday: 'long' }).format(requestedDate);
    return dayFullName.charAt(0).toUpperCase() + dayFullName.slice(1);
}

export function getDisplayDate(date: string, locale = locale_fr) {
    return new Date(date).toLocaleDateString(locale);
}

export function getDisplayTime(date: string, locale = locale_fr) {
    return new Date(date).toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' });
}

export function getDisplayDateAndTime(date: string, locale = locale_fr) {
    return getDisplayDate(date, locale) + ' ' + getDisplayTime(date, locale);
}

export function getDisplayTimeWithoutLocal(date: string) {
   return date.split('T')[1].substring(0, 5)
}

export function getDates(range: string) {
    return range.split('/');
}

export function areRangesEqual(range1: string, range2: string) {
    const [range1StartDate, range1EndDate] = getDates(range1);
    const [range2StartDate, range2EndDate] = getDates(range2);
    return (
        new Date(range1StartDate).getTime() === new Date(range2StartDate).getTime() &&
        new Date(range1EndDate).getTime() === new Date(range2EndDate).getTime()
    );
}

export function getDateTime(duration: Duration) {
    return DateTime.local()
        .setLocale(locale_fr)
        .startOf('week')
        .plus(duration);
}

export function getDateTimeUTC(duration: Duration) {
    return DateTime.utc()
        .setLocale(locale_fr)
        .startOf('week')
        .plus(duration);
}

export function getTimeFromISO(date : string){
    return getDateTime(Duration.fromISO(date)).toString();
}

//Will build a string to be used in TimeInputRangeField components, defining a range using a startTime and endTime
export function getRangeTime(startTime : string, endTime:string){
    return getDateTime(Duration.fromISO(startTime)).toString() + '/' + getDateTime(Duration.fromISO(endTime)).toString();
}

//Will create a object with hours and minutes from a date string
export function getHoursAndMinutesFromStringToISO(date: string){
    const dateTimeFormatted = DateTime.fromISO(date);
    return Duration.fromObject({
        hours : dateTimeFormatted.hour,
        minutes : dateTimeFormatted.minute
    }).toISO()

}
//Will convert the time P0DT11H01M to 11:01  
export const timeConverter = (duration: string) => {
    if (!duration) {
        return duration;
    }
    const dateTime = getDateTime(Duration.fromISO(duration.substr(0, 1) === 'P' ? duration : `PT${duration}`));
    const time = dateTime
        .toISOTime()
        .split('.')[0]
        .slice(0, 5);
    return time;
};

//Will convert the time 11:01 to P0DT11H01M
export const timeConverterReverse = (duration: string) => {
    if (!duration) {
      return duration; 
    }

    const dateTime = duration.split(':');
    const time = `P0DT${dateTime[0]}H${dateTime[1]}M`;
    return time;
  };

export const convertObjectToISO = (value: { duration: number, unit: string } | string) =>
    typeof value === 'object' ? Duration.fromObject({[value.unit]: value.duration}).toISO() : value;
