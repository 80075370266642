import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { AddButton, DeleteButton, Panel, Tooltip, TableWithOptions, Info, InfoType, Icon, Loader } from 'components';
import { PanelContent } from 'components/Panel';
import { getArticleFamilyOptions } from 'services/articleService';
import {
    listLocalArticles,
    listLocalArticles_list_edges_node_LocalArticle,
    listLocalArticlesVariables,
} from 'types/listLocalArticles';
import { deleteByIdVariables } from 'types/deleteById';
import { createOfferItemVariables } from 'types/createOfferItem';
import { Omit } from 'localTypes';
import styled from 'styled-components';
import I18n from '../../services/i18n';
import { formulasKey } from '../../pages/ClickCollect/DailyOffersPage/OfferDetailsPage/FormulasTable';
import { ImportationType } from 'types/globalTypes';

export interface ILocalArticle extends Omit<listLocalArticles_list_edges_node_LocalArticle, 'offerItems'> {
    offerItemId?: string;
}

export type QueryParamKeys = 'search';

const LIST_LOCAL_ARTICLES = loader('../../query/listLocalArticles.gql');
const CREATE_OFFER_ITEM_MUTATION = loader('../../query/createOfferItem.gql');
const DELETE_BY_ID_MUTATION = loader('../../query/deleteById.gql');
const ADD_FORMULA_PRODUCT_TO_OFFER_MUTATION = loader('../../query/offerComposition/addFormulaProductToOffer.gql');
const ADD_ARTICLE_TO_FORMULA = loader('../../query/addFormulaSubProductToOffer.gql');

const ICON_WIDTH = 20;

type FormulasListState = {
  formulaId: string;
  formulaOfferId: string;
};
interface IProps {
    idOffer: string;
    match: {
        params: { idPos: string; idHolding: string };
    };
    isOpen: boolean;
    closeModal: () => void;
    activeFormulasList?: FormulasListState[];
    formula?: IOfferFormula | null;
    idPos?: string;
}

interface IOfferFormula {
    idOfferItemFormula: string;
    stepNumber: number;
    stepArticles;
}

function LocalArticle({
    item: { id, offerItemId, cashSystemCode, label, container, article },
    idOffer,
    queryVariables,
    formula = null
}: {
    item: ILocalArticle;
    idOffer: string;
    queryVariables: listLocalArticlesVariables;
    formula?: IOfferFormula | null;
}) {
    const { t } = useTranslation();
    return (
        <>
            <td
                style={{
                    width: '100%',
                    maxWidth: '315px',
                    overflow: 'hidden',
                    overflowWrap: 'break-word',
                }}
            >
                {label}
            </td>
            <td>{article.lastUse}</td>
            <td style={{ width: '10%' }}>
                {article.importationType === ImportationType.Oscar
                    ? <Icon.Check width={18} height={16} color="#383838" />
                    : <Icon.Cross width={16} color="#383838" />
                }
            </td>
            <StyledTd>
                <StyledAddOrDeleteOfferButton>
                    {container !== null && (
                        <div style={{ marginRight: '20px' }}>
                            <Icon.Container width={ICON_WIDTH} height={ICON_WIDTH} />
                        </div>
                    )}
                    {!!formula?.idOfferItemFormula ?
                        <FormulaArticle
                            articleId={id}
                            idOffer={idOffer}
                            disabled={!cashSystemCode}
                            toolTipContent={t('page:clickcollect.daily-offers.noIdMenuSystem')}
                            formula={formula}
                        />
                        : !!offerItemId ? (
                            <>
                                <StyledInfo display={InfoType.OK}>
                                    <>{t('page:clickcollect.daily-offers.articleOfferTag')}</>
                                </StyledInfo>
                                <DeleteOfferItem offerItemId={offerItemId} queryVariables={queryVariables} />
                            </>
                        ) : (
                            <AddLocalArticle
                                articleId={id}
                                idOffer={idOffer}
                                disabled={!cashSystemCode}
                                toolTipContent={t('page:clickcollect.daily-offers.noIdMenuSystem')}
                                queryVariables={queryVariables}
                            />
                        )}
                </StyledAddOrDeleteOfferButton>
            </StyledTd>
        </>
    );
}

function AddLocalArticle({
    articleId,
    idOffer,
    disabled,
    toolTipContent,
    queryVariables,
}: {
    articleId: string;
    idOffer: string;
    disabled?: boolean;
    toolTipContent: string;
    queryVariables: listLocalArticlesVariables;
}) {
    return (
        <Mutation
            update={(cache: any, { data: { createOfferItem } }: any) => {
                const cachedData = cache.readQuery({
                    query: LIST_LOCAL_ARTICLES,
                    variables: queryVariables,
                }) as listLocalArticles;

                const updatedEdges = cachedData.list.edges.map((edge) => {
                    const localArticle = edge.node as listLocalArticles_list_edges_node_LocalArticle;
                    if (localArticle.id === createOfferItem.localArticle.id) {
                        return {
                            ...edge,
                            node: {
                                ...edge.node,
                                offerItems: [{ id: createOfferItem.id, __typename: 'OfferItem' }],
                            },
                        };
                    }

                    return edge;
                });

                cache.writeQuery({
                    query: LIST_LOCAL_ARTICLES,
                    variables: queryVariables,
                    data: { ...cachedData, list: { ...cachedData.list, edges: updatedEdges } },
                });
            }}
            mutation={CREATE_OFFER_ITEM_MUTATION}
        >
            {(createOfferItem: (param: Record<'variables', createOfferItemVariables>) => Promise<any>) => {
                const onAddClick = () => {
                    createOfferItem({
                        variables: {
                            idOffer,
                            idLocalArticle: articleId,
                            quantityOverall: 0,
                            idsArticleTag: [],
                            idsArticleCertification: [],
                        },
                    });
                };
                return (
                    <StyledTooltip toolTipContent={disabled ? toolTipContent : ''}>
                        <AddButton disabled={disabled} onClick={onAddClick} />
                    </StyledTooltip>
                );
            }}
        </Mutation>
    );
}

function DeleteOfferItem({
    offerItemId,
    queryVariables,
}: {
    offerItemId: string;
    queryVariables: listLocalArticlesVariables;
}) {
    return (
        <Mutation
            update={(cache: any) => {
                const cachedData = cache.readQuery({
                    query: LIST_LOCAL_ARTICLES,
                    variables: queryVariables,
                }) as listLocalArticles;
                const updatedEdges = cachedData.list.edges.map((edge) => {
                    const localArticle = edge.node as listLocalArticles_list_edges_node_LocalArticle;
                    if (localArticle.offerItems[0] && localArticle.offerItems[0].id === offerItemId) {
                        return {
                            ...edge,
                            node: {
                                ...edge.node,
                                offerItems: [],
                            },
                        };
                    }
                    return edge;
                });
                cache.writeQuery({
                    query: LIST_LOCAL_ARTICLES,
                    variables: queryVariables,
                    data: { list: { ...cachedData.list, edges: updatedEdges } },
                });
            }}
            mutation={DELETE_BY_ID_MUTATION}
        >
            {(deleteById: (param: Record<'variables', deleteByIdVariables>) => Promise<any>) => {
                const onDeleteClick = () => {
                    deleteById({
                        variables: { id: offerItemId },
                    });
                };
                return <DeleteButton onClick={onDeleteClick} />;
            }}
        </Mutation>
    );
}

const FormulaArticle = ({
    articleId,
    idOffer,
    disabled,
    toolTipContent,
    formula
}: {
    articleId: string;
    idOffer: string;
    disabled?: boolean;
    toolTipContent: string;
    formula: IOfferFormula;
}) => {
    const { t } = useTranslation();
    const [addArticleToFormula, { loading: addLoading }] = useMutation(ADD_ARTICLE_TO_FORMULA);
    const [deleteById, { loading: deleteLoading }] = useMutation(DELETE_BY_ID_MUTATION);
    const [addedToStep, setAddedToStep] = useState(formula.stepArticles.some((article) => article.localArticle.id === articleId));
    const onAddClick = async () => {
        const res = await addArticleToFormula({ variables: {
            idOffer,
            idLocalArticle: articleId,
            idOfferItemFormula: formula.idOfferItemFormula,
            stepNumber: formula.stepNumber
        } });
        if (!!res?.data?.addFormulaSubProductToOffer?.id) {
            formula.stepArticles = [...formula.stepArticles, {id: res.data.addFormulaSubProductToOffer.id, localArticle: {id: articleId}, stepNumber: formula.stepNumber}]
            setAddedToStep(true);
        }
    };

    const onDeleteClick = async () => {
        const articleIndex = formula.stepArticles.findIndex((article) => article.localArticle.id === articleId);
        const res = await deleteById({
            variables: { id: formula.stepArticles[articleIndex].id },
        });
        if(res?.data?.deleteById){
            const temp = [...formula.stepArticles]
            temp.splice(articleIndex, 1)
            formula.stepArticles = temp;
            setAddedToStep(false);
        }
    };

    return (addLoading || deleteLoading)
        ? <Loader /> : addedToStep
        ? <>
            <StyledInfo display={InfoType.OK}>
                <>{t('page:clickcollect.daily-offers.articleOfferTag')}</>
            </StyledInfo>
            <DeleteButton onClick={onDeleteClick} />
        </>
        : (<StyledTooltip toolTipContent={disabled ? toolTipContent : ''}>
                <AddButton disabled={disabled} onClick={() => onAddClick()} />
            </StyledTooltip>);
}

function LocalCatalogTablePanel({
    idOffer,
    isOpen,
    closeModal,
    activeFormulasList,
    match: {
        params: { idPos, idHolding },
    },
    formula
}: IProps & RouteComponentProps) {
    const { t } = useTranslation();
    const [formulasList, setFormulasList] = useState(activeFormulasList)

    const defaultQueryVariables = {
        idOffer,
        withOfferItems: true,
        order: 'family,label',
        querySearch: [
            { key: 'idPos', value: idPos },
            { key: 'deleted', value: 'false' },
        ],
    };

    const renderLine =
        (queryVariables: listLocalArticlesVariables) =>
        ({ offerItems, ...rest }: listLocalArticles_list_edges_node_LocalArticle) => {
            const localArticle = {
                ...rest,
                offerItemId: offerItems && offerItems.length > 0 ? offerItems[0].id : undefined,
            };

            return (
                <tr key={rest.id}>
                    <LocalArticle item={localArticle} idOffer={idOffer} queryVariables={queryVariables} formula={formula}/>
                </tr>
            );
        };

    const renderLineFormulas = () => (formula: any) => {
        return (
            <tr key={formula.id}>
                <FormulaLine
                    formula={formula}
                    idOffer={idOffer}
                    // @ts-ignore
                    formulasList={formulasList}
                    onUpdate={setFormulasList}
                />
            </tr>
        );
    };

    const papelTitle = formula ? t('page:clickcollect.daily-offers.title.localCatalogPanelFormulas') : t('page:clickcollect.daily-offers.title.localCatalogPanel');

    return (
        <Panel onClose={closeModal} open={isOpen} title={papelTitle}>
            <PanelContent>
                <TableWithOptions
                    withRouting={false}
                    withQueryVariables
                    query={LIST_LOCAL_ARTICLES}
                    variables={defaultQueryVariables}
                    searchPlaceholder={t('app:placeholder.search.article')}
                    renderLine={renderLine}
                    tags={{
                        'article.family': [
                            ...(!formula ? [{
                                id: formulasKey,
                                label: I18n.t('schema:formula.tag'),
                            }] : []),
                            ...getArticleFamilyOptions(),
                        ],
                    }}
                    extra={{
                        idHolding,
                        renderLineFormulas,
                    }}
                />
            </PanelContent>
        </Panel>
    );
}

const FormulaLine = ({
    formula,
    idOffer,
    formulasList,
    onUpdate,
}: {
    formula: any;
    idOffer: string;
    formulasList: FormulasListState[];
    onUpdate: ( value: FormulasListState[] ) => void;
}) => {
    const { id, name } = formula;
    const [addedToOffer, setAddedToOffer] = useState(formulasList.some((eachFormula) => eachFormula.formulaId === id));

    const [addFormulaToProductMutation, { loading: addLoading }] = useMutation(ADD_FORMULA_PRODUCT_TO_OFFER_MUTATION);
    const [deleteById, { loading: removeLoading }] = useMutation(DELETE_BY_ID_MUTATION);


    const onAddClick = async () => {
        if (addedToOffer) {
            return;
        }
        const data: any = await addFormulaToProductMutation({
            variables: {
                idOffer,
                idFormula: id,
            },
        });

        if (!!data?.data?.addFormulaToOffer?.id) {
            onUpdate([...formulasList, {formulaId: id, formulaOfferId: data.data.addFormulaToOffer.id}])
            setAddedToOffer(true)
        }
    };

    const onRemoveClick = async () => {
        if (!addedToOffer) {
            return;
        }
        const formulaOfferToRemove = formulasList.find((eachFormula) => eachFormula.formulaId === id);
        if (formulaOfferToRemove?.formulaOfferId) {
            const res = await deleteById({
                variables: {
                    id: formulaOfferToRemove.formulaOfferId,
                },
            });

            if(res?.data?.deleteById){
                const temp = formulasList.filter(eachFormula => eachFormula.formulaId !== id);
                onUpdate(temp);
                setAddedToOffer(false);
            }
        }
    };

    return (
        <>
            <td
                style={{
                    width: '100%',
                    maxWidth: '335px',
                    overflow: 'hidden',
                    overflowWrap: 'break-word',
                }}
            >
                {name}
            </td>
            <StyledTd>
                <StyledAddOrDeleteOfferButton>
                  {addLoading || removeLoading ? <Loader />  : addedToOffer ? (
                      <StyledTooltip>
                        <DeleteButton onClick={onRemoveClick} />
                      </StyledTooltip>
                    ) : (
                      <StyledTooltip>
                          <AddButton onClick={onAddClick} />
                      </StyledTooltip>
                    )}
                </StyledAddOrDeleteOfferButton>
            </StyledTd>
        </>
    );
};

const StyledAddOrDeleteOfferButton = styled.div`
    display: flex;
    align-items: center;
    padding-top: 12px;
`;

const StyledTd = styled.td`
    display: flex;
    justify-content: flex-end;
`;

const StyledInfo = styled(Info)`
    margin-right: ${({ theme }) => theme.spacing.s}px;
`;

const StyledTooltip = styled((props) => <Tooltip {...props} />)`
    top: ${({ theme }) => theme.spacing.xs}px;
    right: calc(100% - ${({ theme }) => theme.spacing.xs - 10}px);
    min-width: 180px;
    padding: 5px 5px 5px 10px;
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
`;
export default withRouter(LocalCatalogTablePanel);
