import { Column, Text, Button, QueryLoader, Icon } from 'components';
import { ButtonType, IconButton } from 'components/Button';
import { QueryResult } from 'localTypes';
import { loader } from 'graphql.macro';
import React, { useMemo, useState, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from 'styled-react-modal';
import { appTheme } from 'theme';
import { MarketingTypeNew, QuerySearchInput } from 'types/globalTypes';
import { BadgeText, FlagSelector, ImageContainer, ImageWrapper, Preview, StyledBadge } from './commonStyles';
import { ComponentHeader } from './components/ComponentHeader';
import { ParagraphComponent } from './components/ParagraphComponent';
import { ImageSlider } from './components/ImageSlider';
import { ImageSliderPreview } from './components/ImageSliderPreview';
import { fixedTranslation } from 'services/translationService';
import InfoNews from './components/infoNews';
interface IStepProps {
    values?: any;
    setFieldValue?: any;
    isModeEdit: boolean;
    setFieldTouched?: any;
    errors?: any;
}

const PreviewImage = loader('../query/getImageForPreview.gql');

//Enum, describing which kind of ComponentType should be displayed for the user to select in the modal
export enum ComponentType {
    INFO_NEWS = 'infoNews',
    PARAGRAPH = 'paragraph',
    IMAGE = 'image',
}

//maps entries from ComponentType to components to be rendered
const MAPPED_COMPONENTS = (
    key: number,
    name: string,
    lang: string,
    values: any,
    onChange: (value: string, language: string) => void = () => {},
) => ({
    paragraph: <ParagraphComponent id={`communication-paragraph-input-${lang}`} rank={key} name={name} lang={lang} />,
    image: <ImageSlider rank={key} name={name} multipleLanguages values={values} />,
    infoNews: <InfoNews values={values} rank={key} onChange={onChange} />,
});

const StepThree = ({ values, setFieldValue, isModeEdit, setFieldTouched }: IStepProps) => {
    const { t } = useTranslation();

    const contentObj = values[`content${values.languages[0].toUpperCase()}`];
    //State related to the modal
    const [isOpen, setIsOpen] = useState(false);
    const [opacity, setOpacity] = useState(0);

    //State related to selectedComponent and overall rendered components
    const [components, setComponents] = useState<string[]>(contentObj.map((obj) => obj.key));
    const [selectedComponent, setSelectedComponent] = useState<string>('');
    //State related to languages icons
    const [language, setLanguage] = useState(values.languages.includes('fr') ? 'fr' : values.languages[0]);
    const selector = (flag: string) => {
        setLanguage(flag);
    };

    //Some components share the same content trough all the languages. Here store the type of each component that must use this behaviour
    const renderOneTime: string[] = useMemo( () => [ComponentType.IMAGE, ComponentType.INFO_NEWS], []);

    const swapComponents = useCallback((firstElement, secondElement) => {
        //swap form values from each language
        values.languages.forEach((lang: string) => {
            let contentObject = values[`content${lang.toUpperCase()}`];

            let tempContent = contentObject[firstElement];
            contentObject[firstElement] = contentObject[secondElement];
            contentObject[secondElement] = tempContent;
        });

        //swap components
        let newComponents = [...components];
        let temp: string = newComponents[firstElement];
        newComponents[firstElement] = newComponents[secondElement];
        newComponents[secondElement] = temp;
        setComponents(newComponents);
    }, [components, values]);

    //Iterating through all components, and duplicating them, if previously we chose multiple languages
    const memoizedComponents = useMemo(() => {
        return components.map((componentDescription, index) => {
            return (
                <>
                    <ComponentHeader
                        rank={index}
                        totalComponents={components.length}
                        componentDescription={componentDescription}
                        onDelete={(rank) => {
                            let newComponents = [...components];
                            values.languages.forEach((lang: string) => {
                                let contentObject = values[`content${lang.toUpperCase()}`];
                                contentObject.splice(rank, 1);
                                setFieldValue(`content${lang.toUpperCase()}`, [...contentObject]);
                            });
                            newComponents.splice(rank, 1);
                            setComponents(newComponents);
                        }}
                        onUpComponent={(rank: number) => {
                            swapComponents(rank - 1, rank);
                        }}
                        onDownComponent={(rank) => {
                            swapComponents(rank + 1, rank);
                        }}
                    />

                    {renderOneTime.includes(componentDescription) ? (
                        <div key={`${componentDescription}${index}`}>
                            {
                                MAPPED_COMPONENTS(
                                    index,
                                    `content${language.toUpperCase()}[${index}].content`,
                                    language.toUpperCase(),
                                    values,
                                    (value, lang) => {
                                        setFieldValue(`content${lang.toUpperCase()}[${index}].content`, value);
                                        setFieldTouched(`content${lang.toUpperCase()}[${index}].content`, true);
                                    },
                                )[componentDescription]
                            }
                        </div>
                    ) : (
                        values.languages.map((lang: string) => {
                            return (
                                <div key={`${componentDescription}${index}${lang}`}>
                                    {
                                        MAPPED_COMPONENTS(
                                            index,
                                            `content${lang.toUpperCase()}[${index}].content`,
                                            lang.toUpperCase(),
                                            values,
                                        )[componentDescription]
                                    }
                                </div>
                            );
                        })
                    )}
                </>
            );
        });
    }, [components, language, renderOneTime, setFieldTouched, setFieldValue, swapComponents, values]);

    //Functions related to events of Modal
    function toggleModal() {
        setOpacity(0);
        setIsOpen(!isOpen);
    }

    function afterOpen() {
        setTimeout(() => {
            setOpacity(1);
        }, 100);
    }

    function beforeClose() {
        return new Promise((resolve) => {
            setOpacity(0);
            setSelectedComponent('');
            setTimeout(resolve, 300);
        });
    }

    return (
        <Wrapper>
            <MainSubWrapper wrapped={true}>
                <Text bold size={'M'} color={appTheme.color.common.navy}>
                    {t('page:communication.addMarketingCard.contentOfArticle')}
                </Text>

                <div style={{ marginTop: '30px', width: 'inherit' }}>{memoizedComponents}</div>

                <Button display={ButtonType.SECONDARY} fullWidth onClick={toggleModal}>
                    {t('page:communication.addMarketingCard.addContent')}
                </Button>

                <StyledModal
                    isOpen={isOpen}
                    afterOpen={afterOpen}
                    beforeClose={beforeClose}
                    onBackgroundClick={toggleModal}
                    onEscapeKeydown={toggleModal}
                    backgroundProps={{ opacity }}
                >
                    <LabelWrapper>
                        <Trans
                            i18nKey="page:communication.addMarketingCard.modalTitle"
                            components={[<strong></strong>]}
                        />
                    </LabelWrapper>

                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '16px' }}>
                        {Object.values(ComponentType).map((componentDescription) => {
                            return (
                                <StyledOption
                                    isSelected={selectedComponent === componentDescription}
                                    key={componentDescription}
                                    onClick={() => {
                                        setSelectedComponent(componentDescription);
                                    }}
                                >
                                    {selectedComponent === componentDescription ? (
                                        <Icon.Check color={appTheme.color.common.blue} />
                                    ) : null}
                                    {'   ' + t(`page:communication.addMarketingCard.component.${componentDescription}`)}
                                </StyledOption>
                            );
                        })}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            display={selectedComponent === '' ? ButtonType.DISABLED : ButtonType.ACTION}
                            disabled={selectedComponent === ''}
                            onClick={() => {
                                const newComponents = [...components, selectedComponent];

                                for (const lang of values.languages) {
                                    values[`content${lang.toUpperCase()}`].push({
                                        key: selectedComponent,
                                        content: '',
                                    });
                                }

                                setComponents(newComponents);
                                toggleModal();
                            }}
                        >
                            {t('page:communication.addMarketingCard.addComponent')}
                        </Button>
                    </div>
                </StyledModal>
            </MainSubWrapper>

            <VerticalLine />

            <SecondSubWrapper>
                <Text bold size={'M'} color={appTheme.color.common.navy}>
                    {t('page:communication.addMarketingCard.prevArticle')}
                </Text>
                <FlagSelector>
                    {values.languages.includes('fr') && (
                        <IconButton onClick={() => selector('fr')}>
                            {language === 'fr' ? <Icon.FlagFr /> : <Icon.FlagFrDisabled />}
                        </IconButton>
                    )}
                    {values.languages.includes('en') && (
                        <>
                            &nbsp; &nbsp;
                            <IconButton onClick={() => selector('en')}>
                                {language === 'en' ? <Icon.FlagUK /> : <Icon.FlagUKDisabled />}
                            </IconButton>
                        </>
                    )}
                </FlagSelector>

                <Preview>
                    {values && values.type === MarketingTypeNew.ARTICLE && (
                        <StyledBadge>
                            <Icon.Hot />
                            <BadgeText>{t('page:communication.communicationList.article')}</BadgeText>
                        </StyledBadge>
                    )}
                    <ImageContainer>
                        <QueryLoader
                            variables={{
                                querySearch: {
                                    key: 'id',
                                    value: values.image[0].id,
                                    operator: '*',
                                } as QuerySearchInput,
                            }}
                            query={PreviewImage}
                        >
                            {({ data: { image } }: QueryResult<any>) =>
                                image.edges.map((element) => (
                                    <ImageWrapper
                                        key={element.node.path}
                                        src={`${window.config.IMAGE_BASE_URL}/resize/414x303/${element.node.path}`}
                                    />
                                ))
                            }
                        </QueryLoader>
                    </ImageContainer>
                    <Text
                        bold
                        size={'XL'}
                        color={appTheme.color.common.black}
                        wordBreak={'break-word'}
                        lineHeight={'L'}
                        paddingTop={15}
                    >
                        {language === 'fr' ? values.frArticleTitle : values.enArticleTitle}
                    </Text>
                    <Text size={'M'} color={appTheme.color.common.black} overflow={'hidden'} paddingTop={5}>
                        {fixedTranslation(language, `page:communication.addMarketingCard.publishedDate`)}
                        {values.publicationStartDate.toLocaleDateString()}
                    </Text>

                    {values[`content${language.toUpperCase()}`].map((obj, index) => {
                        if (obj.key === 'image' && typeof obj.content === 'string') {
                            const imagesIds =
                                obj.content.length !== 0
                                    ? JSON.parse(obj.content).map((imageObject) => imageObject.id)
                                    : [];
                            return imagesIds.length > 0 ? (
                                <ImageSliderPreview
                                    key={`imageSliderPreview${index}`}
                                    idImageList={imagesIds}
                                    index={index}
                                ></ImageSliderPreview>
                            ) : null;
                        }
                        return <div dangerouslySetInnerHTML={{ __html: obj.content }} />;
                    })}
                </Preview>
            </SecondSubWrapper>
        </Wrapper>
    );
};

interface IStyledOption {
    isSelected: boolean;
}

const Wrapper = styled.div`
    align-items: flex-start;
    padding: ${({ theme }) => theme.spacing.s}px;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    background-color: ${({ theme }) => theme.color.common.white};
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 940px;
`;

const LabelWrapper = styled.div`
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-weight: normal;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    letter-spacing: 0;
    line-height: ${({ theme }) => theme.typography.size.S}px;
    margin-bottom: ${({ theme }) => theme.typography.size.S}px;
`;

const MainSubWrapper = styled(Column)`
    margin: 15px;
    align-items: flex-start;
    width: 500px;
`;
const SecondSubWrapper = styled(Column)`
    margin: 15px;
    align-items: flex-start;
    overflow: hidden;
    word-wrap: break-word;
`;

const VerticalLine = styled.div`
    border-left: 2px solid ${() => appTheme.color.grey[0]};
    min-height: 600px;
    height: 100%;
`;

const StyledOption = styled.button<IStyledOption>`
    background-color: ${({ theme, isSelected }) => (isSelected ? theme.color.common.lightBlue : theme.color.grey[0])};
    color: ${({ theme, isSelected }) => (isSelected ? theme.color.common.blue : theme.color.grey[6])};
    min-height: 36px;
    width: auto;
    padding: 10px;
    margin-right: 10px;
    justify-content: space-evenly;
    border-radius: 4px;
`;

const StyledModal = Modal.styled`
    width: 513px;
    height: 165px;
    padding: 15px 20px 15px 20px;
    background-color: white;
    transition : all 0.3s ease-in-out;
    box-shadow: ${({ theme }) => theme.boxShadow[1]};
    opacity: ${(props) => props.opacity};
    border-radius: 4px; 
  `;

export default StepThree;
