import { InfoType } from 'components';
import { getDates } from 'services/dateService';
import { appTheme } from 'theme';
import { OfferTemplateRouteParam, OfferTemplateType } from 'localTypes';
import { getTableServiceOfferTemplates_list_edges_node_TableServiceOfferTemplate_offers as OfferTemplateOffer } from 'types/getTableServiceOfferTemplates';
import {listOfferTemplate_list_edges_node_OfferTemplate_plannedOffers_v2_edges_node} from "../types/listOfferTemplate";

export enum OfferState {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    READY = 'READY',
    PENDING = 'PENDING',
    EXPIRED = 'EXPIRED',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum ColorKey {
    TEXT = 'TEXT',
    HIGHLIGHTED = 'HIGHLIGHTED',
    BACKGROUND = 'BACKGROUND',
}

export const offerPalette: Record<OfferState, { text: any; highlighted: any; display: InfoType }> = {
    [OfferState.PENDING]: {
        text: appTheme.color.grey[6],
        highlighted: appTheme.color.common.orange,
        display: InfoType.WARNING,
    },
    [OfferState.READY]: {
        text: appTheme.color.common.blue,
        highlighted: appTheme.color.common.blue,
        display: InfoType.DEFAULT,
    },
    [OfferState.OPEN]: {
        text: appTheme.color.common.green,
        highlighted: appTheme.color.common.green,
        display: InfoType.OK,
    },
    [OfferState.CLOSED]: {
        text: appTheme.color.grey[6],
        highlighted: appTheme.color.common.darkRed,
        display: InfoType.KO,
    },
    [OfferState.EXPIRED]: {
        text: appTheme.color.grey[6],
        highlighted: appTheme.color.grey[5],
        display: InfoType.DISABLE,
    },
    [OfferState.ACTIVE]: {
        text: appTheme.color.common.green,
        highlighted: appTheme.color.common.green,
        display: InfoType.OK,
    },
    [OfferState.INACTIVE]: {
        text: appTheme.color.grey[6],
        highlighted: appTheme.color.common.darkRed,
        display: InfoType.KO,
    },
};

export function getOfferState({
    orderRange,
    published,
    isDaysInAdvance = false,
    isRoomService = false,
}: {
  orderRange: string;
    published: null | boolean;
    isDaysInAdvance?: boolean;
    isRoomService?: boolean;
}): OfferState {
    const dates = getDates(orderRange);
    const orderStartDate = new Date(dates[0]);
    const orderEndDate = new Date(dates[1]);

    const currentDate = new Date();
    const inOrderRange = orderStartDate < currentDate;

    if (orderEndDate < currentDate) return OfferState.EXPIRED;
    if (published) {
        return inOrderRange ? OfferState.OPEN : OfferState.READY;
    } else {
      // For offers in advance we don't show the "pending" state (orange ball indicator)
        return isRoomService || isDaysInAdvance || inOrderRange ? OfferState.CLOSED : OfferState.PENDING;
    }
}

export function getBookingOfferState({
    withdrawRange,
    published,
}: {
    withdrawRange: string;
    published: null | boolean;
}): OfferState {
    const dates = getDates(withdrawRange);
    const withdrawEndDate = new Date(dates[1]);
    const currentDate = new Date();
    if (withdrawEndDate < currentDate) return OfferState.EXPIRED;
    if (published) {
        return OfferState.ACTIVE;
    } else {
        return OfferState.INACTIVE;
    }
}

export function filterOffersByDate(offers: OfferTemplateOffer[], date: Date): OfferTemplateOffer[] {
    return offers.filter(({ orderRange }) => {
        return orderRange
            .split('/')
            .map((dateI: string) => dateI.split('T')[0])
            .includes(date.toISOString().split('T')[0]);
    });
}

type Props = {
    plannedOffers: listOfferTemplate_list_edges_node_OfferTemplate_plannedOffers_v2_edges_node[];
    fromDate?: Date;
    isDaysInAdvance?: boolean;
    isRoomService?: boolean;
}

// renamed from listOfferTemplate_list_edges_node_OfferTemplate_plannedOffers
export type PlannedAndDisabledOffer = Partial<listOfferTemplate_list_edges_node_OfferTemplate_plannedOffers_v2_edges_node> & {
    disabled: boolean;
    isDaysInAdvance: boolean;
    isRoomService: boolean;
};

export const getPlannedAndUnavailableOffers = ({ plannedOffers, fromDate, isDaysInAdvance = false, isRoomService = false }: Props) => {
    const futureOffers: PlannedAndDisabledOffer[] = [];

    for (let i = 0; i <= 4; i++) {
        const currentDate = fromDate ? new Date(fromDate) : new Date();
        currentDate.setDate(currentDate.getDate() + i);
        const foundDay = plannedOffers?.find(({ withdrawRange }) => new Date(getDates(withdrawRange)[0]).getDate() === currentDate.getDate());
        if (foundDay) {
            futureOffers.push({ ...foundDay, disabled: false, isDaysInAdvance: isDaysInAdvance, isRoomService: isRoomService })
        } else {
            futureOffers.push({
                withdrawRange: `${currentDate.toISOString()}/`,
                orderRange: `${currentDate.toISOString()}/`,
                disabled: true,
                published: false,
                isDaysInAdvance: isDaysInAdvance, 
                isRoomService: isRoomService
            });
        }
    }

    // we only want to display the 1st 5 offers
    return [...futureOffers].slice(0, 5) as PlannedAndDisabledOffer[];
};

export const OfferTemplateMapper: { [key in OfferTemplateType]: OfferTemplateRouteParam } = {
    [OfferTemplateType.ClickCollect]: OfferTemplateRouteParam.ClickCollect,
    [OfferTemplateType.TableService]: OfferTemplateRouteParam.TableService,
    [OfferTemplateType.BookingService]: OfferTemplateRouteParam.BookingService,
};
