import { loader } from 'graphql.macro';
import React, { useMemo, useCallback } from 'react';
import { Option } from 'react-select';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { createMarketingCard, createMarketingCardVariables } from 'types/createMarketingCard';
import { updateMarketingCard, updateMarketingCardVariables } from 'types/updateMarketingCard';
import AddOrEditMarketingCard from './AddOrEditForm/AddOrEditMarketingCardForm';
import { formData } from './AddOrEditForm/AddOrEditMarketingCardForm';

import { YEAR_INFINITY } from '../../utils';
import routes from 'Router/routes';
import { Loader } from 'components';

const getMarketingCard = loader('./query/getMarketingCard.gql');

/**
 * Verifies and changes label accordingly if it's an update or create draft
 */
const draftLabelDecision = (initialValues: formData, formik: any, t: any) => {
    return initialValues.idDraft && initialValues.isDraft && !formik.dirty
        ? t('page:communication.addMarketingCard.draftWasSaved')
        : t('page:communication.addMarketingCard.saveDraft');
};

const AddMarketingCard = ({
    history,
    match: {
        params: { idCommunication },
    },
}) => {
    const onClose = useCallback((values: formData) => {
        history.push({
            pathname: `${routes.communication.added()}`,
            state: { values },
        });
    }, [history]);

    const { loading, error, data } = useQuery(getMarketingCard, {
        variables: {
            id: idCommunication,
        },
        fetchPolicy: 'network-only',
        skip: !idCommunication,
    });

    if (error) {
        console.error(error);
    }

    const [updateMarketingCardMutation] = useMutation<updateMarketingCard, updateMarketingCardVariables>(
        loader('./query/updateMarketingCard.gql')
    );
    const [createMarketingCardMutation] = useMutation<createMarketingCard, createMarketingCardVariables>(
        loader('./query/createMarketingCard.gql')
    );

    const isModeEdit = useMemo(() => !loading && data && !!data.getOneMarketingCardNew, [data, loading]);

    const onSubmitDraft = useCallback(
        async ({ values, resetForm, setSubmitting }) => {
            setSubmitting(true);

            const {
                title,
                type,
                holdings,
                languages,
                idDraft,
                isDraft,
                publicationStartDate,
                publicationEndDate,
                enArticleTitle,
                frArticleTitle,
                enArticleResume,
                frArticleResume,
                image,
                contentEN,
                contentFR,
                linkToExternalPage
            } = values;
            const holdingIds = holdings ? holdings.map((item: Option) => item.value) : [];
            let createdDraft = idDraft;
            if (createdDraft) {
                try {
                    await updateMarketingCardMutation({
                        variables: {
                            id: createdDraft,
                            title,
                            type,
                            holdings: holdingIds,
                            frArticleTitle,
                            enArticleTitle,
                            enArticleResume,
                            frArticleResume,
                            languages,
                            publicationStartDate: publicationStartDate && publicationStartDate.toISOString(),
                            publicationEndDate:
                                publicationStartDate && publicationEndDate
                                    ? publicationEndDate.toISOString()
                                    : new Date(`${YEAR_INFINITY}`).toISOString(),
                            idImage: !image[0] || image[0].id === '' ? null : image[0].id,
                            contentEN,
                            contentFR,
                            isDraft: true,
                            linkToExternalPage
                        },
                    });
                } catch (error) {
                    console.log(error);
                }
            } else {
                try {
                    const { data } = await createMarketingCardMutation({
                        variables: {
                            title,
                            type,
                            holdings: holdingIds,
                            frArticleTitle,
                            enArticleTitle,
                            enArticleResume,
                            frArticleResume,
                            languages,
                            publicationStartDate: publicationStartDate ? publicationStartDate.toISOString() : null,
                            publicationEndDate: publicationStartDate
                                ? publicationEndDate
                                    ? publicationEndDate.toISOString()
                                    : new Date(`${YEAR_INFINITY}`).toISOString()
                                : null,
                            idImage: image[0].id === '' ? null : image[0].id,
                            contentEN,
                            contentFR,
                            isDraft: true,
                            linkToExternalPage
                        },
                    });
                    createdDraft = data!.createMarketingCard.id;
                    values.idDraft = createdDraft;
                } catch (error) {
                    console.log(error);
                }
            }

            setSubmitting(false);

            // Reset initialValues to submitted values
            resetForm({
                title,
                type,
                holdings,
                languages,
                idDraft: values.idDraft,
                isDraft,
                frArticleTitle,
                enArticleTitle,
                enArticleResume,
                frArticleResume,
                publicationStartDate,
                publicationEndDate,
                image,
                mustHavePublicationEndDate: values.mustHavePublicationEndDate,
                contentEN,
                contentFR,
                linkToExternalPage
            });
        },
        [createMarketingCardMutation, updateMarketingCardMutation]
    );

    const onSubmit = useCallback(
        async (values: formData) => {
            const {
                title,
                type,
                holdings,
                languages,
                idDraft,
                publicationStartDate,
                publicationEndDate,
                enArticleTitle,
                frArticleTitle,
                enArticleResume,
                frArticleResume,
                image,
                contentEN,
                contentFR,
                linkToExternalPage
            } = values;
            const holdingIds = holdings ? holdings.map((item: Option) => item.value) : [];
            let createdDraft = idDraft;
            if (createdDraft) {
                try {
                    await updateMarketingCardMutation({
                        variables: {
                            id: createdDraft,
                            title,
                            type,
                            holdings: holdingIds,
                            frArticleTitle,
                            enArticleTitle,
                            enArticleResume,
                            frArticleResume,
                            languages,
                            publicationStartDate: publicationStartDate && publicationStartDate.toISOString(),
                            publicationEndDate:
                                publicationStartDate && publicationEndDate
                                    ? publicationEndDate.toISOString()
                                    : new Date(`${YEAR_INFINITY}`).toISOString(),
                            idImage: image[0].id === '' ? null : image[0].id,
                            contentEN,
                            contentFR,
                            isDraft: false,
                            linkToExternalPage
                        },
                    });
                } catch (error) {
                    console.log(error);
                }
            } else {
                try {
                    await createMarketingCardMutation({
                        variables: {
                            title,
                            type,
                            holdings: holdingIds,
                            frArticleTitle,
                            enArticleTitle,
                            enArticleResume,
                            frArticleResume,
                            languages,
                            publicationStartDate: publicationStartDate ? publicationStartDate.toISOString() : null,
                            publicationEndDate: publicationStartDate
                                ? publicationEndDate
                                    ? publicationEndDate.toISOString()
                                    : new Date(`${YEAR_INFINITY}`).toISOString()
                                : null,
                            idImage: image[0].id === '' ? null : image[0].id,
                            contentEN,
                            contentFR,
                            isDraft: false,
                            linkToExternalPage
                        },
                    });
                } catch (error) {
                    console.log(error);
                }
            }
            onClose(values);
        },
        [createMarketingCardMutation, onClose, updateMarketingCardMutation]
    );

    if (loading) {
        return <Loader />;
    }

    return (
        <AddOrEditMarketingCard
            isModeEdit={isModeEdit}
            savedData={isModeEdit && data.getOneMarketingCardNew}
            draftLabel={draftLabelDecision}
            onSubmitDraft={onSubmitDraft}
            onSubmit={onSubmit}
        />
    );
};

export default withRouter(AddMarketingCard);
