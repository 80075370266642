import isEqual from 'lodash/isEqual';
import {
    FileInputField,
    Icon,
    SelectField,
    TextInputField,
    Title,
    ToggleSwitchField,
    SubmitButton,
    NumberInputField,
    InfoLabel,
    Tags,
    InputLabel
} from 'components';
import { FieldContainer } from 'components/Inputs/FormikFields';
import { PanelContent, PanelFooter } from 'components/Panel';
import { Field, Form, Formik } from 'formik';
import { loader } from 'graphql.macro';
import ScheduleEdit from 'pages/Holding/Pos/ScheduleEdit';
import { areSchedulesEqual } from 'services/validationService';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import {
    holdingPos_zones_edges_node_Zone,
    holdingPos_concepts_edges_node_Concept,
    holdingPos_posTypes_edges_node_PosTypeModel,
    holdingPos_zones_edges_node_Zone_pointOfSales,
    holdingPos_pos_Pos,
    holdingPos_pos_Pos_image,
    holdingPos_zones_edges,
    holdingPos_holding_Holding,
} from 'types/holdingPos';
import { updatePosVariables, updatePos_updatePos } from 'types/updatePos';
import * as Yup from 'yup';
import { AtSiteTakeAwayType, ImportationType, OpeningHourInput } from 'types/globalTypes';
import { GraphQLError } from 'graphql';
import { formatServerErrors } from 'services/form/formService';
import ExternalServicesEdit from '../ExternalServicesEdit';
import PlcEdit from "./PlcEdit";
import MenuCategoriesEdit from "../MenuCategoriesEdit";
import { fixedTranslation } from "../../../services/translationService";
import LanguageTextInput from "../../../components/LanguageTextInput";
import { appTheme } from "../../../theme";
import styled from "styled-components";

const FORM_ID = 'pos_edit_form';

const UPDATE_POS_MUTATION = loader('../query/updatePos.gql');
const GET_HOLDING_POS_QUERY = loader('../query/holdingPos.gql');

interface IGetSchema {
  t: Function,
  importationType: string,
  takenPositions: number[],
  hasAffluence: boolean
}

const langs = [
    { id: 'fr', label: 'Français', alwaysOn: true },
    { id: 'en', label: 'Anglais' },
]

const getSchema = ({t, importationType, takenPositions, hasAffluence} : IGetSchema) =>
    Yup.object().shape({
        name: Yup.string().required(t('app:error.required')),
        code: importationType === ImportationType.Oscar ? Yup.string().required(t('app:error.required')) : Yup.string(),
        plcs: Yup.array(),
        schedules: Yup.array().required(t('app:error.required')),
        positionNumber: Yup.number().nullable().notOneOf(takenPositions, t('app:error.notOneOf')),
        affluence: Yup.object().when([], (schema) => {
            if (hasAffluence) {
                return schema.shape({
                    enabled: Yup.boolean(),
                    maxCapacity: Yup.string().when('enabled', {
                        is: true,
                        then: Yup.string().nullable().required(t('app:error.required')),
                        otherwise: Yup.string(),
                    }),
                    avgConsumptionTime: Yup.string()
                        .nullable()
                        .when('enabled', {
                            is: true,
                            then: Yup.string().nullable().required(t('app:error.required')),
                            otherwise: Yup.string(),
                        }),
                });
            }
            return schema.shape({
                enabled: Yup.boolean().nullable(),
                maxCapacity: Yup.string().nullable(),
                avgConsumptionTime: Yup.string().nullable(),
            });
        }),
    });

interface IEditPos {
    onSuccess?: Function;
    pos: holdingPos_pos_Pos;
    holding: holdingPos_holding_Holding;
    zones: holdingPos_zones_edges_node_Zone[];
    concepts: holdingPos_concepts_edges_node_Concept[];
    posTypes: holdingPos_posTypes_edges_node_PosTypeModel[];
    takenPositions: number[];
    showPositionNumber: boolean;
}

type updatePosVariablesWithImageTranslations = updatePosVariables & { image: holdingPos_pos_Pos_image | null,
    languages: string[],
    translations: {
        fr: {
            emptyState?: string
        },
        en: {
            emptyState?: string
        }
    }
};


const PlcsInput = ({ field, form, ...rest }: { field: any; form: any }) => (
    <FieldContainer {...{ field, form, ...rest }}>
        <PlcEdit
            plcs={field.value.map(({ id, label, services, displayedServices }) => {
                return ({ id, label, services, displayedServices });
            })}
            onChange={(value) => {
                form.setFieldValue(field.name, value);
                form.setFieldTouched(field.name, true);
            }}
        />
    </FieldContainer>
);

const EditPos = ({ pos, zones, concepts, posTypes, onSuccess, holding, takenPositions, showPositionNumber }: IEditPos) => {
    const initialValues: updatePosVariablesWithImageTranslations = {
        id: pos.id,
        code: pos.code || '',
        plcs: pos.plcs,
        idZone: pos.zone ? pos.zone.id : '',
        idConcept: pos.concept ? pos.concept.id : '',
        name: pos.label,
        description: pos.description,
        open: pos.open,
        schedules: pos.schedules,
        idType: pos.typeModel ? pos.typeModel.id : '',
        image: pos.image ? pos.image : null,
        atSiteTakeAwayType: pos.atSiteTakeAwayType,
        externalServices: pos.externalServices,
        menuCategories: pos.menuCategories,
        hasCat: pos.hasCat,
        hideMenuBooking: pos.hideMenuBooking,
        // @ts-ignore
        affluence: pos.affluence,
        positionNumber: pos.positionNumber,
        languages: ['fr'].concat(pos.translations.find((t) => t.language === 'en') ? 'en' : []),
        translations: {
            fr: {
                emptyState: pos.translations.find((t) => t.language === 'fr')?.emptyState || ''
            },
            en: {
                emptyState: pos.translations.find((t) => t.language === 'en')?.emptyState || ''
            }
        },
    };
    const [t] = useTranslation();

    const [atSiteTakeAwayTypes] = React.useState(
      Object.values(AtSiteTakeAwayType).map((id) => ({
          id,
          label: t(`schema:AtSiteTakeAwayType.${id}`),
      }))
    );

    const handleChangeInLanguages = (form, selectedOptions: string[], pressedValue: string) => {
        if (pressedValue === 'en') {
            form.setFieldValue('translations.en.emptyState', '');
        }
        form.setFieldValue('languages', selectedOptions);
        form.setFieldTouched('languages', true);
    };

    return (
        <Mutation
            mutation={UPDATE_POS_MUTATION}
            update={(cache: any, { data }: any) => {
                const updatedPos = data.updatePos as updatePos_updatePos;
                const cachedData = cache.readQuery({
                    query: GET_HOLDING_POS_QUERY,
                    variables: { idHolding: holding.id, idPos: pos.id, getPos: true, getTakenPositions: false },
                });
                const zoneUpdates = cachedData.zones.edges.map((edge: holdingPos_zones_edges) => {
                    const node = edge.node as holdingPos_zones_edges_node_Zone;
                    const updatedPOSBelongsToZone = updatedPos.zone.id === node.id;
                    const posIsPresentInZoneList = node.pointOfSales.some((pos) => pos.id === updatedPos.id);
                    if (updatedPOSBelongsToZone === posIsPresentInZoneList) return edge;
                    let updatedPOSList = [] as holdingPos_zones_edges_node_Zone_pointOfSales[];
                    // pos should be removed from the list or added to it
                    if (posIsPresentInZoneList) {
                        updatedPOSList = node.pointOfSales.filter((pos) => pos.id !== updatedPos.id);
                    } else {
                        updatedPOSList = [updatedPos, ...node.pointOfSales];
                    }
                    return {
                        ...edge,
                        node: {
                            ...node,
                            pointOfSales: updatedPOSList,
                        },
                    };
                });
                cache.writeQuery({
                    query: GET_HOLDING_POS_QUERY,
                    variables: { idHolding: holding.id, idPos: pos.id, getPos: true, getTakenPositions: false },
                    data: {
                        ...cachedData,
                        zones: {
                            ...cachedData.zones,
                            edges: zoneUpdates,
                        },
                    },
                });
            }}
        >
            {(updatePos: (param: Record<'variables', updatePosVariables>) => Promise<any>) => (
              <>
              {
                pos.deleted && <DeletedPosInfo>
                                <Icon.WarningError />
                                <TextDeletedInfo>{t('page:holding.pos.edit.title.posDeleted')}</TextDeletedInfo>
                              </DeletedPosInfo>
              }
                <Formik
                    initialValues={{
                        ...initialValues,
                        id: String(pos.numericId),
                    }}
                    validationSchema={getSchema({
                                        t,
                                        importationType:holding.importationType,
                                        takenPositions,
                                        hasAffluence: holding.hasAffluence
                                      })}
                    onSubmit={(allValues, { setSubmitting, setErrors }) => {
                        const { image, affluence, translations, languages,  ...values } = allValues;
                        updatePos({
                            variables: {
                                ...values,
                                id: pos.id,
                                schedules: values.schedules.map(({ days, hours }) => ({ days, hours })),
                                idImage: image ? image.id : null,
                                externalServices: values.externalServices.map(({ id, title, content, active }) => ({
                                    id,
                                    title,
                                    content,
                                    active,
                                })),
                                menuCategories: values.menuCategories.map(({ id, idCategoryMenu }) => ({ id, idPos: pos.id, idCategoryMenu })),
                                plcs: values.plcs.map(({ id, displayedServices }) => ({
                                    id,
                                    displayedServices: displayedServices?.map(({ id } ) => ({ id}))
                                })),
                                affluence: {
                                  id: affluence?.id,
                                  maxCapacity: affluence?.maxCapacity || 0,
                                  avgConsumptionTime: affluence?.avgConsumptionTime || 0,
                                  enabled: affluence?.enabled || false,
                                },
                                ...(initialValues.translations.fr.emptyState?.trim() !== translations.fr.emptyState ? { emptyStateFr: translations.fr.emptyState?.trim() } : {}),
                                ...(initialValues.translations.en.emptyState?.trim() !== translations.en.emptyState ? { emptyStateEn: translations.en.emptyState?.trim() } : {}),
                            },
                        })
                            .then(() => {
                                if (onSuccess) onSuccess();
                            })
                            .catch(({ graphQLErrors }: { graphQLErrors: GraphQLError[] }) => {
                                setErrors(formatServerErrors(graphQLErrors, t));
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ errors, touched, values, initialValues }) => {
                        const { schedules: initialSchedules, ...restInitialValues } = initialValues;
                        const { schedules: currrentSchedules, ...restValues } = values;
                        // @todo: check why schedule changes in initalValues also
                        const formHasNoChange =
                            isEqual(restInitialValues, restValues) &&
                            areSchedulesEqual(initialSchedules, currrentSchedules);

                        return (
                            <>
                                <PanelContent>
                                    {
                                      // @ts-ignore
                                      <Form id={FORM_ID}>
                                          <LanguagesWrapper>
                                              <InfoLabel inline fontWeight="bold" name={t('page:communication.addMarketingCard.languages')} paddingRight={4} />
                                              <InfoLabel inline fontColor={appTheme.color.common.darkRed} name="*" />
                                              <Field
                                                  name="languages"
                                                  component={({ field, form, ...rest }: { field: any; form: any }) => (
                                                      <Tags
                                                          options={langs}
                                                          value={values.languages}
                                                          onChange={(selectedOptions: string[], pressedValue: string) => handleChangeInLanguages(form, selectedOptions, pressedValue)}
                                                          fontSize="S"
                                                      />
                                                  )}
                                              />
                                          </LanguagesWrapper>
                                        <Title
                                            mode="H3"
                                            value={t('page:holding.pos.edit.title.general')}
                                            icon={<Icon.Cog />}
                                        />
                                        <Field
                                            label={t('schema:pos.idPos')}
                                            name="id"
                                            component={TextInputField}
                                            disabled
                                            onChange={() => {}}
                                        />

                                        {holding.importationType === ImportationType.Oscar && (
                                            <Field
                                                label={t('schema:pos.code')}
                                                name="code"
                                                component={TextInputField}
                                            />
                                        )}
                                        {holding.importationType === ImportationType.Winapro && (
                                            <Field
                                                name="plcs"
                                                component={PlcsInput}
                                            />
                                        )}
                                        <Field
                                            label={t('schema:pos.zoneName')}
                                            name="name"
                                            component={TextInputField}
                                        />
                                        {holding.importationType === ImportationType.Oscar && <Field
                                            label={t('schema:pos.menuCategory')}
                                            name="menuCategories[0].idCategoryMenu"
                                            component={MenuCategoriesEdit}
                                        />}
                                        <Field label={t('schema:pos.open')} name="open" component={ToggleSwitchField} />
                                        <Field
                                            label={t('schema:pos.zone')}
                                            name="idZone"
                                            data={zones}
                                            component={SelectField}
                                        />
                                        <Field
                                            label={t('schema:pos.concept')}
                                            name="idConcept"
                                            data={concepts}
                                            component={SelectField}
                                        />
                                        <Field
                                            label={t('schema:pos.type')}
                                            name="idType"
                                            data={posTypes}
                                            component={SelectField}
                                        />
                                        <Field
                                            label={t('schema:pos.posType')}
                                            name="atSiteTakeAwayType"
                                            data={atSiteTakeAwayTypes}
                                            component={SelectField}
                                        />
                                        <InputLabel
                                            name={t('schema:pos.emptyState')}
                                            htmlFor={'translations.fr.emptyState'}
                                            labelContainerStyle={{
                                                display: 'inline-block'
                                            }}
                                        />
                                        <LanguageTextInput
                                            key={'translations.fr.emptyState'}
                                            locale={'fr'}
                                            name={'translations.fr.emptyState'}
                                            maxLength={25}
                                            multiline={false}
                                            style={{
                                                height: 40,
                                                fontSize: `${appTheme.typography.fontSizeS}px`,
                                                color: appTheme.color.common.nav,
                                                fontWeight: appTheme.typography.fontWeight.bold,
                                            }}
                                            placeholder={fixedTranslation(
                                                'fr',
                                                `schema:pos.emptyStateDefault`
                                            )}
                                            value={values.translations.fr.emptyState}
                                        />
                                          {values.languages.find((l) => l === 'en') &&
                                              <LanguageTextInput
                                                  key={'translations.en.emptyState'}
                                                  locale={'en'}
                                                  name={'translations.en.emptyState'}
                                                  maxLength={25}
                                                  multiline={false}
                                                  style={{
                                                      height: 40,
                                                      fontSize: `${appTheme.typography.fontSizeS}px`,
                                                      color: appTheme.color.common.nav,
                                                      fontWeight: appTheme.typography.fontWeight.bold
                                                  }}
                                                  placeholder={fixedTranslation(
                                                      'en',
                                                      'schema:pos.emptyStateDefault'
                                                  )}
                                                  value={values.translations.en.emptyState}
                                              />
                                          }
                                        <Field
                                                label={t('schema:pos.hideMenuBooking')}
                                                name="hideMenuBooking"
                                                component={ToggleSwitchField}
                                        />
                                        { // @ts-ignore
                                        holding.hasAffluence && <>
                                            <Title
                                                mode="H3"
                                                value={t('page:holding.pos.edit.title.affluence')}
                                                icon={<Icon.Cog />}
                                            />
                                            <Field
                                            label={t('schema:pos.enableAffluence')}
                                            name="affluence.enabled"
                                            component={ToggleSwitchField}
                                            />
                                            {values.affluence?.enabled && <>
                                                <Field
                                                label={t('schema:pos.maxCapacity')}
                                                name="affluence.maxCapacity"
                                                component={NumberInputField}
                                                minValue={0}
                                                overrideShowRequiredAsterisk={true}
                                                />
                                                <Field
                                                label={t('schema:pos.avgConsumptionTime')}
                                                name="affluence.avgConsumptionTime"
                                                component={NumberInputField}
                                                minValue={0}
                                                overrideShowRequiredAsterisk={true}
                                                />
                                            </>}
                                        </>}
                                        <Title
                                            mode="H3"
                                            value={t('page:holding.pos.edit.title.general')}
                                            icon={<Icon.Cog />}
                                        />
                                        <Field label={t('schema:pos.image')} name="image" component={FileInputField} />
                                        <Field
                                            label={t('schema:pos.description')}
                                            name="description"
                                            component={TextInputField}
                                            multiline
                                        />
                                        <Field
                                            label={t('schema:pos.schedule')}
                                            name="schedules"
                                            component={({ field, form, ...rest }: { field: any; form: any }) => (
                                                <FieldContainer {...{ field, form, ...rest }}>
                                                    <ScheduleEdit
                                                        schedules={field.value.map(
                                                            ({ days, hours }: OpeningHourInput) => ({
                                                                days,
                                                                hours,
                                                            })
                                                        )}
                                                        onChange={(value) => {
                                                            form.setFieldValue(field.name, value);
                                                            form.setFieldTouched(field.name, true);
                                                        }}
                                                    />
                                                </FieldContainer>
                                            )}
                                        />
                                        <Field name="externalServices" component={ExternalServicesEdit} />
                                        {holding.idCashSystem === 1 && (
                                            <Field
                                                label={t('schema:pos.hasCat')}
                                                name="hasCat"
                                                component={ToggleSwitchField}
                                            />
                                        )}
                                        {showPositionNumber && <Field
                                            label={t('schema:pos.positionNumber')}
                                            name="positionNumber"
                                            component={NumberInputField}
                                            minValue={1}
                                        />}
                                    </Form>
                                    }
                                </PanelContent>
                                <PanelFooter>
                                    <SubmitButton
                                        formHasNoChange={formHasNoChange}
                                        form={FORM_ID}
                                        disabled={
                                            formHasNoChange ||
                                            Object.entries(touched).length === 0 ||
                                            Object.entries(errors).length !== 0
                                        }
                                    />
                                </PanelFooter>
                            </>
                        );
                    }}
                </Formik>
                </>
            )}
        </Mutation>
    );
};

const LanguagesWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;
`;

const DeletedPosInfo = styled.div`
    justify-content: flex-start;
    align-items: center;
    display: flex;
    margin-left: 30px;
`

const TextDeletedInfo = styled.div`
   margin-left: 5px;
`
export default EditPos;
