import React, { FunctionComponent } from 'react';
import { withTheme } from 'styled-components';
import { MessageBuilder } from './messageBuilder';
import { MessageType, ErrorMessageProps } from './types';

// @ts-ignore
export const ErrorMessage: FunctionComponent<ErrorMessageProps> = withTheme((props) => 
    // @ts-ignore
    <MessageBuilder {...props} type={MessageType.ERROR} />
);

// @ts-ignore
export const ErrorMessageLighter: FunctionComponent<ErrorMessageProps> = withTheme((props) =>
    // @ts-ignore
    <MessageBuilder {...props} type={MessageType.LIGHTER} />
);

// @ts-ignore
export const WarningMessage: FunctionComponent<ErrorMessageProps> = withTheme((props) => 
    // @ts-ignore
    <MessageBuilder {...props} type={MessageType.WARNING} />
);
