import React from 'react';
import styled from 'styled-components';
import { Icon, CheckBox, Tooltip } from 'components';
import { OfferTemplateWithdrawalType } from 'types/globalTypes';
import { getDisplayDateAndTime } from 'services/dateService';

const handleWithdrawalType = (withdrawalType) => {
    switch (withdrawalType) {
        case OfferTemplateWithdrawalType.POS_AT_SITE:
            return <Icon.ClickandSeat />;
        case OfferTemplateWithdrawalType.POS_TAKE_AWAY:
            return <Icon.ClickandCollect />;
        case OfferTemplateWithdrawalType.POS_CLICK_SERVE:
            return <Icon.SeatClickandServe />;
        case OfferTemplateWithdrawalType.CONNECTED_LOCKERS:
            return <Icon.ConnectedLocker />;
        case OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT:
            return <Icon.InstantClickandCollect />;
        case OfferTemplateWithdrawalType.TABLE_SERVICE:
            return <Icon.TableServiceIcon />;
        case OfferTemplateWithdrawalType.CLICK_AND_PICK_UP:
            return <Icon.ClickAndPickUp />;
    }
};

const OrderRatingTableRow = (orderRating, index, onChangePinCheckbox) => {
    return (
    <tr key={`${orderRating.id}-${index}`}>
      <td id={`orderRating-rating-date_row-${index}`} style={{minWidth: '150px'}}>
        <TextWrapper>{getDisplayDateAndTime(orderRating.created)}</TextWrapper>
      </td>
      <td id={`orderRating-order-date_row-${index}`} style={{minWidth: '150px'}}>
         <TextWrapper>{getDisplayDateAndTime(orderRating.order.created)}</TextWrapper>
      </td>
      <td id={`orderRating-pos-name_row-${index}`} style={{minWidth: '150px'}}>
        <TextWrapper>{orderRating.order.offer.offerTemplate.pos.name}</TextWrapper>
      </td>
      <td id={`orderRating-order-id_row-${index}`} style={{minWidth: '70px'}}>
        <TextWrapper>#{orderRating.order.idHuman}</TextWrapper>
      </td>
      <td id={`orderRating-offer-type-icon_row-${index}`} style={{minWidth: '70px'}}>
        {handleWithdrawalType(orderRating.order.offer.offerTemplate.withdrawalType)}
      </td>
      <td id={`orderRating-order-content_row-${index}`} style={{minWidth: '70px'}}>
        <StyledTooltip toolTipContent={
            <>
                {orderRating.order.orderItems.map((item: { labelWhenAdded: any; quantity: any; }) => 
                 <TextWrapper> {item.quantity} x {item.labelWhenAdded} <br/> </TextWrapper>)
                }
            </>
        }>
            <Icon.Menu width={20} height={20} />
        </StyledTooltip>
      </td>
      <td id={`orderRating-offertemplate-name_row-${index}`} style={{minWidth: '150px'}}>
       <TextWrapper>{orderRating.order.offer.offerTemplate.name}</TextWrapper>
      </td>
      <td id={`orderRating-global-rating_row-${index}`} style={{minWidth: '70px'}}>
        {orderRating.ratings.globalRating > 0
                    ? <RatingContainer> <Icon.Rating width={15} height={15}/> {orderRating.ratings.globalRating}/5 </RatingContainer>
                    : <Icon.Cross color="#383838" />
        }
      </td>
      <td id={`orderRating-service-rating_row-${index}`} style={{minWidth: '70px'}}>
        {orderRating.ratings.serviceRating > 0
                    ? <RatingContainer> <Icon.Rating width={15} height={15}/> {orderRating.ratings.serviceRating}/5 </RatingContainer>
                    : <Icon.Cross color="#383838" />
        }
      </td>
      <td id={`orderRating-presentation-rating_row-${index}`} style={{minWidth: '70px'}}>
        {orderRating.ratings.presentationRating > 0
                ? <RatingContainer> <Icon.Rating width={15} height={15}/> {orderRating.ratings.presentationRating}/5 </RatingContainer>
                : <Icon.Cross color="#383838" />
        }
      </td>
      <td id={`orderRating-meal-rating_row-${index}`} style={{minWidth: '70px'}}>
        {orderRating.ratings.mealRating > 0
            ? <RatingContainer> <Icon.Rating width={15} height={15}/> {orderRating.ratings.mealRating}/5 </RatingContainer>
            : <Icon.Cross color="#383838" />
        }
      </td>
      <td id={`orderRating-comment_row-${index}`} style={{minWidth: '250px'}}>
        <TextWrapper>{orderRating.comment}</TextWrapper>
      </td>
      <td id={`orderRating-consent_row-${index}`} style={{minWidth: '100px'}}>
        {orderRating.consent
            ? <Icon.Check width={16} height={16} color="#383838"/>
            : <Icon.Cross color="#383838" />
        }
      </td>
      <td id={`orderRating-contact-info_row-${index}`} style={{minWidth: '150px'}}>
        <TextWrapper>
            <a href={`mailto:${orderRating.contactInfo}`}>{orderRating.contactInfo}</a>    
        </TextWrapper>
      </td>
      <td id={`orderRating-pin_row-${index}`} style={{minWidth: '70px'}}>
        <CheckBox id={`rating-pin_checkbox-${index}`} checked={!!orderRating.pin} onChange={(value) => onChangePinCheckbox(orderRating.id, value)}/>
      </td>
    </tr>
)};

const RatingContainer = styled.td`
  color: ${({ theme }) => theme.color.grey[6]};
  font-weight: lighter;
`;

const TextWrapper = styled.span`
    font-size: ${({ theme }) => theme.typography.size.S}px;
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    margin-top: ${({ theme }) => theme.spacing.s}px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    letter-spacing: 0;
    font-weight: lighter;
    overflow-wrap: anywhere;
`;

const StyledTooltip = styled((props) => <Tooltip styledTooltip={true} {...props} />)`
    top: ${({ theme, style }) => style?.top ? style.top : theme.spacing.s}px;
    padding: 5px 0px;
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    background-color: white;
    border: solid;
    box-shadow: none;
    width: 150px;
    left: 40%;
    padding-left: 10px;
    text-align: left;
    transform: ${({ style }) => style?.transform ? style.transform : 'none'};
`;


export default OrderRatingTableRow;
