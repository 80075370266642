
import React from 'react';
import { useTranslation } from 'react-i18next';
import appRoutes from 'Router/routes';
import { OfferTemplateRouteParam, OfferTemplateType } from 'localTypes';
import LocalCatalog from 'feature/LocalCatalog';
import DailyOffersPage from './DailyOffersPage';
import { Icon } from 'components';
import TradePage from 'feature/TradePage';
import TagEditorPage from 'feature/TagEditor';
import { withTheme, DefaultTheme } from 'styled-components';

const getRoutes = (t: any) => [
    {
        tabKey: 'daily-offers',
        tabLabel: t('page:tableService.daily-offers.tab'),
        path: appRoutes.tableservice.dailyOffer.list,
        component: DailyOffersPage,
    },
    {
        tabKey: 'catalog',
        tabLabel: t('page:clickcollect.catalog.tab'),
        path: appRoutes.tableservice.catalog.get,
        component: LocalCatalog,
        props: { offerType: OfferTemplateRouteParam.TableService },
    },
    {
        tabKey: 'tag-editor',
        tabLabel: t("page:tableService.article-tag-manager.tab"),
        path: appRoutes.tableservice.tagEditor.get,
        component: TagEditorPage,
        props: { offerType: OfferTemplateRouteParam.TableService },
    },
];

interface IProps {
    theme: DefaultTheme;
}

const TableService = ({ theme }: IProps) => {
    const { t } = useTranslation();
    return (
        <TradePage
            routes={getRoutes(t)}
            pageTitle={t('app:app.tableService')}
            icon={<Icon.TableService color={theme.color.grey[6]} />}
            offerTemplateType={OfferTemplateType.TableService}
        />
    );
};

export default withTheme(TableService);
