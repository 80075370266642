import React from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
    TextInputField,
    Title,
    Icon,
    ToggleSwitchField,
    Struct,
    Button,
} from 'components';
import isEqual from 'lodash/isEqual';
import { FullForm, HeaderWrapper, IconButton, StyledCard, TextButton, Wrapper, WrapperToggle } from '../ServicesPage.style';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import { ButtonType } from 'components/Button';
import ScheduleTable from './ScheduleTable';

const FORM_ID = 'services_edit_add_form';

const getSchema = (t: any) =>
    Yup.object().shape({
        name: Yup.string().required(t('app:error.required')),
        schedulerList: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required(t('app:error.required')),
                deadline: Yup.string().required(t('app:error.required'))
            })
        ).min(1),
    });

interface IAddOrEditCheckListUserFormProps extends RouteComponentProps {
    service?: any;
    updateEPointageService: Function;
    onSuccess?: Function;
    isCreation?: boolean;
    siteId?: string;
}

const AddOrEditServiceForm = ({
    service,
    updateEPointageService,
    onSuccess,
    isCreation,
    siteId,
    history: { push },
}: IAddOrEditCheckListUserFormProps) => {
    const [t] = useTranslation();

    const isEditing = !!service;

    const serviceFormTitle = isEditing ? t('page:checkList.services.edit.title') : t('page:checkList.services.creation.title')

    const handleGoBack = () => push(`${routes.checkList.services.list(siteId)}`)

    const scheduleList = service ? service.schedulers : [];

    return (
        <Formik
            isInitialValid={isEditing}
            validationSchema={getSchema(t)}
            validateOnBlur={false}
            validateOnChange={true}
            initialValues={{
                ...service,
                schedulerList: scheduleList,
            }}
            onSubmit={async (values, { setSubmitting }) => {
                const {id, published, name, schedulerList} = values;

                if (onSuccess) {
                    onSuccess();
                }
                
                await updateEPointageService({
                    variables: {
                        id, 
                        published: published || false,
                        name,
                        holdingId: siteId,
                        schedulers: schedulerList.map(({__typename, serviceId, ...scheduler}) => (scheduler)),
                    },
                })
                    .then(({ errors }: any) => {
                        if (errors) {
                            throw errors;
                        }
                        if (onSuccess) {
                            onSuccess();
                        }
                        setSubmitting(false);
                    })
                    .catch((errs: any) => {
                        setSubmitting(false);
                        return;
                    });
            }}
        >
            {({ errors, isSubmitting, values, initialValues, isValid, setFieldValue, setSubmitting }) => {
                return (
                    <Struct.Section>
                        <HeaderWrapper id='title_section'>  
                            <Title
                                mode="H2" 
                                value={serviceFormTitle}
                                icon={
                                    <IconButton
                                        id='backButton'
                                        onClick={handleGoBack} 
                                        greyIndexColor={0}
                                    >
                                        <Icon.ArrowLeft />
                                    </IconButton>
                                }
                            />
                            <Button
                                form={FORM_ID}
                                display={ButtonType.VALIDATION}
                                disabled={
                                    !isValid ||
                                    (isEditing && isEqual(values, initialValues)) ||
                                    Object.entries(errors).length !== 0 ||
                                    isSubmitting
                                }
                                type="submit"
                            >
                                <TextButton>{t('app:button.validate')}</TextButton>
                            </Button>
                        </HeaderWrapper>
                        <StyledCard>
                            <FullForm id={FORM_ID} >
                                <WrapperToggle>
                                    <Field
                                        label={t('page:checkList.services.servicePublish')}
                                        name="published"
                                        component={ToggleSwitchField}
                                        fontWeight="bold"
                                        inline={true}
                                    />
                                </WrapperToggle>
                                <Title
                                    mode="H3"
                                    value={t('page:checkList.services.serviceSettings')}
                                    icon={<Icon.Cog />}
                                />
                                <Wrapper maxWidth={350}>
                                    <Field
                                        label={t('schema:checkListUser.services.name')}
                                        name="name"
                                        component={TextInputField}
                                    />
                                </Wrapper>
                                <Title
                                    mode="H3"
                                    value={t('page:checkList.services.scheduleSettings')}
                                    icon={<Icon.Cog />}
                                />
                                <Field
                                    name="schedulerList"
                                    component={ScheduleTable}
                                />
                            </FullForm>
                        </StyledCard>
                    </Struct.Section> 
                );
            }}
        </Formik>
    );
};

export default withRouter(AddOrEditServiceForm);
