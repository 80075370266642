import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import routes from 'Router/routes';
import styled from 'styled-components';
import { Tooltip, Icon, IconButton } from 'components';
import { 
    ActionButtonsWrapper,
    CancelButton,
    EditIcon,
    FlexRow,
    Header,
    ModalTitle,
    StyledTD,
    TextWithEllipsis
} from './AdminPage.style';
import DeleteCheckListUserButton from './DeleteCheckListUserButton';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { checklistUser } from 'types/listChecklistUsers';

const ICON_WIDTH = 20;

interface IProps extends RouteComponentProps<{ siteId: string }> {
    item: checklistUser;
    history: any;
    location: any;
    refetchAfterDelete: () => void;
};

const CheckListUser = ({ 
    item,
    history,
    location,
    match: {
        params: { siteId },
    },
    refetchAfterDelete
}: IProps ) => {
    const { id, firstName, lastName, email, idUserRole, schools } = item;
    const { t } = useTranslation();
    const holdingListString = schools.map((school) => school.schoolName).join("; ");

    const BinTooltip = ({ id, onChange }) => {
        const [activeTooltip, setActiveTooltip] = useState(false);
        const [activeTooltipText, setActiveTooltipText] = useState(true);

        return (
            <div onBlur={(e) => { setActiveTooltip(false); setActiveTooltipText(true) }}>
                <StyledTooltipModal
                    clickedToolTip={activeTooltip}
                    toolTipContent={
                        <>
                            <Header>
                                <ModalTitle>{t(`page:checkList.adminList.deleteModalTitle`)}</ModalTitle>
                                <Icon.Delete />
                            </Header>
                            <FlexRow>
                                <CancelButton onClick={() => { setActiveTooltip(false) }}>
                                    {t(`app:button.cancel`)}
                                </CancelButton>
                                <DeleteCheckListUserButton 
                                    id={id}
                                    onChange={onChange}
                                    />
                            </FlexRow>
                        </>
                    }
                >
                    <StyledTooltip toolTipContent={activeTooltipText ? t('app:button.delete') : ''}>
                        <IconButton onClick={() => { setActiveTooltip(true); setActiveTooltipText(false) }} >
                            <Icon.Delete />
                        </IconButton>
                    </StyledTooltip>
                </StyledTooltipModal>
            </div>
        );
    };

    return (
        <>
            <tr>
                <StyledTD width={'15%'} >
                    <TextWithEllipsis maxWidth={100} withEllipsis >
                        {lastName}
                    </TextWithEllipsis>
                </StyledTD>
                <StyledTD width={'15%'} >
                    <TextWithEllipsis maxWidth={100} withEllipsis >
                        {firstName}
                    </TextWithEllipsis>
                </StyledTD> 
                <StyledTD width={'20%'} >
                    <TextWithEllipsis maxWidth={230} withEllipsis >
                        {email}
                    </TextWithEllipsis>
                </StyledTD>
                <StyledTD width={'20%'} >
                    <TextWithEllipsis maxWidth={130} withEllipsis >
                        {holdingListString}
                    </TextWithEllipsis>
                </StyledTD>
                <StyledTD width={'15%'} >
                    <TextWithEllipsis maxWidth={120} >
                        {t(`schema:checkListUser.admin.roles.${idUserRole}`)}
                    </TextWithEllipsis>
                </StyledTD>
                <StyledTD width={'10%'} >
                    <ActionButtonsWrapper>
                        <StyledTooltip toolTipContent={t('app:button.edit')}> 
                            <EditIcon id={`edit-button_row-${id}`} onClick={() => {
                                history.push(`${routes.checkList.admin.checkListUser.edit(siteId, id.toString())}${location.search}`);
                            }}>
                                <Icon.Edit width={ICON_WIDTH} height={ICON_WIDTH} />
                            </EditIcon>
                        </StyledTooltip>
                        <BinTooltip id={id} onChange={refetchAfterDelete} />
                    </ActionButtonsWrapper>
                </StyledTD>
            </tr>
        </>
    );
};

const StyledTooltip = styled((props) => <Tooltip styledTooltip={true} {...props} />)`
    top: ${({ theme, style }) => style?.top ? style.top : theme.spacing.s}px;
    padding: 5px 0px;
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    width: ${({ style }) => style?.width ? style.width : '70'}px;
    left: 50%;
    margin-left: ${({ style }) => style?.transform ? '0' : '-35'}px;
    text-align: center;
    transform: ${({ style }) => style?.transform ? style.transform : 'none'};
`;

const StyledTooltipModal = styled((props) => <Tooltip {...props} />)`
    top: -10px;
    right: -10px;
    width: 330px;
    padding: ${({ theme }) => theme.spacing.xs}px;
    z-index: 2;
`;

export default withRouter(CheckListUser);
