import { Icon } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme, DefaultTheme } from 'styled-components';

import { ChecklistSitePageContainer, PageWithTabs } from 'components/Layout';
import appRoutes from 'Router/routes';
import AdminPage from './AdminPage';
import RoutingServicesPage from './routingServicesPages';

interface IProps {
    theme: DefaultTheme;
}

const getRoutes = (t: any) => [
    {
        tabKey: 'services',
        tabLabel: t('page:checkList.services.list'),
        path: appRoutes.checkList.services.list,
        component: RoutingServicesPage,
    },
    {
        tabKey: 'admin',
        tabLabel: t('page:checkList.adminList.list'),
        path: appRoutes.checkList.admin.list,
        component: AdminPage,
    },
];

const CheckList = ({ theme }: IProps) => {
    const { t } = useTranslation();

    return (
        <ChecklistSitePageContainer>
            <PageWithTabs
                routes={getRoutes(t)}
                indexRoute={appRoutes.checkList.index()}
                title={t(`app:app.checkList`)}
                Icon={<Icon.CheckList color={theme.color.grey[6]} />}
            />
        </ChecklistSitePageContainer>
    );
};

export default withTheme(CheckList);
