import { Button, Struct, Title } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import EmployeesTable from './EmployeesTable';
import { Container, TextButton } from './EmployeesPage.style';
import routes from 'Router/routes';

const EmployeesPage = ({ location, history }: RouteComponentProps) => {
    const { t } = useTranslation();
    const redirectToEmployeeCreatePage = () => {
        history.push(`${routes.admin.employees.employee.add()}${location.search}`);
    };

    return (
        <Struct.Section>
            <Container id='title_section'>
                <Title grow mode="H2" value={t('page:admin.employees.title')} />
                <Button onClick={redirectToEmployeeCreatePage}>
                    <TextButton>{t('app:button.add-employee')}</TextButton>
                </Button>
            </Container>
            <Struct.Card>
                <EmployeesTable />
            </Struct.Card>
        </Struct.Section>
    );
};

export default withRouter(EmployeesPage);
