import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import styled from 'styled-components';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { PriceInput, TextInput, TableWithOptions, Icon } from 'components';
import { StyledTooltip } from 'pages/Communication/CommunicationPage/CommunicationListTable';
import { appTheme } from 'theme';
import { QueryResult } from 'localTypes';
import { listLocalArticles_list_edges_node_LocalArticle } from 'types/listLocalArticles';
import ContainerTablePanel from 'components/Layout/ContainerTablePanel';

const LIST_CONTAINERS = loader('../../query/listAllContainers.gql');


const ICON_SIZE = 20;
const ICON_COLOR = appTheme.color.grey[6];
interface IProps {
    idHolding: string;
    toggleContainerModal: () => void;
    isContainerPanelOpen: boolean;
}

const ContainerLine = (container, index, editLabel, onClickEditBtn) => (
    <tr key={`${container.id}-${index}`}>
        <MessageContainer>
            <TextWrapper>{container.label}</TextWrapper>
        </MessageContainer>
        <InputContainer>
            <TextInput
                name={`cashSystemCode-row-${index}`}
                inline
                onChange={() => null}
                value={container.cashSystemCode}
                disabled
            />
        </InputContainer>
        <InputContainer>
            <LabelPriceInput
                name={`price-row-${index}`}
                onChange={() => null}
                value={container.price.amount}
                disabled
            />
        </InputContainer>
        <ButtonsContainer>
          <StyledTooltip toolTipContent={editLabel}>
            <span id={`edit-button_row-${index}`} onClick={() => onClickEditBtn(index)}>
                <Icon.Edit width={ICON_SIZE} height={ICON_SIZE} color={ICON_COLOR} />
            </span>
          </StyledTooltip>
        </ButtonsContainer>
    </tr>
);

const ContainerTable = ({ idHolding, toggleContainerModal, isContainerPanelOpen }: IProps) => {
    const { t } = useTranslation();
    const editLabel = t('app:button.containerEdit');
    const [ isEditMode, setIsEditMode ] = useState(false);
    const [ indexEditContainer, setIndexEditContainer ] = useState(0);

    const defaultQueryVariables = {
        querySearch: [{ key: 'idHolding', operator: '=', value: idHolding }],
    };

    const onClickEditBtn = (index) => {
      setIndexEditContainer(index);
      setIsEditMode(true);
      toggleContainerModal();
    }

    return (
        <TableWithOptions
            renderLine={(item, index: number) => ContainerLine(item, index, editLabel, onClickEditBtn)}
            headers={getHeaders(t)}
            fetchPolicy="cache-and-network"
            query={LIST_CONTAINERS}
            variables={defaultQueryVariables}
            withSearchBar={false}
            withPagination={false}
        >
            {({ data, refetch }: QueryResult<listLocalArticles_list_edges_node_LocalArticle>) => {
                const container = isEditMode ? get(data, `list.edges[${indexEditContainer}].node`, null) : null;

                const closeModal = ({shouldRefetch = false}) => {
                    if(shouldRefetch){
                      refetch();
                    }

                    setIsEditMode(false);
                    setIndexEditContainer(0);
                    toggleContainerModal();
                };
                return (
                    <ContainerTablePanel
                        closeModal={closeModal}
                        isOpen={isContainerPanelOpen}
                        container={container}
                        isEditMode={isEditMode}
                    />
                );
            }}
        </TableWithOptions>
    );
};

// @ts-ignore
const getHeaders = (t: any) => [
    {
        key: 'label',
        displayName: t('schema:container.label'),
    },
    {
        key: 'cashSystemCode',
        displayName: t('schema:container.cashSystemCode'),
    },
    {
        key: 'price.amount',
        displayName: t('schema:container.price.amount'),
    },
    {
        key: 'action',
        displayName: '',
    },
];

const MessageContainer = styled.td`
  width: 70%;
  max-width: 480px;
  overflow: hidden;
  overflow-wrap: break-word;
`;

const InputContainer = styled.td`
  width: 10%;
`

const LabelPriceInput = styled(PriceInput)`
    text-align: left;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    color: ${({ theme }) => theme.color.navy};
    font-weight: 600;
`;

const TextWrapper = styled.span`
    font-size: ${({ theme }) => theme.typography.size.S}px;
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    margin-top: ${({ theme }) => theme.spacing.s}px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    letter-spacing: 0;
    font-weight: lighter;
`;

const ButtonsContainer = styled.td`
    width: 1%;
    cursor: pointer;
`;

export default ContainerTable;
