import { GraphQLError } from 'graphql';
import { TFunction } from 'i18next';
import { FormikErrors } from 'formik';

export function formatServerErrors<T>(graphQLErrors: GraphQLError[], t: TFunction): FormikErrors<T> {
    const errors =
        (graphQLErrors &&
          // @ts-ignore
            graphQLErrors[0]?.extensions?.exception?.errors) ||
        {};
    return Object.keys(errors).reduce(
        (result, key) => ({ ...result, [key]: t(`app:error.${errors[key].code}`, { param: errors[key].param }) }),
        {}
    );
}
