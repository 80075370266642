import { Column, Row, Text } from 'components';
import React, { ReactNode } from 'react';
import get from 'lodash/get';
import I18n from 'services/i18n';
import styled from 'styled-components';
import { getTableServiceOfferTemplates_list_edges_node_TableServiceOfferTemplate_offers as OfferTemplateOffer } from 'types/getTableServiceOfferTemplates';
import { filterOffersByDate, getOfferState, offerPalette, OfferState } from 'services/offerService';
import { StatusBall } from './OfferDatesCarousel';

const DEFAULT_DATE_COUNT: number = 5;

interface IDateButtonProps {
    date: Date;
    onClick?: () => void;
    className?: string;
    disabled: boolean;
    available: boolean;
    active: boolean;
    offerState: OfferState;
}

export function DateButton({ date, onClick, className, disabled, active, offerState }: IDateButtonProps) {
    const tagColor = offerState ? offerPalette[offerState].highlighted : '';

    return (
        <StyledButton {...{ disabled, onClick, className, active }}>
            <Column>
                <WeekdayRow>{I18n.t(`app:weekDays.${date.getDay()}`)}</WeekdayRow>
                <Row justify="center">{date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric' })}</Row>
            </Column>
            <StatusBall color={tagColor} />
        </StyledButton>
    );
}

export interface IOfferDateButtonsProps {
    className?: string;
    onSelectDate: Function;
    children?: ReactNode;
    offers: OfferTemplateOffer[];
    activeDate: Date;
    firstDate?: Date;
    dateCount?: number;
}

export default function OfferDateButtons({
    className,
    onSelectDate,
    children,
    offers,
    activeDate,
    firstDate = new Date(),
    dateCount = DEFAULT_DATE_COUNT,
}: IOfferDateButtonsProps) {
    const offerDateButtons: {}[] = [];
    let i: number;
    for (i = 0; i < dateCount; i++) {
        const offerDate: Date = new Date(firstDate);
        offerDate.setDate(offerDate.getDate() + i);

        // The first offer is taken arbitrarily
        const offer = filterOffersByDate(offers, offerDate)[0];

        const orderRange = get(offer, 'orderRange', '');
        const isDaysInAdvance = get(offer, 'isDaysInAdvance', false);
        const isOfferAvailable = get(offer, 'published', false);
        const isRoomService = get(offer, 'isRoomService', false);

        offerDateButtons.push(
            <DateButton
                key={i}
                date={offerDate}
                onClick={() => onSelectDate(offerDate)}
                className={className}
                disabled={offer === undefined}
                available={isOfferAvailable}
                active={offerDate.getDate() === activeDate.getDate()}
                offerState={getOfferState({ orderRange, published: isOfferAvailable, isDaysInAdvance, isRoomService })}
            />
        );
    }

    return (
        <Column>
            <Row>
                <Row size={2}></Row>
                <WrapperColumn size={8}>
                    <OfferDateTitleRow justify="center">
                        <OfferDateTitleText bold size="M">
                            {I18n.t(`app:weekDays.${activeDate.getDay()}`)}
                        </OfferDateTitleText>
                        <OfferDateTitleText size="M">
                            {activeDate.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric' })}
                        </OfferDateTitleText>
                    </OfferDateTitleRow>
                </WrapperColumn>
                <Row size={2}>{children}</Row>
            </Row>
            <OfferDateButtonsRow>{offerDateButtons}</OfferDateButtonsRow>
        </Column>
    );
}

interface StyledButtonProps {
    active: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    padding: 0 ${({ theme, active }) => (active ? theme.spacing.s - 1 : theme.spacing.s)}px;
    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.grey[6]};
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    height: 51px;
    min-width: 90px;
    position: relative;
    background-color: transparent;
    &:not(:disabled) {
        ${({ theme, active }) =>
            active ? `border: 3px solid ${theme.color.common.blue};` : `border: 1px solid ${theme.color.grey[4]};`}
    }
    &:not(:disabled):before {
        content: '';
        position: absolute;
        height: 30%;
        width: 15px;
        right: -7px;
        top: -7px;
        background-size: 15px 15px;
        background-repeat: no-repeat;
    }
    &:disabled {
        box-shadow: none;
        color: ${({ theme }) => theme.color.grey[4]};
        cursor: not-allowed;
        border: ${({ theme }) => `1px solid ${theme.color.grey[4]}`};
        background: ${({ theme }) =>
            `repeating-linear-gradient(-45deg, ${theme.color.common.white}, ${theme.color.common.white} 10%, ${theme.color.grey[4]} 11%)`};
    }
`;
const WeekdayRow = styled(Row)`
    text-transform: capitalize;
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    justify-content: center;
`;

const OfferDateButtonsRow = styled.div`
    padding: 0px 10%;
    margin-bottom: ${({ theme }) => theme.spacing.s}px;
`;

const OfferDateTitleRow = styled(Row)`
    margin-bottom: ${({ theme }) => theme.spacing.s}px;
`;

const OfferDateTitleText = styled(Text)`
    color: ${({ theme }) => theme.color.grey[6]};
    text-transform: capitalize;
    margin-right: ${({ theme }) => theme.spacing.xs}px;
`;

const WrapperColumn = styled(Column)`
    padding-top: ${({ theme }) => theme.spacing.xs}px;
`;
