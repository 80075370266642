import React from 'react';
import { RouteComponentProps, Route, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Struct, Title, Button } from 'components';
import { Back } from 'components/Button';
import appRoutes from 'Router/routes';
import { OfferTemplateRouteParam } from 'localTypes';
import LocalCatalogTable from './LocalCatalogTable';
import GlobalCatalogTable from './GlobalCatalogTable';

interface IProps extends RouteComponentProps<{ idHolding: string; idPos: string }> {
    offerType: OfferTemplateRouteParam;
}

const LocalCatalog = ({
    match: {
        params: { idPos, idHolding },
    },
    offerType,
}: IProps) => {
    const { t } = useTranslation();
    return (
        <>
            <Route
                exact
                path={appRoutes[offerType].catalog.get()}
                render={() => (
                    <Struct.Section>
                        <Title grow mode="H2" value={t('page:clickcollect.catalog.title') || ''} testID={'cc-local-catalog-title'}>
                            <Link to={appRoutes[offerType].catalog.addArticle(idHolding, idPos)}>
                                <Button id='add-article-button' onClick={() => {}}>{t('app:button.add-article')}</Button>
                            </Link>
                        </Title>
                        <Struct.Card>
                            <LocalCatalogTable idHolding={idHolding} idPos={idPos} offerType={offerType} />
                        </Struct.Card>
                    </Struct.Section>
                )}
            />
            <Route
                path={appRoutes[offerType].catalog.addArticle()}
                render={() => {
                    return (
                        <Struct.Section>
                            <Title
                                icon={
                                    <Link to={appRoutes[offerType].catalog.get(idHolding, idPos)}>
                                        <Back />
                                    </Link>
                                }
                                mode="H2"
                                value={t('page:clickcollect.catalog.add-article.title') || ''}
                            />
                            <Struct.Card>
                                <GlobalCatalogTable />
                            </Struct.Card>
                        </Struct.Section>
                    );
                }}
            />
        </>
    );
};

export default LocalCatalog;
