import { Button, Loader, Struct } from 'components';
import { Form } from 'formik';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: ${({ theme }) => theme.spacing.s}px;
`;

export const Wrapper = styled.div<{ maxWidth?: number }>`
    width: ${({ maxWidth }) => maxWidth ? `${maxWidth}px` : '100%'};
`;

export const EmptyStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 600px;
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.color.common.white};
`;

export const MessageWrapper = styled.div`
    font-size: ${({ theme }) => theme.typography.size.M}px;
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    margin-top: ${({ theme }) => theme.spacing.s}px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: Helvetica;
    letter-spacing: 0;
    text-align: center;
`;

export const StyledLoader = styled(Loader)`
    left: 0px;
    top: 50px;
`;

export const StyledButton = styled(Button)`
    width: 130px;
    min-width: 130px;
    height: 30px;
    cursor: pointer;
`;

export const StyledTD = styled.td<{ width: string}>`
    width: ${({ width }) => `${width}`};
`;

export const TextWithEllipsis = styled.div<{ maxWidth: number, withEllipsis?: boolean}>`
    max-width: ${({ maxWidth }) => `${maxWidth}px`};
    overflow: hidden;
    text-overflow: ${({ withEllipsis }) => withEllipsis ? "ellipsis" : "clip"};
    white-space: nowrap;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    text-align: left;
    font-weight: normal;
`;

export const ActionButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-right: 5px;
    align-items: center;
`;

export const EditIcon = styled.span`
    cursor: pointer;
`;

export const IconButton = styled.button<{ greyIndexColor: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: -5px;
    border-radius: ${({ theme }) => theme.borderRadius.xs}px;
    width: 28px;
    height: 28px;
    background-color: ${({ theme, greyIndexColor }) => theme.color.grey[greyIndexColor]};
`;

export const StatusText = styled.span<{ published: boolean }>`
    color: ${({ published, theme }) => published ? theme.color.common.darkGreen : theme.color.common.red};;
`;

export const WrapperToggle = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 0px;
`;

export const TextButton = styled.span`    
    font-size: ${({ theme }) => theme.typography.size.S}px;
    line-height:  ${({ theme }) => theme.typography.lineHeight.S}px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.color.white};
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
`;

export const StyledCard = styled(Struct.Card)`
    display: flex;
    justify-content: space-between;
    margin-top: -20px;
    padding: ${({ theme }) => theme.spacing.m}px;
    margin-bottom: 20px;
`;

export const FullForm = styled(Form)`
    width: 100%;
`;

export const ButtonsRowContainer = styled.div`
    margin-bottom: 30px;
    display: flex;
    align-self: flex-end;
`;
