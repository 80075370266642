import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';
import { InputLabel, ErrorMessage } from 'components';
import { connect, getIn } from 'formik';

const isFieldRequired = (validationSchema: any, fieldName: string) => {
    const validation =
        validationSchema && validationSchema.describe && getIn(validationSchema.describe().fields, fieldName);
    if (!validation) return false;
    if (!validation.fields) return validation.tests.some((test: any) => test.name === 'required');
    return Object.keys(validation.fields).some((key: string) =>
        validation.fields[key].tests.some((test: any) => test.name === 'required')
    );
};

const FieldContainer = ({
    field,
    form: { errors, touched },
    label = '',
    subLabel,
    className,
    bottomSize,
    children,
    formik: { validationSchema },
    inline,
    useDefaultErrorMessage = true,
    fontWeight,
    fontSize,
    marginBottom,
    overrideShowRequiredAsterisk = false,
    justify = '',
    infoLabel,
    labelContainerStyle,
    testID
}: any) => {
    return (
        <>
            <Wrapper inline={inline} className={className} bottomSize={bottomSize} justify={justify} data-test={testID}>
                {label && (
                  // @ts-ignore
                    <InputLabel
                        inline={inline}
                        name={label}
                        comment={subLabel}
                        htmlFor={field.name}
                        fontWeight={fontWeight}
                        fontSize={fontSize}
                        marginBottom={marginBottom}
                        showRequiredAsterisk={
                            overrideShowRequiredAsterisk ||
                            isFieldRequired(
                                typeof validationSchema === 'function' ? validationSchema() : validationSchema,
                                field.name
                            )
                        }
                        infoLabel={infoLabel}
                        labelContainerStyle={labelContainerStyle}
                        {...(testID && {testID:`${testID}-label`})}
                    />
                )}
                {React.cloneElement(children, { hasError: !!get(errors, field.name) && !!get(touched, field.name) })}
                {!inline && useDefaultErrorMessage && get(touched, field.name) && get(errors, field.name) ? (
                  // @ts-ignore
                    <ErrorMessage>{get(errors, field.name)}</ErrorMessage>
                ) : null}
            </Wrapper>
            {inline && useDefaultErrorMessage && get(touched, field.name) && get(errors, field.name) ? (
            <ErrorWrapper className={className} justify={justify}>
              {// @ts-ignore
                <ErrorMessage>{get(errors, field.name)}</ErrorMessage>
              }
            </ErrorWrapper> ) : null}
        </>
    );
};
interface IWrapper {
    bottomSize?: 's' | 'm' | 'l' | 'xs';
    inline?: boolean;
    justify?: string;
    backgroundColor?: string;
}
const Wrapper = styled.div<IWrapper>`
    width: 100%;
    display: flex;
    flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
    justify-content: ${({ inline, justify }) => (inline && justify !== 'flex-start' ? 'space-between' : 'flex-start')};
    align-items: ${({ inline }) => (inline ? 'center' : 'flex-start')};
    margin-bottom: ${({ theme, bottomSize = 'm' }) => theme.spacing[bottomSize]}px;
`;

const ErrorWrapper = styled.div<IWrapper>`
    width: 100%;
    display: flex;
    flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
    justify-content: ${({ inline, justify }) => (inline && justify !== 'flex-start' ? 'space-between' : 'flex-start')};
    align-items: ${({ inline }) => (inline ? 'center' : 'flex-start')};
    margin-top: -30px;
    margin-bottom: ${({ theme, inline, bottomSize = 'm' }) => (inline ? theme.spacing[bottomSize] : theme.spacing[bottomSize])}px;
`;

export default connect(FieldContainer);
