import React from 'react';
import get from 'lodash/get';
import { Field } from 'formik';
import { Trans } from 'react-i18next';
import { ImageInputField } from 'components';

//Renders a Image Slider
export const ImageSlider = ({ rank, name, multipleLanguages, values }) => {

    const images = get(values, name);
    const imageList = images && images.length !== 0 ? JSON.parse(images) : [];

    return (
        <Field
            secondaryLabel={<Trans i18nKey="page:communication.addMarketingCard.imageHint" />}
            name={name}
            multipleLanguages={multipleLanguages}
            rank={rank}
            component={ImageInputField}
            multipleImages
            initialImages={imageList}
        />
    );
};
