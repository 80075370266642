import { Column, InfoLabel, Row, ToggleSwitchField } from 'components';
import { Time } from 'components/Inputs/FormikFields/TimeSlotInputField';
import { Field } from 'formik';
import { Duration } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDateTime } from 'services/dateService';
import { getProductFamilyOptions } from 'services/offerTemplateService';
import styled from 'styled-components';
import { OfferTemplateSalesType, OfferTemplateWithdrawalType } from 'types/globalTypes';
import { OfferTemplateSalesTypeDic, OfferTemplateWithdrawalTypeDic } from './index';
import { Wrapper, VerticalLine } from './StepOne';

interface IStepProps {
    values?: any;
}

const durationConverter = (duration: string, t: any) => {
    const dateTime = getDateTime(Duration.fromISO(duration.substr(0, 1) === 'P' ? duration : `PT${duration}`));
    const time = dateTime.toISOTime().split('.')[0].slice(0, 5);
    const day = t(`app:weekDays.${dateTime.toJSDate().getDay()}`);
    return { time: time, day: day };
};

const getTimeUnit = (value: { duration: number; unit: Time }) => {
    const { duration, unit } = value;
    switch (unit) {
        case Time.MINUTES:
            return duration + ' minute(s)';

        case Time.HOURS:
            return duration + ' heures(s)';

        case Time.DAYS:
            return duration + ' jour(s)';
    }
};

const getOrderAndWithdrawalValues = (useInAdvanceComponent, values): {
    orderStartDefault?: string,
    orderEndDefault?: string,
    withdrawalRangeStart?: string,
    withdrawalRangeEnd?: string
} => {
    if (!values) {
        return {};
    }
    if (useInAdvanceComponent) {
        return {
            orderStartDefault: values.daysInAdvance.orderStartDefaultDaily,
            orderEndDefault: values.daysInAdvance.orderEndDefaultDaily,
            withdrawalRangeStart: values.withdrawStartDefault,
            withdrawalRangeEnd: values.withdrawEndDefault
        };
    }

    return {
        orderStartDefault: values.orderStartDefaultDaily,
        orderEndDefault: values.orderEndDefaultDaily,
        withdrawalRangeStart: values.withdrawStartDefault,
        withdrawalRangeEnd: values.withdrawEndDefault
    };
}

const StepFour = ({ values }: IStepProps) => {
    const { t } = useTranslation();

    const isClickServeInstantClickCollectOrTableServiceRule =
        values.withdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE ||
        values.withdrawalType === OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT ||
        values.withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE;
    const isInstantClickCollect = values.withdrawalType === OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT;
    const clickAndPickup = values.withdrawalType === OfferTemplateWithdrawalType.CLICK_AND_PICK_UP;
    const orderAndWithdrawalValues = getOrderAndWithdrawalValues(!isClickServeInstantClickCollectOrTableServiceRule, values);

    const orderStartDefault = values && durationConverter(orderAndWithdrawalValues.orderStartDefault!, t);
    const orderEndDefault = values && durationConverter(orderAndWithdrawalValues.orderEndDefault!, t);
    const withdrawalRangeStart = values && durationConverter(orderAndWithdrawalValues.withdrawalRangeStart!, t);
    const withdrawalRangeEnd = values && durationConverter(orderAndWithdrawalValues.withdrawalRangeEnd!, t);

    const salesType = OfferTemplateSalesTypeDic().find((x) => x.id === values.salesType);
    const withdrawalType = OfferTemplateWithdrawalTypeDic(false).find((x) => x.id === values.withdrawalType);
    const selectedFamilies =
        values &&
        getProductFamilyOptions(values.mealHeartRule.selectedFamilies)
            .map((family) => family.label)
            .join(' - ');

    return (
        <Wrapper>
            <MainWrapper>
                <SubWrapperLeft>
                    <WrapperToggle>
                        <Field
                            label={t('page:admin.cnc.offerPublished')}
                            name="published"
                            component={ToggleSwitchField}
                            fontWeight="bold"
                            inline={true}
                        />
                        <RowWrapperNoMargin>{t('page:admin.cnc.toggleText')}</RowWrapperNoMargin>
                    </WrapperToggle>
                    <StepWrappers>
                        <InfoLabel name={`1. ${t(`page:admin.cnc.offerDescription`)}`} fontWeight="bold" />
                        <InfoLabel name={t(`page:admin.cnc.POS`)} fontWeight="bold" fontSize="S" />
                        {values.posName && <RowWrapper>{values && values.posName}</RowWrapper>}
                        <InfoLabel name={t(`page:admin.cnc.offerName`)} fontWeight="bold" fontSize="S" />
                        <RowWrapper>{values && values.name}</RowWrapper>
                        <InfoLabel name={t(`page:admin.cnc.salesType`)} fontWeight="bold" fontSize="S" />
                        <RowWrapper>{values && salesType && salesType.label}</RowWrapper>
                        <InfoLabel name={t(`page:admin.cnc.withdrawalType`)} fontWeight="bold" fontSize="S" />
                        <RowWrapper>{values && withdrawalType && withdrawalType.label}</RowWrapper>
                        <InfoLabel name={t(`page:admin.cnc.description`)} fontWeight="bold" fontSize="S" />
                        <RowWrapper>{values.description}</RowWrapper>
                        <InfoLabel name={t(`page:admin.cnc.fullDescription`)} fontWeight="bold" fontSize="S" />
                        {values && values.hasFullDescription === true ? (
                            <RowWrapperNoMargin>{t(`page:admin.cnc.yes`)}</RowWrapperNoMargin>
                        ) : (
                            <RowWrapperNoMargin>{t(`page:admin.cnc.no`)}</RowWrapperNoMargin>
                        )}
                    </StepWrappers>
                    <StepWrappers>
                        {!isClickServeInstantClickCollectOrTableServiceRule && (
                            <>
                                {!clickAndPickup && (
                                    <>
                                        <InfoLabel name={`2. ${t(`page:admin.cnc.steps.step2`)}`} fontWeight="bold" />
                                        <InfoLabel
                                            name={t(`page:admin.cnc.minOrderPreparationTimeReview`)}
                                            fontWeight="bold"
                                            fontSize="S"
                                        />
                                        <RowWrapper>{values && getTimeUnit(values.minPreparationDelay)}</RowWrapper>
                                        <InfoLabel name={`3. ${t('page:admin.cnc.deliveryManagement')}`} fontWeight="bold" />
                                        <InfoLabel name={t('page:admin.cnc.selectedPickupPoints')} fontWeight="bold" fontSize="S" />
                                        {values.pickupPoints.length > 0 ? (
                                            values.pickupPoints.map((pickupPoint) => <RowWrapper>{pickupPoint.label}</RowWrapper>)
                                        ) : (
                                            <InfoLabel fontSize="S" name={t('component:pickupPointSelector.emptySelectedList')} />
                                        )}
                                    </>
                                )}
                                <InfoLabel name={t(`page:admin.cnc.orderAdvance`)} fontWeight="bold" fontSize="S" />
                                <RowWrapperItalic>{t(`page:admin.cnc.orderAdvanceNote`)}</RowWrapperItalic>
                                <RowWrapper>
                                    {t(`page:admin.cnc.orderAdvanceDetails`, {
                                        min: values && values.daysInAdvance.min,
                                        max: values && values.daysInAdvance.max,
                                    })}
                                </RowWrapper>
                                <InfoLabel name={t(`page:admin.cnc.orderRange`)} fontWeight="bold" fontSize="S" />
                                <RowWrapper>
                                    {t(`page:admin.cnc.from`)} {values && orderStartDefault.time}{' '}
                                    {t(`page:admin.cnc.to`)} {values && orderEndDefault.time}
                                </RowWrapper>
                            </>
                        )}
                        {isClickServeInstantClickCollectOrTableServiceRule && (
                            <>
                                <InfoLabel name={`2. ${t(`page:admin.cnc.steps.step2`)}`} fontWeight="bold" />
                                <InfoLabel
                                    name={isInstantClickCollect ?
                                        t(`page:admin.cnc.orderRangeInstantClickAndCollectOrder`) :
                                        t(`page:admin.cnc.orderRangeSeatClickAndServe`)}
                                    fontWeight="bold"
                                    fontSize="S"
                                />
                                <RowWrapper>
                                    {t(`page:admin.cnc.from`)} {values && orderStartDefault.time}{' '}
                                    {t(`page:admin.cnc.to`)} {values && orderEndDefault.time}
                                </RowWrapper>
                            </>
                        )}
                    </StepWrappers>
                </SubWrapperLeft>
                <VerticalLine />

                <SubWrapperRight marginTop={500}>
                    {!isClickServeInstantClickCollectOrTableServiceRule && !clickAndPickup && (
                        <>
                            <InfoLabel
                                name={t(`page:admin.cnc.withdrawalRangeReview`)}
                                fontWeight="bold"
                                fontSize="S"
                            />
                            <RowWrapper>
                                {t(`page:admin.cnc.from`)} {values && withdrawalRangeStart.time}{' '}
                                {t(`page:admin.cnc.to`)} {values && withdrawalRangeEnd.time}
                            </RowWrapper>

                            <InfoLabel name={t(`page:admin.cnc.minPreparationDelay`)} fontWeight="bold" fontSize="S" />
                            <RowWrapper>{values && getTimeUnit(values.withdrawSlotDuration)}</RowWrapper>

                            <InfoLabel name={t(`page:admin.cnc.maximumOrder`)} fontWeight="bold" fontSize="S" />
                            <RowWrapper>{values && values.orderPerTimeSlot.max}</RowWrapper>

                            <InfoLabel name={t(`page:admin.cnc.minCancellationDelay`)} fontWeight="bold" fontSize="S" />
                            <RowWrapper>{values && getTimeUnit(values.minCancellationDelay)}</RowWrapper>
                        </>
                    )}

                    {values.salesType !== OfferTemplateSalesType.BUNDLE && (
                        <>
                            {clickAndPickup ? (
                                <InfoLabel name={`2. ${t(`page:admin.cnc.steps.step3`)}`} fontWeight="bold" />
                            ) : (
                                <InfoLabel name={`3. ${t(`page:admin.cnc.steps.step3`)}`} fontWeight="bold" />
                            )}
                            <InfoLabel name={t('page:admin.cnc.mealHeartCategory')} fontWeight="bold" fontSize="S" />
                            <RowWrapper>{selectedFamilies}</RowWrapper>
                            <InfoLabel
                                name={`${t('page:admin.cnc.numberOfHeartMeal')} (${t(
                                    'page:admin.cnc.max'
                                ).toLowerCase()})`}
                                fontWeight="bold"
                                fontSize="S"
                            />
                            <RowWrapper>{values && values.mealHeartQuantityRule.mealQuantity.max}</RowWrapper>
                            <InfoLabel
                                name={`${t('page:admin.cnc.numberOfHeartMeal')} (${t(
                                    'page:admin.cnc.min'
                                ).toLowerCase()})`}
                                fontWeight="bold"
                                fontSize="S"
                            />
                            <RowWrapper>{values && values.mealHeartQuantityRule.mealQuantity.min}</RowWrapper>
                            <InfoLabel
                                name={`${t('page:admin.cnc.orderPersonDay')} (${t(
                                    'page:admin.cnc.max'
                                ).toLowerCase()})`}
                                fontWeight="bold"
                                fontSize="S"
                            />
                            <RowWrapper>{values && values.mealHeartQuantityRule.orderPerDay.max}</RowWrapper>
                            <InfoLabel
                                name={`${t('page:admin.cnc.sameProductPerOrder')} (${t(
                                    'page:admin.cnc.max'
                                ).toLowerCase()})`}
                                fontWeight="bold"
                                fontSize="S"
                            />
                            <RowWrapper>{values && values.mealHeartQuantityRule.sameProductPerOrder.max}</RowWrapper>
                            <InfoLabel
                                name={`${t('page:admin.cnc.productsPerOrder')} (${t(
                                    'page:admin.cnc.max'
                                ).toLowerCase()})`}
                                fontWeight="bold"
                                fontSize="S"
                            />
                            <RowWrapper>{values && values.mealHeartQuantityRule.productsPerOrder.max}</RowWrapper>
                            {clickAndPickup ? (
                                <>
                                    <InfoLabel name={`3. ${t('page:admin.cnc.deliveryManagement')}`} fontWeight="bold" />
                                    <InfoLabel name={t('page:admin.cnc.selectedPickupPoints')} fontWeight="bold" fontSize="S" />
                                    {values.pickupPoints.length > 0 ? (
                                        values.pickupPoints.map((pickupPoint) => <RowWrapper>{pickupPoint.label}</RowWrapper>)
                                    ) : (
                                        <InfoLabel fontSize="S" name={t('component:pickupPointSelector.emptySelectedList')} />
                                    )}
                                    <InfoLabel name={`4. ${t(`page:admin.cnc.steps.step4`)}`} fontWeight="bold" />
                                    <InfoLabel name={t('page:admin.cnc.paymentMethod')} fontWeight="bold" fontSize="S" />
                                    <RowWrapper>{[
                                        values.badge && t('page:admin.cnc.badge'),
                                        values.cc && t('page:admin.cnc.cc'),
                                        values.edenred && t('page:admin.cnc.edenred'),
                                        values.onsite && t('page:admin.cnc.onsite'),
                                    ].filter(type => !!type).join(', ')}
                                    </RowWrapper>                                    
                                </>
                            ) : (
                                <>
                                    <InfoLabel name={`4. ${t(`page:admin.cnc.steps.step4`)}`} fontWeight="bold" />
                                    <InfoLabel name={t('page:admin.cnc.paymentMethod')} fontWeight="bold" fontSize="S" />
                                    <RowWrapper>{[
                                        values.badge && t('page:admin.cnc.badge'),
                                        values.cc && t('page:admin.cnc.cc'),
                                        values.edenred && t('page:admin.cnc.edenred'),
                                        values.onsite && t('page:admin.cnc.onsite'),
                                    ].filter(type => !!type).join(', ')}
                                    </RowWrapper>
                                </>
                            )}
                        </>
                    )}
                </SubWrapperRight>
            </MainWrapper>
        </Wrapper>
    );
};

const WrapperToggle = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 0px;
`;

const MainWrapper = styled(Row)`
    align-items: flex-start;
    height: 100%;
`;

const SubWrapperLeft = styled(Column)`
    align-items: flex-start;
    margin: 0 20px;
    width: 460px;
`;

const RowWrapper = styled(Row)`
    align-items: flex-start;
    margin-top: -15px;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
`;

// @ts-ignore
const RowWrapperNoMargin = styled(RowWrapper)`
    margin-top: 10px;
    margin-bottom: 0;
`;

const RowWrapperItalic = styled(Row)`
    align-items: flex-start;
    margin-top: -15px;
    margin-bottom: 15px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    font-style: italic;
`;

const SubWrapperRight = styled(SubWrapperLeft)`
    margin-top: 100px;
`;

const StepWrappers = styled.div`
    margin-top: 30px;
`;

export default StepFour;
