import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Row } from 'components';
import Select from 'react-select';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';

const GET_ORDER_RATING_FILTERS = loader('./query/getOrderRatingFiltersData.gql');

interface IProps {
    idHolding: string;
    callback: (filters: any) => void;
}

const OrderRatingTableFilters = ({ idHolding, callback}: IProps) => {
    const { t } = useTranslation();

    const [pos, setPos] = useState({id: '', label: ''});
    const [offerTemplate, setOfferTemplate] = useState({id: '', label: ''});
    const [offerType, setOfferType] = useState({id: '', label: ''});
    const [comment, setComment] = useState(false);
    const [pin, setPin] = useState(false);
    const [applyFilters, setAppplyFilters] = useState(false);

    const { data } = useQuery(GET_ORDER_RATING_FILTERS, {
        variables: { idHolding, idPos: pos.id.toString(), idOfferTemplate: offerTemplate.id.toString(), offerType: offerType.id },
    });

    const defaultQueryVariables = [
            { key: 'idHolding', operator: '=', value: idHolding },
            { key: 'comment', operator: '=', value: comment.toString() },
            { key: 'pin', operator: '=', value: pin.toString() },
    ];

    if(!!pos.id){
        defaultQueryVariables.push({ key: 'idPos', operator: '=', value: pos.id.toString() });
    }
    if(!!offerTemplate.id){
        defaultQueryVariables.push({ key: 'idOfferTemplate', operator: '=', value: offerTemplate.id.toString() });
    }
    if(!!offerType.id){
        defaultQueryVariables.push({ key: 'offerType', operator: '=', value: offerType.id });
    }

     if(applyFilters){
        callback(defaultQueryVariables);
        setAppplyFilters(false);
    } 

    const filterList = (list: any[]) => {
        if(!list){
            return null;
        }
        return list.filter((value, index, self) =>
            index === self.findIndex((t) => (
            t.value === value.value
            )   
        ));
    };
  
    const posOptions = filterList(data?.getOrderRatingFiltersData?.map((n) => {
        return {
            value: n.posId,
            label: n.posName,
        };
    }));

    const offerTemplateOptions = filterList(data?.getOrderRatingFiltersData?.map((n) => {
        return {
            value: n.offerTemplateId,
            label: n.offerTemplateName,
        };
    }));

    const offerTypes = filterList(data?.getOrderRatingFiltersData?.map((n) => {
        return {
            value:  n.offerTemplateWithdrawalType,
            label: t(`schema:OfferWithdrawalType.${n.offerTemplateWithdrawalType}`),
        };
    }));

    const Checkbox = ({ children, ...props }: JSX.IntrinsicElements['input']) => (
        <label style={{ marginRight: '1em' }}>
          <input type="checkbox" {...props} />
          {children}
        </label>
    );

    return (
        <>
        <RowWrapper>
                <Select
                id="pos_input"
                placeholder={t('schema:orderRating.pos')}
                options={posOptions}
                onChange={(pos: { value: string; label: string; }) => {
                    pos ?  
                        setPos({id: pos.value, label: pos.label}) : 
                        setPos({id: '', label: ''})
                        setAppplyFilters(true)
                }}
                isClearable={true}
                styles={{
                    control: (baseStyles: any) => ({
                      ...baseStyles,
                      width: '250px',
                      marginRight: '10px'
                    }),
                }}
                />

                <Select
                    id="offerName_input"
                    placeholder={t('schema:orderRating.offerName')}
                    options={offerTemplateOptions}
                    onChange={(offerTemplate: { value: string; label: string; }) => {
                        offerTemplate ?  
                            setOfferTemplate({id: offerTemplate.value, label: offerTemplate.label}) : 
                            setOfferTemplate({id: '', label: ''})
                            setAppplyFilters(true)
                    }}
                    isClearable={true}
                    styles={{
                        control: (baseStyles: any) => ({
                        ...baseStyles,
                        width: '250px',
                        marginRight: '10px'
                        }),
                    }}
                />

                <Select
                    id="offerType_input"
                    placeholder={t('schema:orderRating.orderType')}
                    options={offerTypes}
                    onChange={(offerType: { value: string; label: string; }) => {
                        offerType ?  
                            setOfferType({id: offerType.value, label: offerType.label}) : 
                            setOfferType({id: '', label: ''})
                            setAppplyFilters(true)
                    }}
                    isClearable={true}
                    styles={{
                        control: (baseStyles: any) => ({
                        ...baseStyles,
                        width: '250px'
                        }),
                    }}
                />
        </RowWrapper>
        
        <RowWrapper>
            <Checkbox
             id="comment_checkBox"
             checked={!!comment}
             onChange={() => {
                setComment((state) => !state)
                setAppplyFilters(true)
            }}
            >
                {t('schema:orderRating.comment')}
            </Checkbox>
            <Checkbox
             id="pin_checkBox"
             checked={!!pin}
             onChange={() => {
                setPin((state) => !state)
                setAppplyFilters(true)
            }}
            >
                {t('schema:orderRating.pin')}
            </Checkbox>
        </RowWrapper>
        </>
    );
};

const RowWrapper = styled(Row)`
    margin-bottom: 20px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
`;

export default OrderRatingTableFilters;
