import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { QueryLoader, Table, Loader, Button, Row } from 'components';
import { useMutation } from '@apollo/client';
import OrderRatingTableRow from './OrderRatingTableRow';
import useTableOptions from '../../components/Table/useTableOptions';
import { QueryResult } from 'localTypes';
import Paginator, { rowPerPageOptions } from 'components/Table/Paginator';
import { getNodes } from 'services/queryService';
import OrderRatingTableFilters from './OrderRatingTableFilters';
import { ButtonType } from 'components/Button';
import OrderRatingExportModal from './OrderRatingExportModal';

const LIST_ORDER_RATING = loader('./query/listAllOrderRating.gql');
const UPDATE_ORDER_RATING_MUTATION = loader('./query/updateOrderRating.gql');

interface IProps {
    idHolding: string;
}

interface IList {
    list: {
        edges: IEdge[];
        pageInfo: {
            endCursor: any | null;
            startCursor: any | null;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
        };
        totalCount: number;
    };
}

export interface IEdge {
    node: any;
}

const OrderRatingTable = ({ idHolding }: IProps) => {
    const { t } = useTranslation();
    const [updateOrderRating] = useMutation(UPDATE_ORDER_RATING_MUTATION);
    const [isModalOpen, setIsModalOpen] = useState(false);

    let { onPaginationChange, paginationOptions } = useTableOptions<any>();
    
    const [defaultQuerySearch, setDefaultQuerySearch] = useState( [
            { key: 'idHolding', operator: '=', value: idHolding }
        ],
    );

    const queryVariables = {
        querySearch: defaultQuerySearch,
        ...paginationOptions,
    };

    const tableHasData = (data: IList): boolean => !!(data && data.list!);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen)
    }

    return (
        <QueryLoader
            query={LIST_ORDER_RATING}
            fetchPolicy="cache-and-network"
            variables={queryVariables}
            hasData={tableHasData}
        >
            {(queryResultProps: QueryResult<IList>) => {
                const {
                    data,
                    data: { list },
                    loading
                } = queryResultProps;

                const onChangePinCheckbox = (id, pin) => {
                    updateOrderRating({
                        variables: {
                            id,
                            pin
                        },
                    });
                };

                const handleFiltersCallback = (filters: any) => {
                    setDefaultQuerySearch(filters);
                };

                return (
                    <Wrapper id='table'>
                       <>
                         <OrderRatingTableFilters idHolding={idHolding} callback={handleFiltersCallback} />
                         <Row justify="flex-end">
                            <Button display={ButtonType.ACTION} onClick={toggleModal}>{t("app:button.orderRatingReport.button")}</Button>
                            <PaginationWrapper
                              id='pagination'
                            >
                                <Paginator
                                    pageInfo={list.pageInfo}
                                    onPaginate={onPaginationChange}
                                    rowPerPage={(paginationOptions.first || paginationOptions.last) as rowPerPageOptions}
                                />
                            </PaginationWrapper>
                         </Row>
                        {
                          (loading && tableHasData(data)) ? <StyledLoader /> :
                          <TableOverflow>
                            <Table
                                renderLine={(item, index: number) => OrderRatingTableRow(item, index, onChangePinCheckbox)}
                                data={getNodes(list)}
                                headers={getHeaders(t)}
                            />
                          </TableOverflow>
                        }
                         <OrderRatingExportModal 
                            open={isModalOpen}
                            idHolding={idHolding}
                            toggleModal={toggleModal}/>
                        </>
                    </Wrapper>
                );
            }}
        </QueryLoader>
    );
};

const getHeaders = (t: any) => [
    {
        key: 'orderRatingDate',
        displayName: t('schema:orderRating.orderRatingDate'),
    },
    {
        key: 'orderDate',
        displayName: t('schema:orderRating.orderDate'),
    },
    {
        key: 'pos',
        displayName: t('schema:orderRating.pos'),
    },
    {
        key: 'orderId',
        displayName: t('schema:orderRating.orderId'),
    },
    {
        key: 'orderType',
        displayName: t('schema:orderRating.orderType'),
    },
    {
        key: 'orderContent',
        displayName: t('schema:orderRating.orderContent'),
    },
    {
        key: 'offerName',
        displayName: t('schema:orderRating.offerName'),
    },
    {
        key: 'globalNote',
        displayName: t('schema:orderRating.globalNote'),
    },
    {
        key: 'serviceNote',
        displayName: t('schema:orderRating.serviceNote'),
    },
    {
        key: 'presentationNote',
        displayName: t('schema:orderRating.presentationNote'),
    },
    {
        key: 'mealNote',
        displayName: t('schema:orderRating.mealNote'),
    },
    {
        key: 'comment',
        displayName: t('schema:orderRating.comment'),
    },
    {
        key: 'consent',
        displayName: t('schema:orderRating.consent'),
    },
    {
        key: 'contactInfo',
        displayName: t('schema:orderRating.contactInfo'),
    },
    {
        key: 'pin',
        displayName: t('schema:orderRating.pin'),
    },
];

const StyledLoader = styled(Loader)`
    left: 0px;
    top: 50px;
`;

const Wrapper = styled.div`
    position: relative;
`;

const TableOverflow = styled.div`
    overflow-y: auto;
`;

const PaginationWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-left: 20px;
`;

export default OrderRatingTable;
