import { Icon } from 'components';
import React from 'react';
import styled from 'styled-components';
import { appTheme } from 'theme';

export enum InfoType {
    OK = 'OK',
    KO = 'KO',
    DEFAULT = 'DEFAULT',
    WARNING = 'WARNING',
    DISABLE = 'DISABLE',
    BLACK = 'BLACK',
}

interface IProps {
    checked: boolean | null | undefined;
    className?: string;
    onChange: (value: boolean) => void;
    rounded?: boolean;
    disabled?: boolean;
    id?: string;
}
// FIXME should be backed on input[type=checkbox]
export default function CheckBox({ checked, className, onChange, rounded = false, disabled = false, id }: IProps) {
    const handleChange = (checked, disabled) => {
        if (!disabled) {
            return onChange(checked);
        }
    };

    return (
        <Check
            id={id}
            className={className}
            checked={checked}
            onClick={() => handleChange(!checked, disabled)}
            rounded={rounded}
            disabled={disabled}
        >
            {checked === true && <Icon.Check color={appTheme.color.common.white} />}
            {checked === null && <Icon.Less color={appTheme.color.grey[6]} />}
        </Check>
    );
}

interface ICheckProps {
    checked: boolean | null | undefined;
    rounded: boolean;
    disabled: boolean;
}

const Check = styled.span<ICheckProps>`
    display: inline-block;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    overflow: hidden;
    border-radius: ${({ theme, rounded }) => (rounded ? '50%' : `${theme.borderRadius.xs}px`)};

    border-width: ${({ rounded }) => (rounded ? 'thick' : 'none')};
    border-style: ${({ rounded }) => (rounded ? 'solid' : 'none')};
    border-color: ${({ checked }) => (checked ? appTheme.color.common.blue : 'none')};

    background-color: ${({ checked, rounded }) => {
        switch (checked) {
            case true:
                return rounded ? appTheme.color.common.white : appTheme.color.common.blue;
            case false:
                return rounded ? appTheme.color.grey[2] : appTheme.color.grey[1];
            case null:
                return appTheme.color.grey[2];
        }
    }};
    color: ${({ checked, rounded }) => {
        switch (checked) {
            case true:
                return appTheme.color.common.white;
            case false:
                return rounded ? appTheme.color.grey[2] : appTheme.color.grey[1];
            case null:
                return appTheme.color.grey[6];
        }
    }};
`;
