import React from 'react';
import { Button, Title, Icon, Row, TextInputField, Toggle } from 'components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { ButtonType } from 'components/Button';
import { ComponentHeader } from 'pages/Communication/AddOrEditForm/components/ComponentHeader';
import { ExternalLink } from 'types/holdingPos';

interface IProps {
    field: any;
    form: any;
    asRow: boolean;
    disabled: boolean;
}

const ExternalServicesEdit: React.FC<IProps> = React.memo(({ field, form, asRow, disabled }) => {
    const [t] = useTranslation();

    const externalLinks = field?.value?.length ? field.value : [{ title: '', content: '', active: false }];

    const onChange = (value: ExternalLink[]) => {
        form.setFieldValue(field.name, value);
        form.setFieldTouched(field.name, true);
    };

    const onAdd = () => {
        onChange([
            ...externalLinks,
            {
                title: '',
                content: '',
                active: false
            }
        ]);
    };

    const editExternalLink = (index: number, param: string, value?: any) => (inputValue?: any) => {
        onChange(externalLinks.map((link: ExternalLink, i: number) => ((i === index) ? { ...link, [param]: inputValue?.value || value } : link)));
    };

    const ButtonsRow = React.memo(({ link, index }: { link: ExternalLink, index: number }) => (
        <Row>
            <Toggle
                disabled={disabled}
                width={133.33}
                checked={!!link.active}
                onChange={editExternalLink(index, 'active', !link.active)}
                onLabel={t('schema:offer.state.ACTIVE')}
                offLabel={t('schema:offer.state.INACTIVE')}
            />
            <ComponentHeader
                disabled={disabled}
                rank={index}
                hideRank
                componentDescription=''
                onDelete={(rank: number, e: React.MouseEvent<Element, MouseEvent>) => {
                    e.preventDefault();
                    onChange(externalLinks.filter((link: ExternalLink, index: number) => index !== rank));
                }}
                onUpComponent={(rank: number, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.preventDefault();
                    const newExternalLinks = [...externalLinks];
                    newExternalLinks[rank - 1] = externalLinks[rank];
                    newExternalLinks[rank] = externalLinks[rank - 1];
                    onChange(newExternalLinks);
                }}
                onDownComponent={(rank: number, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.preventDefault();
                    const newExternalLinks = [...externalLinks];
                    newExternalLinks[rank + 1] = externalLinks[rank];
                    newExternalLinks[rank] = externalLinks[rank + 1];
                    onChange(newExternalLinks);
                }}
                totalComponents={externalLinks.length}
            />
        </Row>
    ));

    return (
        <Wrapper>
            <Row>
                <Title
                    mode="H3"
                    value={t('page:holding.pos.edit.title.externalServices')}
                    icon={<Icon.Cog />}
                >
                    <Button
                        disabled={disabled}
                        type="button"
                        display={disabled ? ButtonType.DISABLED : ButtonType.SECONDARY}
                        onClick={onAdd}
                        inline
                    >
                        + {t('page:communication.addMarketingCard.addComponent')}
                    </Button>
                </Title>
            </Row>
            {externalLinks?.map((link: ExternalLink, index: number) => (
                <ExternalLinkContainer key={index} asRow={asRow}>
                    <Row align="flex-start">
                        <FieldWrapper asRow={false}>
                            <Field
                                disabled={disabled}
                                key={`title_${index}`}
                                id={`title_${index}`}
                                label={index === 0 ? t('schema:pos.buttonTitle') : ''}
                                name={`title_${index}`}
                                component={TextInputField}
                                maxLength={30}
                                value={link?.title}
                                onChange={editExternalLink(index, 'title')}
                            />
                            {!asRow && <ButtonsRow link={link} index={index} />}
                        </FieldWrapper>
                        <FieldWrapper asRow={asRow}>
                            <Field
                                disabled={disabled}
                                key={`content_${index}`}
                                id={`content_${index}`}
                                label={index === 0 ? 'Url' : ''}
                                name={`content_${index}`}
                                component={TextInputField}
                                value={link.content}
                                onChange={editExternalLink(index, 'content')}
                            />
                        </FieldWrapper>
                        {asRow && <ButtonsRowContainer><ButtonsRow link={link} index={index} /></ButtonsRowContainer>}
                    </Row>
                </ExternalLinkContainer>
            ))}
        </Wrapper>
    );
});

const Wrapper = styled.div`
    width: 100%;
`;

const FieldWrapper = styled.div<{ asRow?: boolean }>`
    width: 100%;
    margin-right: ${({ asRow }) => asRow === undefined ? 0 : 26}px;
    min-width: 150px;
`;

const ButtonsRowContainer = styled.div`
    margin-bottom: 30px;
    display: flex;
    align-self: flex-end;
`;

const ExternalLinkContainer = styled.div<{ asRow?: boolean }>`
    margin-bottom: ${({ asRow }) => asRow ? -20 : 30}px;
`;

export default ExternalServicesEdit;
