import { Duration } from 'luxon';

import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import { loader } from 'graphql.macro';
import { Trans, useTranslation } from 'react-i18next';

import { Field, Form, Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
    InfoLabel,
    Panel,
    NumberInput,
    NumberInputField,
    TimeRangeInputField,
    SubmitButton,
    ToggleSwitchField,
    Icon,
    Row,
} from 'components';
import { PanelContent, PanelFooter } from 'components/Panel';
import { isRangeStartBeforeEnd, isBookingWithdrawRangeValid } from 'services/form/offerValidator';
import { areRangesEqual } from 'services/validationService';
import { appTheme } from 'theme';
import { updateBookingOfferVariables } from 'types/updateBookingOffer';

const UPDATE_BOOKING_OFFER_MUTATION = loader('./updateBookingOffer.gql');

const FORM_ID = 'offer_edit_form';

interface IProps {
    isOpen: boolean;
    closeModal: () => void;
    offer: any;
    offerTemplate: any;
}

interface IFormValues {
    published: boolean;
    maxOrdersPerSlot: number | null;
    orderRange: string;
    withdrawRange: string;
}

function BookingOfferParameterFormPanel({
    isOpen,
    closeModal,
    offer: { id, published, maxOrdersPerSlot, orderRange, withdrawRange, acceptedOrdersCount, withdrawSlotDuration },
    offerTemplate,
}: IProps & RouteComponentProps) {
    const { t } = useTranslation();
    const duration = Duration.fromISO(withdrawSlotDuration).as('minutes');
    const initialWithdrawRange = withdrawRange;
    const initialMaxOrdersPerSlot = maxOrdersPerSlot;
    const validate = () => ({ withdrawRange, maxOrdersPerSlot }: IFormValues) => {
        let errors = {} as any;
        if (acceptedOrdersCount !== 0) {
            if (!isBookingWithdrawRangeValid(withdrawRange, initialWithdrawRange)) {
                errors.withdrawRange = t('page:bookingService.admin.timeRangeInvalid');
            }
            if (maxOrdersPerSlot && maxOrdersPerSlot > 0 && maxOrdersPerSlot < initialMaxOrdersPerSlot) {
                errors.maxOrdersPerSlot = t('page:bookingService.admin.maxOrdersPerSlotInvalid');
            }
        }

        return errors;
    };
    return (
        <Panel
            testID='booking-offer-parameter-form-panel'
            onClose={closeModal}
            open={isOpen}
            title={t('page:bookingService.daily-offers.bookingOfferParameterPanel')}
        >
            <Mutation mutation={UPDATE_BOOKING_OFFER_MUTATION}>
                {(updateBookingOffer: (param: Record<'variables', updateBookingOfferVariables>) => Promise<any>) => {
                    return (
                        <Formik
                            validate={validate()}
                            validationSchema={getOfferSchema(t)}
                            initialValues={{
                                published,
                                maxOrdersPerSlot,
                                orderRange,
                                withdrawRange,
                            }}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                updateBookingOffer({
                                    variables: {
                                        ...values,
                                        id: id,
                                        maxOrdersPerSlot: values.maxOrdersPerSlot || 0,
                                        published: values.published,
                                    },
                                })
                                    .then(
                                        ({
                                            errors,
                                            data: {
                                                updateBookingOffer: {
                                                    published,
                                                    maxOrdersPerSlot,
                                                    orderRange,
                                                    withdrawRange,
                                                },
                                            },
                                        }) => {
                                            if (errors) {
                                                throw errors;
                                            }
                                            setSubmitting(false);
                                            // @ts-ignore
                                            resetForm({ published, maxOrdersPerSlot, orderRange, withdrawRange });
                                            closeModal();
                                        }
                                    )
                                    .catch((error: any) => {
                                        console.log(error);
                                        setSubmitting(false);
                                    });
                            }}
                        >
                            {({ errors, isSubmitting, values, initialValues }) => {
                                const isDisabled = !values.published;
                                const formHasNoChange =
                                    values.published === initialValues.published &&
                                    values.maxOrdersPerSlot === initialValues.maxOrdersPerSlot &&
                                    areRangesEqual(values.withdrawRange, initialValues.withdrawRange);
                                return (
                                    <>
                                        <PanelContent>
                                            <WarningWrapper>
                                                <Row justify="center">
                                                    <WarningIconWrapper>
                                                        <Icon.Warning color={appTheme.color.common.blue} />
                                                    </WarningIconWrapper>
                                                    <WarningTextWrapper data-test='booking-offer-parameter-form-warning-message'>
                                                        {t('page:bookingService.daily-offers.warningMessage')}
                                                    </WarningTextWrapper>
                                                </Row>
                                            </WarningWrapper>
                                            { 
                                            // @ts-ignore
                                            <Form id={FORM_ID}>
                                                <InfoLabel
                                                    testID='booking-offer-parameter-form-description'
                                                    name={
                                                        isDisabled ? (
                                                            <>
                                                                <strong>
                                                                    {t(
                                                                        'page:bookingService.admin.toggleStatusDescriptionWarningBold'
                                                                    )}{' '}
                                                                </strong>
                                                                {t('page:bookingService.admin.toggleStatusDescription')}{' '}
                                                                <strong>
                                                                    {t(
                                                                        'page:bookingService.admin.inactiveDescriptionBold'
                                                                    )}
                                                                </strong>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {t('page:bookingService.admin.toggleStatusDescription')}{' '}
                                                                <strong>
                                                                    {t(
                                                                        'page:bookingService.admin.activeDescriptionBold'
                                                                    )}
                                                                </strong>
                                                            </>
                                                        )
                                                    }
                                                    fontColor={
                                                        isDisabled ? appTheme.color.common.red : appTheme.color.grey[6]
                                                    }
                                                    capitalize
                                                />
                                                <Field
                                                    name="published"
                                                    component={ToggleSwitchField}
                                                    sideLabel={true}
                                                    onLabel={t('app:state.activeFem')}
                                                    offLabel={t('app:state.inactiveFem')}
                                                    testID='booking-offer-parameter-form-published'
                                                />

                                                <InfoLabel
                                                    testID='booking-offer-parameter-form-slot-duration'
                                                    name={
                                                        <Trans
                                                            i18nKey="page:bookingService.admin.slotDuration"
                                                            components={[<strong></strong>]}
                                                        />
                                                    }
                                                />
                                                <StyledNumberInput disabled onChange={() => {}} value={duration} testID='booking-offer-parameter-form-slot-duration' />
                                                <Field
                                                    label={
                                                        <Trans
                                                            i18nKey="page:bookingService.admin.bookableSlotRange"
                                                            components={[<strong></strong>]}
                                                        />
                                                    }
                                                    testID='booking-offer-parameter-form-withdraw-range'
                                                    name="withdrawRange"
                                                    component={TimeRangeInputField}
                                                    step={duration}
                                                    disabled={isDisabled}
                                                />
                                                <Field
                                                    label={
                                                        <Trans
                                                            i18nKey="page:bookingService.admin.maxGuestsPerSlot"
                                                            components={[<strong></strong>]}
                                                        />
                                                    }
                                                    testID='booking-offer-parameter-form-max-guests-per-slot'
                                                    name="maxOrdersPerSlot"
                                                    component={NumberInputField}
                                                    disabled={isDisabled}
                                                />
                                            </Form>}
                                        </PanelContent>
                                        <PanelFooter>
                                            <SubmitButton
                                                testID='booking-offer-parameter-form-submit'
                                                form={FORM_ID}
                                                formHasNoChange={formHasNoChange}
                                                disabled={
                                                    formHasNoChange ||
                                                    Object.entries(errors).length !== 0 ||
                                                    isSubmitting
                                                }
                                            />
                                        </PanelFooter>
                                    </>
                                );
                            }}
                        </Formik>
                    );
                }}
            </Mutation>
        </Panel>
    );
}

const StyledNumberInput = styled(NumberInput)`
    margin-bottom ${({ theme }) => theme.spacing.m}px;
`;

const WarningWrapper = styled.div`
    height: 81px;
    width: 100%;
    padding: 20px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.color.grey[0]};
    margin-bottom ${({ theme }) => theme.spacing.m}px;
`;

const WarningIconWrapper = styled.div`
    margin: 0 10px;
    display: flex;
    align-content: center;
`;

const WarningTextWrapper = styled.div`
    height: 44px;
    width: 100%;
    color: ${(props) => props.theme.color.grey[6]};
    font-family: 'Open Sans';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
`;

const getOfferSchema = (t: any) =>
    Yup.object().shape({
        maxOrdersPerSlot: Yup.number().min(1, t('app:error.invalid')).required(t('app:error.required')),
        withdrawRange: Yup.string()
            .required(t('app:error.required'))
            // @ts-ignore
            .test('is start before end', t('page:bookingService.admin.timeRangeInvalid'), isRangeStartBeforeEnd),
    });

export default withRouter(BookingOfferParameterFormPanel);
