import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import { BaseInput, IInputProps } from './common';

export enum CurrencyType {
    EURO = '€',
}

interface IProps extends IInputProps<string> {
    currency?: CurrencyType;
}

export default function PriceInput({
    value,
    onChange,
    onBlur,
    name = 'price',
    className,
    hasError,
    currency = CurrencyType.EURO,
    disabled,
    testID
}: IProps) {
    const exceptThisSymbols = ['e', 'E', '+', '-'];
    function onValueChange(event: ChangeEvent<HTMLInputElement>) {
        const { value } = event.currentTarget;
        onChange(value);
    }
    return (
        <Wrapper data-test={testID}>
            <Input
                disabled={disabled}
                hasError={hasError}
                className={className}
                value={value}
                type="number"
                name={name}
                id={name}
                onChange={onValueChange}
                onBlur={onBlur}
                min={0}
                step={0.01}
                onKeyDown={(e) => (exceptThisSymbols.includes(e.key) || e.key === '+') && e.preventDefault()}
            />
            <Currency htmlFor={name} hasError={hasError} disabled={disabled}>
                {currency}
            </Currency>
        </Wrapper>
    );
}

const Wrapper = styled.span`
    display: flex;
    align-items: center;
`;

interface CurrencyProps {
    readonly hasError?: boolean;
    readonly disabled?: boolean;
}

const Currency = styled.label<CurrencyProps>`
    position: relative;
    right: 1px;
    width: 30px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.color.input.backgroundColorDisabled};
    border-radius: ${({ theme }) => `0 ${theme.borderRadius.xs}px ${theme.borderRadius.xs}px 0`};
    font-size: 18px;
    color: ${({ theme, hasError }) => (hasError ? theme.color.input.textColorError : theme.color.input.textColor)};
    font-weight: ${({ theme }) => theme.typography.fontWeight.lighter};
    text-align: center;
    transform: translate(-31px);
    transition: color ${({ theme }) => theme.transition.duration[1]}ms;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
`;

const Input = styled(BaseInput)`
    width: 120px;
    padding-right: ${({ theme }) => theme.spacing.m}px;
    font-family: ${({ theme }) => theme.typography.fontFamilySecondary};
    font-weight: ${({ theme }) => theme.typography.fontWeight.light};
`;
