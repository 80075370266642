
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

export enum Languages {
  FR = 'fr',
  EN = 'en'
}

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: process.env.NODE_ENV === 'development',
        lng: Languages.FR,
        fallbackLng: Languages.EN,
        ns: ['app', 'page', 'schema', 'error', 'component'],
        defaultNS: 'page',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18next;

export const changeLanguage = async (language: Languages) => {
  await i18next.changeLanguage(language);
}
export const getSelectedLanguage = (): Languages => {
  return i18next.language as Languages;
}
