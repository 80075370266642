import { EmployeeRole, Role } from "types/globalTypes";

export const computeUserRoles = (user) => {
    const roles: Role[] = [];

    if (user.role === EmployeeRole.Marketer) {
        roles.push(Role.Marketer);
    } else if (user.role === EmployeeRole.ChecklistAdmin){
        roles.push(Role.ChecklistAdmin);
    } else {
        roles.push(Role.Employee);
    }

    if (user.role === EmployeeRole.Admin) {
        roles.push(Role.Admin);
    }
    if (user.role === EmployeeRole.ManagerAdmin) {
        roles.push(Role.ManagerAdmin);
    }

    return roles;
}

export const isUserHasRole = (user, roles) => {
    const userRoles = computeUserRoles(user);
    return roles.some(role => userRoles.includes(role));
}