import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import styled from 'styled-components';
import { loader } from 'graphql.macro';
import * as Yup from 'yup';

import { TableWithOptions, Button, TextInput, Table, ErrorMessage, Loader } from 'components';
import { ButtonType } from 'components/Button';
import {
    listUsers_list_edges_node_User as UserEdges,
    listUsers_list_edges_node_User_guests_edges as GuestsEdges,
} from 'types/listUsers';
import { updateUserEmailVariables } from 'types/updateUserEmail';
import ApolloErrors from 'services/apollo/errors';
import { ErrorResponse } from '@apollo/client/link/error';

const USERS_LIST_QUERY = loader('./query/listUsers.gql');

enum ERRORS {
    EMAIL_FORMAT = 'error:email.FORMAT',
    EMAIL_ALREADY_TAKEN = 'error:email.ALREADY_TAKEN',
    EXTERNAL_API_ERROR = 'error:message.EXTERNAL_API_ERROR',
}
type ErrorType = keyof typeof ERRORS;

const getHeaders = (t: any) =>
    ['name', 'email'].map(key => ({ key, displayName: t(`schema:user.${key}`) }));
const getSublineHeaders = (t: any) => [
    {
        key: 'name',
        displayName: t('schema:holding.name'),
    },
    {
        key: 'serialNumber',
        displayName: t('schema:guest.serialNumber'),
    },
];

const renderLine = (item: UserEdges) => <User key={item.id} item={item} />;

const renderSubline = (guest: GuestsEdges) => (
    <tr key={guest.node.id}>
        <td>{guest.node.holding.name}</td>
        <td>{guest.node.supportSerialNumber}</td>
    </tr>
);

const User = ({ item: { id, firstName, lastName, email, guests } }: { item: UserEdges }) => {
    const [editing, setEditing] = useState(false);
    const [newEmail, setEmail] = useState(email);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>('');
    const { t } = useTranslation();
    const isEmailUpdated = () => !newEmail || email !== newEmail;

    const loadingView = (
        <td colSpan={2} style={{ width: '31%' }}>
            <LoaderSized />
        </td>
    );

    const idleButtons = (
        <>
            <TdRight style={{ width: '18%' }}></TdRight>
            <TdRight style={{ width: '13%' }}>
                <Button inline onClick={() => setEditing(true)}>
                    {t('app:button.edit')}
                </Button>
            </TdRight>
        </>
    );

    const editingButtons = (
        <>
            <TdRight style={{ width: '18%' }}>
                <Mutation mutation={loader('./query/updateUserEmail.gql')}>
                    {(updateUserEmail: (param: Record<'variables', updateUserEmailVariables>) => Promise<any>) => (
                        <Button
                            inline
                            display={ButtonType.VALIDATION}
                            disabled={!isEmailUpdated()}
                            onClick={async () => {
                                if (!isEmailUpdated()) {
                                    return;
                                }

                                setError('');

                                if (
                                    !(await Yup.object()
                                        .shape({
                                            newEmail: Yup.string()
                                                .email()
                                                .required(),
                                        })
                                        .isValid({ newEmail }))
                                ) {
                                    setError(t(ERRORS.EMAIL_FORMAT));

                                    return;
                                }

                                setLoading(true);

                                try {
                                    const response = await updateUserEmail({
                                        variables: {
                                            input: {
                                                idGuest: id,
                                                email: newEmail || '',
                                            },
                                        },
                                    });
                                    setEmail(response.data.updateUserEmail.email);
                                } catch (err: any) {
                                    const errors: string[] = ApolloErrors.errorsMessages(err as ErrorResponse);
                                    const errorKey: ErrorType = (errors[0] || '') as ErrorType;

                                    setLoading(false);
                                    setError(t(ERRORS[errorKey] || err.message));

                                    return;
                                }

                                setEditing(false);
                                setLoading(false);
                            }}
                        >
                            {t('app:button.save')}
                        </Button>
                    )}
                </Mutation>
            </TdRight>
            <TdRight style={{ width: '13%' }}>
                <Button
                    inline
                    display={ButtonType.SECONDARY}
                    onClick={() => {
                        setError('');
                        setEmail(email);
                        setEditing(false);
                    }}
                >
                    {t('app:button.cancel')}
                </Button>
            </TdRight>
        </>
    );

    return (
        <>
            <tr>
                <td style={{ width: '22%' }}>{`${lastName} ${firstName}`}</td>
                <td>
                    <TextInput
                        autoFocus={true}
                        disabled={loading || !editing}
                        value={newEmail || undefined}
                        onChange={value => {
                            setError('');
                            setEmail(value);
                        }}
                    />
                </td>
                {loading ? loadingView : editing ? editingButtons : idleButtons}
            </tr>
            {error ? (
                <tr>
                    <td colSpan={4}>
                        <ErrorMessagePadded>{error}</ErrorMessagePadded>
                    </td>
                </tr>
            ) : null}
            <tr>
                <td colSpan={4}>
                    <DivSubline>
                        <Table<GuestsEdges>
                            headers={getSublineHeaders(t)}
                            renderLine={renderSubline}
                            data={guests.edges}
                        />
                    </DivSubline>
                </td>
            </tr>
        </>
    );
};

const UsersTable = () => {
    const { t } = useTranslation();

    return (
        <TableWithOptions
            headers={getHeaders(t)}
            renderLine={renderLine}
            fetchPolicy="cache-and-network"
            query={USERS_LIST_QUERY}
            searchPlaceholder={t('app:placeholder.search.user')}
            parentPage='administration-user'
        />
    );
};

const TdRight = styled.td`
    text-align: right;
`;

const DivSubline = styled.div`
    margin: 0 0 0 20px;
    padding: 0 20px;
    background: ${({ theme }) => theme.color.grey[0]};
`;

const LoaderSized = styled(Loader)`
    position: relative;
    top: 10px;
`;

const ErrorMessagePadded = styled(ErrorMessage)`
    margin: 0 0 20px;
`;

export default withRouter(UsersTable);
