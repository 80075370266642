import React, { FunctionComponent, useState } from 'react';
import { FieldProps } from 'formik';
import get from 'lodash/get';
import { Select } from '../../../components';
import { ISelectOption } from '../Select';
import { fuzzyMatch } from 'services/searchService';
import FieldContainer from './FieldContainer';

interface IProps {
    id?: string;
    data: ISelectOption[];
    className?: string;
    label?: string;
    disabled?: boolean;
    onChange?: (value: any) => void;
    onSearch?: (search?: string) => void;
    fullWidth?: boolean;
    width?: number;
    color?: string;
    resultSize?: number;
    testID?: string;
}
const SelectField: FunctionComponent<FieldProps & IProps> = (props) => {
    const { field, form, data, onChange: onChangeProps, disabled, onSearch, width, color, id, resultSize } = props;
    const [filter, setFilter] = useState('');
    const onChange = (value: ISelectOption) => {
        form.setFieldValue(field.name, value.id);
        form.setFieldTouched(field.name);
        onChangeProps && onChangeProps(value);
    };
    return (
        <FieldContainer {...props}>
            <Select
                {...props}
                id={id}
                disabled={disabled}
                data={data.filter(({ label }) => fuzzyMatch(filter, label))}
                selected={data.find(({ id }) => field.value !== undefined && id === field.value)}
                onChange={(o: ISelectOption) => onChange(o)}
                onSearch={(search: string | undefined) => {
                    onSearch && onSearch(search);
                    setFilter(search || '');
                }}
                hasError={!!get(form.errors, field.name) && !!get(form.touched, field.name)}
                width={width}
                color={color}
                resultSize={resultSize}
            />
        </FieldContainer>
    );
};

export default SelectField;
