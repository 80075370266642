import { Select, Loader } from 'components';
import { loader } from 'graphql.macro';
import { OfferTemplateType } from 'localTypes';
import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getNodes } from 'services/queryService';
import { setItem, getItem, LocalStorageKey } from 'services/persistentData';
import {
    getPOSWithOfferTemplate_get_Pos,
    getPOSWithOfferTemplate_list_edges_node_Pos,
} from 'types/getPOSWithOfferTemplate';
import { searchPOS_get_Pos, searchPOS_list_pointOfSales_pos_Pos } from 'types/searchPOS';

const SELECT_POS_QUERY = loader('../../query/getPOSWithOfferTemplate.gql');
const SELECT_POS = loader('../../feature/SelectPOS/searchPOS.gql');

interface IProps {
    offerTemplateType?: OfferTemplateType;
    match: {
        params: { idHolding?: string; idPos?: string };
    };
}
const SelectLayoutPOS = ({
    history,
    offerTemplateType,
    match: {
        params: { idHolding, idPos },
    },
}: IProps & RouteComponentProps) => {
    const { t } = useTranslation();

    let selectedPosId: string = idPos || '';

    function onChange(selected: { id: string; label: string }): void {
        const { pathname } = window.location;
        if (!idPos) {
            history.replace(`${pathname}/${selected.id}`);
        } else {
            history.replace(pathname.replace(idPos!, selected.id));

            const persistedIdPos = getItem(LocalStorageKey.POS_ID);
            if (selected.id !== persistedIdPos) {
                setItem(LocalStorageKey.POS_ID, selected.id);
            }
        }
    }
    const placeHolderText = t(`app:select-pos`);

    return offerTemplateType === OfferTemplateType.BookingService ? (
      <BookingSelect
        onChange={onChange}
        selectedPosId={selectedPosId}
        idHolding={idHolding}
        placeHolder={placeHolderText}
      />
  ) : (
      <CcSelect
        onChange={onChange}
        selectedPosId={selectedPosId}
        idHolding={idHolding}
        placeHolder={placeHolderText}
        offerTemplateType={offerTemplateType}
      />
  );
};

const BookingSelect = ({onChange, selectedPosId, idHolding, placeHolder} : { onChange: any, selectedPosId?: string, idHolding?: string, placeHolder: string }) => {
  const { loading, error, data, refetch } = useQuery(SELECT_POS, {
    context: {
      debounceKey: 'pos'
    },
    variables: {
      search: '%',
      idHolding,
      idPos: selectedPosId,
      useId: true,
    },
    fetchPolicy: "cache-first"
  });

  return loading ? 
    <Loader /> : ( 
    <Select
          testID={'select-POS-input-booking'}
          onChange={onChange}
          placeholder={placeHolder}
          loading={loading}
          selected={data && (data.get as searchPOS_get_Pos)}
          data={
              idHolding && !loading && !error && data
                  ? data?.list?.pointOfSales?.map((d) => d.pos as searchPOS_list_pointOfSales_pos_Pos)
                  : []
          }
          onSearch={(search: any) => refetch({ search: `%${search}%`, idHolding })}
      />
  )
}

const CcSelect = ({onChange, selectedPosId, idHolding, placeHolder, offerTemplateType} : { 
    onChange: any, 
    selectedPosId?: string, 
    idHolding?: string, 
    placeHolder: string,
    offerTemplateType?: string
  }) => {
  const { loading, error, data, refetch } = useQuery(SELECT_POS_QUERY, {
    context: {
      debounceKey: 'pos'
    },
    variables: {
      search: '%',
      idHolding,
      idPos: selectedPosId,
      offerTemplateType: offerTemplateType,
      useId: !!selectedPosId,
    },
    fetchPolicy: "cache-first"
  });
  return loading ? 
    <Loader /> : ( 
    <Select
        testID={'select-POS-input-cc'}
        onChange={onChange}
        placeholder={placeHolder}
        loading={loading}
        selected={data && data.get as getPOSWithOfferTemplate_get_Pos}
        data={
            !loading && !error && data
                ? (getNodes(data?.list) as getPOSWithOfferTemplate_list_edges_node_Pos[])
                : []
        }
        onSearch={(search) => refetch({ search: `%${search}%`, idHolding })}
    />
  )
}

export default withRouter(SelectLayoutPOS);
