import React, { FunctionComponent } from 'react';
import { FieldProps } from 'formik';
import { FullRichTextInput } from 'components';
import FieldContainer from './FieldContainer';
import { OptionToolbar } from '../FullRichTextInput';
interface IProps {
    id?: string;
    className?: string;
    label?: string;
    disabled?: boolean;
    value: string;
    optionsToolbar?: OptionToolbar[];
    minHeight?: number;
    lang? : string;
}

const FullRichTextInputField: FunctionComponent<FieldProps & IProps> = (props) => {
    const { id, field, form, disabled, className, optionsToolbar, minHeight, lang } = props;
    const onChange = (value) => {
        // @todo: check why onChange is call unnecessarily
        if (value === field.value) return;
        form.setFieldValue(field.name, value);
        form.setFieldTouched(field.name);
    };
    return (
        <FieldContainer {...props}>
            <FullRichTextInput
                {...field}
                id={id}
                onChange={onChange}
                className={className}
                hasError={!!form.errors[field.name] && !!form.touched[field.name]}
                disabled={disabled}
                optionsToolbar={optionsToolbar}
                minHeight={minHeight}
                lang={lang}
            />
        </FieldContainer>
    );
};

export default FullRichTextInputField;
