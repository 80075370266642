import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { InfoLabel, Tags, Icon } from 'components';
import { appTheme } from 'theme';
import { Wrapper, SubWrapper, VerticalLine } from './StepOne';
import { getProductFamilyOptions } from 'services/offerTemplateService';
import { serializeIQuantityRulesIntoFormValue } from 'services/mealHeartService';
import { Field, FormikErrors } from 'formik';
import { RangeInputField } from 'components/Inputs/FormikFields';
import { MealHeartQuantityRule } from '.';
import { TagTheme } from 'components/Tag';

const AdminRule = loader('../query/getAdminRule.gql');

interface IStepProps {
    values?: any;
    validate?: Function;
    setFieldValue?: Function;
    validationSchema?: Yup.ObjectSchema<any>;
    setStepSchema: Function;
    isModeEdit: boolean;
    errors?: any;
    setAdminLimitValues: (value: Partial<MealHeartQuantityRule>) => void;
    validateForm?: () => Promise<FormikErrors<any>>;
}
const StepThree = ({
    values,
    setFieldValue,
    setStepSchema,
    isModeEdit,
    errors,
    setAdminLimitValues,
    validateForm,
    validationSchema
}: IStepProps) => {
    const { t } = useTranslation();
    const { data } = useQuery(AdminRule);

    useEffect(() => {
        const yupMinMax = Yup.object({
            min: Yup.number()
                .integer(t('page:admin.cnc.form.error.invalidUnit') || undefined)
                .required()
                .typeError(t('page:admin.cnc.required') || ''),
            max: Yup.number()
                .integer(t('page:admin.cnc.form.error.invalidUnit')  || undefined)
                .required()
                .typeError(t('page:admin.cnc.required') || ''),
        });

        setStepSchema(
            Yup.object().shape({
                mealHeartQuantityRule: Yup.object({
                    mealQuantity: yupMinMax,
                    orderPerDay: yupMinMax,
                    productsPerOrder: yupMinMax,
                    sameProductPerOrder: yupMinMax,
                }),
            })
        );
    }, [setStepSchema, t]);

    useEffect(() => {
      if(validationSchema && validateForm) {
        validateForm();
      }
      
    }, [validationSchema, validateForm, values.loadedAdminRule]);

    const getAdminRule = useCallback(() => {
        if (values.loadedAdminRule || !data) {
            return;
        }

        const { __typename, ...rest } = data.getDefaultHeartMealRule;
        const limitMealHeartQuantityRule = serializeIQuantityRulesIntoFormValue(rest.quantityRules);

        if (setFieldValue) {
            if(!isModeEdit){
              setFieldValue('mealHeartRule', rest);
              const defaultMealHeartQuantityRule = serializeIQuantityRulesIntoFormValue(rest.defaultQuantityRules);
              setFieldValue('mealHeartQuantityRule', defaultMealHeartQuantityRule);
            }
            setFieldValue('loadedAdminRule', true);
        }

        setAdminLimitValues(limitMealHeartQuantityRule);
    }, [data, isModeEdit, setAdminLimitValues, setFieldValue, values.loadedAdminRule]);

    useEffect(() => {
        getAdminRule();
    }, [values.loadedAdminRule, data, isModeEdit, getAdminRule]);

    const handleTagsOnChange = (selectedFamilies: string[]) => {
        setFieldValue && setFieldValue('mealHeartRule', { ...values.mealHeartRule, selectedFamilies });
    };

    return (
        <Wrapper>
            <SubWrapper>
                <InfoLabel inline fontWeight="bold" name={t('page:admin.cnc.productFamilies')} />
                <StyledTags
                    options={getProductFamilyOptions(values.mealHeartRule.allowedFamilies)}
                    value={values.mealHeartRule.selectedFamilies}
                    onChange={handleTagsOnChange}
                    tagTheme={TagTheme.darker}
                />
                <Field
                    label={t('page:admin.cnc.numberOfHeartMeal')}
                    labelStart={t('page:admin.cnc.min')}
                    labelEnd={t('page:admin.cnc.max')}
                    width={60}
                    fontWeight="bold"
                    name="mealHeartQuantityRule.mealQuantity"
                    overrideShowRequiredAsterisk
                    validateFormOnChange
                    useDefaultErrorMessage={false}
                    component={RangeInputField}
                    disabled={values.mealHeartRule.selectedFamilies.length === 0}
                />
            </SubWrapper>
            <VerticalLine />
            <SubWrapper>
                <Field
                    label={t('page:admin.cnc.orderPersonDay')}
                    labelEnd={t('page:admin.cnc.max')}
                    width={60}
                    fontWeight="bold"
                    name="mealHeartQuantityRule.orderPerDay"
                    overrideShowRequiredAsterisk
                    validateFormOnChange
                    useDefaultErrorMessage={false}
                    component={RangeInputField}
                />
                <Field
                    label={t('page:admin.cnc.sameProductPerOrder')}
                    labelEnd={t('page:admin.cnc.max')}
                    width={60}
                    fontWeight="bold"
                    name="mealHeartQuantityRule.sameProductPerOrder"
                    overrideShowRequiredAsterisk
                    validateFormOnChange
                    useDefaultErrorMessage={false}
                    component={RangeInputField}
                />
                <Field
                    label={t('page:admin.cnc.productsPerOrder')}
                    labelEnd={t('page:admin.cnc.max')}
                    width={60}
                    fontWeight="bold"
                    name="mealHeartQuantityRule.productsPerOrder"
                    overrideShowRequiredAsterisk
                    validateFormOnChange
                    useDefaultErrorMessage={false}
                    component={RangeInputField}
                />
                <FlexRow>
                    <IconWrapper>
                        <Icon.Info color={appTheme.color.common.mediumOrange} width={30} />
                    </IconWrapper>
                    <InfoLabel inline fontSize="XS" fontWeight="bold" name={t('page:admin.cnc.infoMealHeartTitle')} />
                </FlexRow>
                <InfoTitle>{t('page:admin.cnc.infoMealHeart')}</InfoTitle>
                <InfoText>{values.mealHeartRule.information}</InfoText>
            </SubWrapper>
        </Wrapper>
    );
};

const IconWrapper = styled.div`
    margin-bottom: -${({ theme }) => theme.spacing.xs}px;
`;

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const InfoTitle = styled.span`
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-weight: ${({ theme }) => theme.typography.fontWeight.bolder};
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    color: ${({ theme }) => theme.color.grey[6]};
    letter-spacing: 0;
    margin-top: ${({ theme }) => theme.spacing.xs}px;
`;

const InfoText = styled.span`
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-weight: ${({ theme }) => theme.typography.fontWeight.light};
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    color: ${({ theme }) => theme.color.grey[6]};
    letter-spacing: 0;
    white-space: pre-line;
`;

const StyledTags = styled(Tags)`
    margin-bottom: ${({ theme }) => theme.spacing.s}px;
    margin-top: ${({ theme }) => theme.spacing.s}px;
`;

export default StepThree;
