import {
  Button,
  Icon,
  QueryLoader,
  Struct,
  Title,
  SubmitButton,
  ToggleSwitchField,
  SelectField,
} from 'components';
import { ButtonType } from 'components/Button';
import { Field, Form, Formik } from 'formik';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import get from 'lodash/get';
import React, { useState } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { updateHoldingSettingVariables } from 'types/updateHoldingSetting';
import * as Yup from 'yup';
import { holdingSetting } from 'types/holdingSettings';
import { PosSortingStrategy } from 'types/globalTypes';
import posSortingStrategyMapper from '../../../mappers/posSortingStrategyMapper';

interface IProps extends RouteComponentProps<{ idHolding: string }> { }


const getSchema = () =>
  Yup.object().shape({
    productTransparencyPreferences: Yup.object().shape({
      showLabels: Yup.boolean(),
      showIngredients: Yup.boolean(),
      showAllergens: Yup.boolean(),
      showNutritionalComposition: Yup.boolean(),
      showPrice: Yup.boolean(),
      showDescription: Yup.boolean(),
      showPhoto: Yup.boolean(),
    }),
    offersProductTransparencyPreferences: Yup.object().shape({
      showLabels: Yup.boolean(),
      showIngredients: Yup.boolean(),
      showAllergens: Yup.boolean(),
      showNutritionalComposition: Yup.boolean(),
      showPrice: Yup.boolean(),
      showDescription: Yup.boolean(),
      showPhoto: Yup.boolean(),
    }),
    posSortingStrategy: Yup.string().oneOf(Object.values(PosSortingStrategy)),
  });

const Personalization = ({
  match: {
    params: { idHolding },
  },
}: IProps) => {
  const [editable, setEditable] = useState(false);
  const [t] = useTranslation();

  return (
      <QueryLoader
          hasData={(data: holdingSetting): boolean => !!(data && data.holding)}
          query={loader('../query/getHoldingSettings.gql')}
          variables={{ id: idHolding }}
      >
        {({ data }: QueryResult<holdingSetting>) => {
          const holding = data.holding;

          const initialValues = {
            productTransparencyPreferences: {
              showLabels: get(holding, 'settings.productTransparencyPreferences.showLabels', false),
              showIngredients: get(holding, 'settings.productTransparencyPreferences.showIngredients', false),
              showAllergens: get(holding, 'settings.productTransparencyPreferences.showAllergens', false),
              showNutriScore: get(holding, 'settings.productTransparencyPreferences.showNutriScore', false),
              showEcoScore: get(holding, 'settings.productTransparencyPreferences.showEcoScore', false),
              showCalories: get(holding, 'settings.productTransparencyPreferences.showCalories', false),
              showNutritionalData: get(holding, 'settings.productTransparencyPreferences.showNutritionalData', false),
              showPhoto: get(holding, 'settings.productTransparencyPreferences.showPhoto', false),
              showPrice: get(holding, 'settings.productTransparencyPreferences.showPrice', false),
              showDescription: get(holding, 'settings.productTransparencyPreferences.showDescription', false),
            },
            offersProductTransparencyPreferences: {
              showLabels: get(holding, 'settings.offersProductTransparencyPreferences.showLabels', false),
              showIngredients: get(holding, 'settings.offersProductTransparencyPreferences.showIngredients', false),
              showAllergens: get(holding, 'settings.offersProductTransparencyPreferences.showAllergens', false),
              showNutriScore: get(holding, 'settings.offersProductTransparencyPreferences.showNutriScore', false),
              showEcoScore: get(holding, 'settings.offersProductTransparencyPreferences.showEcoScore', false),
              showCalories: get(holding, 'settings.offersProductTransparencyPreferences.showCalories', false),
              showNutritionalData: get(holding, 'settings.offersProductTransparencyPreferences.showNutritionalData', false),
              showPhoto: get(holding, 'settings.offersProductTransparencyPreferences.showPhoto', false),
              showPrice: get(holding, 'settings.offersProductTransparencyPreferences.showPrice', false),
              showDescription: get(holding, 'settings.offersProductTransparencyPreferences.showDescription', false),
            },
            posSortingStrategy: get(holding, 'settings.posSortingStrategy', PosSortingStrategy.Default),
          };

          return (
              <Mutation mutation={loader('../query/updateHoldingSetting.gql')}>
                {(updateHoldingSetting: (param: Record<'variables', updateHoldingSettingVariables>) => Promise<any>) => (
                    <Formik
                        key={holding.numericId}
                        validationSchema={getSchema()}
                        initialValues={initialValues}
                        onSubmit={async (values, { setSubmitting }) => {
                          const { productTransparencyPreferences, offersProductTransparencyPreferences, posSortingStrategy } = values;

                          try {
                            await updateHoldingSetting({
                              variables: {
                                id: holding.id,
                                productTransparencyPreferences,
                                offersProductTransparencyPreferences,
                                posSortingStrategy,
                              },
                            });
                          } catch (error) {
                            console.log(error);
                          }
                          setSubmitting(false);
                          setEditable(false);
                        }}
                    >
                      {({ resetForm }) => {
                        return (
                          // @ts-ignore
                            <Form>
                              <Struct.Section>
                                <Title grow mode="H2" value={holding.name}>
                                  {!editable ? (
                                      <Button
                                          display={ButtonType.ACTION}
                                          onClick={(e) => {
                                            setEditable(true);
                                            e.preventDefault();
                                          }}
                                      >
                                        {t('app:button.edit')}
                                      </Button>
                                  ) : (
                                      <>
                                        <Button
                                            display={ButtonType.GHOST}
                                            onClick={(e) => {
                                              setEditable(false);
                                              resetForm();
                                            }}
                                        >
                                          {t('app:button.cancel')}
                                        </Button>
                                        <SubmitButton fullWidth={false} displayRequiredIndication={false} />
                                      </>
                                  )}
                                </Title>
                                <StyledCard>
                                  <StyledColumn>
                                    <Title
                                        mode="H3"
                                        value={t('page:holding.info.title.general')}
                                        icon={<Icon.Cog />}
                                    />
                                    <Label> {t('page:holding.preferences.productTransparency.ingredients')}</Label>
                                    <Label> {t('page:holding.preferences.productTransparency.labels')}</Label>
                                    <Label> {t('page:holding.preferences.productTransparency.allergens')}</Label>
                                    <Label> {t('page:holding.preferences.productTransparency.description')}</Label>
                                    <Label> {t('page:holding.preferences.productTransparency.price')}</Label>
                                    <Label> {t('page:holding.preferences.productTransparency.photo')}</Label>
                                  </StyledColumn>
                                  <StyledColumn>
                                    <CentredColumn>
                                      <Title
                                          mode="H3"
                                          value={t('page:holding.info.title.menus')}
                                      />
                                      <CheckBoxsContainer>
                                        <Field
                                            disabled={!editable}
                                            name="productTransparencyPreferences.showIngredients"
                                            component={ToggleSwitchField}
                                            sideLabel={false}
                                        />
                                        <Field
                                            disabled={!editable}
                                            name="productTransparencyPreferences.showLabels"
                                            component={ToggleSwitchField}
                                            sideLabel={false}
                                        />
                                        <Field
                                            disabled={!editable}
                                            name="productTransparencyPreferences.showAllergens"
                                            component={ToggleSwitchField}
                                            sideLabel={false}
                                        />
                                        <Field
                                            disabled={!editable}
                                            name="productTransparencyPreferences.showDescription"
                                            component={ToggleSwitchField}
                                            sideLabel={false}
                                        />

                                        <Field
                                            disabled={!editable}
                                            name="productTransparencyPreferences.showPrice"
                                            component={ToggleSwitchField}
                                            sideLabel={false}
                                        />
                                      </CheckBoxsContainer>
                                    </CentredColumn>
                                  </StyledColumn>
                                  <StyledColumn>
                                    <CentredColumn>
                                      <Title
                                          mode="H3"
                                          value={t('page:holding.info.title.offers')}
                                      />
                                      <CheckBoxsContainer>
                                        <Field
                                            disabled={!editable}
                                            name="offersProductTransparencyPreferences.showIngredients"
                                            component={ToggleSwitchField}
                                            sideLabel={false}
                                        />
                                        <Field
                                            disabled={!editable}
                                            name="offersProductTransparencyPreferences.showLabels"
                                            component={ToggleSwitchField}
                                            sideLabel={false}
                                        />
                                        <Field
                                            disabled={!editable}
                                            name="offersProductTransparencyPreferences.showAllergens"
                                            component={ToggleSwitchField}
                                            sideLabel={false}
                                        />
                                        <Field
                                            disabled={!editable}
                                            name="offersProductTransparencyPreferences.showDescription"
                                            component={ToggleSwitchField}
                                            sideLabel={false}
                                        />

                                        <Field
                                            disabled={!editable}
                                            name="offersProductTransparencyPreferences.showPrice"
                                            component={ToggleSwitchField}
                                            sideLabel={false}
                                        />
                                        <Field
                                            disabled={!editable}
                                            name="offersProductTransparencyPreferences.showPhoto"
                                            component={ToggleSwitchField}
                                            sideLabel={false}
                                        />
                                      </CheckBoxsContainer>
                                    </CentredColumn>
                                  </StyledColumn>
                                </StyledCard>
                              <PosSortingCard>
                                <StyledColumn>
                                  <Title
                                    mode="H3"
                                    value={t('page:holding.preferences.posSortingStrategyTitle')}
                                  />
                                  <Field
                                    disabled={!editable}
                                    fullWidth
                                    name="posSortingStrategy"
                                    component={SelectField}
                                    data={
                                      Object.values(PosSortingStrategy).map(sortingStrategy => ({
                                        id: sortingStrategy,
                                        label: t(posSortingStrategyMapper(sortingStrategy)),
                                      }))
                                    }
                                  />
                                </StyledColumn>
                              </PosSortingCard>
                              </Struct.Section>
                            </Form>
                        );
                      }}
                    </Formik>
                )}
              </Mutation>
          );
        }}
      </QueryLoader>
  );
};

// @ts-ignore
const StyledCard = styled(Struct.Card)`
    display: flex;
    justify-content: space-between;
`;

const PosSortingCard = styled(Struct.Card)`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;
    margin-top: 1rem;
`;

const StyledColumn = styled.div`
    flex-basis: 48%;
    display: flex;
    flex-direction: column;
`;

const CentredColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CheckBoxsContainer = styled.div`
  width: 35px;
`

const Label = styled.label`
    color: ${({ theme }) => theme.color.grey[6]};
    font-size: ${({ theme }) => theme.typography.fontSizeM}px;
    margin-bottom:  30px
`;

export default withRouter(Personalization);
