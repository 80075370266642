import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import { loader } from 'graphql.macro';
import { TableWithOptions } from 'components';
import {
    listEmployees_list_edges_node_Employee as EmployeeEdges, listEmployees
} from 'types/listEmployees';
import { QueryResult } from 'localTypes';
import { TransitionGroup } from 'react-transition-group';
import EditEmployeePanel from './AddOrEditEmployee/EditEmployeePanel';
import Employee from './Employee';
import AddEmployeePanel from './AddOrEditEmployee/AddEmployeePanel';

const EMPLOYEES_LIST_QUERY = loader('./query/listEmployees.gql');

const getHeaders = (t: any) =>
    ['lastName','firstName', 'email', 'holdings', 'role', 'actions'].map(key => ({ key, displayName: t(`schema:employee.${key}`) }));

const EmployeesTable = ({ location, history }: RouteComponentProps) => {
    const { t } = useTranslation();
    const [refetchAfterDelete, setRefetchAfterDelete] = React.useState(false);

    const renderLine = (item: EmployeeEdges) => 
        <Employee 
            key={item.id}
            item={item}
            history={history}
            location={location}
            refetchAfterDelete={() => setRefetchAfterDelete(true)}
        />;

    return (
        <TableWithOptions
            headers={getHeaders(t)}
            renderLine={renderLine}
            fetchPolicy="cache-and-network"
            query={EMPLOYEES_LIST_QUERY}
            searchPlaceholder={t('app:placeholder.search.user')}
        > 
        {({ refetch }: QueryResult<listEmployees>) => {
                        if (refetchAfterDelete) {
                            refetch();
                            setRefetchAfterDelete(false);
                        }
                        return (
                            <TransitionGroup>
                                <Route
                                    path={routes.admin.employees.employee.edit()}
                                    render={() => <EditEmployeePanel beforeClose={refetch} />}
                                />
                                <Route
                                    path={routes.admin.employees.employee.add()}
                                    render={() => <AddEmployeePanel beforeClose={refetch} />}
                                />
                            </TransitionGroup>
                        );
                    }}
        </TableWithOptions>
    );
};

export default withRouter(EmployeesTable);
