import get from 'lodash/get';

import React, { ReactElement, ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { H3 } from 'components';

const TabsContext = createContext({
    onChange: (index: number) => {},
    activeIndex: 0,
    setChildWidth: (index: number, width: number) => {},
});

interface ITabsProps {
    children: ReactElement | ReactElement[];
    value: number;
    onChange: (value: number) => void;
    className?: string;
}

interface ITabProps {
    label: ReactNode;
    index?: number;
    linkTo?: string;
    id?: string;
    testID?: string;
}

function getChildrenWithIndex(children: ReactElement | ReactElement[]) {
    let childIndex = 0;
    return React.Children.map(children, child =>
        React.cloneElement(child, {
            index: childIndex++,
        })
    );
}

export function Tabs({ className, children, value, onChange }: ITabsProps) {
    const [childWidths, setChildWidths] = useState({} as { [key in number]: number });
    function setChildWidth(index: number, width: number) {
        if (!childWidths[index]) setChildWidths({ ...childWidths, [index]: width });
    }
    function getLeftOffset() {
        return Object.values(childWidths).reduce((acc, width, index) => {
            return index < value ? acc + width : acc;
        }, 0);
    }
    return (
        <TabsContext.Provider value={{ onChange, activeIndex: value, setChildWidth }}>
            <Wrapper className={className}>
                {getChildrenWithIndex(children)}
                <ActiveTabBorder width={childWidths[value]} left={getLeftOffset()} />
            </Wrapper>
        </TabsContext.Provider>
    );
}

export function Tab({ label, index = 0, linkTo, id, testID }: ITabProps) {
    const { activeIndex, onChange, setChildWidth } = useContext(TabsContext);
    const tabEl = useRef(null);

    useEffect(() => {
        const width = get(tabEl, 'current.clientWidth') || 0;
        setChildWidth(index, width);
    }, [index, setChildWidth]);

    return (
        <StyledTab 
          ref={tabEl} 
          id={id} 
          onClick={() => onChange(index)} 
          inactive={!(activeIndex === index)}
          data-test={testID}
        >
            {linkTo ? (
                <Link to={linkTo}>
                    <Label>{label}</Label>
                </Link>
            ) : (
                <Label>{label}</Label>
            )}
        </StyledTab>
    );
}

interface ActiveTabBorderProps {
    left: number;
    width: number;
}

const Wrapper = styled.div`
    position: relative;
    display: flex;
`;

const ActiveTabBorder = styled.span<ActiveTabBorderProps>`
    position: absolute;
    height: 3px;
    width: ${({ width }) => width}px;
    left: ${({ left }) => left}px;
    bottom: 0;
    outline: none;
    background: ${({ theme }) => theme.color.common.blue};
    transition: width ${({ theme }) => theme.transition.duration[1]}ms,
        left ${({ theme }) => theme.transition.duration[1]}ms;
`;

interface WrapperProps {
    readonly inactive: boolean;
}

const StyledTab = styled(H3)<WrapperProps>`
    cursor: pointer;
    transition: color ${({ theme }) => theme.transition.duration[1]}ms;
`;

const Label = styled.span`
    display: block;
    padding: ${({ theme }) => theme.spacing.s}px;
`;
