import { DefaultTheme } from 'styled-components';

export enum MessageType {
  ERROR = 'ERROR',
  LIGHTER = 'LIGHTER',
  WARNING = 'WARNING',
}

export interface ErrorMessageProps {
  children: string | object | null;
  className?: string;
  theme?: DefaultTheme;
  renderChildrenAsIs?: boolean
}

export interface MessageBuilderProps extends ErrorMessageProps{
  type?: MessageType;
}