import React from 'react';
import { RouteComponentProps, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OfferTemplateRouteParam } from 'localTypes';
import { Struct, Title } from 'components';
import appRoutes from 'Router/routes';
import OrderRatingTable from './OrderRatingTable';

interface IProps extends RouteComponentProps<{ idHolding: string; idPos: string }> {
    offerType: OfferTemplateRouteParam;
}

const OrderRating = ({
    match: {
        params: { idHolding },
    },
    offerType,
}: IProps) => {
    const { t } = useTranslation();
    return (
        <>
         <Route
                exact
                path={appRoutes[offerType].orderRating.get()}
                render={() => (
                    <Struct.Section style={{minWidth: '80%'}}>
                        <Title grow mode="H2" value={t('page:clickcollect.orderRating.title')}>
                        </Title>
                        <Struct.Card>
                            <OrderRatingTable 
                                idHolding={idHolding}
                            />
                        </Struct.Card>
                    </Struct.Section>
                )}
            />
        </>
    );
};

export default OrderRating;
