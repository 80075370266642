import { useTranslation} from "react-i18next";
import React, {useRef, useState} from "react";
import { OKButton, Row} from "../../../../components";
import {Field} from "formik";
import {DatePickerField} from "../../../../components/Inputs/FormikFields/DatePickerField";
import ReactDatePicker from 'react-datepicker';
import CheckBox from "../../../../components/CheckBox";
import styled from "styled-components";

export enum DatePickers {
	NONE,
	START_DATE,
	END_DATE
}

/**
 * This component is responsible for rendering 2 input input fields which on click open a calendar, allowing the user
 * to select a start and end date.
 * The input are sharing the same calendar, clicking in both input fields will result in opening the same calendar.
 * Allowing to improve the user experience, when clicking in the start date input it will open a calendar and the first
 * click will set the start date, the second and other clicks will result in the user setting the end date.
 *
 *
 * @param values
 * @param setFieldValue
 */
const PublicationDates = ({ values, setFieldValue }) => {
	const { t } = useTranslation();

	const [datePickerSelected, setDatePickerSelected] = useState(DatePickers.NONE);

	const datePickerStartDateRef = useRef<ReactDatePicker>();
	const datePickerEndDateRef = useRef<ReactDatePicker>();

	const shouldSaveDates = (values.publicationStartDate && values.publicationEndDate) ||
		(values.publicationStartDate && !values.mustHavePublicationEndDate);

	const onChangeDate = (date: string) => {
		const newDate = new Date(date);

		if (datePickerSelected === DatePickers.START_DATE) {
			// to ensure the start date starts at the beginning of the day
			newDate.setHours(0, 0, 0, 0);

			let endDate = values.publicationEndDate;
			if (endDate) {
				// necessary just to compare the dates, because start date starts at time 0 and the times must match for the dates to be equal
				endDate = new Date(endDate);
				endDate.setHours(0, 0,0,0);
			}

			if (newDate >= endDate) {
				setFieldValue && setFieldValue('publicationEndDate', null);
			}

			setFieldValue && setFieldValue('publicationStartDate', newDate);
			setDatePickerSelected(DatePickers.END_DATE);
		}
		else if (datePickerSelected === DatePickers.END_DATE) {
			// to make sure the end date ends at the end of the day
			newDate.setHours(23, 59, 59, 999);

			if (!values.mustHavePublicationEndDate) {
				setFieldValue && setFieldValue('mustHavePublicationEndDate', true);
			}

			if (newDate <= values.publicationStartDate) {
				// to ensure the start date starts at the beginning of the day
				newDate.setHours(0, 0, 0, 0);
				setFieldValue && setFieldValue('publicationStartDate', newDate);
				setFieldValue && setFieldValue('publicationEndDate', null);
				return;
			}

			setFieldValue && setFieldValue('publicationEndDate', newDate);
		}
	}

	const onClickDatePickerStartDate = () => {
		// on clicking in the start date allow the user to change the start date
		setDatePickerSelected(DatePickers.START_DATE);
	};

	const onClickDatePickerEndDate = () => {
		// on clicking in the start date allow the user to change the start date
		datePickerStartDateRef.current.setOpen(true); // force the opening of the shared calendar
		setDatePickerSelected(DatePickers.END_DATE);
	};

	const onCloseStartDatePicker = () => {
		setDatePickerSelected(DatePickers.NONE);
	}

	const renderDatePickerFooter = ({ datePickerRef }) => {
		return (
			<DatePickerFooter
				shouldBeDisabled={shouldSaveDates}
				allowEndDate={values.mustHavePublicationEndDate}
				setAllowEndDate={(value) => {
					setFieldValue && setFieldValue('mustHavePublicationEndDate', value);
				}}
				datePickerRef={datePickerRef}
				changeEndDateToInitialValue={() => {
					setFieldValue && setFieldValue('publicationEndDate', null);
				}}
			/>
		);
	};

	const endDatePlaceholder = () => !values.mustHavePublicationEndDate
		? t(`page:communication.addMarketingCard.noDateEnd`)
		: t(`page:communication.addMarketingCard.dateEnd`);

	const isEndDateInInvalidState = () => values.publicationStartDate &&
		values.mustHavePublicationEndDate &&
		values.publicationEndDate === null;

	/**
	 * This function is responsible to associate a classname (style) for the start and end dates of the publication rage
	 * Allowing the start and end dates for the publication range to have a specific style
	 *
	 * The class name is defined in datePicker.css
	 */
	const highlightStartAndEndOfRange = () => [
		{
			"datepickerStartAndEndDate": [values.publicationStartDate, values.publicationEndDate].filter((date) => date)
		}
	];

	return (
		<>
			<Field
				component={DatePickerField}
				onChange={onChangeDate}
				name="publicationStartDate"
				placeholder={t(`page:communication.addMarketingCard.dateStart`)}
				selected={values.publicationStartDate}
				minDate={new Date()}
				startDate={values.publicationStartDate}
				endDate={values.publicationEndDate}
				innerRef={datePickerStartDateRef}
				onClick={onClickDatePickerStartDate}
				onClose={onCloseStartDatePicker}
				onMouseDown={() => {
					// manually trigger the opening to avoid the closing
					datePickerStartDateRef.current.setOpen(true);
				}}
				className={datePickerSelected === DatePickers.START_DATE ? 'selected' : 'notSelected'}
				highlightDates={highlightStartAndEndOfRange()}
				children={renderDatePickerFooter({
					datePickerRef: datePickerStartDateRef
				})}
			/>
			<Field
				component={DatePickerField}
				onChange={() => {}}
				style={{ paddingRight: '20px' }}
				name="publicationEndDate"
				placeholder={endDatePlaceholder()}
				disabled={true}
				selected={values.publicationEndDate}
				innerRef={datePickerEndDateRef}
				onClick={onClickDatePickerEndDate}
				onMouseDown={() => {
					/* try to trigger the opening of the end datepicker to have a moment in time where there is no
						calendar being shown, but since it's disabled it will not be rendered and it will prevent
						the start calendar to be closed
					*/
					datePickerEndDateRef.current.setOpen(true);
				}}
				className={datePickerSelected === DatePickers.END_DATE ? 'selected' : 'notSelected'}
				showError={isEndDateInInvalidState()}
			/>
		</>
	);
};

const DatePickerFooter = ({ allowEndDate, setAllowEndDate, datePickerRef, changeEndDateToInitialValue, shouldBeDisabled }) => {
	const { t } = useTranslation();

	return (
		<DatePickerChildWrapper>
			<StyledRow justify="space-between">
				<CheckBoxAndLabel>
					<CheckBox
						checked={!allowEndDate}
						onChange={() => {
							changeEndDateToInitialValue();
							setAllowEndDate(!allowEndDate);
						}}
					/>
					<LabelWrapper>{t(`page:communication.addMarketingCard.noDateEnd`)}</LabelWrapper>
				</CheckBoxAndLabel>
				<OKButton
					disabled={!shouldBeDisabled}
					onClick={() => {
						datePickerRef.current.setOpen(false);
					}}
				/>
			</StyledRow>
		</DatePickerChildWrapper>
	);
};

const DatePickerChildWrapper = styled.div`
    display: flex;
    height: 55px;
    margin-top: -5px;
`;

const StyledRow = styled(Row)`
    padding-left: 16px;
    padding-right: 21px;
`;

const CheckBoxAndLabel = styled.div`
    display: flex;
    align-items: center;
`;

const LabelWrapper = styled.label`
    padding-left: 12px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    letter-spacing: 0;
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
`;

export default PublicationDates;