import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button, { ButtonType } from 'components/Button';

interface ISubmitButtonProps {
    className?: string;
    form?: string;
    disabled?: boolean;
    displayRequiredIndication?: boolean;
    formHasNoChange?: boolean;
    fullWidth?: boolean;
    testID?: string;
}

export default function SubmitButton({
    form,
    disabled,
    className,
    displayRequiredIndication = true,
    formHasNoChange,
    fullWidth = true,
    testID
}: ISubmitButtonProps) {
    const { t } = useTranslation();
    return (
        <Wrapper fullWidth={fullWidth}>
            <Required {...(testID && {'data-test':`${testID}-required`})}>{displayRequiredIndication && `* ${t('app:required')}`}</Required>
            <ButtonWrapper>
                {formHasNoChange && <NoChange {...(testID && {'data-test':`${testID}-no-change`})}>{t('app:no_change')}</NoChange>}
                <Button
                    form={form}
                    disabled={disabled}
                    className={className}
                    display={ButtonType.VALIDATION}
                    type="submit"
                    {...(testID && {testID:`${testID}-btn`})}
                >
                    {t('app:button.validate')}
                </Button>
            </ButtonWrapper>
        </Wrapper>
    );
}

interface WrapperProps {
    fullWidth: boolean;
}

const Wrapper = styled.div<WrapperProps>`
    width: 100%;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ButtonWrapper = styled.span`
    display: flex;
    align-items: center;
    align-self: flex-end;
`;

const Required = styled.span`
    font-style: italic;
    color: ${({ theme }) => theme.color.common.darkRed};
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    margin-right: ${({ theme }) => theme.spacing.xs}px;
`;

const NoChange = styled.span`
    font-style: italic;
    color: ${({ theme }) => theme.color.grey[4]};
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    margin-right: ${({ theme }) => theme.spacing.xs}px;
`;
