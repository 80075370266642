import { QueryLoader } from 'components';
import { appTheme } from 'theme';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from 'react-i18next';
import Select, { Option } from 'react-select';
import FieldContainer from '../../components/Inputs/FormikFields/FieldContainer';
import { FieldProps } from 'formik';
import styled from 'styled-components';
import { checklistSite, searchChecklistSite } from 'types/searchChecklistSite';

interface IProps {
    isMultiSelect?: boolean | false;
    onChange: (value: any) => void;
    initialValues? : Option[]
}

const SEARCH_CHECKLIST_SITES_QUERY = loader('./searchCheclistSites.gql');

/**
 * 
 * @param props Dropdown that allows to select multiple Checklist Sites at the same time
 * @returns 
 */
const DropdownChecklistSites: FunctionComponent<FieldProps & IProps> = (props) => {
    const {  onChange, isMultiSelect, initialValues } = props;
    const [siteValues, setSiteValues] = useState(initialValues);
    const { t } = useTranslation();

    const customStyles = {
        clearIndicator: (prevStyle) => ({
            ...prevStyle,
            color: appTheme.color.common.red,
            ':hover': { color: appTheme.color.common.red },
            backgroundColor: appTheme.color.grey[0],
        }),
        multiValue: (base) => {
            return {
                ...base,
                backgroundColor: appTheme.color.grey[0],
            };
        },
        multiValueRemove: (base) => {
            return {
                ...base,
                backgroundColor: false,
                color: appTheme.color.common.blue,
                ':hover': { color: appTheme.color.common.blue },
                order: 1,
            };
        },
        multiValueLabel: (base) => {
            return {
                ...base,
                backgroundColor: false,
                order: 2,
            };
        },
        option: (base) => ({
            ...base,
            ':hover': { color: appTheme.color.common.blue, fontWeight: appTheme.typography.fontWeight.bold, backgroundColor : appTheme.color.grey[0] },
            backgroundColor: false,
        }),
        placeholder: (base) => ({
            ...base,
            color: appTheme.color.grey[5],
            fontSize: appTheme.typography.fontSizeS,
            fontWeight: appTheme.typography.fontWeight.bold,
        }),
        control: (base) => ({
            ...base,
            minHeight: 52,
        }),
        indicatorSeparator: (styles) => ({display:'none'}),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
            color : appTheme.color.common.navy
        })
    };

    let selectedOptions: Option[];

    return (
        <QueryLoader
            context={{ debounceKey: 'checklistSites' }}
            query={SEARCH_CHECKLIST_SITES_QUERY}
            errorPolicy="all"
            renderWhileLoading
        >
            {({  data, refetch }: QueryResult<searchChecklistSite>) => {
                let choices: checklistSite[] = [];

                try {
                    choices = data.choices;

                    const dataToUse = choices;

                    selectedOptions = dataToUse.map((site) => {
                        return {
                            value: site.id,
                            label: site.label,
                        };
                    });
                } catch (e) {}

                return (
                    <FieldContainer {...props}>
                        <FieldWrapper>
                            <Select
                                onChange={(value) => {
                                    setSiteValues(value);
                                    onChange(value);
                                }}
                                isMulti={isMultiSelect}
                                minMenuHeight={52}
                                value={siteValues}
                                options={selectedOptions}
                                onInputChange={(inputValue: string) => {
                                    refetch({ search: `%${inputValue}%` });
                                }}
                                placeholder={t('page:communication.addMarketingCard.holdingsPlaceholder')}
                                closeMenuOnSelect={!isMultiSelect}
                                styles={customStyles}
                            />
                        </FieldWrapper>
                    </FieldContainer>
                );
            }}
        </QueryLoader>
    );
};

const FieldWrapper = styled.div`
    width: inherit;
`;
export default DropdownChecklistSites;
