import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import { Button, QueryLoader, Struct, Tags, Icon } from 'components';
import { getBooleanOptions } from 'components/Tag';
import { QueryResult } from 'localTypes';
import { EmptyStateWrapper, HeaderWrapper, MessageWrapper, StyledLoader } from './ServicesPage.style';
import ServicesTable from './ServicesTable';
import { listChecklistServices } from 'types/listChecklistServices';

const EmptyServicesPage = () => {
    const { t } = useTranslation();
    return (
        <EmptyStateWrapper>
            <Icon.BlankPage />
            <MessageWrapper>{t('page:checkList.services.emptyStateMessage')}</MessageWrapper>
        </EmptyStateWrapper>
    )
}
interface IPropsPanel extends RouteComponentProps<{ siteId: string }> {
    beforeClose: () => void;
}

export enum FilterKey {
    PUBLISHED = 'published',
}

const ServicesPage = ({ 
        location, 
        history, 
        match: {
            params: { siteId },
        }
    }: IPropsPanel) => {

    const { t } = useTranslation();

    const initialQueryVariables = {
        siteId
    };

    const [queryVariables, setQueryVariables] = useState(initialQueryVariables);
    const [refetchAfterDelete, setRefetchAfterDelete] = useState<boolean>(false);
    const [tagValues, setTagValues] = useState<string[]>([]);
    
    const redirectToServiceCreatePage = () => {
        history.push(`${routes.checkList.services.add(siteId)}`);
    };

    useEffect(()=> {
        setQueryVariables({siteId})
    },[siteId])

    const optionsPublished = getBooleanOptions(t('page:checkList.services.published'), t('page:checkList.services.unpublished'));

    const onFilterChange = (key: FilterKey) => (value: string[]) => {
        const newQueryVariables = queryVariables;
        if (value.length !== 1) delete newQueryVariables[key];
        if (value.length === 1) newQueryVariables[key] = value[0];

        setTagValues(value)
        setQueryVariables(newQueryVariables)
    };

    return (
        <QueryLoader
            notifyOnNetworkStatusChange
            fetchPolicy='network-only'
            query={loader('./query/getChecklistServices.gql')}
            variables={queryVariables}
            hasData={(data: listChecklistServices): boolean => !!(data && data.list)}
        >
            {({ data: { list }, refetch, loading }: QueryResult<listChecklistServices>) => { 
                if (refetchAfterDelete) {
                    refetch();
                    setRefetchAfterDelete(false);
                };

                return (
                    <Struct.Section> 
                        <HeaderWrapper>
                            <Tags
                                onChange={onFilterChange(FilterKey.PUBLISHED)}
                                value={tagValues}
                                options={optionsPublished}
                            />
                            <Button id='createService-button' onClick={redirectToServiceCreatePage}>
                                {t('app:button.add-service')}
                            </Button>
                        </HeaderWrapper>
                        {(loading && !!list) ? <StyledLoader /> :
                            list.length === 0 ? (
                                <EmptyServicesPage />
                            ) : (
                                <Struct.Card>
                                    <ServicesTable
                                        data={list}
                                        siteId={siteId}
                                        refetchAfterDelete={() => setRefetchAfterDelete(true)}
                                    />
                                </Struct.Card>
                        )}
                    </Struct.Section>
                );
            }}
        </QueryLoader>
    );
};

export default withRouter(ServicesPage);
