import * as qs from 'qs';
import isEqual from 'lodash/isEqual';

import { useState, useEffect } from 'react';
import { History, Location } from 'history';
import { QuerySearchInput } from 'types/globalTypes';
import { PaginationOptions } from 'localTypes';
import { resetPagination, DEFAULT_ROW_PER_PAGE } from 'components/Table/Paginator';

export default function useRoutingForTableOptions<T extends string>(
    location: Location,
    history?: History,
    linesPerPage?: number
): {
    queryParams: { [key in T]?: QuerySearchInput };
    onSearchValueChange: (params: { [key in T]?: QuerySearchInput }) => void;
    querySearch: QuerySearchInput[];
    onPaginationChange: (paginationOptions: PaginationOptions) => void;
    paginationOptions: PaginationOptions;
} {
    const [queryParams, setQueryParams] = useState({} as { [key in T]?: QuerySearchInput } & PaginationOptions);
    const { first, after, last, before, ...querySearchValues } = queryParams;
    const querySearch = Object.values(querySearchValues) as QuerySearchInput[];

    useEffect(() => {
        const currentParams: { [key in T]?: QuerySearchInput } = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        }) as { [key in T]?: QuerySearchInput | undefined };
        if (!isEqual(queryParams, currentParams)) setQueryParams(currentParams);
    }, [location.search, queryParams]);

    const onSearchValueChange = (newParams: { [key in T]?: QuerySearchInput }) => {
        // reset pagination if needed
        const newPaginationOptions = after || last || before ? { ...resetPagination, first: first || last } : {};

        history && history.push({ search: qs.stringify({ ...queryParams, ...newParams, ...newPaginationOptions }) });
    };

    const onPaginationChange = (paginationOptions: PaginationOptions) => {
        history && history.push({ search: qs.stringify({ ...queryParams, ...paginationOptions }) });
    };

    return {
        queryParams,
        onSearchValueChange,
        querySearch,
        onPaginationChange,
        paginationOptions: {
            first: !first && !last ? linesPerPage ? linesPerPage : DEFAULT_ROW_PER_PAGE : parseInt(first as any, 10),
            after,
            last: last && parseInt(last as any, 10),
            before,
        },
    };
}
