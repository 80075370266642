import React from 'react';
import FieldContainer from './FieldContainer';
import DatePicker, { IDatePickerProps } from '../DatePicker';

export const DatePickerField: React.FC<IDatePickerProps> = (props) => {

	return (
    // @ts-ignore
		<FieldContainer {...props}>
      {
        // @ts-ignore
        <DatePicker {...props}>
          {!!props.children && <div className="col-12">{props.children}</div>}
        </DatePicker>
      }
		</FieldContainer>
	);
};


export default DatePickerField;

