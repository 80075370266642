import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import isEqual from 'lodash/isEqual';
import { Field, Form, Formik } from 'formik';
import { Panel, TextInputField, SubmitButton } from 'components';
import * as Yup from 'yup';
import { PanelContent, PanelFooter } from 'components/Panel';
import LanguageTextInput from 'components/LanguageTextInput';
import { appTheme } from 'theme';

const CREATE_PICK_UP_POINT_MUTATION = loader('../../query/createPickUpPoint.gql');
const UPDATE_PICK_UP_POINT_MUTATION = loader('../../query/updatePickUpPoint.gql');
const FORM_ID = 'pick_up_point_add_edit_form';

const getSchema = (t: Function) =>
    Yup.object().shape({
        name: Yup.string().required(t('app:error.required')),
        deliveryTimes: Yup.string().required(t('app:error.required')),
        description: Yup.string().notRequired().nullable(true),
        address: Yup.string().notRequired().nullable(true),
        productionSite: Yup.string().notRequired().nullable(true),
        companyCode: Yup.string().notRequired().nullable(true),
    });
interface IProps {
    match: {
        params: { idHolding: string };
    };
    pickUpPoint: any | null;
    isOpen: boolean;
    closeModal: ({ shouldRefetch }: { shouldRefetch?: boolean | undefined }) => void;
    isEditMode: boolean;
}

function PickUpPointTablePanel({
    isOpen,
    closeModal,
    match: {
        params: { idHolding },
    },
    pickUpPoint,
    isEditMode,
}: IProps & RouteComponentProps) {
    const { t } = useTranslation();
    const [mutatePickUpPoint] = useMutation<'variables', any>(
        isEditMode ? UPDATE_PICK_UP_POINT_MUTATION : CREATE_PICK_UP_POINT_MUTATION
    );

    const panelTitle = isEditMode
        ? t('page:clickcollect.pickUpPoints.pickUpPointsPanel.editTitle')
        : t('page:clickcollect.pickUpPoints.pickUpPointsPanel.title');

    // will get the Type after it is generated with gentypes
    const initialValues = {
        idHolding: idHolding,
        name: pickUpPoint ? pickUpPoint.name : '',
        description: pickUpPoint ? pickUpPoint.description : '',
        address: pickUpPoint ? pickUpPoint.address : '',
        productionSite: pickUpPoint ? pickUpPoint.productionSite : '',
        deliveryTimes: pickUpPoint ? pickUpPoint.withdrawalSchedule : '',
        // there isn't any field for the companyCode so far and it is required to create/update the pickup point
        companyCode: pickUpPoint ? pickUpPoint.companyCode : idHolding,
    };

    return (
        <Panel onClose={() => closeModal({})} open={isOpen} title={panelTitle}>
            <Formik
                initialValues={initialValues}
                validationSchema={getSchema(t)}
                validateOnBlur={false}
                validateOnChange={true}
                onSubmit={(values, { setSubmitting }) => {
                    mutatePickUpPoint({
                        variables: {
                            idHolding: values.idHolding,
                            name: values.name?.trim(),
                            description: values.description?.trim(),
                            address: values.address?.trim(),
                            productionSite: values.productionSite?.trim(),
                            withdrawalSchedule: values.deliveryTimes?.trim(),
                            companyCode: values.companyCode?.trim(),
                            ...(isEditMode && { id: pickUpPoint.id }),
                        },
                    })
                        .then(() => {
                            closeModal({ shouldRefetch: true });
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({ errors, touched, values, initialValues }) => {
                    const { ...restInitialValues } = initialValues;
                    const { ...restValues } = values;
                    const formHasNoChange = isEqual(restInitialValues, restValues);
                    return (
                        <>
                            <PanelContent>
                              {
                                // @ts-ignore
                                <Form id={FORM_ID}>
                                    <Field
                                        label={t('schema:pickUpPoints.panelName')}
                                        placeholder={t('schema:pickUpPoints.panelNamePlaceholder')}
                                        name="name"
                                        component={TextInputField}
                                        style={{
                                            fontFamily: appTheme.typography.fontFamily,
                                        }}
                                        isPlaceholderBold
                                    />
                                    <Field
                                        label={t('schema:pickUpPoints.panelDeliveryTimes')}
                                        placeholder={t('schema:pickUpPoints.panelDeliveryTimesPlaceholder')}
                                        name="deliveryTimes"
                                        component={TextInputField}
                                        style={{
                                            fontFamily: appTheme.typography.fontFamily,
                                        }}
                                        isPlaceholderBold
                                    />
                                    <LanguageTextInput
                                        label={t('schema:pickUpPoints.descriptionPanel')}
                                        placeholder={t('schema:pickUpPoints.panelDescriptionPlaceholder') || ''}
                                        name="description"
                                        maxLength={60}
                                        value={values.description}
                                        multiline
                                        style={{
                                            fontSize: `${appTheme.typography.fontSizeS}px`,
                                        }}
                                        isPlaceholderBold
                                    />
                                    <LanguageTextInput
                                        label={t('schema:pickUpPoints.addressPanel')}
                                        placeholder={t('schema:pickUpPoints.panelAddressPlaceholder') || ''}
                                        name="address"
                                        maxLength={300}
                                        value={values.address}
                                        multiline
                                        style={{
                                            fontSize: `${appTheme.typography.fontSizeS}px`,
                                        }}
                                        isPlaceholderBold
                                    />
                                    <LanguageTextInput
                                        label={t('schema:pickUpPoints.productionSitePanel')}
                                        placeholder={t('schema:pickUpPoints.productionSitePlaceholder')}
                                        name="productionSite"
                                        maxLength={300}
                                        value={values.productionSite}
                                        multiline
                                        style={{
                                            fontSize: `${appTheme.typography.fontSizeS}px`,
                                        }}
                                        isPlaceholderBold
                                    />
                                </Form>
                              }
                            </PanelContent>
                            <PanelFooter>
                                <SubmitButton
                                    formHasNoChange={formHasNoChange}
                                    form={FORM_ID}
                                    disabled={
                                        formHasNoChange ||
                                        Object.entries(touched).length === 0 ||
                                        Object.entries(errors).length !== 0
                                    }
                                />
                            </PanelFooter>
                        </>
                    );
                }}
            </Formik>
        </Panel>
    );
}

export default withRouter(PickUpPointTablePanel);
