import React, { ReactNode, useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { Icon, Struct, IconButton } from 'components';
import SideNav from './SideNav';

interface IProps {
    theme: DefaultTheme;
    children?: ReactNode;
}

const PageContainer = ({ theme, children }: IProps) => {
    const [isSideNavOpen, setSideNavOpen] = useState(false);
    const openSideNav = () => {
        setSideNavOpen(true);
    };
    const closeSideNav = () => {
        setSideNavOpen(false);
    };
    return (
        <>
            <SideNav isOpen={isSideNavOpen} onClose={closeSideNav} />
            <Struct.Header>
                <Struct.Top>
                    <IconButton onClick={openSideNav}>
                        <Icon.Menu color={theme.color.grey[6]} />
                    </IconButton>
                </Struct.Top>
            </Struct.Header>
            <Main isSideNavOpen={isSideNavOpen}>{children}</Main>
        </>
    );
};

interface IMainProps {
    isSideNavOpen: boolean;
}

const Main = styled.div<IMainProps>`
    margin-left: ${({ theme, isSideNavOpen }) => (isSideNavOpen ? theme.dimension.width.sideNav : 0)}px;
    transition: margin-left ${({ theme }) => theme.transition.duration[2]}ms;
`;

export default withTheme(PageContainer);
