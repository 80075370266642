import { Button } from 'components';
import styled from 'styled-components';

interface IFlexRow {
    justifyContent?: string;
    marginBottom?: number;
}

export const Container = styled.div`
    justify-content: space-between;
    flex-grow: 1;
    flex-direction: row;
    display: flex;
    margin-bottom: 20px;
`;

export const TextButton = styled.span`    
    font-size: ${({ theme }) => theme.typography.size.S}px;
    line-height:  ${({ theme }) => theme.typography.lineHeight.S}px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.color.white};
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
`;

export const TextWithEllipsis = styled.div<{ maxWidth: number, withEllipsis?: boolean}>`
    max-width: ${({ maxWidth }) => `${maxWidth}px`};
    overflow: hidden;
    text-overflow: ${({ withEllipsis }) => withEllipsis ? "ellipsis" : "clip"};
    white-space: nowrap;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    text-align: left;
    font-weight: normal;
`;

export const ActionButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-right: 5px;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
`;

export const ModalTitle = styled.div`
    display: flex;
    font-size: 12px;
    max-width: 250px;
`;

export const FlexRow = styled.div<IFlexRow>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)}px;
`;

export const CancelButton = styled.span`
    font-size: ${({ theme }) => theme.typography.size.S}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bolder};
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    color: ${({ theme }) => theme.color.common.blue};
    font-family: Open Sans;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;
    padding: 0 40px;
    display: flex;
    align-self: center;
    cursor: pointer;
`;

export const StyledButton = styled(Button)`
    width: 130px;
    min-width: 130px;
    height: 30px;
    cursor: pointer;
`;

export const StyledTD = styled.td<{ width: string}>`
    width: ${({ width }) => `${width}`};
`;

export const EditIcon = styled.span`
    cursor: pointer;
`;

export const FieldWrapper = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.s}px;
    width: inherit;
`;
