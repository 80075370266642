/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ArticleBaking {
  MEDIUM = "MEDIUM",
  MEDIUMRARE = "MEDIUMRARE",
  RARE = "RARE",
  RAW = "RAW",
  ROSE = "ROSE",
  WELLDONE = "WELLDONE",
}

export enum ArticleFamily {
  BEVERAGE = "BEVERAGE",
  BREAD = "BREAD",
  BREAKFAST = "BREAKFAST",
  CHEESE = "CHEESE",
  DAIRY = "DAIRY",
  DESSERT = "DESSERT",
  DISH = "DISH",
  FRUIT = "FRUIT",
  MISCELLANEOUS = "MISCELLANEOUS",
  OTHERS = "OTHERS",
  PASTRY = "PASTRY",
  SALAD = "SALAD",
  SANDWICH = "SANDWICH",
  SIDE_DISH = "SIDE_DISH",
  SNACKING = "SNACKING",
  SOUP = "SOUP",
  STARTER = "STARTER",
  BREAK = "BREAK",
  INGREDIENTS = "INGREDIENTS",
}

export enum Currency {
  EUR = "EUR",
}

export enum MarketingTypeNew {
  ARTICLE = "ARTICLE",
  IMPORTANT_MESSAGE = "IMPORTANT_MESSAGE",
}

export enum OfferItemToSell {
  ACTIVATED = "ACTIVATED",
  DEACTIVATED = "DEACTIVATED",
  TO_DECIDE = "TO_DECIDE",
}

export enum OfferItemFormulaToSell {
  ACTIVATED = "ACTIVATED",
  DEACTIVATED = "DEACTIVATED",
}

export enum OfferTemplatePeriod {
  DAILY = "DAILY",
  WEEKLY_MON = "WEEKLY_MON",
}

export enum OfferTemplateSalesType {
  BUNDLE = "BUNDLE",
  FREE_SALE = "FREE_SALE",
}

export enum OfferTemplateWithdrawalType {
  POS_AT_SITE = "POS_AT_SITE",
  POS_TAKE_AWAY = "POS_TAKE_AWAY",
  TABLE_SERVICE = "TABLE_SERVICE",
  POS_CLICK_SERVE = "POS_CLICK_SERVE",
  INSTANT_CLICK_COLLECT = "INSTANT_CLICK_COLLECT",
  CLICK_AND_PICK_UP = "CLICK_AND_PICK_UP",
  CONNECTED_LOCKERS = "CONNECTED_LOCKERS",
}

export enum AtSiteTakeAwayType {
  AtSite = 'AT_SITE',
  TakeAway = 'TAKE_AWAY',
  AtSiteTakeAway = 'AT_SITE_TAKE_AWAY',
  None = 'NONE',
}

export enum ImportationTypeType {
  Oscar = 'OSCAR',
  Winapro = 'WINAPRO',
  Other = 'OTHER',
};

export enum PrintingPriority {
  mealHeart = 'MEAL_HEART',
  articleFamily = 'ARTICLE_FAMILY',
}

export enum ImportationType {
  Oscar = 'OSCAR',
  Winapro = 'WINAPRO',
  Other = 'OTHER',
}

export enum QuantityRuleType {
  mealQuantity = "mealQuantity",
  orderPerDay = "orderPerDay",
  productsPerOrder = "productsPerOrder",
  sameProductPerOrder = "sameProductPerOrder",
}

export enum EmployeeRole {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  ManagerAdmin= 'MANAGER_ADMIN',
  Marketer = 'MARKETER',
  ChecklistAdmin = 'CHECKLIST_ADMIN'
}

export enum CheckListUserRole {
  Consulting = 'READER',
  Editing = 'WRITER',
}

export interface AddressInput {
  address1: string;
  address2?: string | null;
  city: string;
  zip?: string | null;
  latitude: number;
  longitude: number;
}

export interface ExternalServiceInput {
  title?: string | null;
  content?: string | null;
  active?: boolean | null;
}

export interface MarketingCardKeyContentInput {
  key?: string | null;
  content?: string | null;
}

export interface OfferTemplateTranslationInput {
  language: string;
  name: string;
  description: string;
  fullDescription?: string | null;
  onlineHelp?: string | null;
}

/**
 * Horaires d'ouverture.
 */
export interface OpeningHourInput {
  days: number[];
  hours: any[];
};

export interface MenuCategoryInput {
  id: string;
  idPos: string;
  idCategoryMenu: number[];
}

export interface PickupPointsInput {
  id: string;
  label?: string | null;
}

export interface QuantityRulesInput {
  property: string;
  min: number;
  max: number;
}

export interface ServiceInput {
  id: number;
  wid?: string;
}

export interface PlcInput {
  id: string;
  services?: ServiceInput[]
  displayedServices?: ServiceInput[]
}

export interface AffluenceInput {
    id: string;
    enabled: boolean;
    maxCapacity: number;
    avgConsumptionTime: number;
}

export interface QuerySearchInput {
  key: string;
  operator?: string | null;
  value: string;
}

export interface UpdateUserEmail {
  idGuest: string;
  email: string;
}


export interface ProductTrancparencyPreferencesInput {
  showLabels: boolean;
  showIngredients: boolean;
  showAllergens: boolean;
  showNutriScore: boolean;
  showEcoScore: boolean;
  showCalories: boolean;
  showNutritionalData: boolean;
  showPhoto: boolean;
  showPrice: boolean;
  showDescription: boolean;
}

export enum PosSortingStrategy {
  Default = 'DEFAULT',
  Alphabetical = 'ALPHABETICAL',
  Customized = 'CUSTOMIZED',
}

export interface MercanetConfig {
  code: string;
  secret: string;
  version: string;
}

export interface EdenredConfig {
  edenredMid: string;
}

export enum Role {
  Employee = 'Employee',
  Admin = 'Admin',
  Marketer = 'Marketer',
  ManagerAdmin = 'ManagerAdmin',
  ChecklistAdmin = 'ChecklistAdmin',
}

//==============================================================
// END Enums and Input Objects
//==============================================================

export default null
