import styled from 'styled-components';

export const FlagSelector = styled.div`
    padding-top: ${({ theme }) => theme.spacing.s}px;
    background-color: ${({ theme }) => theme.color.common.white};
    display: flex;
    align-self: flex-end;
`;

export const Preview = styled.div`
    padding-top: ${({ theme }) => theme.spacing.xxs}px;
    padding-bottom: ${({ theme }) => theme.spacing.s}px;
    background-color: ${({ theme }) => theme.color.common.white};
    display: flex;
    width: inherit;
    flex-direction: column;
    word-wrap: break-word;
`;

export const BadgeText = styled.span`
    margin-left: ${({ theme }) => theme.spacing.xxs}px;
`;
interface IImageContainerProps {
    importantMessage?: boolean;
}
export const ImageContainer = styled.div<IImageContainerProps>`
    ${({ importantMessage, theme }) =>
        importantMessage
            ? `
            border-radius: ${theme.borderRadius.m}px;
            height: 250px;
            width: 100%;
            position: relative;
        `
            : `
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 280px;
            width: 100%;
            border-radius: ${theme.borderRadius.m}px;
            background: #e1f2ff;
        `}
`;

export const ImageWrapper = styled.img`
    display: flex;
    flex-shrink: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.m}px;
`;

interface IStyledBadgeProps {
    backgroundColor?: string;
}

export const StyledBadge = styled.span<IStyledBadgeProps>`
    ${({ theme, backgroundColor }) => `background: ${backgroundColor ? backgroundColor : theme.color.common.yellow}`};
    color: ${({ theme }) => theme.color.grey[7]};
    border-radius: ${({ theme }) => theme.borderRadius.l}px;
    font-size: ${({ theme }) => theme.typography.fontSizeXXS}px;
    padding: ${({ theme }) => `${theme.spacing.xxs - 2}px ${theme.spacing.xs}px`};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: ${({ theme }) => theme.spacing.xs}px;
    left: ${({ theme }) => theme.spacing.xs}px;
    width: fit-content;
    z-index: 1;
`;
