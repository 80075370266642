import React from 'react';
import { Route, withRouter } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import appRoutes from "../../../Router/routes";
import Add from "./Add";
import Edit from "./Edit";
import List from "./List";

function Printing() {
    return (
        <TransitionGroup>
            <Route exact path={appRoutes.holding.printing.edit()} component={Edit}/>
            <Route exact path={appRoutes.holding.printing.add()} component={Add}/>
            <Route exact path={appRoutes.holding.printing.index()} component={List} />
        </TransitionGroup>)
};


export default withRouter(Printing);
