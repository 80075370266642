import React, { useState } from 'react';
import { OfferItemToSell } from 'types/globalTypes';
import { ErrorToast } from 'components/ErrorToast';
import { toast } from 'react-toastify';
import MultipleToggle from '../../../../components/Inputs/MultipleToggle';
import { Loader } from 'components';

interface IOfferItemsToggleProps {
    id: string;
    toSell: OfferItemToSell;
    cashSystemCode: string | null;
    onToSellUpdate: (id: string, toSell: OfferItemToSell) => void;
    preCheckArticle: ((article:string) => any) | undefined
    onErrorArticle?: (items:any)=> void
}

const OfferItemsToggle = ({
    id,
    toSell,
    cashSystemCode,
    onToSellUpdate,
    preCheckArticle,
    onErrorArticle
}: IOfferItemsToggleProps)  => {
    const [ toSellState, setToSellState ] = useState(toSell);
    const [ isLoading , setLoading ] = useState(false)
    const toSellList = [OfferItemToSell.DEACTIVATED, OfferItemToSell.TO_DECIDE, OfferItemToSell.ACTIVATED];
    const nextIndexes = {
        0: 2,
        1: 2,
        2: 0
    } as unknown as {number: number};
    const onChange = async (value: any) => {
        if(preCheckArticle && value === OfferItemToSell.ACTIVATED){
          setLoading(true)
          const articles = await preCheckArticle(id)
          setLoading(false)
          if(articles.length && onErrorArticle){
            onErrorArticle(articles)
            return;
          }
        }
        if((value == OfferItemToSell.ACTIVATED || value == OfferItemToSell.DEACTIVATED) && !cashSystemCode){
            setToSellState(OfferItemToSell.ACTIVATED);
            setTimeout(() => {
                setToSellState((prevValue => {
                    if (prevValue === OfferItemToSell.ACTIVATED) {
                        toast(<ErrorToast errorMessage="NO_CASH_SYSTEM_CODE"/>);
                        return OfferItemToSell.TO_DECIDE
                    }
                    return prevValue;
                }));
            }, 200);
        } else {
            if(toSellState !== value) {
                setToSellState(value);
                onToSellUpdate(id, value);
            }
        }
    }
    if(isLoading){
      return <div style={{ height : "43px" }}><Loader /></div>
    }
    return (
        <MultipleToggle
            values={toSellList}
            onChange={onChange}
            initialValue={toSellState}
            nextIndexes={nextIndexes}
            width={50}
            height={30}
            style={{
                selectedStyle: {
                    background: toSellState === OfferItemToSell.TO_DECIDE ? '#5b9ad5' : '#ffffff',
                    fontSize: '12px',
                    lineHeight: '32px',
                    textAlign: 'center',
                    transition: '250ms',
                },
                wrapperStyle: {
                    background: toSellState === OfferItemToSell.ACTIVATED ? '#70ad47' : '#d9d9d9',
                    padding: 0,
                },
            }}
        />
    );
}

export default OfferItemsToggle;
