import React from 'react';
import { withRouter } from "react-router-dom";
import HubForm from "./HubForm";
import { loader } from "graphql.macro";
import { Mutation } from '@apollo/client/react/components';
import { decodeEntityId } from "../../../utils";
import appRoutes from "../../../Router/routes";

function Add({
                 match: {
                     params: { idHolding },
                 },
                 history
             }) {
    return <Mutation onCompleted={() => history.push(appRoutes.holding.printing.index(idHolding))} mutation={loader('../query/addPosHub.gql')}>
        {(addPosHub: (param) => Promise<any>, {
            loading,
        }) => (
            <HubForm
                loading={loading}
                // @ts-ignore
                initialValues={{}}
                onSubmit={values => addPosHub({
                    variables: {
                        input: {
                            ...values,
                            idHolding: Number(decodeEntityId(idHolding)),
                            pos: {
                                id: Number(decodeEntityId(values.pos.id))
                            }
                        }
                    },
                })}
            />
        )}
    </Mutation>
};

export default withRouter(Add);
