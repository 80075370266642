import { DayPicker, OKButton } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TimeInput from './Inputs/TimeInput';

interface IProps {
    value: string | undefined;
    valueFrom: string;
    valueTo: string;
    onChange: (duratoin: string) => void;
    onClose: () => void;
    className?: string;
}

function IntervalPicker({ value, valueFrom, valueTo, onChange, onClose, className }: IProps) {
    const { t } = useTranslation();
    const [durationInputDay, setDurationInputDay] = useState(valueFrom.charAt(1));
    const [durationInputTimeFrom, setDurationInputTimeFrom] = useState(valueFrom.substr(4));
    const [durationInputTimeTo, setDurationInputTimeTo] = useState(valueTo.substr(4));

    const handleChangeDay = (day: string) => {
        setDurationInputDay(day);
        onChange(`P${day}DT${durationInputTimeFrom}/P${day}DT${durationInputTimeTo}`);
    };
    const handleChangeTimeFrom = (time: string) => {
        setDurationInputTimeFrom(time);
        onChange(`P${durationInputDay}DT${time}/P${durationInputDay}DT${durationInputTimeTo}`);
    };
    const handleChangeTimeTo = (time: string) => {
        setDurationInputTimeTo(time);
        onChange(`P${durationInputDay}DT${durationInputTimeFrom}/P${durationInputDay}DT${time}`);
    };
    return (
        <Wrapper className={className}>
            <CustomDayPicker activeDays={[parseInt(valueFrom.charAt(1))]} onChange={day => handleChangeDay(day)}>
                <WrapperTimeInput>
                    <TimeInput
                        value={value !== undefined ? value.split('/')[0] : valueFrom}
                        onSelect={() => {}}
                        width={30}
                        label={t(`page:admin.cnc.from`)}
                        initialValue={valueFrom}
                        onChange={timeFrom => {
                            handleChangeTimeFrom(timeFrom);
                        }}
                    />
                </WrapperTimeInput>
                <WrapperTimeInput>
                    <TimeInput
                        value={value !== undefined ? value.split('/')[1] : valueTo}
                        onSelect={() => {}}
                        width={30}
                        label={t(`page:admin.cnc.to`)}
                        initialValue={valueTo}
                        onChange={timeTo => {
                            handleChangeTimeTo(timeTo);
                        }}
                    />
                </WrapperTimeInput>
                <RowWrapper>
                    <OKButton onClick={onClose} />
                </RowWrapper>
            </CustomDayPicker>
        </Wrapper>
    );
}

const CustomDayPicker = styled(DayPicker)`
    padding: 20px 10px 20px 5px;
`;

const Wrapper = styled.span`
    box-shadow: ${({ theme }) => theme.boxShadow[0]};
    position: absolute;
    margin-top: 40px;
    margin-left: 3px;
    width: 360px;
    z-index: 100;
`;

const WrapperTimeInput = styled.span`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export default IntervalPicker;
