import { ErrorMessageLighter, InputLabel } from 'components';
import { FieldProps, FormikErrors } from 'formik';
import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';
import { BaseInputThirdly, InputState } from '../common';
import FieldContainer from './FieldContainer';
import TimeField from 'react-simple-timefield';
import { getDisplayTime } from 'services/dateService';
import { getHoursAndMinutesFromStringToISO, getTimeFromISO } from 'services/dateService';

enum Item {
    START = 'START',
    END = 'END',
}

interface IProps {
    readonly className?: string;
    readonly label?: string;
    readonly disabled?: boolean;
    readonly labelStart?: string;
    readonly labelEnd: string;
    readonly width?: number;
    readonly marginLeft?: number;
    readonly validateFormOnChange?: boolean;
    readonly useDefaultErrorMessage?: boolean;
    readonly labelEndInline?: string;
    readonly labelsFontSize?: string;
    readonly nameStart: string;
    readonly nameEnd: string;
}

const RangeInputField = (props: FieldProps & IProps) => {
    const {
        field,
        form,
        disabled,
        labelStart,
        labelEnd,
        width,
        validateFormOnChange,
        useDefaultErrorMessage = true,
        labelEndInline,
        labelsFontSize = 'L',
        nameStart,
        nameEnd,
    } = props;
    const [startValue, endValue] = [form.values[nameStart], form.values[nameEnd]];
    const handleChange = (item: Item) => (_, time) => {
        if (!(item === Item.START ? nameStart : nameEnd)) {
            return;
        }

        form.setFieldValue(item === Item.START ? nameStart : nameEnd, getHoursAndMinutesFromStringToISO(time));
        form.setFieldTouched(item === Item.START ? nameStart : nameEnd);

        if (validateFormOnChange) {
            setTimeout(() => {
                form.validateForm();
            }, 0);
        }
    };

    const renderError = (error?: string | string[] | FormikErrors<any> | FormikErrors<any>[], inline?: boolean) => {
        if (!error) {
            return null;
        }
        // When is object, avoid render when all values are undefined
        if (typeof error === 'object' && Object.values(error).findIndex((e) => !!e) === -1) {
            return null;
        }

        return (
            <ErrorWrapper inline={inline}>
                <ErrorMessageLighter>{error}</ErrorMessageLighter>
            </ErrorWrapper>
        );
    };

    return (
        <FieldContainer {...props}>
            <Wrapper {...props}>
                <Vertical>
                    <AllInputsWrapper>
                        <VerticalWithMargin marginLeft={!labelEndInline}>
                            <InputWrapper>
                                {labelStart && (
                                    <InputLabel
                                        name={labelStart}
                                        htmlFor={nameStart}
                                        fontSize={labelsFontSize}
                                        inline
                                        noMarginRight
                                    />
                                )}
                                <TimeField
                                    value={startValue ? getDisplayTime(getTimeFromISO(startValue)) : ''}
                                    onChange={handleChange(Item.START)}
                                    input={
                                        <Input
                                            {...field}
                                            name={nameStart}
                                            width={width}
                                            disabled={disabled}
                                            state={
                                                !!get(form.errors, nameStart) && !!get(form.touched, nameStart)
                                                    ? InputState.INVALID
                                                    : InputState.VALID
                                            }
                                        />
                                    }
                                />
                            </InputWrapper>
                        </VerticalWithMargin>
                        <Vertical>
                            <InputWrapper>
                                <InputLabel
                                    name={labelEnd}
                                    htmlFor={nameEnd}
                                    inline
                                    noMarginRight
                                    fontSize={labelsFontSize}
                                />
                                <TimeField
                                    value={endValue ? getDisplayTime(getTimeFromISO(endValue)) : ''}
                                    onChange={handleChange(Item.END)}
                                    input={
                                        <Input
                                            {...field}
                                            name={nameEnd}
                                            width={width}
                                            disabled={disabled}
                                            state={
                                                !!get(form.errors, nameEnd) && !!get(form.touched, nameEnd)
                                                    ? InputState.INVALID
                                                    : InputState.VALID
                                            }
                                        />
                                    }
                                />
                                {labelEndInline && (
                                    <InputLabel
                                        name={labelEndInline}
                                        htmlFor={nameEnd}
                                        inline
                                        noMarginRight
                                        fontSize={labelsFontSize}
                                    />
                                )}
                                {!useDefaultErrorMessage &&
                                    !labelStart &&
                                    !labelEndInline &&
                                    renderError(get(form.errors, nameEnd))}
                            </InputWrapper>
                        </Vertical>
                    </AllInputsWrapper>
                    {!useDefaultErrorMessage &&
                        (labelStart || labelEndInline) &&
                        (renderError(get(form.errors, nameStart), true)||renderError(get(form.errors, nameEnd), true))}
                </Vertical>
            </Wrapper>
        </FieldContainer>
    );
};

const Wrapper = styled.div<{ marginLeft?: number }>`
    display: flex;
    margin-left: ${({ marginLeft }) => (marginLeft || '0')}px;
`;

const Vertical = styled.div`
    flex-direction: row;
`;

// @ts-ignore
const VerticalWithMargin = styled(Vertical)<{ marginLeft?: boolean }>`
    &:not(:last-child) {
        margin-right: ${({ marginLeft }) => (marginLeft ? '38' : '0')}px;
    }
`;

const Input = styled(BaseInputThirdly)<Pick<IProps, 'width'>>`
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    text-align: center;
    font-size: ${({ theme }) => theme.typography.fontSizeL}px;
    margin: 0 ${({ theme }) => theme.spacing.xxs}px;

    &::placeholder {
        color: ${({ theme }) => theme.color.grey[6]};
        font-weight: 200;
    }
`;

const InputWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const AllInputsWrapper = styled.div`
    display: flex;
`;

const ErrorWrapper = styled.div<{ inline?: boolean }>`
    margin-left: ${({ theme, inline }) => (inline ? 0 : theme.spacing.xs)}px;
    margin-top: ${({ theme, inline }) => (inline ? theme.spacing.s : 0)}px;
    max-height: ${({ theme }) => theme.dimension.height.element}px;
`;

export default RangeInputField;
