import React, { FunctionComponent } from "react";
import styled from "styled-components";
import CheckBox from '../components/CheckBox';

interface ICheckboxCardProps {
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    renderBody?: React.ReactElement;
}

const CheckBoxCard: FunctionComponent<ICheckboxCardProps> = ({ checked, onChange, label, renderBody }) => {

    return (
            <CheckBoxCardWrapper style={{ background: checked ? '#E9F5FE' : '#F8F8F8' }} onClick={() => onChange(!checked)}>
                <CheckBoxAndLabel> 
                    <CheckBox checked={checked} onChange={onChange} rounded />
                    <LabelWrapper>{label}</LabelWrapper>
                </CheckBoxAndLabel>
                {!!renderBody && renderBody}
            </CheckBoxCardWrapper>
    );
};
 
const CheckBoxCardWrapper = styled.div`
    border: 0 solid;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    cursor: pointer;
    padding: ${({ theme }) => theme.spacing.xs}px;
    width: inherit;
`;

const CheckBoxAndLabel = styled.div`
    display: flex;
    align-items: center;
    margin-bottom : ${({ theme }) => theme.spacing.xs}px;
`;

const LabelWrapper = styled.label`
    padding-left: ${({ theme }) => theme.spacing.xs}px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-weight: normal;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    letter-spacing: 0;
    line-height: ${({ theme }) => theme.typography.size.S}px;
`;

export default CheckBoxCard;
