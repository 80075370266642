import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Struct, Title, QueryLoader } from 'components';
import { OfferTemplateRouteParam } from 'localTypes';
import { TagsEditor } from 'components/Tag';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import { getArticleTags } from 'types/getArticleTags';
import { withApollo } from '@apollo/client/react/hoc';
import { client } from '../../services/apollo/client'
import { BaseInput } from 'components/Inputs/common';
import styled from 'styled-components';

const LIST_ARTICLE_TAGS = loader('./Query/listArticleTags.gql');
const DELETE_ARTICLE_TAG = loader('./Query/deleteArticleTag.gql');
const CREATE_ARTICLE_TAG = loader('./Query/createArticleTag.gql');

interface IProps extends RouteComponentProps<{ idHolding: string; idPos: string }> {
    offerType: OfferTemplateRouteParam;
}

const TagEditorPage = ({
    match: {
        params: { idPos, idHolding },
    },
    offerType,
}: IProps) => {
    const { t } = useTranslation();
    
    return (<Struct.Section>
                <Title grow mode="H2" value={t("page:tableService.article-tag-manager.title") || ''}/>
                <Struct.Card>
                    <QueryLoader 
                        query={LIST_ARTICLE_TAGS}
                        variables={{idHolding:idHolding}}>
                        {({ loading, data, errors, refetch }: QueryResult<getArticleTags>) => {
                        const onRemove = async (tagId : string)  => {
                            await client.mutate({mutation : DELETE_ARTICLE_TAG, variables : { id: tagId}});
                            await refetch();
                        }

                        const inputKeyDown = (e : any) => {
                            const val = e.target.value;
                            if (e.key === 'Enter' && val) {
                                addNewTag(val);
                                e.target.value = '';
                            }
                        }

                        const addNewTag = async (label : string) => {
                            await client.mutate({ mutation : CREATE_ARTICLE_TAG, variables : { idHolding : idHolding, label: label }});
                            await refetch();
                        }
                        return (
                            <>
                            <Title mode="H3" value={t("page:tableService.article-tag-manager.list") || ''}/>
                            <TagsEditor onRemove={onRemove} options={data.getArticleTags}/>
                           
                            <Title mode="H3" value={t("page:tableService.article-tag-manager.add") || ''}/>
                            <MyInput type="text" onKeyDown={inputKeyDown} width={300} placeholder={t("page:tableService.article-tag-manager.placeholder") || ''} />
                            </>
                        )
                        }}
                    </QueryLoader>
                    
                </Struct.Card>
            </Struct.Section>
          
    );
};

// @ts-ignore
const MyInput = styled(BaseInput)`
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    text-align: left;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
`;

// @ts-ignore
export default withApollo(TagEditorPage);
