import { Icon } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme, DefaultTheme } from 'styled-components';

import { PageWithTabs } from 'components/Layout';
import appRoutes from 'Router/routes';

import routingCommunicationsPage from './routingCommunicationPages';

interface IProps {
    theme: DefaultTheme;
}

const getRoutes = (t: any) => [
    {
        tabKey: 'list',
        tabLabel: t('page:communication.communicationList.list'),
        path: appRoutes.communication.list,
        component: routingCommunicationsPage,
    },
];

const Communication = ({ theme }: IProps) => {
    const { t } = useTranslation();

    return (
        <PageWithTabs
            routes={getRoutes(t)}
            indexRoute={appRoutes.communication.index()}
            title={t(`app:app.communication`)}
            Icon={<Icon.Communication color={theme.color.grey[6]} />}
        />
    );
};

export default withTheme(Communication);
