import React, { useState } from 'react';
import { RouteComponentProps, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OfferTemplateRouteParam } from 'localTypes';
import { Struct, Title, Button } from 'components';
import FormulasTable from './FormulasTable';
import appRoutes from 'Router/routes';
interface IProps extends RouteComponentProps<{ idHolding: string; idPos: string }> {
    offerType: OfferTemplateRouteParam;
}
const Formulas = ({
    match: {
        params: { idHolding },
    },
    offerType,
}: IProps) => {
    const { t } = useTranslation();
    const [isFormulasPanelOpen, setFormulasPanelOpen] = useState(false);
    const toggleFormulasModal = () => {
      setFormulasPanelOpen(!isFormulasPanelOpen);
    };
    return (
        <Route
              exact
              path={appRoutes[offerType].formulas.get()}
              render={() => (
                  <Struct.Section>
                      <Title grow mode="H2" value={t('page:clickcollect.formula.title')}>
                          <Button id='add-formula-button' onClick={toggleFormulasModal}>{t('app:button.add-formula')}</Button>
                      </Title>
                      <Struct.Card>
                          <FormulasTable 
                            idHolding={idHolding}
                            toggleFormulasModal={toggleFormulasModal}
                            isFormulasPanelOpen={isFormulasPanelOpen}
                          />
                      </Struct.Card>
                  </Struct.Section>
              )}
          />
    );
};
export default Formulas;