import React, { ReactElement, useState, useEffect } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { ExitHandler } from 'react-transition-group/Transition';
import FocusLock from 'react-focus-lock';
import { rgba } from 'polished';
import { appTheme } from 'theme';
import { Icon, H3 } from 'components';
import Portal from 'components/Portal';

const ANIMATION_TIMEOUT = 500;
const DEFAULT_MODAL_WIDTH = 576;
const ESC_KEY = 27;

interface IProps {
    open?: boolean;
    children?: ReactElement | ReactElement[] | string | null | Function;
    title?: ReactElement | ReactElement[] | string | null;
    className?: string;
    width?: number;
    onClose: () => void;
    onExited?: ExitHandler;
    testID?: string;
}

export interface IRenderProps {
    onClose: () => void;
}

type TransitionState = 'exited' | 'entering' | 'entered' | 'exiting';

export function RoutablePanel({ open = true, ...props }: IProps) {
    const [isOpen, setOpen] = useState(open);
    const closeModal = () => setOpen(false);
    return <Panel {...props} open={isOpen} onClose={closeModal} onExited={props.onClose} />;
}

export function Panel({
    open = false,
    children,
    width = DEFAULT_MODAL_WIDTH,
    className,
    onClose,
    title,
    onExited,
    testID
}: IProps) {
    const onKeyDown = (event: any) => {
        return event.keyCode === ESC_KEY && onClose();
    };

    useEffect(() => {
        document.body.style.overflow = open ? 'hidden' : 'auto';
        if (open) window.addEventListener('keydown', onKeyDown);
        return () => {
            if (open) window.removeEventListener('keydown', onKeyDown);
        };
    });
    return (
        <Portal>
            <CSSTransition unmountOnExit in={open} timeout={ANIMATION_TIMEOUT} onExited={onExited}>
                {(state: TransitionState) => {
                    return (
                        <FocusLock>
                            <BackDrop onClick={onClose} state={state} />
                            <PanelTag
                                role="dialog"
                                tabIndex={-1}
                                aria-modal="true"
                                width={width}
                                className={className}
                                state={state}
                            >
                                <PanelHeader>
                                    <Title
                                      {...(testID && {'data-test':`${testID}-title`})}
                                    >
                                      {title}
                                    </Title>
                                    <Close
                                      tabIndex={1}
                                      onClick={onClose}
                                      {...(testID && {'data-test':`${testID}-close-btn`})}
                                    >
                                        <Icon.Close color={appTheme.color.grey[4]} />
                                    </Close>
                                </PanelHeader>
                                {typeof children === 'function' ? children({ onClose }) : children}
                            </PanelTag>
                        </FocusLock>
                    );
                }}
            </CSSTransition>
        </Portal>
    );
}

const PanelTag = styled.div<{ width: number; state: TransitionState }>`
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: ${({ theme }) => theme.color.common.white};
    right: ${({ width }) => `-${width}px`};
    width: ${({ width }) => `${width}px`};
    bottom: 0;
    top: 0;
    transition: ${({ theme }) => theme.transition.duration[2]}ms;
    overflow-y: scroll;
    transform: translateX(${({ state, width }) => (state === 'entered' ? -width : 0)}px);
`;

const PanelHeader = styled.div`
    display: flex;
    padding: ${({ theme }) => theme.spacing.m}px;
`;

// should be used to dislay any panel content
export const PanelContent = styled.div`
    padding: ${({ theme }) => theme.spacing.s}px ${({ theme }) => theme.spacing.m}px;
    flex: 1;
    overflow: auto;
`;

// should be used to dislay any panel content
export const PanelFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: ${({ theme }) => theme.spacing.s}px;
    box-shadow: ${({ theme }) => theme.boxShadow[1]};
`;

const Title = styled(props => <H3 {...props}/>)`
    flex: 1;
`;

const BackDrop = styled.div<{ state: TransitionState }>`
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ state, theme }) =>
        state === 'entered' ? rgba(theme.color.common.black, 0.5) : theme.color.common.transparent};
    pointer-events: ${({ state }) => (state === 'entered' ? 'default' : 'none')};
    transition: background-color ${({ theme }) => theme.transition.duration[2]}ms;
`;
const Close = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
