import { Struct, Title } from 'components';
import React, { ReactElement, ReactNode } from 'react';
import { OfferTemplateType } from 'localTypes';
import SelectLayoutPOS from './SelectLayoutPOS';

interface INavItemProps {
    title: string;
    children?: ReactNode;
    icon: ReactElement;
    offerTemplateType?: OfferTemplateType;
    hideSelectLayoutPOS?: boolean
}

const PageHeaderWithSelectPOS = ({ children, title, offerTemplateType, icon, hideSelectLayoutPOS }: INavItemProps) => {
    const renderSelectLayoutPOS = () => {
        if (!hideSelectLayoutPOS){
            return <SelectLayoutPOS offerTemplateType={offerTemplateType} />
        }
    }

    return (
        <Struct.Header>
            <Title mode="H1" value={title} icon={icon}>
                {renderSelectLayoutPOS()}
            </Title>
            {children}
        </Struct.Header>
    );
};

export default PageHeaderWithSelectPOS;
