import Modal from 'styled-react-modal'
import React from 'react'
import styled, { DefaultTheme } from 'styled-components';
import { isFunction } from "lodash";

import * as Icons from './Icons'
import { useTranslation } from 'react-i18next';
import { IconButton } from "./Button";
import { Icon } from "components";

export type TArticleErrorLabels = Array<{
  id: string;
  cashSystemCode: string | null;
  label: string;
}>

interface IProps {
    open: boolean;
    theme?: DefaultTheme;
    articleErrorLabels: TArticleErrorLabels;
    toggleModal: any;
    onClickItem?: (id: string) => void;
}

export function CheckOfferValidModal(props: IProps) {
    const { t } = useTranslation();

    return (
        <StyledModal
            isOpen={props.open} >
            <StyledHeader>
                <div>{t("component:checkOfferValidModal.title")}</div>
                <StyledButton onClick={props.toggleModal}><Icons.Close theme={props.theme} color={"#2196F3"} height={39} /></StyledButton>
            </StyledHeader>
            <StyledBody>
                <StyledBodyIcon>
                    { props.articleErrorLabels.length > 0 && <Icons.Warning theme={props.theme} color={"#F76262"} width={53} height={53} />}
                    { props.articleErrorLabels.length === 0 && <Icons.RoundedCheck theme={props.theme} color={"#55DA98"} width={53} height={53} />}
                </StyledBodyIcon>

                <StyledBodyText>
                    { props.articleErrorLabels.length > 0 && <ErrorText labels={props.articleErrorLabels} onClickItem={props.onClickItem}/>}
                    { props.articleErrorLabels.length === 0 && <OkText/>}
                </StyledBodyText>
            </StyledBody>
        </StyledModal>
    )
}

function ErrorText(props: {
  labels: Array<{ id: string; cashSystemCode: string | null; label: string }>;
  onClickItem?: (id: string) => void;
}) {
    const { t } = useTranslation();

    return (
        <StyledDiv>
            <span style={{color:'#F76262', fontWeight:"bold"}}>{t("component:checkOfferValidModal.errorText1")}</span>
            <hr />
            <LabelsContainer>
              {props.labels.map((l) => (
                  <BodyErrorContainer>
                    <BodyContainer>
                      ({l.cashSystemCode}) - {l.label}
                      </BodyContainer>
                    <IconButton
                      onClick={() => {
                        if (isFunction(props.onClickItem)) {
                          props.onClickItem(l.id as string);
                        }
                      }}
                      style={{ marginLeft: "5px" }}
                    >
                      <Icon.SolidArrow />
                    </IconButton>
                  </BodyErrorContainer>
              ))}
            </LabelsContainer>
            <hr />
            <div style={{ fontWeight: "bold" }}>{t("component:checkOfferValidModal.errorText2")}</div>
        </StyledDiv>
    )
}

function OkText() {
    const { t } = useTranslation();

    return (
        <StyledDiv>
            <span style={{color:'#55DA98', fontWeight:"bold"}}>{t("component:checkOfferValidModal.okText1")}</span><span>{t("component:checkOfferValidModal.okText2")}</span>
    <div style={{fontWeight:"bold"}}>{t("component:checkOfferValidModal.okText3")}</div>
        </StyledDiv>
    )
}

const StyledDiv = styled.div`
    color: #5F7081;
    text-align: center;
`

const StyledModal = Modal.styled`
    max-height: 500px;
    width: 442px;
    border-radius: 4px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction : column;
    font-family: "Open Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    justify-content: space-between;
`


const StyledHeader = styled.div`
  display: flex;
  flex-direction : row;
  border-radius: 4px 4px 0 0;
  background-color: #5F7081;
  color: #FFFFFF;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 15px;
`

const StyledBody = styled.div`
  display: flex;
  flex-direction : column;
  justify-content: stretch;
  align-items:center;
  flex: 1;
  padding: 20px;
  overflow-x: "scroll";
`

const StyledBodyIcon = styled.div`
  margin-bottom: 5px;
`

const StyledBodyText = styled.div`
`

const StyledButton = styled.button`
    background: none;
    border: 0px solid transparent;
    &:focus {
        outline: none;
    }
`;

const BodyErrorContainer = styled.div`
  display: flex;
  padding: 10px;
`;

const BodyContainer = styled.span`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 350px;
  // @ts-ignore
  text-wrap: nowrap;
`

const LabelsContainer = styled.div`
  overflow-y: scroll;
  max-height: 200px;
`
