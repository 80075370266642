import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Loader } from 'components';
import { ImageSlider as ImageSliderHtml } from 'utils';

const PreviewImage = loader('../../query/getImageForPreview.gql');

//Renders a Image Slider
export const ImageSliderPreview = ({ idImageList, index }: { idImageList: string[]; index: number }) => {
    const [htmlSlider, setHtmlSlider] = useState('');

    const list = idImageList.map((idImage) => {
        return idImage.replace('Image:', '');
    });

    const { loading, error, data } = useQuery(PreviewImage, {
        variables: {
            querySearch: {
                key: 'id',
                value: `${list.join(',')}`,
                operator: '*',
            },
        },
    });

    useEffect(() => {
        const element = document.getElementById(`imageSlider${index}`) as HTMLIFrameElement;
        if (!element) return;
        var doc = element.contentWindow!.document;
        doc.open();
        doc.write(htmlSlider);
        doc.close();
    }, [htmlSlider, index]);

    useEffect(() => {
        if (!loading && data && data.image) {
            const srcList = data.image.edges
                .sort((a, b) => {
                    const aIndex = idImageList.indexOf(a.node.id);
                    const bIndex = idImageList.indexOf(b.node.id);
                    return aIndex - bIndex;
                })
                .map((image) => `${window.config.IMAGE_BASE_URL}/resize/414x303/${image.node.path}`);
            setHtmlSlider(ImageSliderHtml(srcList));
        } else {
            setHtmlSlider('');
        }
    }, [data, loading, idImageList]);

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return null;
    }

    return <ImageSliderWrapper id={`imageSlider${index}`} src="about:blank" />;
};

const ImageSliderWrapper = styled.iframe`
    border: none;
    width: 100%;
    height: 320px;
    margin-top: 20px;
    margin-bottom: 20px;
`;
