
import React from 'react';
import { useTranslation } from 'react-i18next';
import appRoutes from 'Router/routes';
import { OfferTemplateType } from 'localTypes';
import { Icon } from 'components';
import TradePage from 'feature/TradePage';
import AdminPage from './AdminPage';
import DailyBookingServicePage from './DailyBookingServicePage';
import { withTheme, DefaultTheme } from 'styled-components';

interface IProps {
    theme: DefaultTheme;
}

const getRoutes = (t: any) => [
    {
        tabKey: 'daily-reservations',
        tabLabel: t('page:bookingService.tabReservation'),
        path: appRoutes.bookingservice.reservations.list,
        component: DailyBookingServicePage,
    },
    {
        tabKey: 'admin',
        tabLabel: t('page:bookingService.tabAdmin'),
        path: appRoutes.bookingservice.reservations.admin,
        component: AdminPage,
    },
];

const BookingService = ({ theme }: IProps) => {
    const { t } = useTranslation();
    return (
        <TradePage
            routes={getRoutes(t)}
            pageTitle={t('app:app.bookingService')}
            icon={<Icon.Booking color={theme.color.grey[6]}/>}
            offerTemplateType={OfferTemplateType.BookingService}
        />
    );
};

export default withTheme(BookingService);
