import get from 'lodash/get';
import React, { FunctionComponent } from 'react';
import { FieldProps } from 'formik';

import { TimeRangeInput } from 'components';
import FieldContainer from './FieldContainer';

interface IProps {
    className?: string;
    label?: string;
    disabled?: boolean;
    step?: number;
    testID?: string;
}

const TimeRangeInputField: FunctionComponent<FieldProps & IProps> = props => {
    const { field, form, step, disabled, testID } = props;
    const onChange = (value: string) => {
        form.setFieldValue(field.name, value);
        form.setFieldTouched(field.name);
    };

    return (
        <FieldContainer {...props}>
            <TimeRangeInput
                {...(testID && {testID:`${testID}-time-range-input`})}
                {...field}
                disabled={disabled}
                onChange={onChange}
                hasError={!!get(form.errors, field.name) && !!get(form.touched, field.name)}
                step={step}
            />
        </FieldContainer>
    );
};

export default TimeRangeInputField;
