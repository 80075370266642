import { FullRichTextInputField } from 'components';
import { OptionToolbar } from 'components/Inputs/FullRichTextInput';
import { Field } from 'formik';
import React from 'react';

//Renders a commponent that allows the user to input and style some content
export const ParagraphComponent = ({ id, rank, name, lang }) => {
    return (
        <Field
            id={id}
            name={name}
            component={FullRichTextInputField}
            optionsToolbar={[
                OptionToolbar.HEADING,
                OptionToolbar.BOLD,
                OptionToolbar.ITALIC,
                OptionToolbar.LINK,
                OptionToolbar.BULLET_LIST,
                OptionToolbar.NUMBERED_LIST,
                OptionToolbar.MEDIA_EMBED,
                OptionToolbar.UNDO,
                OptionToolbar.REDO,
            ]}
            minHeight={160}
            lang={lang}
        />
    );
};