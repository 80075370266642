import React, { useState } from 'react';
import { OfferItemFormulaToSell } from 'types/globalTypes';
import MultipleToggle from '../../../../components/Inputs/MultipleToggle';
import { Loader } from 'components';


interface IOfferItemsToggleProps {
    id: string;
    toSell: OfferItemFormulaToSell;
    onToSellUpdate: (id: string, toSell: OfferItemFormulaToSell) => void;
    preCheckArticle: ((article:string) => any) | undefined
    onErrorArticle?: (items:any)=> void
}

const OfferItemsFormulaToggle = ({
    id,
    toSell,
    onToSellUpdate,
    preCheckArticle,
    onErrorArticle
}: IOfferItemsToggleProps)  => {
    const [ toSellState, setToSellState ] = useState(toSell);
    const toSellList = [OfferItemFormulaToSell.DEACTIVATED, OfferItemFormulaToSell.ACTIVATED];
    const nextIndexes = {
        0: 1,
        1: 0,
    } as unknown as { number: number};
    const [ isLoading , setLoading ] = useState(false)

    const onChange = async (value: any) => {
      if(preCheckArticle && value === OfferItemFormulaToSell.ACTIVATED){
        setLoading(true)
        const articles = await preCheckArticle(id)
        setLoading(false)
        if(articles.length && onErrorArticle){
          onErrorArticle(articles)
          return;
        }
      }
        if(toSellState !== value) {
            setToSellState(value);
            onToSellUpdate(id, value);
        }
    }

    if(isLoading){
      return <div style={{ height : "43px" }}><Loader /></div>
    }

    return (
        <MultipleToggle
            values={toSellList}
            onChange={onChange}
            initialValue={toSellState}
            nextIndexes={nextIndexes}
            width={50}
            height={30}
            style={{
                selectedStyle: {
                    background: '#ffffff',
                    fontSize: '12px',
                    lineHeight: '32px',
                    textAlign: 'center',
                    transition: '250ms',
                },
                wrapperStyle: {
                    background: toSellState === OfferItemFormulaToSell.ACTIVATED ? '#70ad47' : '#d9d9d9',
                    padding: 0,
                },
            }}
        />
    );
}

export default OfferItemsFormulaToggle;
