import { Button, Struct } from 'components';
import Badge from 'components/Block/Badge';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import { appTheme } from 'theme';
import styled from 'styled-components';
import CommunicationListTable from './CommunicationListTable';


const CommunicationPage = ({ location, history }: RouteComponentProps) => {
    const { t } = useTranslation();
    const [totalCount, setTotalCount] = useState(0);
    const redirectToCommunicationCreatePage = () => {
        history.push(`${routes.communication.add()}`);
    };

    return (
        <Struct.Section>
            {totalCount === 0 ?
                <Container id='title_section'>
                    <H4>
                        {t('page:communication.communicationList.noContent')}                    
                    </H4>
                    <Button onClick={redirectToCommunicationCreatePage}>
                        <TextButton>{t('app:button.add-communication')}</TextButton>
                    </Button>
                </Container>
                :
                <Container id='title_section'>
                    <Badge 
                        pill 
                        color={appTheme.color.common.darkGrey} 
                        fontColor={appTheme.color.common.white} 
                        content={`${totalCount} ${t('page:communication.communicationList.active')}`}
                    />
                    <Button onClick={redirectToCommunicationCreatePage}>
                        <TextButton>{t('app:button.add-communication')}</TextButton>
                    </Button>
                </Container>
            }
            <Struct.Card>
                <CommunicationListTable loadTotalCount={(value: number) => {setTotalCount(value)}}/>
            </Struct.Card>
        </Struct.Section>
    );
};

const Container = styled.div`
    justify-content: space-between;
    flex-grow: 1;
    flex-direction: row;
    display: flex;
    margin-bottom: 20px;
`;

const TextButton = styled.span`    
    font-size: ${({ theme }) => theme.typography.size.S}px;
    line-height:  ${({ theme }) => theme.typography.lineHeight.S}px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.color.white};
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
`;

const H4 = styled.h4`
    flex-shrink: 0;
    color: ${({ theme }) => theme.color.grey[6]};
    font-size: ${({ theme }) => theme.typography.size.M}px;
    line-height:  ${({ theme }) => theme.typography.lineHeight.S}px;
    font-family: Helvetica;
    letter-spacing: 0;
    max-width: 850px;
    margin-top: 14px;
`;

export default withRouter(CommunicationPage);
