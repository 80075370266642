import { QueryLoader, RoutablePanel } from 'components';
import { IRenderProps as IPanelRenderProps } from 'components/Panel';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import AddOrEditCheckListUserForm from './AddOrEditCheckListUserForm';
import { getChecklistUser } from 'types/listChecklistUsers';

const CHECKLISTUSER_MUTATION = loader('../query/updateEPointageUser.gql');
const CHECKLISTUSER_QUERY = loader('../query/getEPointageUser.gql');

interface IPropsPanel extends RouteComponentProps<{ siteId: string, idChecklistUser: string }> {
    beforeClose: () => void;
    useMockdata?: boolean;
}

const EditCheckListUserPanel = ({
    match: {
        params: { idChecklistUser, siteId },
    },
    history: { push },
    location: { search },
    beforeClose,
}: IPropsPanel) => {
    const [t] = useTranslation();
    const onClose = () => {
        beforeClose();
        push(`${routes.checkList.admin.list(siteId)}${search}`);
    };

    return (
        <RoutablePanel title={t(`page:checkList.adminList.edit.title`)} open={!!idChecklistUser} onClose={onClose}> 
            {({ onClose }: IPanelRenderProps) => (
                <QueryLoader
                    hasData={(data: getChecklistUser): boolean => !!(data && data.user)}
                    variables={{ userId: parseInt(idChecklistUser) }}
                    query={CHECKLISTUSER_QUERY}
                >
                    {({ data: { user } }: QueryResult<getChecklistUser>) => {
                        return (
                            <Mutation
                                mutation={CHECKLISTUSER_MUTATION}
                                update={(cache: any, { data: { updateEPointageUser } }: any) => {
                                    const cachedData = cache.readQuery({
                                        query: CHECKLISTUSER_QUERY,
                                        variables: {
                                            userId: parseInt(idChecklistUser),
                                        },
                                    });
                                    cache.writeQuery({
                                        query: CHECKLISTUSER_QUERY,
                                        variables: {
                                            userId: parseInt(idChecklistUser),
                                        },
                                        data: { ...cachedData, user: updateEPointageUser },
                                    });
                                }}
                            >
                                {(updateEPointageUser: (param: Record<'variables', any>) => Promise<any>) => (
                                    <AddOrEditCheckListUserForm
                                        {...{
                                            onSuccess: onClose,
                                            user,
                                            updateEPointageUser,
                                            siteId
                                        }}
                                    />
                                )}
                            </Mutation>
                        );
                    }}
                </QueryLoader>
            )}
        </RoutablePanel>
    );
};

export default withRouter(EditCheckListUserPanel);
