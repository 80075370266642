import React from 'react';
import styled from 'styled-components';

import { Info, InfoType, InfoSize } from 'components';

interface IProps {
    name: string;
    children: string | number;
}

function OfferInfo({ children, name }: IProps) {
    return (
        <Container>
            <Name>{name}</Name>
            <Info size={InfoSize.TALL} display={InfoType.INFO}>
                {children}
            </Info>
        </Container>
    );
}

interface IInfoTagProps {}
const Container = styled.div<IInfoTagProps>`
    display: flex;
    align-items: center;
`;
const Name = styled.div`
    margin-right: ${props => props.theme.spacing.s / 2}px;
    color: ${props => props.theme.color.grey[6]};
    font-size: ${props => props.theme.typography.fontSizeM}px;
    text-transform: capitalize;
`;

export default OfferInfo;
