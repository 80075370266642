import { Struct, Title } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import UsersTable from './UsersTable';

const UsersPage = () => {
    const { t } = useTranslation();

    return (
        <Struct.Section>
            <Title grow mode="H2" value={t('page:admin.users.title')} testID={'administration-users-title'}/>
            <Struct.Card>
                <UsersTable />
            </Struct.Card>
        </Struct.Section>
    );
};

export default withRouter(UsersPage);
