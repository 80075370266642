import { FocusEvent } from 'react';
import styled from 'styled-components';

export enum InputState {
    UNSET,
    VALID,
    INVALID,
}
interface BaseInputProps {
    readonly hasError?: boolean;
    readonly useDefaultErrorColoring?: boolean;
    readonly defaultBorderColor?: string;
    readonly validateOnChange?: boolean;
}

export const BaseInput = styled.input<BaseInputProps>`
    height: ${({ theme }) => theme.dimension.height.element}px;
    padding: ${({ theme }) => theme.spacing.xs}px;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    border: 1px solid ${({ theme }) => theme.color.input.borderColor};
    font-size: ${({ theme }) => theme.typography.fontSizeL}px;
    color: ${({ theme }) => theme.color.input.textColor};
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    text-align: center;
    &::placeholder {
        color: ${({ theme }) => theme.color.input.placeholderColor};
    }
    &:not(:focus) {
        border: 1px solid
            ${({ hasError, useDefaultErrorColoring = true, theme }) => {
                if (useDefaultErrorColoring) {
                    return hasError ? theme.color.input.borderColorError : theme.color.input.borderColor;
                }
                return theme.color.input.borderColor;
            }};
        background-color: ${({ hasError, useDefaultErrorColoring = true, theme }) => useDefaultErrorColoring ?
            hasError ? theme.color.input.backgroundColorError : theme.color.input.backgroundColor
            : theme.color.input.backgroundColor};
        color: ${({ hasError, useDefaultErrorColoring = true, theme }) => (useDefaultErrorColoring ?
            hasError ? theme.color.input.textColorError : theme.color.input.textColor
            : theme.color.input.textColor )};
    }
    &:disabled {
        background-color: ${({ theme }) => theme.color.input.backgroundColorDisabled};
    }

    &:focus {
        ${({ theme, validateOnChange, hasError }) => validateOnChange && hasError && `
            outline-width: 0;
            outline: none !important;
            border: 1px solid ${theme.color.input.borderColorError}
        `}
    }
`;

interface BaseInputSecondaryProps {
    readonly state?: InputState;
}

// @ts-ignore
export const BaseInputSecondary = styled(BaseInput)<BaseInputSecondaryProps>`
    border: 1px solid ${({ theme, state }) => state === InputState.INVALID ? theme.color.input.borderColorError : theme.color.input.borderColorSecondary};
    outline: none !important;
    color: ${({ theme }) => theme.color.input.textColorSecondary};
    &:not(:focus) {
        color: ${({ theme }) => theme.color.input.textColorSecondary};
        font-weight: ${({ theme, state }) => state === InputState.VALID ? theme.typography.fontWeight.light : theme.typography.fontWeight.bold};
        border: 1px solid
            ${({ theme, state }) => {
                if (state === InputState.INVALID) {
                    return theme.color.input.borderColorError
                }
                return theme.color.input.borderColorSecondary;
            }};
    }
    &:focus {
        border-color: ${({ theme, state }) => state === InputState.VALID && theme.color.input.borderColorValid}
    }
`;

export const BaseInputThirdly = styled(BaseInput)<BaseInputSecondaryProps>`
    border: 1.2px solid ${({ theme, state }) => state === InputState.INVALID ? theme.color.input.borderColorError : theme.color.input.borderColor};
    outline: none !important;
    color: ${({ theme }) => theme.color.input.textColorSecondary};
    &:not(:focus) {
        color: ${({ theme }) => theme.color.input.textColorSecondary};
        font-weight: ${({ theme, state }) => state === InputState.VALID ? theme.typography.fontWeight.light : theme.typography.fontWeight.bold};
        border: 1.2px solid ${({ theme, state }) => state === InputState.INVALID ? theme.color.input.borderColorError : theme.color.input.borderColor};
    }
    &:focus {
        border-color: ${({ theme, state }) => state === InputState.VALID && theme.color.input.borderColorValid}
    }
    height: 40px;
`;

export interface IInputProps<T> {
    value?: T;
    onChange: (value: T) => void;
    onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    name?: string;
    className?: string;
    hasError?: boolean;
    disabled?: boolean;
    placeholder?: string;
    testID?: string;
}
