import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'components';
import Portal from 'components/Portal';
import { ButtonType } from 'components/Button';

type Props = {
  message: string;
  isOpen: boolean;
  showBtn?: boolean;
  btnOnClick?: () => void;
  btnText?: string;
  showClose?: boolean;
  onClose?: () => void;
};

const InfoModal = ({ message, isOpen, showBtn = false, btnOnClick, btnText, showClose = false, onClose } : Props) => {
    const { t } = useTranslation();
    const closeLabel = t('app:button.close');

    if (!isOpen) return null;
    return (
        <Portal>
            <BackDrop />
            <ModalContainer className="modal">
                <MessageIconWrapper>
                    <MessageContainer>{message}</MessageContainer>
                    {showClose && (
                        <BtnContainer onClick={onClose}>
                            <CrossIcon title={closeLabel}/>
                        </BtnContainer>
                    )}
                </MessageIconWrapper>
                {showBtn && (
                    <BtnContainer>
                        <Button onClick={btnOnClick} display={ButtonType.CONFIRMATION}>
                            {btnText}
                        </Button>
                    </BtnContainer>
                )}
            </ModalContainer>
        </Portal>
    );
};

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 4;
    width: 542px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.color.common.white};
`;

const BackDrop = styled.div`
    overflow: hidden;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => rgba(theme.color.common.black, 0.5)};
    pointer-events: 'default';
`;

const MessageContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 459px;
    font-size: ${({ theme }) => theme.typography.size.L}px;
    line-height:  ${({ theme }) => theme.typography.lineHeight.M}px;
    color: ${({ theme }) => theme.color.common.navy};
    font-family: ${({ theme }) => theme.typography.fontFamilySecondary};
    font-weight: normal;
`;
const BtnContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 25px;
`;

const CrossIcon = styled(Icon.Cross)`
  margin-left: 10px;
`;

const MessageIconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 25px 25px 20px 25px;
    align-items: baseline;
`;

export default InfoModal;
