import {
    Icon,
    PriceInputField,
    QueryLoader,
    RoutablePanel,
    SelectField,
    Struct,
    SubmitButton,
    TagField,
    Text,
    TextInputField,
} from 'components';
import { IRenderProps as IPanelRenderProps, PanelContent, PanelFooter } from 'components/Panel';
import { Field, Form, Formik } from 'formik';
import { rgba } from 'polished';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation, Trans } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import { getArticleFamilyOptions } from 'services/articleService';
import styled from 'styled-components';
import { appTheme } from 'theme';
import { getOfferItem, getOfferItem_offerItem_OfferItem } from 'types/getOfferItem';
import { ArticleBaking } from 'types/globalTypes';
import {
    getTableServiceOfferTemplates_ArticleCertifications_edges_node_ArticleCertification as ArticleCertificationsEdge,
    getTableServiceOfferTemplates_ArticleTags_edges_node_ArticleTag as ArticleTagsEdge,
} from 'types/getTableServiceOfferTemplates';
import get from "lodash/get";
import { ImportationType } from 'types/globalTypes';
import { LocalStorageKey, getItem } from 'services/persistentData';

const ORACLE_CASHSYSTEM_ID = '3';

const FORM_ID = 'product_edit_form';

const UPDATE_TABLE_SERVICE_OFFER_ITEM_MUTATION = loader('./query/updateTableServiceOfferItem.gql');
const LOCAL_ARTICLE_QUERY = loader('./query/getOfferItem.gql');

interface IPropsPanel {
    beforeClose: () => void;
    idOfferItem: string;
    idHolding: string;
    idPos: string;
    idOffer?: string
    articleTags?: ArticleTagsEdge[];
    articleLabels?: ArticleCertificationsEdge[];
}

type SubmitValueType = {
  idCash: string;
  priceHint: string;
  idsArticleTags: string;
  idsArticleLabels: string;
  baking: string;
  family: string;
  label: string;
  printer: string;
  importationType?: ImportationType
}

const makeTagArray = (castedofferItem: any, propertyName: string) => {
    return castedofferItem && castedofferItem[propertyName].reduce((acc: [], a: any) => acc.concat(a.id), [] as any);
};

const EditOfferItemPanel = ({
    idOfferItem,
    idHolding,
    idPos,
    idOffer,
    history: { push },
    location: { search },
    beforeClose,
    articleTags,
    articleLabels,
}: IPropsPanel & RouteComponentProps) => {
    const isClickAndCollect = !!idOffer;
    const [t] = useTranslation();
    const onClose = () => {
        beforeClose();
        if (isClickAndCollect) {
            push(`${routes.cnc.offers.getOffer(idHolding, idPos, idOffer)}${search}`);
        } else {
            push(`${routes.tableservice.dailyOffer.list(idHolding, idPos)}${search}`);
        }
    };

    return (
        <RoutablePanel title={t(`page:tableService.daily-offers.editForm.title`)} onClose={onClose}>
            {({ onClose }: IPanelRenderProps) => (

                <QueryLoader
                    hasData={(data: getOfferItem): boolean => !!(data && data.offerItem)}
                    variables={{ id: idOfferItem }}
                    query={LOCAL_ARTICLE_QUERY}
                >
                    {({ data: { offerItem } }: QueryResult<getOfferItem>) => {
                        const castedofferItem = offerItem && (offerItem as getOfferItem_offerItem_OfferItem);
                        const bakingOptions = Object.values(ArticleBaking).map(baking => ({
                            id: baking,
                            label: t(`schema:ArticleBaking.${baking}`),
                        }));
                        const posPrinters = get(offerItem, 'offer.offerTemplate.pos.posHub.posPrinters', [])
                        const printers = posPrinters.map(({ id, printer: { name } }) => ({
                            id: id,
                            label: name,
                        }));

                        // @ts-ignore
                        const isMealHeart = get(castedofferItem, 'offer.offerTemplate.mealHeartRule.selectedFamilies', []).includes(castedofferItem.localArticle.article.family);
                        const defaultPrinter = get(castedofferItem, 'offerItemPrinter.posPrinter.id',
                            // @ts-ignore
                            posPrinters.find(({ scopes }) => scopes.includes(castedofferItem.localArticle.article.family))?.id
                            // @ts-ignore
                            || posPrinters.find((posPrinter) => posPrinter.default)?.id
                        )
                        return (
                            <Mutation mutation={UPDATE_TABLE_SERVICE_OFFER_ITEM_MUTATION}>
                                {(updateTableServiceOfferItem: (param: Record<'variables', any>) => Promise<any>) => (
                                    <Formik
                                        initialValues={
                                            castedofferItem
                                                ? {
                                                    priceHint: castedofferItem.localArticle.price.amount,
                                                    idCash: castedofferItem.localArticle.cashSystemCode,
                                                    family: castedofferItem.localArticle.article.family,
                                                    importationType: castedofferItem.localArticle.article.importationType,
                                                    lastUse: castedofferItem.localArticle.article.lastUse,
                                                    baking: castedofferItem.baking,
                                                    idsArticleLabels: makeTagArray(
                                                        castedofferItem,
                                                        'articleCertifications'
                                                    ),
                                                    idsArticleTags: makeTagArray(castedofferItem, 'articleTags'),
                                                    label: castedofferItem.localArticle.label,
                                                    ...(isMealHeart ? {
                                                        printer: defaultPrinter,
                                                    } : {})

                                                }
                                                : { importationType: undefined }
                                        }
                                        onSubmit={(values, { setSubmitting }) => {
                                         
                                            const {
                                                idCash,
                                                priceHint,
                                                idsArticleTags,
                                                idsArticleLabels,
                                                baking,
                                                family,
                                                label,
                                                printer
                                            } = values as SubmitValueType;
                                            const payload = {
                                                variables: {
                                                    idOfferItem,
                                                    idLocalArticle: castedofferItem!.localArticle.id,
                                                    idArticle: castedofferItem!.localArticle.article.id,
                                                    cashSystemCode: idCash,
                                                    price: priceHint,
                                                    baking,
                                                    family,
                                                    label,
                                                    ...(isMealHeart && defaultPrinter != printer ? {
                                                        offerItemPrinter: {
                                                            // @ts-ignore
                                                            id: castedofferItem!.offerItemPrinter?.id,
                                                            posPrinter: {
                                                                id: printer,
                                                            }
                                                        }
                                                    } : {}),
                                                },
                                            }
                                            if (!isClickAndCollect) {
                                                payload.variables['idsArticleTag'] = idsArticleTags;
                                                payload.variables['idsArticleCertification'] = idsArticleLabels;
                                            }
                                            updateTableServiceOfferItem(payload)
                                                .then(({ errors }: any) => {
                                                    if (errors) {
                                                        throw errors;
                                                    }
                                                    if (onClose) {
                                                        onClose();
                                                    }
                                                    setSubmitting(false);
                                                })
                                                .catch((error: any) => {
                                                    console.log(error);
                                                    setSubmitting(false);
                                                });
                                        }}
                                    >
                                        {({ errors, isSubmitting, values, initialValues, isValid }) => {
                                            return (
                                                <>
                                                    <StyledPanelContent>
                                                      {
                                                        // @ts-ignore
                                                        <StyledForm id={FORM_ID}>
                                                            <Section>
                                                                <Field
                                                                    label={t('schema:localArticle.label')}
                                                                    name="label"
                                                                    component={TextInputField}
                                                                    disabled={isClickAndCollect}
                                                                />
                                                                <Field
                                                                    label={t('schema:localArticle.importType')}
                                                                    name="importationType"
                                                                    component={TextInputField}
                                                                    disabled={true}
                                                                    value={
                                                                        castedofferItem?.localArticle?.article
                                                                            ?.importationType ===
                                                                        ImportationType.Other
                                                                            ? t('schema:localArticle.manual')
                                                                            : castedofferItem?.localArticle?.article
                                                                                  ?.importationType
                                                                    }
                                                                />
                                                                {castedofferItem?.localArticle?.article?.lastUse&&<Field
                                                                    label={t('schema:localArticle.offeredOn')}
                                                                    name="lastUse"
                                                                    component={TextInputField}
                                                                    disabled={true}
                                                                />}
                                                                <Field
                                                                    label={t('schema:localArticle.price.amount')}
                                                                    name="priceHint"
                                                                    type="numeric"
                                                                    component={PriceInputField}
                                                                />
                                                                <Field
                                                                    label={t('schema:localArticle.cashSystemCode')}
                                                                    name="idCash"
                                                                    component={TextInputField}
                                                                    disabled={
                                                                        getItem(LocalStorageKey.CASH_SYSTEM_ID) ===
                                                                            ORACLE_CASHSYSTEM_ID &&
                                                                        values &&
                                                                        values?.importationType ===
                                                                            ImportationType.Oscar
                                                                    }
                                                                />
                                                                <Field
                                                                    disabled
                                                                    label={t('schema:localArticle.family')}
                                                                    name="family"
                                                                    data={getArticleFamilyOptions(true)}
                                                                    component={SelectField}
                                                                    fullWidth
                                                                />
                                                                {isMealHeart && <Field
                                                                    label={t('schema:localArticle.printer')}
                                                                    name="printer"
                                                                    data={printers}
                                                                    component={SelectField}
                                                                    fullWidth
                                                                />}

                                                            </Section>
                                                            <LowerSection>
                                                                <Alert>
                                                                    <Icon.Info/>
                                                                    <Text color={appTheme.color.common.red}>
                                                                        <Trans
                                                                            i18nKey="page:tableService.daily-offers.editForm.attentionMsg">
                                                                            _<strong>_</strong>_
                                                                        </Trans>
                                                                    </Text>
                                                                </Alert>
                                                                {(!isClickAndCollect) &&
                                                                    <Field
                                                                        label={t('schema:offerItem.labels')}
                                                                        subLabel={t(
                                                                            'page:tableService.daily-offers.editForm.onlyToday'
                                                                        )}
                                                                        name="idsArticleLabels"
                                                                        options={articleLabels}
                                                                        component={TagField}
                                                                    />
                                                                }
                                                                <Field
                                                                    label={t('schema:offerItem.bakings')}
                                                                    subLabel={t(
                                                                        'page:tableService.daily-offers.editForm.onlyToday'
                                                                    )}
                                                                    name="baking"
                                                                    options={bakingOptions}
                                                                    component={TagField}
                                                                />
                                                                {(!isClickAndCollect) &&
                                                                    <Field
                                                                        label={t('schema:offerItem.tags')}
                                                                        subLabel={t(
                                                                            'page:tableService.daily-offers.editForm.onlyToday'
                                                                        )}
                                                                        name="idsArticleTags"
                                                                        options={articleTags}
                                                                        component={TagField}
                                                                    />
                                                                }
                                                            </LowerSection>
                                                        </StyledForm>}
                                                    </StyledPanelContent>
                                                    <PanelFooter>
                                                        <SubmitButton
                                                            formHasNoChange={isEqual(values, initialValues)}
                                                            form={FORM_ID}
                                                            disabled={
                                                                !isValid ||
                                                                isEqual(values, initialValues) ||
                                                                Object.entries(errors).length !== 0 ||
                                                                isSubmitting
                                                            }
                                                        />
                                                    </PanelFooter>
                                                </>
                                            );
                                        }}
                                    </Formik>
                                )}
                            </Mutation>
                        );
                    }}
                </QueryLoader>
            )}
        </RoutablePanel>
    );
};

// @ts-ignore
const Alert = styled(Struct.Card)`
  margin: ${({ theme }) => theme.spacing.s}px 0px;
  border: ${({ theme }) => `1px solid ${theme.color.grey[2]}`};
  color: ${({ theme }) => theme.color.common.red};
  display: flex;
`;

const StyledForm = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledPanelContent = styled(PanelContent)`
  padding: 0;
`;

const Section = styled.div`
  padding: ${({ theme }) => theme.spacing.s}px ${({ theme }) => theme.spacing.m}px;
`;

const LowerSection = styled(Section)`
  border-top: ${({ theme }) => `1px solid ${theme.color.grey[2]}`};
  background-color: ${({ theme }) => rgba(theme.color.grey[2], 0.1)};
  flex: 1;
`;

export default withRouter(EditOfferItemPanel);
