import React, { ReactElement } from 'react';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import { QueryLoader } from 'components';
import { getNodes } from 'services/queryService';
import { listArticleConcepts, listArticleConcepts_list_edges_node_ArticleConcept } from 'types/listArticleConcepts';

const ARTICLE_CONCEPT_LIST_QUERY = loader('../../query/listArticleConcepts.gql');

interface IArticleConceptListProps {
    children: (
        props: QueryResult<listArticleConcepts> & {
            concepts: listArticleConcepts_list_edges_node_ArticleConcept[];
        }
    ) => ReactElement;
}

const ArticleConceptList = ({ children }: IArticleConceptListProps) => (
  // @ts-ignore
    <QueryLoader
        displayErrorMessage={false}
        hasData={(data: listArticleConcepts): boolean => !!(data && data.list)}
        query={ARTICLE_CONCEPT_LIST_QUERY}
    >
        {(props: QueryResult<listArticleConcepts>) => {
            const concepts = getNodes(props.data.list) as listArticleConcepts_list_edges_node_ArticleConcept[];
            return children({ ...props, concepts });
        }}
    </QueryLoader>
);

export default ArticleConceptList;
