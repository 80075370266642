import { Column, DurationPicker, Icon, IntervalPicker, Row } from 'components';
import { Duration } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDateTime } from 'services/dateService';
import styled from 'styled-components';
import { appTheme } from 'theme';

interface IProps {
    value: string | undefined;
    type: 'Both' | 'Start' | 'End';
    isOpen?: boolean;
    initialValue: string;
    enabled?: boolean;
    onClose: () => void;
    onClick: () => void;
    onSelect: Function;
    hasError: boolean | undefined;
}

function SelectDateAndTime({
    value,
    type,
    isOpen = false,
    initialValue,
    onClose,
    onClick,
    onSelect,
    hasError,
    enabled = true,
}: IProps) {
    const { t } = useTranslation();
    const dicText = {
        Both: t(`page:admin.cnc.fromTo`),
        Start: t(`page:admin.cnc.beginWith`),
        End: t(`page:admin.cnc.endWith`),
    };
    const initialDuration = value !== undefined ? value : initialValue;
    const getTimeFromDuration = (duration: string) => {
        return getDateTime(Duration.fromISO(duration))
            .toISOTime()
            .split('.')[0]
            .slice(0, -3);
    };
    const [selectDayTime, setSelectDayTime] = useState(initialDuration);
    const [selectedDay, setSelectedDay] = useState(
        value === undefined
            ? t(`page:admin.cnc.day`)
            : t(
                  `app:weekDays.${getDateTime(
                      Duration.fromISO(type === 'Both' ? initialDuration.split('/')[0] : initialDuration)
                  )
                      .toJSDate()
                      .getDay()}`
              )
    );
    const [selectedTime, setSelectedTime] = useState(
        value === undefined
            ? ''
            : type === 'Both'
            ? getTimeFromDuration(initialDuration.split('/')[0]) +
              '/' +
              getTimeFromDuration(initialDuration.split('/')[1])
            : getTimeFromDuration(initialDuration)
    );

    const onSelectDurationPicker = (duration: string) => {
        setSelectDayTime(duration);
        const dateTime = getDateTime(Duration.fromISO(duration));
        setSelectedTime(getTimeFromDuration(duration));
        setSelectedDay(t(`app:weekDays.${dateTime.toJSDate().getDay()}`));
        onSelect(duration);
    };

    const onSelectIntervalPicker = (duration: string) => {
        setSelectDayTime(duration);
        const dateTimeFrom = getDateTime(Duration.fromISO(duration.split('/')[0]));
        setSelectedTime(
            getTimeFromDuration(duration.split('/')[0]) + '/' + getTimeFromDuration(duration.split('/')[1])
        );
        setSelectedDay(t(`app:weekDays.${dateTimeFrom.toJSDate().getDay()}`));
        onSelect(duration);
    };

    return (
        <Column>
            <Row>
                <InputDate
                    hasError={hasError}
                    enabled={enabled || value !== undefined}
                    onClick={() => {
                        if ((enabled || value !== undefined) && onClick) {
                            onClick();
                        }
                    }}
                >
                    <WrapperDayTime>
                        <Row auto>
                            <StyledIconCalendar
                                color={
                                    hasError
                                        ? appTheme.color.common.darkRed
                                        : enabled || value !== undefined
                                        ? appTheme.color.grey[6]
                                        : appTheme.color.grey[0]
                                }
                            />
                            <SelectedDay hasError={hasError} enabled={selectedTime !== ''} bold={true}>
                                {selectedDay}
                            </SelectedDay>
                        </Row>
                        {selectedTime && type !== 'Both' && (
                            <WrapperTime>
                                <SelectedDay enabled={selectedTime !== ''} bold={false}>
                                    {dicText[type]}
                                </SelectedDay>
                                <SelectTime>{selectedTime}</SelectTime>
                            </WrapperTime>
                        )}
                        {selectedTime && type === 'Both' && (
                            <WrapperTime>
                                <SelectedDay enabled={selectedTime !== ''} bold={false}>
                                    {dicText[type].split('/')[0]}
                                </SelectedDay>
                                <SelectTime>{selectedTime.split('/')[0]}</SelectTime>
                                <SelectedDay enabled={selectedTime !== ''} bold={false}>
                                    {dicText[type].split('/')[1]}
                                </SelectedDay>
                                <SelectTime hasError={hasError}>{selectedTime.split('/')[1]}</SelectTime>
                            </WrapperTime>
                        )}
                    </WrapperDayTime>
                </InputDate>
            </Row>
            {isOpen && type !== 'Both' && (
                <DurationPicker
                    value={value}
                    label={dicText[type]}
                    initialValue={selectDayTime}
                    onClose={() => {
                        if (selectDayTime === initialValue) onSelectDurationPicker(initialValue);
                        onClose();
                    }}
                    onChange={duration => onSelectDurationPicker(duration)}
                />
            )}
            {isOpen && type === 'Both' && (
                <IntervalPicker
                    value={selectDayTime}
                    valueFrom={selectDayTime.split('/')[0]}
                    valueTo={selectDayTime.split('/')[1]}
                    onClose={() => {
                        if (selectDayTime === initialValue) onSelectIntervalPicker(initialValue);
                        onClose();
                    }}
                    onChange={duration => onSelectIntervalPicker(duration)}
                />
            )}
        </Column>
    );
}

interface IInputDate {
    enabled: boolean;
    hasError: boolean | undefined;
}

const InputDate = styled.div<IInputDate>`
    height: ${({ theme }) => theme.dimension.height.element}px;
    padding: 5px;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    font-size: ${({ theme }) => theme.typography.fontSizeL}px;
    color: ${({ theme, enabled, hasError }) =>
        hasError ? theme.color.common.darkRed : enabled ? theme.color.grey[6] : theme.color.grey[0]};
    background-color: ${({ theme, hasError }) => (hasError ? theme.color.common.lightRed : '')};
    text-align: left;
    border: 1px solid
        ${({ theme, enabled, hasError }) =>
            hasError ? theme.color.common.darkRed : enabled ? theme.color.grey[6] : theme.color.grey[0]};
    flex-wrap: wrap;
    margin-left: 5px;
    margin-bottom: 10px;
    width: 360px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.lighter};
    text-align: center;
    transition: color ${({ theme }) => theme.transition.duration[1]}ms,
        background-color ${({ theme }) => theme.transition.duration[1]}ms,
        border ${({ theme }) => theme.transition.duration[1]}ms;
`;

const StyledIconCalendar = styled(Icon.Calendar)`
    margin: 2px 10px 2px 10px;
`;

const WrapperDayTime = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
`;

const WrapperTime = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 10px;
`;

interface ISelectTime {
    hasError?: boolean | undefined;
}

const SelectTime = styled.div<ISelectTime>`
    height: 19px;
    margin-left: 10px;
    margin-right: 10px;
    color: ${({ theme, hasError }) => (hasError ? theme.color.common.darkRed : theme.color.common.blue)};
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    line-height: 19px;
    text-align: right;
`;

interface ISelectedDay {
    enabled: boolean;
    bold: boolean;
    hasError?: boolean | undefined;
}

const SelectedDay = styled.div<ISelectedDay>`
    justify-content: flex-start;
    height: 19px;
    color: ${({ theme, hasError }) => (hasError ? theme.color.common.darkRed : theme.color.grey[6])};
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: ${({ bold, theme }) => (bold ? theme.typography.fontWeight.bold : '')};
    line-height: 19px;
    opacity: ${({ enabled }) => (enabled ? 1 : 0.13)};
`;

export default SelectDateAndTime;
