import React from 'react';
import { Button, Row, TextInputField, Table } from 'components';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { ButtonType } from 'components/Button';
import { ComponentHeader } from 'pages/Communication/AddOrEditForm/components/ComponentHeader';
import { ButtonsRowContainer, StyledTD, Wrapper } from '../ServicesPage.style';
import HourInputField from 'components/Inputs/FormikFields/HourInputField';
import CheckBoxInputField from 'components/Inputs/FormikFields/CheckBoxInputField';

interface IProps {
    field: any;
    form: any;
    asRow: boolean;
    disabled: boolean;
}

interface ISchedule {
    id: number;
    name: string;
    deadline: string;
    isInvoice: boolean;
    order: number;
}

interface IInputValue {
    fieldName: String;
    value: any;
}

const SCHEDULES_MAX = 3;

const ScheduleTable: React.FC<IProps> = React.memo(({ field, form, asRow, disabled }) => {
    const [t] = useTranslation();

    const defaultScheduleValues = {
        id: undefined,
        name: "",
        deadline: "00:00",
        nbJours: 0,
        isInvoice: false,
        order: field?.value?.length + 1,

    };

    const schedulesList = field?.value?.length ? 
        [...field.value] :
        [{...defaultScheduleValues}];

    const sortedSchedulesList = schedulesList.sort((firstSchedule, secondSchedule) => firstSchedule.order - secondSchedule.order );
    const isServicesListAtMax = field?.value?.length >= SCHEDULES_MAX;
    const alreadyHaveInvoiceActive = sortedSchedulesList.filter((schedule: ISchedule) => schedule.isInvoice === true ).length > 0;

    const onChange = (value: ISchedule[]) => {
        form.setFieldValue(field.name, value);
        form.setFieldTouched(field.name, true);
    };

    const onAdd = () => {
        onChange([
            ...sortedSchedulesList,
            {...defaultScheduleValues}
        ]);
    };

    const editSchedule = (index: number, param: string) => (inputValue?: IInputValue) => {
        if (param === 'isInvoice' && alreadyHaveInvoiceActive && inputValue?.value) return;
        onChange(sortedSchedulesList.map((schedule: ISchedule, i: number) => ((i === index) ? { ...schedule, [param]: inputValue?.value } : schedule)));
    };

    const renderLine = (schedule: ISchedule, index: number) => {
        return (
            <tr key={`${index}`}>
                <StyledTD width={'15%'} >
                    <Field
                        key={`name_${index}`}
                        id={`name_${index}`}
                        form={form}
                        name={`name_${index}`}
                        component={TextInputField}
                        width={250}
                        value={schedule?.name}
                        onChange={editSchedule(index, 'name')}
                    />
                </StyledTD>
                <StyledTD width={'10%'} >
                    <Field
                        key={`deadline${index}`}
                        form={form}
                        id={`deadline${index}`}
                        name={`deadline${index}`}
                        style={{ textAlign: "center", maxWidth: 70 }}
                        component={HourInputField}
                        value={schedule?.deadline}
                        onChange={editSchedule(index, 'deadline')}
                    />
                </StyledTD>
                <StyledTD width={'15%'} >
                    <Field
                        key={`isInvoice${index}`}
                        form={form}
                        id={`isInvoice${index}`}
                        name={`isInvoice${index}`}
                        component={CheckBoxInputField}
                        value={schedule?.isInvoice}
                        onChange={editSchedule(index, 'isInvoice')}
                    />
                </StyledTD>
                <StyledTD width={'5%'} >
                    <ButtonsRowContainer>
                        <ButtonsRow index={index} isDisabled={!!schedule?.id || sortedSchedulesList.length <= 1} />
                    </ButtonsRowContainer>
                </StyledTD>
            </tr>
        );
    };

    const ButtonsRow = React.memo(({ index, isDisabled }: { index: number, isDisabled: boolean }) => (
        <Row>
            <ComponentHeader
                disableDelete={isDisabled}
                rank={index}
                hideRank
                componentDescription=''
                onDelete={(rank: number, e: React.MouseEvent<Element, MouseEvent>) => {
                    e.preventDefault();
                    onChange(sortedSchedulesList.filter((schedule: ISchedule, index: number) =>  index !== rank));
                }}
                onUpComponent={(rank: number, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.preventDefault();
                    const newScheduleList = [...sortedSchedulesList];
                    newScheduleList[rank - 1] = sortedSchedulesList[rank];
                    newScheduleList[rank] = sortedSchedulesList[rank - 1];
                    newScheduleList[rank - 1].order -= 1;
                    newScheduleList[rank].order += 1;
                    onChange(newScheduleList);
                }}
                onDownComponent={(rank: number, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.preventDefault();
                    const newScheduleList = [...sortedSchedulesList];
                    newScheduleList[rank + 1] = sortedSchedulesList[rank];
                    newScheduleList[rank] = sortedSchedulesList[rank + 1];
                    newScheduleList[rank + 1].order += 1;
                    newScheduleList[rank].order -= 1;
                    onChange(newScheduleList);
                }}
                totalComponents={sortedSchedulesList.length}
            />
        </Row>
    ));

    const getHeaders = (t: any) =>
    [
        {title: 'name', center: false, required: true},
        {title: 'deadline', center: false, required: true},
        {title: 'isInvoice', center: true, required: false},
        {title: 'actions', center: false, required: false},
    ].map(({title, center, required}) => ({ key: title, displayName: t(`schema:checkListUser.services.schedulerList.${title}`), center, required }));

    return (
        <Wrapper>
            <Table renderLine={renderLine} data={sortedSchedulesList} headers={getHeaders(t)} noSeparator />
            <Button
                disabled={isServicesListAtMax}
                type="button"
                display={isServicesListAtMax ? ButtonType.GHOST : ButtonType.SECONDARY}
                onClick={onAdd}
                inline
            >
                + {t('page:checkList.services.addComponent')}
            </Button>
        </Wrapper>
    );
});

export default ScheduleTable;
