import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import isEqual from 'lodash/isEqual';
import { Field, Form, Formik } from 'formik';
import {
    Panel,
    TextInputField,
    SubmitButton,
    ImageInputField,
    InfoLabel,
    PriceInputField,
    FormulaStepCreation,
    Select
} from 'components';
import * as Yup from 'yup';
import { PanelContent, PanelFooter } from 'components/Panel';
import { WarningMessage } from 'components/ErrorMessages';
import { ISelectOption } from 'components/Inputs/Select';
import { getNodes } from 'services/queryService';
import { appTheme } from 'theme';
import styled from 'styled-components';

const CREATE_FORMULA_MUTATION = loader('../../query/createFormula.gql');
const UPDATE_FORMULA_MUTATION = loader('../../query/updateFormula.gql');
const LIST_CONTAINERS = loader('../../query/listAllContainers.gql');
const FORM_ID = 'formula_add_edit_form';

const getSchema = (t: Function) =>
    Yup.object().shape({
        name: Yup.string().required(t('app:error.required')),
        description: Yup.string().notRequired().nullable(true),
        steps: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().min(1).required(t('app:error.required')),
                maxProducts: Yup.number().moreThan(0).required(t('app:error.required'))
            })
        ),
        price: Yup.number().required(t('app:error.required')),
        cashSystemCode: Yup.string().required(t('app:error.required')),
        idContainer: Yup.string().notRequired().nullable(true),
        image: Yup.object({
            id: Yup.string(),
        }).nullable(true),
    });

interface IProps {
    match: {
        params: { idHolding: string };
    };
    formula?: any;
    isOpen: boolean;
    closeModal: ({ shouldRefetch }: { shouldRefetch?: boolean | undefined }) => void;
    isEditMode: boolean;
}


interface IFormula {
    idHolding: string;
    name: string;
    description: string;
    price: number;
    cashSystemCode: string;
    image: { id: string; };
    steps: { name: string; maxProducts: number; order: number; }[]
    idContainer: string | null;
}

function FormulaTablePanel({
    isOpen,
    closeModal,
    match: {
        params: { idHolding },
    },
    formula,
    isEditMode,
}: IProps & RouteComponentProps) {
    const { t } = useTranslation();
    const [mutatePickUpPoint] = useMutation<'variables', any>(
      isEditMode ? UPDATE_FORMULA_MUTATION : CREATE_FORMULA_MUTATION
    );

    const containersQueryVariables = {
        querySearch: [{ key: 'idHolding', operator: '=', value: idHolding}],
    };
    const { data, loading } = useQuery(LIST_CONTAINERS, {
        variables: containersQueryVariables,
        skip: !isOpen
    });
    const containers: any[] = !loading && !!data ? getNodes(data.list) : [];

    if (containers.length > 0) {
        containers.unshift({
            id: null,
            label: t('schema:localArticle.noContainer')
        })
    }

    const [idImage, setIdImage] = useState(formula && formula.image && formula.image.id !== '' ? formula.image.id : null);

    useEffect(() => {
        if (idImage === null && formula && formula.image && formula.image.id !== '') {
            setIdImage(formula.image.id);
        }
    }, [formula]);

    const panelTitle = isEditMode ? t('page:clickcollect.formula.bundlesPanel.editTitle') : t('page:clickcollect.formula.bundlesPanel.title');
    const initialValues: IFormula = {
        idHolding: idHolding,
        name: formula ? formula.name : '',
        description: formula ? formula.description : '',
        price: formula ? formula.price.amount : '',
        cashSystemCode: formula ? formula.cashSystemCode : '',
        image: formula && formula.image ? formula.image : null,
        steps: formula && formula.steps ? formula.steps : [{name: '', maxProducts: 1, order: 0}],
        idContainer: formula?.container ? formula.container.id: null,
    };

    return (
        <Panel onClose={() => closeModal({})} open={isOpen} title={panelTitle}>
            <Formik
                initialValues={initialValues}
                validationSchema={getSchema(t)}
                validateOnBlur
                validateOnChange
                onSubmit={(values, { setSubmitting }) => {
                    mutatePickUpPoint({
                        variables: {
                            idHolding: values.idHolding,
                            name: values.name?.trim(),
                            description: values.description?.trim(),
                            price: values.price,
                            cashSystemCode: values.cashSystemCode?.trim(),
                            idImage: values.image?.id !== '' ? values.image.id : null,
                            steps: values.steps.map(({ name, maxProducts }, index) => ({ name, maxProducts, order: index }) ),
                            idContainer: values.idContainer,
                            ...(isEditMode && { id: formula.id }),
                        },
                    })
                        .then(() => {
                            closeModal({ shouldRefetch: true });
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({ errors, values, initialValues, setFieldValue }) => {
                    const { ...restInitialValues } = initialValues;
                    const { ...restValues } = values;
                    const formHasNoChange = isEqual(restInitialValues, restValues);

                    return (
                        <>
                            <PanelContent>
                                <Form id={FORM_ID}>
                                    <Field
                                        label={t('schema:formula.panelName')}
                                        name="name"
                                        component={TextInputField}
                                        style={{
                                            fontFamily: appTheme.typography.fontFamily,
                                        }}
                                        placeholder={t('schema:formula.namePlaceholder')}
                                        isPlaceholderBold
                                    />
                                    <Field
                                        label={t('schema:formula.description')}
                                        name="description"
                                        component={TextInputField}
                                        style={{
                                            fontFamily: appTheme.typography.fontFamily,
                                        }}
                                        placeholder={t('schema:formula.descriptionPlaceholder')}
                                        isPlaceholderBold
                                    />
                                    <InfoLabel name={t(`schema:formula.label`)} />
                                    {isEditMode && (
                                      <WarningMessage>
                                        {t('schema:formula.editWarning')}
                                      </WarningMessage>
                                    )}
                                    <StyledSection>
                                        <FormulaStepCreation
                                            steps={values.steps}
                                            onChangeSteps={(steps) => {setFieldValue('steps', steps)}}
                                            /* @ts-ignore */
                                            errors={errors.steps}
                                        />
                                    </StyledSection>
                                    <StyledSection>
                                        <Field
                                            label={t('schema:formula.price.amount')}
                                            name="price"
                                            inline={true}
                                            multiline={false}
                                            component={PriceInputField}
                                            justify={'flex-start'}
                                        />
                                        <Field
                                            label={t('schema:formula.cashSystemCode')}
                                            name="cashSystemCode"
                                            inline={true}
                                            multiline={false}
                                            component={TextInputField}
                                            justify={'flex-start'}
                                            width={150}
                                        />
                                        {containers.length > 0 && (
                                            <Row>
                                                <InfoLabel
                                                    fontSize="M"
                                                    paddingRight={8}
                                                    inline
                                                    name={t('schema:formula.container')}
                                                />
                                                <Select
                                                    id='container-select-formula'
                                                    data={containers}
                                                    selected={containers.find(({ id }) => values.idContainer === id)}
                                                    onChange={(o: ISelectOption) => setFieldValue('idContainer', o.id)}
                                                    disabled={false}
                                                    hasError={false}
                                                    width={195}
                                                    color={appTheme.color.grey[6]}
                                                />
                                            </Row>
                                        )}
                                    </StyledSection>
                                    <StyledSection>
                                        <Field
                                            id="imageInputField"
                                            label={<Trans i18nKey="schema:formula.imageUploadLabel" components={[<strong />]} />}
                                            secondaryLabel={t('schema:formula.imageHint')}
                                            warningMessageDefaultImage={values.image && values.image.id ? null : t('schema:formula.uploadImageWarningMessage')}
                                            name="image"
                                            component={ImageInputField}
                                            hasId={(idList: any[]) => {
                                                if (idList.length) {
                                                    values.image = { id: idList[0].id };
                                                    // it's only necessary to "force" a new render when the id image is changed
                                                    if (idImage && values.image.id !== idImage) {
                                                        // called setImage to trigger a render, otherwise warningMessageDefaultImage could or not be updated
                                                        setIdImage(values.image.id);
                                                    }
                                                } else {
                                                    values.image = { id: '' };
                                                    setIdImage(null)
                                                }
                                            }}
                                            initialImages={isEditMode && idImage ? [{ id: idImage }] : []}
                                        />
                                    </StyledSection>
                                </Form>
                            </PanelContent>
                            <PanelFooter>
                                <SubmitButton
                                    form={FORM_ID}
                                    disabled={
                                        formHasNoChange ||
                                        Object.entries(errors).length !== 0
                                    }
                                />
                            </PanelFooter>
                        </>
                    );
                }}
            </Formik>
        </Panel>
    );
}

const StyledSection = styled.div`
    margin-top: ${({ theme }) => theme.spacing.m}px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export default withRouter(FormulaTablePanel);
