import React, { useState } from 'react';
import { RouteComponentProps, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OfferTemplateRouteParam } from 'localTypes';
import { Struct, Title, Button } from 'components';
import appRoutes from 'Router/routes';
import ContainerTable from './ContainerTable';

interface IProps extends RouteComponentProps<{ idHolding: string; idPos: string }> {
    offerType: OfferTemplateRouteParam;
}

const Container = ({
    match: {
        params: { idPos, idHolding },
    },
    offerType,
}: IProps) => {
    const { t } = useTranslation();
    const [isContainerPanelOpen, setContainerPanelOpen] = useState(false);
    const toggleContainerModal = () => {
        setContainerPanelOpen(!isContainerPanelOpen);
    };
    return (
        <>
         <Route
                exact
                path={appRoutes[offerType].container.get()}
                render={() => (
                    <Struct.Section>
                        <Title grow mode="H2" value={t('page:clickcollect.container.title') || ''}>
                            <Button id='add-container-button' onClick={toggleContainerModal}>{t('app:button.add-container')}</Button>
                        </Title>
                        <Struct.Card>
                            <ContainerTable 
                                idHolding={idHolding}
                                toggleContainerModal={toggleContainerModal}
                                isContainerPanelOpen={isContainerPanelOpen}
                            />
                        </Struct.Card>
                    </Struct.Section>
                )}
            />
        </>
    );
};

export default Container;
