// @ts-nocheck
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Close } from 'components/Icons';
import { appTheme } from 'theme';

const Wrapper = styled.div`
    padding-left: 20px;
`;

const ErrorCode = styled.div`
    margin-bottom: 8px;
`;

const Message = styled.div`
    font-size: ${({ theme }) => theme.typography.fontSizeM}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.light};
`;

interface IToastProps {
    errorCode?: number;
    errorMessage: string;
}

export const ErrorToast = ({ errorCode, errorMessage }: IToastProps) => {
    const { t } = useTranslation();
    return (
        <ThemeProvider theme={appTheme}>
            <Wrapper>
                {errorCode && <ErrorCode>{t('error:toast.message.errorCode', { errorCode })}</ErrorCode>}
                <Message>{t(`error:message.${errorMessage}`)}</Message>
            </Wrapper>
        </ThemeProvider>
    );
};

const StyledButton = styled.button`
    background: none;
    border: 0px solid transparent;
    &:focus {
        outline: none;
    }
`;

export const CloseButton = ({ closeToast }: any) => (
    <ThemeProvider theme={appTheme}>
        <StyledButton onClick={closeToast}>
            <Close />
        </StyledButton>
    </ThemeProvider>
);