import React from 'react';
import { Icon, Table } from 'components';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { StyledTooltip } from '../../Communication/CommunicationPage/CommunicationListTable';
import { PosHub } from "../../../types/posHub";
import appRoutes from "../../../Router/routes";


const ICON_WIDTH = 20;

interface IPropsPanel extends RouteComponentProps<{ idHolding: string; }> {}

function Item ({
    item,
    onClickEdit,
    onClick,
    index,
}: {
    item: PosHub;
    onClickEdit: any;
    onClick: any;
    index: number;
}) {
    const { t } = useTranslation();
    const { pos, hub } = item;
    return (
        <>
            <td id={`offer-name_row-${index}`} onClick={() => null} style={{ width: '25%' }}>
                <TextWrapper>{hub.name}</TextWrapper>
            </td>
            <td id={`POS-name_row-${index}`} onClick={() => null}  style={{ width: '17%' }}>
                <TextWrapper>{pos.name}</TextWrapper>
            </td>
            <td style={{ width: '1%' }}>
                <ActionButtonsWrapper>
                    <StyledTooltip toolTipContent={t('page:clickcollect.offers.edit')}>
                        <span id={`edit-button_row-${index}`} onClick={onClickEdit}>
                            <Icon.Edit width={ICON_WIDTH} height={ICON_WIDTH} />
                        </span>
                    </StyledTooltip>
                </ActionButtonsWrapper>
            </td>
        </>
    );
};

interface IProps {
    data: PosHub[];
    idHolding?: string;
}

function PosHubTable ({
    data,
    history,
    idHolding,
}: IProps & IPropsPanel & RouteComponentProps) {
    const { t } = useTranslation();

    const renderLine = (item: { node : PosHub }, index: number) => {
        return (
            <StyledTR key={`${item.node.id}-${index}`}>
                <Item
                    index={index}
                    item={item.node}
                    onClickEdit={() => {
                      // @ts-ignore
                        history.push(`${appRoutes.holding.printing.edit(idHolding, item.node.id)}`);
                    }}
                    // @ts-ignore
                    onClick={() => history.push(`${appRoutes.holding.printing.edit(idHolding, item.node.id)}`)}
                />
            </StyledTR>
        );
    };
    // @ts-ignore
    return <Table renderLine={renderLine} data={data} headers={getHeaders(t)} />
};

const getHeaders = (t: any) => [
    {
        key: 'name',
        displayName: t('schema:posHub.name'),
    },
    {
        key: 'pos.name',
        displayName: t('schema:posHub.pos'),
    },
    {
        key: 'actions',
        displayName: t('schema:posHub.actions'),
    },
];

const StyledTR = styled.tr`
    cursor: pointer;
`;

const TextWrapper = styled.span`
    font-size: ${({ theme }) => theme.typography.size.S}px;
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    margin-top: ${({ theme }) => theme.spacing.s}px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    letter-spacing: 0;
    font-weight: lighter;
`;

const ActionButtonsWrapper = styled.td`
    display: flex;
    justify-content: center;
`;

export default withRouter(PosHubTable);
