import { Button, Icon, NumberInput, Row, TableWithOptions } from 'components';
import { ButtonType, IconButton } from 'components/Button';
import TableRow from 'components/Table/TableRow';
import { TagItem } from 'components/Tag';
import { loader } from 'graphql.macro';

import React, { useState, useEffect } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
    listTableServiceOfferItem_list_edges_node_OfferItem,
} from 'types/listTableServiceOfferItem';
import {
    getTableServiceOfferTemplates_ArticleCertifications_edges_node_ArticleCertification as ArticleCertificationsEdge,
    getTableServiceOfferTemplates_ArticleTags_edges_node_ArticleTag as ArticleTagsEdge,
} from 'types/getTableServiceOfferTemplates';
import { updateOfferItemVariables } from 'types/updateOfferItem';
import { getArticleFamilyOptions } from 'services/articleService';
import EditOfferItemPanel from './EditOfferItemPanel';
import LocalCatalogTablePanel from '../../../components/Layout/LocalCatalogTablePanel';
import OfferItemsToggle from 'pages/ClickCollect/DailyOffersPage/OfferDetailsPage/OfferItemsToggle';
import { OfferItemToSell } from '../../../types/globalTypes';

const OFFER_ITEM_LIST_QUERY = loader('./query/listTableServiceOfferItem.gql');
const UPDATE_OFFER_ITEM_MUTATION = loader('../../../query/updateOfferItem.gql');

type updatedListTableServiceOfferItem = {
    value: number | string;
    id: string;
    quantityOverall: number;
};
interface IPropsOfferItem {
    item: listTableServiceOfferItem_list_edges_node_OfferItem;
    hasError: boolean;
    valueAtZero: boolean;
    onChange: (item: updatedListTableServiceOfferItem) => void;
    onClick: () => void;
    onSubmit: (item: updatedListTableServiceOfferItem) => void;
    onToSellUpdate: (id: string, toSell: OfferItemToSell) => void;
    preCheckArticle: ((articleId:string) => any) | undefined,
    onErrorArticle?: (items:any)=> void
}

const OfferItem = ({
    item: { id, inheritedLabel, quantityOverall, quantityRemaining, localArticle, toSell },
    hasError,
    valueAtZero,
    onChange,
    onClick,
    onSubmit,
    onToSellUpdate,
    preCheckArticle,
    onErrorArticle
}: IPropsOfferItem) => {
    const [numberInput, setNumberInput] = useState<number | string>(0);
    const { t } = useTranslation();
    useEffect(() => {
        if (valueAtZero) {
            setNumberInput(0);
        }
    }, [valueAtZero]);
    return (
        <>
            <td
                style={{
                    width: '30%',
                    maxWidth: '280px',
                    overflow: 'hidden',
                    overflowWrap: 'break-word',
                }}
            >
                {inheritedLabel}
                <Row justify="space-between">
                    {hasError && (
                        <TagItem selected={false} onClick={() => {}} hasError={hasError}>
                            {t('page:tableService.daily-offers.incomplete')}
                        </TagItem>
                    )}
                </Row>
            </td>
            <td style={{ width: '20%', paddingLeft: '4.3%' }}>
                <NumberInput
                    value={numberInput}
                    onChange={(value: number | string) => {
                        setNumberInput(value);
                        onChange({ id, quantityOverall, value: typeof value === 'number' ? value : 0 });
                    }}
                />
            </td>
            <td style={{ width: '15%' }}>
                <QuantityWrapper>{quantityRemaining}</QuantityWrapper>
            </td>
            <td style={{ width: '15%' }}>
            <OfferItemsToggle
               id={id}
               toSell={toSell}
               cashSystemCode={localArticle.cashSystemCode}
               onToSellUpdate={onToSellUpdate}
               preCheckArticle={preCheckArticle}
               onErrorArticle={onErrorArticle}
            />
            </td>
            <td style={{ width: '10%' }}>
                {!numberInput && (
                    <IconButton onClick={onClick}>
                        <Icon.SolidArrow />
                    </IconButton>
                )}
                <ButtonWrapper>
                    {!!numberInput && (
                        <Button
                            inline
                            onClick={() => {
                                onSubmit({ id, quantityOverall, value: numberInput });
                                setNumberInput(0);
                            }}
                            display={ButtonType.VALIDATION}
                        >
                            OK
                        </Button>
                    )}
                </ButtonWrapper>
            </td>
        </>
    );
};

interface IProps {
    match: {
        params: { idHolding: string; idPos: string };
    };
    defaultQuerySearch: { key: string; value: string }[];
    articleTags: ArticleTagsEdge[];
    articleLabels: ArticleCertificationsEdge[];
    toggleCatalogModal: () => void;
    isCatalogPanelOpen: boolean;
    preCheckArticle?: ((article:string) => any) | undefined
}
const OfferItemsTable = ({
    defaultQuerySearch,
    match: {
        params: { idHolding, idPos },
    },
    articleTags,
    articleLabels,
    toggleCatalogModal,
    isCatalogPanelOpen,
    preCheckArticle
}: IProps & RouteComponentProps) => {
    const { t } = useTranslation();
    const [updatedValues, setUpdatedValues] = useState<updatedListTableServiceOfferItem[]>([]);
    const [valuesAtZero, setValuesAtZero] = useState<boolean>(true);
    const [idOfferItem, setIdOfferItem] = useState<string>('');

    return (
        <Mutation mutation={UPDATE_OFFER_ITEM_MUTATION}>
            {(updateOfferItem: (param: Record<'variables', updateOfferItemVariables>) => Promise<any>) => {
                const onUpdate = (list: updatedListTableServiceOfferItem[], multiLine: boolean) => {
                    list.forEach((el: updatedListTableServiceOfferItem) => {
                        const { id, quantityOverall, value } = el;
                        const finalQuantity = typeof value === 'number' ? quantityOverall + value : quantityOverall;
                        updateOfferItem({
                            variables: {
                                id,
                                quantityOverall: finalQuantity,
                            },
                        });
                    });
                    if (multiLine) {
                        setUpdatedValues([]);
                        setValuesAtZero(true);
                    }
                };

                const onToSellUpdate = (id: string, toSell: OfferItemToSell) => {
                    updateOfferItem({
                        variables: {
                            id,
                            toSell,
                        },
                    });
                };

                const renderLine = (item: listTableServiceOfferItem_list_edges_node_OfferItem) => {
                    const hasError = !item.localArticle.cashSystemCode || !parseFloat(item.localArticle.price.amount);
                    return (
                        <>
                            <TableRow key={item.id} hasError={hasError}>
                                <OfferItem
                                    item={item}
                                    hasError={hasError}
                                    onClick={() => {
                                        setIdOfferItem(item.id);
                                    }}
                                    valueAtZero={valuesAtZero}
                                    onChange={(item: updatedListTableServiceOfferItem) => {
                                        const tempState = updatedValues.filter(
                                            (el: updatedListTableServiceOfferItem) => el.id !== item.id
                                        );
                                        setValuesAtZero(false);
                                        if (item.value === 0) {
                                            setUpdatedValues([...tempState]);
                                        } else {
                                            setUpdatedValues([...tempState, item]);
                                        }
                                    }}
                                    onSubmit={(item) => {
                                        const newState = updatedValues.filter(
                                            (el: updatedListTableServiceOfferItem) => el.id !== item.id
                                        );
                                        onUpdate([item], false);
                                        setUpdatedValues(newState);
                                    }}
                                    onToSellUpdate={onToSellUpdate}
                                    preCheckArticle={preCheckArticle}
                                />
                            </TableRow>
                        </>
                    );
                };
                const idOffer = defaultQuerySearch[0].value;

                const getHeaders = (t: any) => [
                    {
                        key: 'label',
                        displayName: t('schema:article.label'),
                    },
                    {
                        key: 'changedStock',
                        displayName: t('page:tableService.daily-offers.table.header.stock'),
                    },
                    {
                        key: 'stock',
                        displayName: t('schema:offerItem.quantityRemaining'),
                    },
                    {
                        key: 'toSell',
                        displayName: t('schema:offerItem.toSell'),
                    },
                    {
                        key: 'action',
                        displayName:
                            updatedValues.length > 1 ? (
                                <ValidateAll>
                                    <ValidateAllButtonWrapper>
                                        <Button
                                            onClick={() => onUpdate(updatedValues, true)}
                                            display={ButtonType.VALIDATION}
                                        >
                                            {t('schema:offer.validateAll')}
                                        </Button>
                                    </ValidateAllButtonWrapper>
                                </ValidateAll>
                            ) : (
                                ''
                            ),
                    },
                ];

                return (
                    <TableWithOptions
                        // rerender table when offer is changed
                        key={idOffer}
                        withRouting={false}
                        renderLine={renderLine}
                        headers={getHeaders(t)}
                        pollInterval={15000}
                        query={OFFER_ITEM_LIST_QUERY}
                        searchPlaceholder={t('app:placeholder.search.article')}
                        variables={{ querySearch: defaultQuerySearch }}
                        tags={{
                            'article.family': getArticleFamilyOptions(),
                        }}
                        groupBy={{
                            key: 'inheritedFamily',
                            options: getArticleFamilyOptions(true, t(`schema:article.familyOption.OTHER`) || undefined),
                        }}
                    >
                        {({ refetch }: any) => {
                            const closeModal = () => {
                                refetch();
                                toggleCatalogModal();
                            };
                            const closeEditOffer = () => {
                                setIdOfferItem('');
                                refetch();
                            };
                            return (
                                <>
                                    <LocalCatalogTablePanel
                                        idOffer={idOffer}
                                        idPos={idPos}
                                        closeModal={closeModal}
                                        isOpen={isCatalogPanelOpen}
                                    />
                                    {idOfferItem &&
                                        <EditOfferItemPanel
                                            // @ts-ignore
                                            idHolding={idHolding}
                                            idOfferItem={idOfferItem}
                                            idPos={idPos}
                                            articleLabels={articleLabels}
                                            articleTags={articleTags}
                                            beforeClose={closeEditOffer}
                                        />
                                    }
                                </>
                            );
                        }}
                    </TableWithOptions>
                );
            }}
        </Mutation>
    );
};

const ButtonWrapper = styled.div`
    width: 60px;
`;

const QuantityWrapper = styled.div`
    text-align: center;
    width: 50%;
`;

const ValidateAll = styled.div`
    position: absolute;
`;

const ValidateAllButtonWrapper = styled.div`
    width: 100%;
    position: relative;
    right: 67px;
    bottom: 20px;
`;

export default withRouter(OfferItemsTable);