import React, { MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
    children?: ReactNode;
    toolTipContent?: string | ReactNode;
    className?: string;
    styledTooltip?: boolean;
    clickedToolTip? : boolean;
    id?: string;
    testID?: string;
}
const Tooltip = ({ children, toolTipContent, className, styledTooltip, clickedToolTip, id, testID }: IProps) => {
    const onClick = (e: MouseEvent) => {
        e.stopPropagation();
    };

    return (
        <Trigger id={id} onClick={onClick} clickedToolTip={clickedToolTip} data-test={testID}>
            {children}
            {toolTipContent && 
            
                <TooltipContainer className={className}>

                    {!!styledTooltip ?
                        <TextTooltip>{toolTipContent}</TextTooltip>
                        : 
                        <>{toolTipContent}</>
                        
                    }
                    
                </TooltipContainer>}            
        </Trigger>
    );
};

const TooltipContainer = styled.div`
    display: none;
    position: absolute;
    z-index: 1;
    border-radius: ${({ theme }) => theme.borderRadius.m}px;
    background-color: ${({ theme }) => theme.color.common.white};
    box-shadow: ${({ theme }) => theme.boxShadow[0]};
`;

interface ITriggerProps {
    clickedToolTip? : boolean;
}

const Trigger = styled.div<ITriggerProps>`
    position: relative;
    ${({ clickedToolTip }) => clickedToolTip === undefined ?
        `&:hover ${TooltipContainer} {
            display: block;
        }`
    : clickedToolTip === true ?
        `${TooltipContainer} {
            display: block;
        }`
    :
    `${TooltipContainer} {
        display: none;
    }`

}
`;
    
const TextTooltip = styled.span`    
    font-size: ${({ theme }) => theme.typography.size.S}px;
    line-height:  ${({ theme }) => theme.typography.lineHeight.S}px;
    margin-top: ${({ theme }) => theme.spacing.s}px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: Rubik;
    letter-spacing: 0;
    font-weight: lighter;
`;

export default Tooltip;
