import { ErrorMessageLighter, InputLabel } from 'components';
import { FieldProps, FormikErrors } from 'formik';
import get from 'lodash/get';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { isNaN, isNull } from 'lodash';
import { BaseInputSecondary, InputState } from '../common';
import FieldContainer from './FieldContainer';
import Select, { ISelectOption } from 'components/Inputs/Select';
import { appTheme } from 'theme';

export enum Time {
    MINUTES = 'minutes',
    HOURS = 'hours',
    DAYS = 'days'
}

interface IProps {
    readonly className?: string;
    readonly label?: string;
    readonly disabled?: boolean;
    readonly labelStart?: string;
    readonly labelEnd?: string;
    readonly width?: number;
    readonly validateFormOnChange?: boolean;
    readonly useDefaultErrorMessage?: boolean;
    readonly labelsFontSize?: string; 
    readonly selectData: Time[];
    readonly handleChange?: Function;
    readonly hasMarginLeft?: boolean;
}

const TimeSlotInputField = (props: FieldProps & IProps) => {
    const { t } = useTranslation();
    const { field, form, disabled, labelStart, labelEnd, validateFormOnChange, useDefaultErrorMessage = true, labelsFontSize = "L", selectData, handleChange } = props;
    const [timeToSelect, setTimeToSelect] = useState(selectData.map((tm: Time) => { return { id: tm, label: t(`page:admin.cnc.${tm}`) }}));
    const [durationValue, setDurationValue] = useState(field.value?.duration || 0);
    const [unitValue, setUnitValue] = useState(field.value?.unit? {id: field.value.unit, label: t(`page:admin.cnc.${field.value.unit}`)} : timeToSelect[0]);
    const nameDuration = useMemo(() => `${field.name}.duration`, [field.name]);
    const nameUnit = useMemo(() => `${field.name}.unit`, [field.name]);

    const handleSelectChange = (value: ISelectOption) => {
        setUnitValue(value)
        form.setFieldValue(nameUnit, value.id);
        form.setFieldTouched(nameUnit);
        handleChange && handleChange(durationValue, value.id)
        setTimeout(() => {
          form.validateForm();
      }, 0);
    };
      
    const handleInputChange = ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
      setDurationValue(value);
      form.setFieldValue(nameDuration, value.length > 0 ? Number(value) : '');
      form.setFieldTouched(nameDuration);
      handleChange && handleChange(value, unitValue.id)
      if (validateFormOnChange) {
        setTimeout(() => {
            form.validateForm();
        }, 0);
      }
    };

    // Remove specific type of time option from the 'Select' 
    const removeTime = (times, type: Time) => {
        times = times.filter((time) => {
            return time.id !== type;
        });
        return times;
    };

    useEffect(() => {
        setDurationValue(isNaN(field.value?.duration) || isNull(field.value?.duration) ? '' : field.value.duration);
    }, [field.value]);

    // Manage the options available in 'Select' based on the business rule
    useEffect(() => {
        if (field.name === 'minCancellationDelay') {
            if (
                form.values?.daysInAdvance.min === 0 &&
                form.values?.daysInAdvance.max === 0
            ) {
                const times = removeTime(timeToSelect, Time.DAYS);
                setTimeToSelect(times);

                if (unitValue.id === Time.DAYS) {
                    setUnitValue(times[0]);
                }
            } else {
                setTimeToSelect(
                    selectData.map((tm: Time) => { return { id: tm, label: t(`page:admin.cnc.${tm}`) }})
                );
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.daysInAdvance]);

    const renderError = (error?: string | FormikErrors<any>, inline?: boolean) => {
        if (!error) {
            return null;
        }
        // When is object, avoid render when all values are undefined
        if (typeof error === 'object' && Object.values(error).findIndex((e) => !!e) === -1) {
            return null;
        }
        return (
            <ErrorWrapper inline={inline}>
                <ErrorMessageLighter>{error}</ErrorMessageLighter>
            </ErrorWrapper>
        );
    };

    return (
        <FieldContainer {...props}>
            <Wrapper>
                <Vertical>
                    <InputWrapper>
                        {labelStart && (
                            <InputLabel
                                name={labelStart}
                                htmlFor={nameDuration}
                                fontSize={labelsFontSize}
                                inline
                                noMarginRight
                            />
                        )}
                        <Input
                            {...field}
                            id='timeslot-input'
                            name={nameDuration}
                            onChange={handleInputChange}
                            value={durationValue}
                            state={
                                !!get(form.errors, nameDuration) && !!get(form.touched, nameDuration)
                                    ? InputState.INVALID
                                    : InputState.VALID
                            }
                            width={60}
                            disabled={disabled}
                            hasMarginLeft={!!labelStart}
                        />
                        <Select
                            id='timeslot-select'
                            data={timeToSelect}
                            selected={unitValue}
                            onChange={handleSelectChange}
                            disabled={disabled}
                            hasError={false}
                            width={125}
                            color={appTheme.color.grey[6]}
                        />                        
                    </InputWrapper>
                    {labelEnd && (
                        <InputLabel
                            name={labelEnd}
                            htmlFor={nameDuration}
                            fontSize={labelsFontSize}
                            inline
                            noMarginRight
                        />
                    )}
                    {!useDefaultErrorMessage &&
                        renderError({ start: get(form.errors, nameDuration), end: get(form.errors, nameUnit) }, true)}
                </Vertical>
            </Wrapper>
        </FieldContainer>
    );
};

const Wrapper = styled.div`
    display: flex;
`;
const Vertical = styled.div`
    flex-direction: row;
`;
const Input = styled(BaseInputSecondary)<Pick<IProps, 'width' |'hasMarginLeft'>>`
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    text-align: left;
    font-size: ${({ theme }) => theme.typography.fontSizeL}px;
    margin: ${({ theme, hasMarginLeft }) => ` 0 ${theme.spacing.xs}px 0 ${hasMarginLeft ? theme.spacing.xs+'px' : '0'}`};
    &::placeholder {
        color: ${({ theme }) => theme.color.grey[6]};
        font-weight: 200;
    }
`;
const InputWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 3px;
`;
const ErrorWrapper = styled.div<{ inline?: boolean }>`
    margin-left: ${({ theme }) => theme.spacing.xs}px;
    margin-top: ${({ theme, inline }) => (inline ? theme.spacing.s : 0)}px;
    max-height: ${({ theme }) => theme.dimension.height.element}px;
`;

export default TimeSlotInputField;
