import TextInputField from 'components/Inputs/FormikFields/TextInputField';
import { Field } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { appTheme } from 'theme';

import { FlagUK, FlagFr } from './Icons';

export enum locale {
    fr = 'fr',
    en = 'en',
}

interface ILangTextInputProps {
    id?: string;
    maxLength?: number;
    multiline?: boolean;
    locale?: locale | string;
    label?: any;
    name: string;
    placeholder?: string | null;
    value?: string;
    onChange?: (value) => void;
    useDefaultErrorMessage?: boolean;
    useDefaultErrorColoring?: boolean;
    validate?:Function;
    bottomSize?: string;
    onChangeField?: Function;
    style?: object;
    isPlaceholderBold?: boolean;
}

const HEIGHT = 82;

//using this value, so the text wont overflow to the Flag and Counter location
const PADDING_RIGHT = 52;

const LanguageTextInput = ({ label, name, maxLength, multiline, locale, placeholder, value, onChange, useDefaultErrorMessage,
                               useDefaultErrorColoring, validate, bottomSize, onChangeField, style, id, isPlaceholderBold }: ILangTextInputProps) => {
    const characters = value ? value.length : 0;
    let Flag: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    switch (locale) {
        case 'en':
            Flag = FlagUK;
            break;
        case 'fr':
            Flag = FlagFr;
            break;
        default:
            Flag = FlagFr;
            break;
    }

    return (
        <LanguageTextInputWrapper bottomSize={bottomSize}>
            <CharsCounterAndFlagWrapper>
                {!!locale && (
                    <FlagContainer>
                        <FlagWrapper>
                            <Flag />
                        </FlagWrapper>
                    </FlagContainer>
                )}
                {(!!maxLength || multiline) && (
                    <FlagContainer>
                        <CharsCounterWrapper locale={locale} multiline={multiline}>
                            <span style={characters !== maxLength ? { color: '#48A9F5' } : { color: 'red' }}>
                                {characters}
                            </span>
                            /{maxLength}
                        </CharsCounterWrapper>
                    </FlagContainer>
                )}
            </CharsCounterAndFlagWrapper>

            <Field
                // allows to validate the field individually
                id={id}
                validate={validate}
                value={value}
                useDefaultErrorColoring={useDefaultErrorColoring}
                useDefaultErrorMessage={useDefaultErrorMessage}
                label={label ? label : ''}
                placeholder={placeholder}
                name={name}
                bottomSize={bottomSize}
                component={TextInputField}
                onChangeField={onChangeField}
                maxLength={maxLength}
                multiline={multiline}
                onChange={onChange && onChange}
                style={
                    !!Flag || !!maxLength
                        ? {
                              resize: 'none',
                              paddingRight: PADDING_RIGHT,
                              height: HEIGHT,
                              fontWeight: 'normal',
                              color: appTheme.color.grey[6],
                              fontFamily: appTheme.typography.fontFamily,
                              fontSize: `${appTheme.typography.size.XS}px`,
                              lineHeight: `${appTheme.typography.lineHeight.S}px`,
                              letterSpacing: '0',
                              ...style
                          }
                        : {
                              fontWeight: 'normal',
                              ...style
                          }
                }
                isPlaceholderBold={isPlaceholderBold}
            />
        </LanguageTextInputWrapper>
    );
};

interface ICharsCounterProps {
    locale?: locale | null | undefined | string;
    multiline?: boolean;
}

const CharsCounterWrapper = styled.p<ICharsCounterProps>`
    font-size: ${({ theme }) => theme.typography.size.XXS}px;
    text-align: right;
    color: ${({ theme }) => theme.color.grey[6]};
    position: relative;
    right: ${({ theme, multiline }) => !multiline ? 45 : theme.spacing.s}px;
    top: ${({ locale, multiline }) => (!multiline ? '-7px' : (locale ? '34px' : '103px'))};
`;

const FlagWrapper = styled.div<ICharsCounterProps>`
    border: 0 solid;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    height: 17px;
    width: 28px;
    overflow: hidden;
    position: relative;
    top: ${({ locale }) => (locale ? '53px' : '10px')};
    right: ${({ theme }) => theme.spacing.xs}px;
`;

const FlagContainer = styled.div`
    width: inherit;
    display: flex;
    flex-direction: row-reverse;
`;

interface ILanguageTextInputWrapper {
    bottomSize?: string;
}
const LanguageTextInputWrapper = styled.div<ILanguageTextInputWrapper>`
    width: inherit;
    margin-bottom: ${({ theme, bottomSize }) => (bottomSize ? theme.spacing[bottomSize] : 15)}px;
`;

const CharsCounterAndFlagWrapper = styled.div`
    height: 0;
`;

export default LanguageTextInput;
