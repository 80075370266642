import { RoutablePanel } from 'components';
import { IRenderProps as IPanelRenderProps } from 'components/Panel';
import { loader } from 'graphql.macro';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import AddOrEditCheckListUserForm from './AddOrEditCheckListUserForm';

const CREATE_CHECKLISTUSER_MUTATION = loader('../query/addEPointageUser.gql');

interface IPropsPanel extends RouteComponentProps<{ siteId: string }> {
    beforeClose: () => void;
}

const AddCheckListUserPanel = ({ 
    history: { push },
    location: { search },
    match: {
        params: { siteId },
    },
    beforeClose }: IPropsPanel) => {
    const [t] = useTranslation();
    const onClose = () => {
        beforeClose();
        push(`${routes.checkList.admin.list(siteId)}${search}`);
    };

    return (
        <RoutablePanel 
          title={t(`page:checkList.adminList.creation.title`)} 
          onClose={onClose}
        >
            {({ onClose }: IPanelRenderProps) => (
                <Mutation mutation={CREATE_CHECKLISTUSER_MUTATION}>
                    {(createCheckListUser: (param: Record<'variables', any>) => Promise<any>) => (
                        <AddOrEditCheckListUserForm
                            {...{
                                onSuccess: onClose,
                                updateEPointageUser: createCheckListUser,
                                isCreation: true,
                                siteId
                            }}
                        />
                    )}
                </Mutation>
            )}
        </RoutablePanel>
    );
};

export default withRouter(AddCheckListUserPanel);
