import React, { Component, useState } from 'react';
import styled from 'styled-components';
import { CheckBox, Icon, Info, InfoType, NumberInput, Table } from '../../components';

interface IItemOffer {
    id: string;
    name: string;
    changedStock: number;
    stock: number;
    permanent?: boolean;
}

function ItemOffer(props: { item: IItemOffer; selected: boolean; onChange: (item: IItemOffer) => void }) {
    const item = props.item;
    const [numberInput, setNumberInput] = useState<number | string>(item.changedStock);
    return (
        <>
            <td>
                <WrapName>
                    <CheckBox checked={props.selected} onChange={() => props.onChange(item)} />
                    <span>{item.name}</span>
                    {item.permanent ? <Info display={InfoType.DISABLE}>Permanent</Info> : null}
                </WrapName>
            </td>
            <td>
                <WrapInput>
                    <NumberInput value={numberInput} onChange={setNumberInput} />
                </WrapInput>
            </td>
            <td style={{ textAlign: 'center' }}>{item.stock}</td>
        </>
    );
}

interface IProps {
    data: IItemOffer[];
}

interface IState {
    selected: Set<string>;
}
export default class ItemOfferTable extends Component<IProps, IState> {
    state: IState = { selected: new Set() };
    handleSelectChange = (item: IItemOffer) => {
        const selected = this.state.selected;
        if (selected.has(item.id)) {
            selected.delete(item.id);
        } else {
            selected.add(item.id);
        }
        this.setState({ selected: new Set(selected) });
    };
    renderLine = (item: IItemOffer) => {
        const selected = this.state.selected;
        return (
            <tr key={item.id}>
                <ItemOffer item={item} selected={selected.has(item.id)} onChange={this.handleSelectChange} />
            </tr>
        );
    };
    render() {
        return (
            <>
                {!!this.state.selected.size && (
                    <GroupAction>
                        <CheckBox checked={null} onChange={v => this.setState({ selected: new Set() })} />
                        {this.state.selected.size} produits selectionnés
                        <Icon.Bin />
                        <span>Supprimer</span>
                    </GroupAction>
                )}
                <Table renderLine={this.renderLine} data={this.props.data} headers={headers} />
            </>
        );
    }
}

const headers = [
    {
        key: 'name',
        displayName: 'articles',
    },
    {
        key: 'changedStock',
        displayName: 'Ajouter ou supprimer du stock',
    },
    {
        key: 'stock',
        displayName: 'stock disponnible',
    },
];

const GroupAction = styled.div`
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    background-color: ${props => props.theme.color.grey[1]};
    color: ${props => props.theme.color.common.blue};
    padding: 10px;
    display: flex;
    height: 50px;
    margin: 10px 0;
    align-items: center;
    & > * {
        margin-right: 10px;
    }
`;

const WrapName = styled.div`
    display: flex;
    & > * {
        margin-right: 10px;
    }
    color: ${props => props.theme.color.common.blue};
`;

const WrapInput = styled.div`
    width: 160px;
    margin: auto;
`;
