import React, { ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { QueryResult, Omit } from 'localTypes';
import { getNodes } from 'services/queryService';
import { SearchInput, Tags, Loader, QueryLoader } from 'components';
import { ITagOption } from 'components/Tag';
import useRoutingForTableOptions from './useRoutingForTableOptions';
import useTableOptions from './useTableOptions';
import Table, { ITableHeader, IGroupBy } from './index';
import Paginator, { rowPerPageOptions, PaginationWrapper } from './Paginator';
import { decodeEntityId } from '../../utils';
import { formulasKey } from '../../pages/ClickCollect/DailyOffersPage/OfferDetailsPage/FormulasTable';

enum SearchKey {
    SEARCH = 'search',
}

interface IProps extends Omit<any, 'children'>, RouteComponentProps {
    headers?: ITableHeader[];
    headersWithoutPadding?: boolean;
    renderLine: Function;
    searchPlaceholder?: string;
    tags?: { [key: string]: ITagOption[] };
    children?: Function;
    // should pagination, filter and search params added to the url?
    withRouting?: boolean;
    // should pass query variables to render function (needed for cache update)
    withQueryVariables?: true;
    groupBy?: IGroupBy;
    initialBlankPage?: any;
    withSearchBar?: boolean;
    withPagination?: boolean;
    parentPage?: string;
    extra?: {
        idHolding: string,
        renderLineFormulas: Function
    }
    renderSubHeader?: Function;
    mockData?: any;
}

export interface IList {
    // given the current support, the component is starting to lose its generic approach
    formulaItems: any; //TODO: add formulaItems type
    // necessary to filter "local article" by formulas and add formula product to an offer
    formulasList: {
        edges: {
            node: any // todo- describe formula type here -> run gentypes and check the generated types?
        }[]
    };
    list: {
        edges: IEdge[];
        pageInfo: {
            endCursor: any | null;
            startCursor: any | null;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
        };
        totalCount: number;
    };
}

export interface IEdge {
    node: any;
}

const TableWithOptions = ({
    withRouting = true,
    withQueryVariables,
    children,
    location,
    history,
    headers,
    headersWithoutPadding,
    renderLine,
    variables = {},
    searchPlaceholder,
    tags,
    groupBy,
    initialBlankPage,
    withSearchBar = true,
    withPagination = true,
    parentPage,
    extra,
    renderSubHeader,
    mockData,
    ...queryProps
}: IProps) => {
    let { queryParams, querySearch, onSearchValueChange, onPaginationChange, paginationOptions } =
        useTableOptions<any>();

    const {
        queryParams: routedQueryParams,
        querySearch: routedQuerySearch,
        onSearchValueChange: routesSearchValueChange,
        onPaginationChange: routedOnPaginationChange,
        paginationOptions: routedPaginationOptions,
    } = useRoutingForTableOptions<any>(location, history);

    if (withRouting) {
        queryParams = routedQueryParams;
        querySearch = routedQuerySearch;
        onSearchValueChange = routesSearchValueChange;
        onPaginationChange = routedOnPaginationChange;
        paginationOptions = routedPaginationOptions;
    }

    const defaultQuerySearch = variables.querySearch ? variables.querySearch : [];
    const queryVariables = {
        fetchOnlyFormulas: false,
        ...variables,
        querySearch: [...defaultQuerySearch, ...querySearch],
        ...paginationOptions,
    };
    const onSearchChange = (value: string) => {
        onSearchValueChange({ [SearchKey.SEARCH]: { key: SearchKey.SEARCH, value } });
    };

    const getFilterValue = (key: string): string[] => {
        return queryParams[key] ? queryParams[key]!.value.split(',') : ([] as string[]);
    };

    const onFilterChange = (key: string) => (value: string[]) => {
        onSearchValueChange({
            [key]: value.length > 0 ? { key, value: value.join(), operator: '*' } : undefined,
        });
    };
    const tableHasData = (data: IList): boolean => !!(data && (data.list || data.formulasList));

    const filteringByFormulas = queryVariables.querySearch && queryVariables.querySearch.findIndex((q) => q.key === 'article.family' && q.value === formulasKey) !== -1;

    if (filteringByFormulas && extra && extra.idHolding) {
        queryVariables.fetchOnlyFormulas = true;
        queryVariables.querySearch = []; // override default query search
        queryVariables.querySearch.push({ key: 'idHolding', value: decodeEntityId(extra.idHolding) });
    }

    return (
        <>
            {withSearchBar && (
                <StyledSearchInput
                    value={queryParams.search ? queryParams.search.value : ''}
                    onChange={onSearchChange}
                    placeholder={searchPlaceholder || ''}
                />
            )}
            <QueryLoader
                context={{
                    debounceKey: 'list',
                    debounceTimeout: 500
                }}
                {...queryProps}
                variables={queryVariables}
                hasData={tableHasData}
            >
                {(queryResultProps: QueryResult<IList>) => {
                    const {
                        data,
                        data: { list: itemsList, formulasList },
                        loading,
                    } = !!mockData ? mockData : queryResultProps;
                    const formulaItemsLocalArticle = data?.formulaItems?.edges?.map(({ node }) => node?.localArticle?.id);
                    const list = itemsList ? {
                        ...itemsList,
                        edges: itemsList?.edges?.map?.((item) => ({ ...item, node: { ...item?.node, isStockShared: formulaItemsLocalArticle?.includes(item?.node?.localArticle?.id) } }))
                    } : undefined;

                    const tagKeys = tags && Object.keys(tags);

                    const tableData = (list ? getNodes(list) : getNodes(formulasList)).filter((e: any) => e?.id);
                    const hasData = tableData.length > 0;

                    return (
                        <Wrapper id="table">
                            {(!initialBlankPage || data.list.totalCount > 0 || queryParams.search?.value) && (
                                <>
                                    {tagKeys && (
                                        <TagWrapper id="tags">
                                            {tagKeys.map((key) => (
                                                <Tags
                                                    wrapped={false}
                                                    key={key}
                                                    options={tags![key]}
                                                    value={getFilterValue(key)}
                                                    onChange={onFilterChange(key)}
                                                    clearAllWhenSelecting={formulasKey}
                                                />
                                            ))}
                                        </TagWrapper>
                                    )}
                                    {withPagination && (
                                        <PaginationWrapper id="pagination">
                                            <Paginator
                                                pageInfo={list ? list.pageInfo : formulasList.pageInfo }
                                                onPaginate={onPaginationChange}
                                                rowPerPage={
                                                    (paginationOptions.first || paginationOptions.last) as rowPerPageOptions
                                                }
                                            />
                                        </PaginationWrapper>
                                    )}
                                    {loading && tableHasData(data) ? (
                                        <StyledLoader />
                                    ) : (
                                        <Table
                                            groupBy={groupBy}
                                            renderLine={
                                                (filteringByFormulas && extra ? extra.renderLineFormulas(formulasList) :
                                                    withQueryVariables ? renderLine(queryVariables) : renderLine) as (
                                                    item: any,
                                                    index: number
                                                ) => ReactElement
                                            }
                                            data={tableData}
                                            headers={headers}
                                            {... (renderSubHeader && {subHeaderComponent: renderSubHeader(loading, hasData ? data : { list: { edges: [] }, formulaItems: { edges: []}})})}
                                            headersWithoutPadding={headersWithoutPadding}
                                        />
                                    )}
                                    {children && children(queryResultProps)}
                                </>
                            )}
                            {initialBlankPage && ((data.list && data.list.totalCount === 0) || (formulasList && formulasList.totalCount === 0)) && initialBlankPage()}
                        </Wrapper>
                    );
                }}
            </QueryLoader>
        </>
    );
};

const StyledSearchInput = styled(SearchInput)`
    margin-bottom: ${({ theme }) => theme.spacing.xs}px;
`;

const TagWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
`;

const StyledLoader = styled(Loader)`
    left: 0px;
    top: 50px;
`;

const Wrapper = styled.div`
    position: relative;
`;

export default withRouter(TableWithOptions);
