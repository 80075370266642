import React, { ReactElement } from 'react';
import { whoami_tokenOwner_Employee } from 'types/whoami';
import { jwtDecode } from 'jwt-decode';

interface IUserProps {
    children: (props: { user?: whoami_tokenOwner_Employee }) => ReactElement | null;
}

let user: whoami_tokenOwner_Employee = {__typename: 'Employee', id:''};

const User = ({ children }: IUserProps) => {

    const accessToken = window.localStorage.getItem('accessToken');

    if (accessToken != null && accessToken !== '') {
        const tokenDecoded = jwtDecode(accessToken);
        user.role = tokenDecoded['userRole'];
    }

    return children({ user });
};

export default User;
