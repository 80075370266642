import React, { useState, MouseEvent } from 'react';
import { Editor, EditorState, RichUtils, convertFromRaw, convertToRaw } from 'draft-js';
import styled from 'styled-components';

//@ts-ignore
import { markdownToDraft, draftToMarkdown } from 'markdown-draft-js';

interface IPropsBtn {
    style: string;
    onToggle: (value: string) => void;
    active: boolean | undefined;
    label: String;
}

const StyleButton = ({ onToggle, active, label, style }: IPropsBtn) => {
    const onToggleMouseDown = (e: MouseEvent) => {
        e.preventDefault();
        onToggle(style);
    };

    let className = '';
    if (active) {
        className = 'SimpleRichEditor-activeButton';
    }

    return (
        <WrapperButton className={className} onMouseDown={onToggleMouseDown}>
            {label}
        </WrapperButton>
    );
};

const INLINE_STYLES = [{ label: 'Bold', style: 'BOLD' }, { label: 'Italic', style: 'ITALIC' }];

interface IInlineStyleControls {
    onToggle: (value: string) => void;
    editorState: EditorState;
}

const InlineStyleControls = ({ editorState, onToggle }: IInlineStyleControls) => {
    const currentStyle = editorState.getCurrentInlineStyle();
    return (
        <WrapperControl>
            {INLINE_STYLES.map(type => (
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={onToggle}
                    style={type.style}
                />
            ))}
        </WrapperControl>
    );
};

interface IProps {
    value: string | EditorState;
    onChange: (value: EditorState) => void;
    hasError?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    width?: number;
}

function SimpleRichTextInput({ onChange, value, className, readOnly, hasError, width }: IProps) {
    const contentState = convertFromRaw(markdownToDraft(value));
    const [initalState, setInitialState] = useState(
        value instanceof EditorState ? value : EditorState.createWithContent(contentState)
    );

    const [focus, setFocus] = useState(false);

    const onFocus = () => {
        setFocus(true);
    };

    const onBlur = () => {
        setFocus(false);
    };

    const onChangeText = (editorState: EditorState) => {
        const markdown = draftToMarkdown(convertToRaw(editorState.getCurrentContent()));
        setInitialState(editorState);
        onChange(markdown);
    };

    const toggleInlineStyle = (style: string) => {
        onChangeText(RichUtils.toggleInlineStyle(initalState, style));
    };

    return (
        <WrapperRoot className={className} hasError={hasError} focus={focus} width={width}>
            <InlineStyleControls editorState={initalState} onToggle={toggleInlineStyle} />
            <WrapperEditor>
                <Editor
                    onFocus={onFocus}
                    onBlur={onBlur}
                    editorState={initalState}
                    onChange={onChangeText}
                    spellCheck={true}
                    readOnly={readOnly}
                />
            </WrapperEditor>
        </WrapperRoot>
    );
}

export default SimpleRichTextInput;
interface InputEditorProps {
    readonly hasError?: boolean;
    readonly focus: boolean;
    readonly width?: number;
}

const WrapperRoot = styled.div<InputEditorProps>`
    min-height: 160px;
    width: ${({ width }) => (width ? `${width}px` : '400px')};
    padding: ${({ theme }) => theme.spacing.xs}px;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    border: 1px solid
        ${({ hasError, focus, theme }) => {
            if (focus) return theme.color.input.borderColorFocus;
            return hasError && !focus ? theme.color.input.borderColorError : theme.color.input.borderColor;
        }};
    background-color: ${({ hasError, focus, theme }) =>
        hasError && !focus ? theme.color.input.backgroundColorError : theme.color.input.backgroundColor};
    color: ${({ hasError, theme, focus }) =>
        hasError && !focus ? theme.color.input.textColorError : theme.color.input.textColor};
    font-size: ${({ theme }) => theme.typography.fontSizeL}px;
    text-align: left;
    transition: color 300ms, background-color 300ms, border 300ms;

    &:disabled {
        cursor: not-allowed;
        opacity: ${({ theme }) => theme.opacity.disabled};
    }
    &::placeholder {
        color: ${({ theme }) => theme.color.input.placeholderColor};
    }
`;
const WrapperEditor = styled.div`
    border-top: 1px solid ${({ theme }) => theme.color.input.borderColor};
    cursor: text;
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    margin-top: 10px;
    margin: 0 -15px -15px;
    min-height: 100px;

    .public-DraftEditor-content {
        min-height: 113px;
        overflow-y: auto;
        padding: ${({ theme }) => theme.spacing.xs}px;
    }
`;

const WrapperControl = styled.div`
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    margin-bottom: 5px;
    user-select: none;
`;

const WrapperButton = styled.div`
    color: ${({ theme }) => theme.color.grey[6]};
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;

    &.SimpleRichEditor-activeButton {
        color: ${({ theme }) => theme.color.common.blue};
    }
`;
