import { Duration } from 'luxon';
import React, { useState } from 'react';
import { getDateTime, getDisplayTime, convertObjectToISO } from 'services/dateService';
import styled from 'styled-components';
import { AddButton, DeleteButton } from '../Button';
import { BaseInput } from './common';

const DEFAULT_STEP = 5;

interface IProps {
    id?: string;
    value: string | undefined;
    label: string;
    initialValue: string;
    onChange: (selectedValue: string) => void;
    hasError?: boolean;
    width?: number;
    enabled?: boolean | undefined;
    onClose?: () => void;
    onSelect: Function;
    minValue?: string;
    maxValue?: string;
    step?: number;
}

enum Action {
    ADD = 'ADD',
    SUBSTRACT = 'SUBSTRACT',
}

function TimeInput({
    id,
    value,
    label,
    initialValue,
    onChange,
    hasError,
    width,
    enabled = true,
    onClose,
    onSelect,
    minValue,
    maxValue,
    step = DEFAULT_STEP,
}: IProps) {
    const dateTimeToDuration = (datetime: Date) => {
        return `PT${datetime.getHours()}H${datetime.getMinutes()}M`;
    };
    const durationToISO = (duration: string) => {
        return Duration.fromISO(duration).toISO();
    };
    const durationStep = Duration.fromISO('PT5M');

    const duration = Duration.fromISO(value !== undefined ? convertObjectToISO(value): initialValue);
    const dateTime = getDateTime(duration).toISO();
    let displayDate = dateTime;
    const [enableSubtract, setEnableSubtract] = useState(
        minValue ? duration.minus(durationStep) >= Duration.fromISO(minValue) : enabled
    );
    const [enableAdd, setEnableAdd] = useState(
        maxValue ? duration.plus(durationStep) <= Duration.fromISO(maxValue) : enabled
    );
    const updateTime = (action: Action | undefined) => {
        const newDate = new Date(displayDate);
        newDate.setMinutes(newDate.getMinutes() + (action === Action.ADD ? step : -step));
        displayDate = newDate.toISOString();
        setEnableSubtract(!(minValue && durationToISO(dateTimeToDuration(newDate)) === durationToISO(minValue)));
        setEnableAdd(!(maxValue && durationToISO(dateTimeToDuration(newDate)) === durationToISO(maxValue)));
        onChange(dateTimeToDuration(newDate).substr(2));
        onSelect(dateTimeToDuration(newDate));
    };
    return (
        <Wrapper>
            <WrapperTime enabled={enabled || value !== undefined}>
                {label && <Label width={width}>{label}</Label>}
                <StyledSubtractButton disabled={!enableSubtract} onClick={() => updateTime(Action.SUBSTRACT)} />
                <Input id={id} disabled hasError={hasError} value={getDisplayTime(displayDate)} type="text" />
                <StyledAddButton disabled={!enableAdd} onClick={() => updateTime(Action.ADD)} />
            </WrapperTime>
        </Wrapper>
    );
}

interface ILblProps {
    width: number | null | undefined;
}

interface IWrapperTime {
    enabled: boolean | null | undefined;
}

const WrapperTime = styled.div<IWrapperTime>`
    display: flex;
    align-items: center;
    opacity: ${({ enabled }) => (enabled ? 1 : 0.33)};
`;

const Wrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const Input = styled(BaseInput)`
    width: 75px;
    font-family: ${({ theme }) => theme.typography.fontFamilySecondary};
    font-weight: ${({ theme }) => theme.typography.fontWeight.light};
`;

const StyledAddButton = styled(AddButton)`
    margin-left: 2px;
`;

const StyledSubtractButton = styled(DeleteButton)`
    margin-right: 2px;
`;

const Label = styled.div<ILblProps>`
    align-items: center;
    font-size: ${({ theme }) => theme.typography.fontSizeM}px;
    color: ${({ theme }) => theme.color.grey[6]};
    margin: 0 ${({ theme }) => theme.spacing.s}px;
    text-transform: uppercase;
    width: ${({ width }) => `${width}px`};
`;

export default TimeInput;
