import { Button, Circle, Column, H2, Icon, Row, Struct } from 'components';
import { ButtonType, IconButton } from 'components/Button';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { appTheme } from 'theme';

interface IProps {
    steps: Array<{
        name: string;
        component: ReactElement;
        validationFunctionsPerButtons?: {
            draft: (props: FormikProps<FormikValues>) => boolean;
            next: (props: FormikProps<FormikValues>) => boolean;
        };
    }>;
    onSubmit: Function;
    backRoute?: string;
    submitDraft?: Function;
    draftLabel?: Function;
    continueButtonStyle?: any;
    nextLabel?: string | null;
    finishLabel?: string | null;
    validateOnBlur?: boolean;
    validateOnChange?: boolean;
    withMargin?: boolean;
    skipStepIndex?: number;
    width?: number;
    // shouldReinitialize - will enable the Formik prop enableReinitialize
    // which will re-render the whole form if any of the initialValues change
    shouldReinitialize?: boolean;
}

/*
 * Checks if form was modified from initialValues
 */
const formUntouched = (props) => !(props.isValid && props.dirty);

/**
 * use style if passed. if not, use the default ones depending on isLastPage
 */
const buttonStyleDecider = (isLastPage: boolean, style?: ButtonType) => {
    return style ? style : !isLastPage ? ButtonType.ACTION : ButtonType.VALIDATION;
};

function MultiStep<T>({
    steps,
    onSubmit,
    initialValues,
    backRoute,
    submitDraft,
    draftLabel,
    continueButtonStyle,
    nextLabel,
    finishLabel,
    validateOnBlur = false,
    validateOnChange = false,
    withMargin = true,
    skipStepIndex,
    width,
    shouldReinitialize = false,
}: IProps & { initialValues: T }) {
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState(0);
    const isLastPage = currentStep === steps.length - 1;
    const isFirstPage = currentStep === 0;

    const next = () => {
        if (skipStepIndex) {
            if (skipStepIndex === currentStep + 1) {
                setCurrentStep(currentStep + 2);
                return;
            }
        }
        setCurrentStep(currentStep + 1);
    };

    const previous = () => {
        if (skipStepIndex) {
            if (skipStepIndex === currentStep - 1) {
                setCurrentStep(currentStep - 2);
                return;
            }
        }
        setCurrentStep(currentStep - 1);
    };

    const renderProgress = (currentStep: number) =>
        steps.map((s, i) => {
            const circleColor = currentStep > i ?
                (skipStepIndex === i ? appTheme.color.grey[3] : appTheme.color.common.green) :
                (currentStep === i ? appTheme.color.grey[7] : appTheme.color.grey[4]);

            return (
            <Step key={steps[i].name}>
                <Circle
                    color={circleColor}
                    active={currentStep >= i}
                >
                    {currentStep > i && (
                        <Check
                            width={16}
                            height={16}
                            color={skipStepIndex === i ? appTheme.color.grey[3] : appTheme.color.common.green}
                        />
                    )}
                    {currentStep <= i && <>{i + 1}</>}
                </Circle>
                {currentStep === i && <H2>{steps[i].name}</H2>}
            </Step>
        )});

    const validate = (values: T) => {
        const { validate } = steps[currentStep].component.props;
        return validate ? validate(values) : {};
    };

    const validationSchema = () => {
        const { validationSchema } = steps[currentStep].component.props;
        return validationSchema ? validationSchema : null;
    };

    const handleSubmit = (values: T) => {
        if (currentStep < steps.length - 1) {
            return next();
        }
        onSubmit(values);
    };

    const isDraftButtonReadyToUse = (props) => {
        const currentStepValidationFunctions = steps[currentStep].validationFunctionsPerButtons;

        if (!currentStepValidationFunctions || !currentStepValidationFunctions.draft || !props.dirty) {
            return !formUntouched(props);
        }

        return currentStepValidationFunctions.draft(props);
    };

    const chooseStyleToApplyToReadyButton = (isReadyToUse) => {
        return isReadyToUse ? ButtonType.SECONDARY : ButtonType.DISABLED;
    };

    const isNextButtonReadyToUse = (props) => {
        const currentStepValidationFunctions = steps[currentStep].validationFunctionsPerButtons;

        if (!currentStepValidationFunctions || !currentStepValidationFunctions.next) {
            // default behaviour is to be available
            return true;
        }

        return currentStepValidationFunctions.next(props);
    };

    const chooseStyleToApplyToNextButton = (isReadyToUse) => {
        if (!isReadyToUse) {
            return ButtonType.DISABLED;
        }

        return buttonStyleDecider(isLastPage, continueButtonStyle);
    };

    return (
      
        <Formik
            enableReinitialize={shouldReinitialize}
            // @ts-ignore
            validate={validate}
            validationSchema={validationSchema}
            // @ts-ignore
            onSubmit={handleSubmit}
            // @ts-ignore
            initialValues={initialValues}
            validateOnBlur={validateOnBlur}
            validateOnChange={validateOnChange}
        >
            {(props: FormikProps<FormikValues>) => (
                <Struct.Section>
                    <Column>
                        <FormWrapper withMargin={withMargin} width={width}>
                            <Row auto>
                                {(!backRoute || !isFirstPage) && (
                                    <IconButton id='multiStep-backButton' disabled={isFirstPage} onClick={previous}>
                                        <Icon.ArrowLeft />
                                    </IconButton>
                                )}
                                {backRoute && isFirstPage && (
                                    <Link to={backRoute} id='multiStep-back-Button'>
                                        <Icon.ArrowLeft />
                                    </Link>
                                )}
                                {renderProgress(currentStep)}
                            </Row>

                            <Row auto>
                                {submitDraft && draftLabel && (
                                    <div style={{ marginRight: '10px' }}>
                                        <Button
                                            id='multistep-draft-button'
                                            display={chooseStyleToApplyToReadyButton(isDraftButtonReadyToUse(props))}
                                            onClick={() => submitDraft(props)}
                                            disabled={!isDraftButtonReadyToUse(props)}
                                        >
                                            {draftLabel(props.values, props, t)}
                                        </Button>
                                    </div>
                                )}
                                <Button
                                    id='multistep-submit-button'
                                    display={chooseStyleToApplyToNextButton(isNextButtonReadyToUse(props))}
                                    disabled={!isNextButtonReadyToUse(props)}
                                    type="submit"
                                >
                                    {!isLastPage ? nextLabel : finishLabel}
                                </Button>
                            </Row>
                        </FormWrapper>
                        {React.cloneElement(steps[currentStep].component, props)}
                    </Column>
                </Struct.Section>
            )}
        </Formik>
    );
}

const Step = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;

    & > H2 {
       margin-left: 10px; 
    }
`;

const Check = styled(Icon.Check)`
    margin-top: 8px;
`;

const FormWrapper = styled((props) => <Form {...props} />)<{ withMargin: boolean; width?: number }>`
    height: 100%;
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    margin-bottom: 20px;
    margin-right: ${({ withMargin, width }) => (width ? '0' : withMargin ? '50px' : '0')};
    flex-grow: 0;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export default MultiStep;
