import { QueryLoader, RoutablePanel } from 'components';
import { IRenderProps as IPanelRenderProps } from 'components/Panel';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import AddOrEditEmployeeForm from './AddOrEditForm';
import { getEmployee } from 'types/listEmployees';

const EMPLOYEE_MUTATION = loader('../query/updateEmployee.gql');
const EMPLOYEE_QUERY = loader('../query/getEmployee.gql');

interface IPropsPanel extends RouteComponentProps<{ idEmployee: string }> {
    beforeClose: () => void;
}

const EditEmployeePanel = ({
    match: {
        params: { idEmployee },
    },
    history: { push },
    location: { search },
    beforeClose,
}: IPropsPanel) => {
    const [t] = useTranslation();
    const onClose = () => {
        beforeClose();
        push(`${routes.admin.employees.list()}${search}`);
    };

    return (
        <RoutablePanel title={t(`page:admin.employees.edit.title`)} open={!!idEmployee} onClose={onClose}> 
            {({ onClose }: IPanelRenderProps) => (
                <QueryLoader
                    hasData={(data: getEmployee): boolean => !!(data && data.employee)}
                    variables={{ id: idEmployee }}
                    query={EMPLOYEE_QUERY}
                >
                    {({ data: { employee } }: QueryResult<getEmployee>) => {
                        return (
                            <Mutation
                                mutation={EMPLOYEE_MUTATION}
                                update={(cache: any, { data: { updateEmployee } }: any) => {
                                    const cachedData = cache.readQuery({
                                        query: EMPLOYEE_QUERY,
                                        variables: {
                                            id: idEmployee,
                                        },
                                    });
                                    cache.writeQuery({
                                        query: EMPLOYEE_QUERY,
                                        variables: {
                                            id: idEmployee,
                                        },
                                        data: { ...cachedData, employee: updateEmployee },
                                    });
                                }}
                            >
                                {(updateEmployee: (param: Record<'variables', any>) => Promise<any>) => (
                                    <AddOrEditEmployeeForm
                                        {...{
                                            onSuccess: onClose,
                                            employee,
                                            updateEmployee,
                                        }}
                                    />
                                )}
                            </Mutation>
                        );
                    }}
                </QueryLoader>
            )}
        </RoutablePanel>
    );
};

export default withRouter(EditEmployeePanel);
