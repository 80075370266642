import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Auth from 'services/auth0';

class CallbackComponent extends Component<{} & RouteComponentProps> {
    async componentDidMount() {
        await Auth.handleAuthentication();

        this.props.history.replace('/');
    }

    render() {
        return <p>Loading permissions...</p>;
    }
}

export default withRouter(CallbackComponent);
