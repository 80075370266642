import React from 'react';
import { useTranslation } from 'react-i18next';
import { loader } from 'graphql.macro';
import { Mutation } from '@apollo/client/react/components';
import { StyledButton } from './AdminPage.style';

const DELETE_CHECKLISTUSER = loader('./query/removeEPointageUser.gql');

const DeleteCheckListUserButton = ({ 
    id,
    onChange,
}: {
    id: string;
    onChange: () => void;
}) => {
    const { t } = useTranslation();

    return (
        <Mutation mutation={DELETE_CHECKLISTUSER}>
            {(deleteChecklistUser: (param: Record<'variables', any>) => Promise<any>) => {
                const onDeleteClick = async () => {
                    await deleteChecklistUser({
                        variables: { userId: id },
                    });
                    onChange();
                };

                return (
                    <StyledButton onMouseDown={onDeleteClick}>
                        {t(`app:button.delete`)}
                    </StyledButton>
                );
            }}
        </Mutation>
    );
}

export default (DeleteCheckListUserButton);
