import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Icon, InfoModal } from 'components';
import { ButtonType } from 'components/Button';
import { StyledTooltip } from 'pages/Communication/CommunicationPage/CommunicationListTable';

const GET_LATEST_OFFER_WITH_ITEMS = loader('../../DailyOffersPage/query/getLatestOfferWithItems.gql');
const CLONE_OFFER_ITEMS = loader('../../DailyOffersPage/query/cloneOfferItems.gql');

type Props = {
    idOfferTemplate: string;
    idOffer: string;
    setShouldRefetchItems: (param: boolean) => void;
};

const OfferItemsCloner = ({ idOfferTemplate, idOffer, setShouldRefetchItems }: Props) => {
    const { t } = useTranslation();
    const [latestOfferWithItem, setLatestOfferWithItem] = useState(null);
    const [offerCloneError, setOfferCloneError] = useState('');

    const duplicateLabel = t('app:button.duplicate');
    const infoMessage = t('page:clickcollect.daily-offers.clonerModal.cloneInfo');
    const errorMessagesByType = {
        NO_OFFERS_WITH_ITEMS: t('page:clickcollect.daily-offers.clonerModal.noOfferItemsError'),
        OFFER_ALREADY_HAS_ITEMS: t('page:clickcollect.daily-offers.clonerModal.notEmptyError'),
    };

    const { refetch: getLatestOfferRefetch } = useQuery(GET_LATEST_OFFER_WITH_ITEMS, {
        variables: {
            idOfferTemplate: idOfferTemplate,
            idOffer: idOffer,
        },
        skip: true,
    });

    const { refetch: cloneOfferItemsRefetch } = useQuery(CLONE_OFFER_ITEMS, {
        variables: {
            idOfferToCloneFrom: latestOfferWithItem,
            idOfferToCloneTo: idOffer,
        },
        skip: true,
    });

    const closeModal = () => {
        setLatestOfferWithItem(null);
        setOfferCloneError('');
    };

    const onClickOpenDuplicateModal = async () => {
        if (idOfferTemplate) {
            const {
                data: {
                    getLatestOfferWithItems: { errorType, latestOfferWithItems: latestOfferId },
                },
            } = await getLatestOfferRefetch();

            if (latestOfferId) {
                setLatestOfferWithItem(latestOfferId);
            }

            if (errorType && errorMessagesByType[errorType]) {
                setOfferCloneError(errorMessagesByType[errorType]);
            }
        }
    };

    const onClickDuplicateMenu = async () => {
        const {
            data: {
                cloneOfferItems: { wasCloned },
            },
        } = await cloneOfferItemsRefetch();

        if (wasCloned) {
            setShouldRefetchItems(true);
        }

        closeModal();
    };

    return (
        <>
            <StyledTooltip
                toolTipContent={t('page:clickcollect.tooltips.duplicateOffer')}
                style={{
                    top: '35',
                    width: '200',
                    transform: 'translate(-50%)',
                }}
            >
                <Button onClick={onClickOpenDuplicateModal} display={ButtonType.SECONDARY} styleSvg>
                    <DuplicateIcon title={duplicateLabel}/>
                    {t('app:button.duplicateOfferItems')}
                </Button>
            </StyledTooltip>
            <InfoModal
                message={offerCloneError !== '' ? offerCloneError : infoMessage}
                isOpen={latestOfferWithItem !== null || offerCloneError !== ''}
                onClose={closeModal}
                btnText={duplicateLabel}
                btnOnClick={onClickDuplicateMenu}
                showBtn={offerCloneError === ''}
                showClose
            />
        </>
    );
};

const DuplicateIcon = styled(Icon.DuplicateClean)`
    margin-right: 3px;
`;

export default OfferItemsCloner;
