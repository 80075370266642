import React, { useState } from 'react';
import styled from 'styled-components';
import { appTheme } from 'theme';
import {
    Button,
    Icon,
    Info,
    NumberInput,
    PriceInput,
    Select,
    Struct,
    Tab,
    Tabs,
    Tags,
    TextInput,
    Title,
    ToggleSwitch,
    InfoType,
    DurationPicker,
    IntervalPicker,
    InfoSize,
    FileInput,
} from 'components';
import { ButtonType } from 'components/Button';
import ItemOfferTable from './ItemOfferTable';
import { withTheme, DefaultTheme } from 'styled-components';

interface IProps {
    theme: DefaultTheme;
}

const Demo = ({ theme }: IProps) => {
    const [numberInput, setNumberInput] = useState< number | string>(5);
    const [textInput, setTextInput] = useState('546 - 348');
    const [priceInput, setPriceInput] = useState('10');
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [isChecked, setChecked] = useState(true);
    const [idImage, setImageId] = useState(null);
    function onToggle() {
        setChecked(!isChecked);
    }

    return (
        <>
            <Struct.Header>
                <Struct.Top>
                    <Struct.Breadcrumb>
                        <Icon.Menu color={appTheme.color.grey[6]} />
                        <span>Accueil</span>
                    </Struct.Breadcrumb>
                    <Select id='exploitation-select' placeholder="Exploitation" onChange={() => {}} onSearch={e => {}} data={[]} />
                </Struct.Top>
            </Struct.Header>
            <Struct.Header>
                <Title mode="H1" value="Clic &amp; Collect" icon={<Icon.CnC color={theme.color.grey[6]}/>}>
                    <Select id='restaurant-select' placeholder="Restaurant" onChange={() => {}} onSearch={e => {}} data={[]} />
                </Title>
            </Struct.Header>
            <Struct.Header>
                <Tabs value={selectedTabIndex} onChange={setSelectedTabIndex}>
                    <Tab label="Offres quotidiennes" />
                    <Tab label="Autres offres" />
                    <Tab label="Catalogue" />
                </Tabs>
            </Struct.Header>
            <Struct.PageContent>
                <DurationPicker
                    value={undefined}
                    label="a partir de"
                    initialValue="P2DT9H0M"
                    onClose={() => {}}
                    onChange={() => {}}
                />
                <DurationPicker
                    value={undefined}
                    label="jusqua"
                    initialValue="P5DT17H0M"
                    onClose={() => {}}
                    onChange={() => {}}
                />
                <IntervalPicker
                    value={undefined}
                    valueFrom="P2DT9H30M"
                    valueTo="P5DT17H30M"
                    onClose={() => {}}
                    onChange={() => {}}
                />
                <Struct.Section>
                    <Title
                        mode="H2"
                        value="Offre Délimarché"
                        subTitle={
                            <>
                                <strong>Aujourd'hui</strong> Lundi 11 juin 2019
                            </>
                        }
                        icon={<Icon.ArrowLeft color={appTheme.color.grey[7]} />}
                    >
                        <Button onClick={() => {}}>Ajouter un produit</Button>
                    </Title>

                    <StyledCard>
                        <Title
                            mode="H3"
                            value="Upload"
                            grow
                            icon={<Icon.Cog color={appTheme.color.grey[6]} />}
                        >
                            <span>non modifiable</span>
                        </Title>

                        <FileInput value={idImage} onChange={({ id }) => setImageId(id)} />
                    </StyledCard>

                    <StyledCard>
                        <Title
                            mode="H3"
                            value="Paramètres de l'offre"
                            grow
                            icon={<Icon.Cog color={appTheme.color.grey[6]} />}
                        >
                            <span>non modifiale</span>
                        </Title>

                        <Tags
                            onChange={() => {}}
                            value={['1', '3']}
                            options={[
                                { id: '1', label: 'Entrées' },
                                { id: '2', label: 'Plats' },
                                { id: '3', label: 'Desserts' },
                                { id: '4', label: 'Boissons' },
                                { id: '5', label: 'Snaking' },
                                { id: '6', label: 'Bienfait' },
                                { id: '7', label: 'Délimarché' },
                            ]}
                        />
                        <ItemOfferTable
                            data={[
                                {
                                    id: '1',
                                    name: 'carottes',
                                    changedStock: 0,
                                    stock: 1,
                                },
                                {
                                    id: '2',
                                    name: 'Coca',
                                    changedStock: 0,
                                    stock: 20,
                                    permanent: true,
                                },
                                {
                                    id: '3',
                                    name: 'Coca light',
                                    changedStock: 0,
                                    stock: 20,
                                    permanent: true,
                                },
                            ]}
                        />
                    </StyledCard>

                    <StyledCard>
                        <Title
                            mode="H3"
                            value={<>les produits</>}
                            icon={<Icon.Article color={appTheme.color.grey[6]} />}
                        >
                            <Info size={InfoSize.ROUNDED} display={InfoType.BLACK}>
                                24 actifs
                            </Info>
                            <div style={{ flexGrow: 1 }} />
                            <Button display={ButtonType.VALIDATION} onClick={() => {}}>
                                Valider l'offre
                            </Button>
                        </Title>

                        <Button onClick={() => {}}>Ajouter un produit</Button>
                        <br />
                        <br />
                        <Button display={ButtonType.VALIDATION} onClick={() => {}}>
                            Valider l'offre
                        </Button>
                        <br />
                        <br />
                        <Button display={ButtonType.SECONDARY} onClick={() => {}}>
                            Secondary action
                        </Button>
                        <br />
                        <br />
                        <Button display={ButtonType.GHOST} onClick={() => {}}>
                            Ghost
                        </Button>
                        <br />
                        <br />
                        <Button disabled onClick={() => {}}>
                            Inactive
                        </Button>
                    </StyledCard>
                </Struct.Section>

                <Struct.Section>
                    <div style={{ flexGrow: 0.5, marginRight: 10 }}>
                        <Title
                            mode="H3"
                            value={
                                <>
                                    <strong>Aujourd’hui</strong> le 24/04/19
                                </>
                            }
                        />
                        <StyledCard>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Info display={InfoType.OK}>Ouvert</Info>
                                <StyledInfo size={InfoSize.ROUNDED} display={InfoType.OK}>
                                    Ouvert
                                </StyledInfo>
                                <Icon.SolidArrow />
                            </div>
                        </StyledCard>
                        <StyledCard>
                            <Info display={InfoType.KO}>Fermé</Info>
                            <StyledInfo size={InfoSize.ROUNDED} display={InfoType.KO}>
                                Fermé
                            </StyledInfo>
                            <Icon.Warning />
                        </StyledCard>
                        <StyledCard>
                            <Info display={InfoType.DISABLE}>Fermé</Info>
                            <StyledInfo size={InfoSize.ROUNDED} display={InfoType.DISABLE}>
                                Fermé
                            </StyledInfo>
                        </StyledCard>
                    </div>
                    <div style={{ flexGrow: 0.5, marginLeft: 10 }}>
                        <Title
                            mode="H3"
                            value={
                                <>
                                    <strong>Demain</strong> le 25/04/19
                                </>
                            }
                        />
                        <StyledCard>
                            <Info>Prêt</Info>
                            <StyledInfo size={InfoSize.ROUNDED}>Prêt</StyledInfo>
                        </StyledCard>
                        <StyledCard>
                            <Info display={InfoType.WARNING}>A valider</Info>
                            <StyledInfo size={InfoSize.ROUNDED} display={InfoType.WARNING}>
                                A valider
                            </StyledInfo>
                        </StyledCard>
                        <StyledCard>
                            <Info display={InfoType.BLACK}>Fermé</Info>
                            <StyledInfo size={InfoSize.ROUNDED} display={InfoType.BLACK}>
                                Fermé
                            </StyledInfo>
                        </StyledCard>
                    </div>
                </Struct.Section>
                <Struct.Section>
                    <StyledCard>
                        <ToggleSwitch checked={isChecked} onChange={onToggle} />

                        <NumberInput value={numberInput} onChange={setNumberInput} />
                        <br />
                        <TextInput value={textInput} onChange={setTextInput} hasError={textInput.length < 2} />
                        <br />
                        <br />
                        <PriceInput value={priceInput} onChange={setPriceInput} />
                        <br />
                        <br />
                        <Select
                            placeholder="select"
                            onChange={() => {}}
                            renderItem={item => (
                                <>
                                    <strong>{item.id}</strong>- {item.label}
                                </>
                            )}
                            data={Array.from({ length: 100 }).map((_, i) => ({ id: `${i}`, label: `test${i}` }))}
                            onSearch={e => new Promise<void>((res, rej) => setTimeout(() => res(), Math.random() * 300))}
                        />
                    </StyledCard>
                </Struct.Section>
            </Struct.PageContent>
        </>
    );
};

// @ts-ignore
const StyledCard = styled(Struct.Card)`
    margin-bottom: ${({ theme }) => theme.spacing.s}px;
    vertical-align: center;
`;

const StyledInfo = styled(Info)`
    margin: 0 ${({ theme }) => theme.spacing.s / 2}px;
`;

export default withTheme(Demo);
