import React from 'react';
import { Table } from 'components';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import Service from './Service';
import { checklistService } from 'types/listChecklistServices';

interface IPropsPanel extends RouteComponentProps<{ idHolding: string }> {}

interface IProps {
    data: any[];
    siteId?: string;
    refetchAfterDelete: () => void;
}

const getHeaders = (t: any) =>
    ['name','published', 'actions'].map(key => ({ key, displayName: t(`schema:checkListUser.services.${key}`) }));

const ServicesTable = ({
    data,
    history,
    location,
    siteId,
    refetchAfterDelete
}: IProps & IPropsPanel & RouteComponentProps) => {
    const { t } = useTranslation();

    const renderLine = (item: checklistService, index: number) => {
        return (
            <Service
                item={item}
                key={index}
                index={index}
                refetchAfterDelete={refetchAfterDelete}
                onClickEdit={() => {
                    history.push(`${routes.checkList.services.edit(siteId, item.id.toString())}`);
                }}
            />
        );
    };

    return (
        <Table renderLine={renderLine} data={data} headers={getHeaders(t)} />
    );
};

export default withRouter(ServicesTable);
