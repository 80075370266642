import Modal from 'styled-react-modal'
import React, { useRef, useState } from 'react'
import styled, { DefaultTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'components';
import DatePicker from 'components/Inputs/DatePicker';
import { ButtonType } from 'components/Button';
import { appTheme } from 'theme';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { ErrorToast } from 'components/ErrorToast';
import { extractFilenameFromHeader, triggerBrowserFileDownload } from 'utils';
import { LocalStorageKey, getItem } from 'services/persistentData';

interface IProps {
    open: boolean;
    idHolding: string;
    toggleModal: any;
    theme?: DefaultTheme;
}

const OrderRatingExportModal = ({ open, idHolding, toggleModal, theme }: IProps) => {
    const { t } = useTranslation();

    const [handleExcelReportButtonDebounce, setHandleExcelReportButtonDebounce] = useState<number | null>(null);

    const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] =  useState<Date | null>(null);

	const datePickerStartDateRef = useRef<ReactDatePicker>();
	const datePickerEndDateRef = useRef<ReactDatePicker>();

    const handleStartDateChange = (date: string) => {
        const newDate = new Date(date); 
        newDate.setHours(0, 0, 0, 0);
        setStartDate(newDate);

        if(endDate && endDate < newDate){
            setEndDate(newDate);
        }
        datePickerEndDateRef.current.setOpen(true);
    };

    const handleEndDateChange = (date: string) => {
        const newDate = new Date(date); 
        newDate.setHours(23, 59, 59, 999);
        setEndDate(newDate);

        if(startDate && newDate < startDate){
            setStartDate(newDate);
        }
    };

    /**
     * Use fetch api, because graphql is not good on dealing with file download.
     *
     * @param url
     * @param authToken
     */
    const fetchOrderRatingExcelReport = async (url: string, authToken: string) => {
        try {
            const headers = new Headers();
            headers.append('authorization', authToken);

            const report = await fetch(url, {
                headers
            });

            if (report.status !== 200) {
                toast(<ErrorToast errorMessage="GENERATING_ORDER_RATING_REPORT" />);
                return;
            }

            const defaultReportFilename = t(`app:file.orderRatingExcelDefaultFilename`);
            const filename =
                extractFilenameFromHeader(report.headers.get('content-disposition')) || defaultReportFilename;

            await triggerBrowserFileDownload(await report.blob(), filename);
        } catch (error) {
            toast(<ErrorToast errorMessage="GENERATING_ORDER_RATING_REPORT" />);
        }
    };

    const handleExcelExport = async () => {
         if (handleExcelReportButtonDebounce !== null) {
             // clear previous timer
             clearTimeout(handleExcelReportButtonDebounce);
             setHandleExcelReportButtonDebounce(null);
         }

         const params = {
            idHolding,
            startDate: startDate && startDate.toISOString(),
            endDate: endDate && endDate.toISOString(),
         }
 
         const url = composeOrderRatingExcelReportURL(window.config.API_GRAPHQL_ENDPOINT, params);
         setHandleExcelReportButtonDebounce(
             setTimeout(async () => {
                 await fetchOrderRatingExcelReport(url, getItem(LocalStorageKey.ACCESS_TOKEN) || '');
             }, 500)
         );
         closeModal();
    };

    const composeOrderRatingExcelReportURL = (graphqlEndpoint: string, params: {}) => {
        const apiEndpoint = graphqlEndpoint.split('/graphql')[0];
        return `${apiEndpoint}/order-rating/report?${paramsToQueryString(params)}`;
    };

    const paramsToQueryString = (params) => {
        const result : string[] = [];
        for (const key in params) {
            result.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
        }
        return result.join('&');
    }

    const closeModal = () => {
        setStartDate(null);
        setEndDate(null);
        toggleModal();
    };

    return (
        <StyledModal onBackgroundClick={closeModal}
            isOpen={open} >
            <StyledHeader>
                <div>{t("app:button.orderRatingReport.header")}</div>
                <StyledClose onClick={closeModal}><Icon.Close theme={theme} color={appTheme.color.common.blue} height={25} /></StyledClose>
            </StyledHeader>
            <StyledBody>
                <DatePickerWrapper>
                    <DatePicker 
                        datePickerName={'startDate'} 
                        placeholder={t("app:button.orderRatingReport.dateStart") || ''} 
                        selected={startDate} 
                        showError={!!endDate && startDate === null}
                        shouldCloseOnSelect={true}
                        innerRef={datePickerStartDateRef}
                        onChange={(date) => handleStartDateChange(date)}/>
                </DatePickerWrapper>
                <DatePickerWrapper>
                    <DatePicker 
                        datePickerName={'endDate'} 
                        placeholder={t("app:button.orderRatingReport.dateEnd") || ''} 
                        selected={endDate}
                        showError={!!startDate && endDate === null}
                        shouldCloseOnSelect={true}
                        innerRef={datePickerEndDateRef}
                        onChange={(date) => handleEndDateChange(date)}/>
                </DatePickerWrapper>
            </StyledBody>
                <StyledButton display={ButtonType.ACTION} disabled={!startDate || !endDate} onClick={handleExcelExport}>{t("app:button.orderRatingReport.button")}</StyledButton>
        </StyledModal>
    )
}

const StyledModal = Modal.styled`
    height: 275px;
    width: 400px;
    border-radius: 4px;
    background-color: #FFFFFF;  
    display: flex;
    flex-direction : column;
    font-family: "Open Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    justify-content: space-between;
`;


const StyledHeader = styled.div`
  display: flex;
  flex-direction : row;
  border-radius: 4px 4px 0 0;
  background-color: #5F7081;
  height: 57px;
  color: #FFFFFF;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 15px;
`;

const StyledBody = styled.div`
  display: flex;
  flex-direction : row;
  justify-content: center;
  flex: 1;
  padding-top:55px;
`

const StyledClose = styled.button`
    background: none;
    border: 0px solid transparent;
    &:focus {
        outline: none;
    }
`;

const StyledButton = styled(Button)`
    width: 100%;
    height: 20%;
    cursor: pointer;
`;

const DatePickerWrapper = styled.div`
  padding:10px;
`;

export default OrderRatingExportModal;