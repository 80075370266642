import { QueryLoader, Select } from 'components';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { LocalStorageKey, getItem, setItem } from 'services/persistentData';
import {
    checklistSite, searchChecklistSite,
} from 'types/searchChecklistSite';
import { isNull } from 'lodash';

interface IProps {
    match: {
        params: { siteId?: string};
    };
}
const SelectLayoutChecklistSite = ({
    history,
    match: {
        params: { siteId },
    },
}: IProps & RouteComponentProps) => {
    const { t } = useTranslation();

    const persistedSiteId = getItem(LocalStorageKey.CHECKLIST_SITE_ID);

    const updateHistory = (newSiteId: string) => {
        const { pathname } = window.location;
        history.replace(pathname.replace(siteId!, newSiteId));
    }

    if(persistedSiteId !== siteId) {
        persistedSiteId && updateHistory(persistedSiteId);
    }

    function onChange(selected: { id: string; label: string }): void {
        if (!siteId) history.replace(`/checkList/${selected.id}`);
        setItem(LocalStorageKey.CHECKLIST_SITE_ID, selected.id);
        updateHistory(selected.id);
    }

    return (
        <QueryLoader
            context={{ debounceKey: 'siteId' }}
            query={loader('../../query/searchChecklistSite.gql')}
            variables={{ siteId: persistedSiteId || '', useId: !!persistedSiteId }}
            fetchPolicy='network-only'
            renderWhileLoading
        >
            {({ loading, data, refetch }: QueryResult<searchChecklistSite>) => {
                let sortedChoices: checklistSite[] = [],
                    selected: checklistSite | null = null;

                try {
                    const choices = [...data.choices];
                    selected = data.selected ?? null;

                    sortedChoices = choices.sort((a, b) =>
                        a.label.localeCompare(b.label));
                    
                    if (isNull(persistedSiteId)) {
                        setItem(LocalStorageKey.CHECKLIST_SITE_ID, sortedChoices[0].id);
                        updateHistory(sortedChoices[0].id);
                    }
                } catch (e) {
                    console.error(e);
                }
                return (
                    <Select
                        id='checklistHolding-select'
                        onChange={onChange}
                        placeholder={t(`app:select-checklistHolding`) || ''} 
                        loading={loading}
                        selected={selected}
                        data={sortedChoices}
                        onSearch={(search: any) => refetch({ search: `%${search}%` })}
                    />
                );
            }}
        </QueryLoader>
    );
};

export default withRouter(SelectLayoutChecklistSite);
