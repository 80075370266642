import React, { ReactElement } from 'react';
import { QueryResult } from 'localTypes';
import { Loader } from 'components';
import { NetworkStatus } from '@apollo/client';
import { Query } from '@apollo/client/react/components';


interface IQueryLoaderProps{
    omitLoader?: boolean;
    hasData?: (data: any) => boolean;
    className?: string;
    children: (props: QueryResult<any>) => ReactElement | any;
    displayErrorMessage?: boolean;
    renderWhileLoading?: boolean;
    query?: any;
    variables?: any;
    context?: any;
    errorPolicy?: string;
    skip?: boolean;
    fetchPolicy?: string;
    notifyOnNetworkStatusChange?: boolean;
}

const QueryLoader = ({
    omitLoader,
    hasData,
    children,
    className,
    displayErrorMessage = true,
    renderWhileLoading = false,
    ...rest
}: IQueryLoaderProps) => (
  // @ts-ignore
    <Query {...rest}>
        {(props: QueryResult<any>) => {
            const { networkStatus, errors, data } = props;
            const defaultHasData = () => !!data;
            const hasDataCheck = hasData || defaultHasData;
            const showLoader =
                !omitLoader && (networkStatus === NetworkStatus.loading || (!hasDataCheck(data) && !errors));
            const showChildren = omitLoader || (!(networkStatus === NetworkStatus.loading) && hasDataCheck(data));

            if (errors && displayErrorMessage) return <div>An error has occurred</div>;
            return (
                <>
                    {showLoader && <Loader />}
                    {(!showLoader || (showLoader && renderWhileLoading)) && showChildren && children({ ...props })}
                </>
            );
        }}
    </Query>
);

export default QueryLoader;
