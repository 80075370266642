import get from 'lodash/get';
import React, { FunctionComponent } from 'react';
import { FieldProps } from 'formik';

import { FileInput } from 'components';
import FieldContainer from './FieldContainer';

interface IProps {
    className?: string;
    label?: string;
    disabled?: boolean;
    testID?: string;
}

const FileInputField: FunctionComponent<FieldProps & IProps> = props => {
    const { field, form, disabled, testID } = props;
    const onChange = (value: string) => {
        form.setFieldValue(field.name, value);
        form.setFieldTouched(field.name, true);
    };
    return (
        <FieldContainer {...props}>
            <FileInput
                {...field}
                onChange={onChange}
                hasError={!!get(form.errors, field.name) && !!get(form.touched, field.name)}
                disabled={disabled}
                {...(testID && {testID:`${testID}-file-input`})}
            />
        </FieldContainer>
    );
};

export default FileInputField;
