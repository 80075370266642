import React from 'react';
import styled from 'styled-components';

import { getDates } from 'services/dateService';
import { getDisplayTime } from 'services/dateService';
import { AddButton, DeleteButton } from '../Button';
import { BaseInput, IInputProps } from './common';

const DEFAULT_STEP = 15;

interface IProps extends IInputProps<string> {
    value: string;
    step?: number;
    disabled?: boolean;
    testID?: string;
}

enum Action {
    ADD = 'ADD',
    SUBSTRACT = 'SUBSTRACT',
}

enum Item {
    START = 'START',
    END = 'END',
}

function TimeRangeInput({ value, onChange, name, className, hasError, step = DEFAULT_STEP , disabled, testID}: IProps) {
    const [startDate, endDate] = getDates(value);
    const updateTime = (action: Action, item: Item) => () => {
        const newDate = new Date(item === Item.START ? startDate : endDate);
        newDate.setMinutes(newDate.getMinutes() + (action === Action.ADD ? step : -step));
        onChange(item === Item.START ? `${newDate.toISOString()}/${endDate}` : `${startDate}/${newDate.toISOString()}`);
    };

    return (
        <Wrapper data-test={testID} className={className}>
            <StyledSubtractButton disabled={disabled} onClick={updateTime(Action.SUBSTRACT, Item.START)} />
            <Input disabled hasError={hasError} name={Item.START} value={getDisplayTime(startDate)} type="text" />
            <StyledAddButton disabled={disabled} onClick={updateTime(Action.ADD, Item.START)} />
            <To>À</To>
            <StyledSubtractButton disabled={disabled} onClick={updateTime(Action.SUBSTRACT, Item.END)} />
            <Input disabled hasError={hasError} name={Item.END} value={getDisplayTime(endDate)} type="text" />
            <StyledAddButton disabled={disabled} onClick={updateTime(Action.ADD, Item.END)} />
        </Wrapper>
    );
}

const Wrapper = styled.span`
    display: flex;
    align-items: center;
`;

// @ts-ignore
const Input = styled(BaseInput)`
    width: 75px;
    font-family: ${({ theme }) => theme.typography.fontFamilySecondary};
    font-weight: ${({ theme }) => theme.typography.fontWeight.light};
    &:disabled {
        background-color: inherit;
    }
`;

const StyledAddButton = styled(AddButton)`
    margin-left: 2px;
`;

const StyledSubtractButton = styled(DeleteButton)`
    margin-right: 2px;
`;

const To = styled.div`
    margin: 0 ${({ theme }) => theme.spacing.s}px;
`;

export default TimeRangeInput;
