import { Icon } from 'components';
import React from 'react';
import styled from 'styled-components';
import { appTheme } from 'theme';

interface IProps {
    indication?: boolean | undefined;
}

function Indicator({ indication }: IProps) {
    return (
        <>
            {indication && (
                <IndicateWrapper>
                    <Icon.ArrowLeft width={11} height={36} rotate={180} color={appTheme.color.common.blue} />
                </IndicateWrapper>
            )}
        </>
    );
}
const IndicateWrapper = styled.div`
    margin-top: 16px;
    margin-left: -25px;
    margin-right: 14px;
`;

export default Indicator;
