import { Icon, Info, InfoType, Struct, Title, Text, Column, Row } from 'components';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
    holdingPos_zones_edges_node_Zone,
    holdingPos_zones_edges_node_Zone_pointOfSales,
    holdingPos_zones_edges_node_Zone_pointOfSales_schedules,
} from 'types/holdingPos';
import appRoutes from 'Router/routes';
import { useTranslation } from 'react-i18next';

/**
 * Take an array of days and split non consecutive days into differents arrays
 * e.g. [1, 2, 3, 5] => [[1, 2, 3], [5]]
 * @param days number[]
 */
const splitDays = (days: number[]): number[][] => {
    return days.reduce((memo: number[][], value: number) => {
        if (memo.length === 0) {
            return [[value]];
        } else {
            const lastItem = memo[memo.length - 1];
            if (lastItem[lastItem.length - 1] === value - 1) {
                lastItem.push(value);
                return memo;
            } else {
                return [...memo, [value]];
            }
        }
    }, []);
};

const Schedules = ({ schedules = [] }: { schedules?: holdingPos_zones_edges_node_Zone_pointOfSales_schedules[] }) => {
    const { t } = useTranslation();
    return (
        <>
            {schedules.map(({ days, hours }: { days: number[]; hours: string[] }, i: number) => (
                <ScheduleWrapper key={i}>
                    <Column size={7}>
                        {splitDays(days).map((splitDay, index) => (
                            <Row key={`${index}_row`}>
                                {splitDay.length > 1 && (
                                    <Text textTransform="capitalize">{t(`app:day.from`)}&nbsp;</Text>
                                )}
                                <Text textTransform={splitDay.length > 1 ? undefined : 'capitalize'}>
                                    {t(`app:weekDays.${splitDay[0]}`)}
                                </Text>
                                {splitDay.length > 1 && (
                                    <>
                                        <Text>&nbsp;{t(`app:day.to`)}&nbsp;</Text>
                                        <Text>{t(`app:weekDays.${splitDay[splitDay.length - 1]}`)}</Text>
                                    </>
                                )}
                                {splitDays(days).length > 1 && index !== splitDays(days).length - 1 && (
                                    <Text>&nbsp;{t(`app:and`)}&nbsp;</Text>
                                )}
                            </Row>
                        ))}
                    </Column>
                    <Row size={5} wrapped justify="flex-end">
                        {hours.map((hour, index) => (
                            <React.Fragment key={index}>
                                {index % 2 !== 0 && <Text>&nbsp;{t(`app:to`)}&nbsp;</Text>}
                                <Text>{hour}</Text>
                            </React.Fragment>
                        ))}
                    </Row>
                </ScheduleWrapper>
            ))}
        </>
    );
};
interface IZoneProps extends RouteComponentProps<{ idHolding: string }> {
    zone: holdingPos_zones_edges_node_Zone;
    hideEmpty: boolean;
}
const Zone = ({
    zone,
    hideEmpty,
    match: {
        params: { idHolding },
    },
}: IZoneProps) => {
    const [t] = useTranslation();
    const isEmpty = zone.pointOfSales.length === 0;
    if (isEmpty && hideEmpty) return null;
    const pointOfSales = zone.pointOfSales as holdingPos_zones_edges_node_Zone_pointOfSales[];
    return (
        <Struct.Section>
            <StyledLink to={appRoutes.holding.pos.zoneEdit(idHolding, zone.id)}>
                <Title mode="H3" value={zone.label}>
                    <Icon.SolidArrow />
                </Title>
            </StyledLink>
            <ZoneWrapper>
                {!isEmpty &&
                    pointOfSales.map((pos, index) =>
                        hideEmpty && !pos.open ? null : (
                            <PosCardWrapper key={`pos-${index}`}>
                                <Link to={appRoutes.holding.pos.edit(idHolding, pos.id)}>
                                    <Struct.Card>
                                        <PosCard>
                                            <PosName>{pos.label}</PosName>
                                              <StyledInfo display={pos.open ? InfoType.OK : InfoType.KO}>
                                                  <>{pos.open ? t('app:state.open') : t('app:state.closed')}</>
                                              </StyledInfo>
                                            <Icon.SolidArrow />
                                        </PosCard>
                                        { pos.deleted &&
                                         <DeletedPOSInfo>
                                           <Icon.WarningError />
                                           <TextDeletedInfo>{t('page:holding.pos.label.posDeleted')}</TextDeletedInfo>
                                         </DeletedPOSInfo>
                                        }
                                        {pos.schedules.length > 0 && (
                                            <>
                                                <Horaire>{t('schema:pos.schedule')}</Horaire>
                                                <SchedulesWrapper>
                                                    <Schedules schedules={pos.schedules} />
                                                </SchedulesWrapper>
                                            </>
                                        )}
                                    </Struct.Card>
                                </Link>
                            </PosCardWrapper>
                        )
                    )}
            </ZoneWrapper>
        </Struct.Section>
    );
};
export default withRouter(Zone);

const StyledLink = styled(Link)`
    margin-left: ${({ theme }) => -theme.spacing.s}px;
`;

const Horaire = styled.h4`
    margin-top: ${({ theme }) => theme.spacing.s}px;
    font-size: ${({ theme }) => theme.typography.fontSizeXXS}px;
    color: ${({ theme }) => theme.color.grey[5]};
    text-transform: uppercase;
`;

const PosName = styled.div`
    color: ${props => props.theme.color.grey[6]};
    font-size: 14px;
    line-height: 14px;
    margin-right: ${({ theme }) => theme.spacing.s}px;
    flex: 1;
`;

const StyledInfo = styled(Info)`
    margin-right: ${({ theme }) => theme.spacing.s}px;
`;

const PosCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const PosCardWrapper = styled.div`
    width: 50%;
    padding: 0 ${({ theme }) => theme.spacing.s}px 0 0;
    margin-bottom: ${({ theme }) => theme.spacing.s}px;
`;

const ZoneWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-right: ${({ theme }) => -theme.spacing.s}px;
`;

const SchedulesWrapper = styled(Column)`
    margin-top: ${({ theme }) => theme.spacing.xs}px;
    align-items: normal;
`;

const ScheduleWrapper = styled(Row)`
    align-items: flex-start;
    margin-bottom: ${({ theme }) => theme.spacing.xs}px;
`;

const DeletedPOSInfo = styled.div`
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-right: 40px;
  padding-top: 5px;
  align-items: center;
`

const TextDeletedInfo = styled.div`
    font-size: 14px;
    margin-left: 5px;
`;