import React from 'react';
import { withRouter } from "react-router-dom";
import HubForm from "./HubForm";
import { loader } from "graphql.macro";
import { Mutation } from '@apollo/client/react/components';
import { decodeEntityId } from "../../../utils";
import appRoutes from "../../../Router/routes";
import { QueryResult } from "../../../localTypes";
import { QueryLoader } from "../../../components";
import { PosHub } from "../../../types/posHub";

const GET_POS_HUB = loader('../query/getPosHub.gql');
function Edit({
                  match: {
                      params: { idHolding, idHub },
                  },
                  history
              }) {
    return <Mutation onCompleted={() => history.push(appRoutes.holding.printing.index(idHolding))}
                     mutation={loader('../query/updatePosHub.gql')}>
        {(updatePosHub: (param) => Promise<any>, {
            loading,
        }) => (
            <QueryLoader
                hasData={(data): boolean => !!(data && data.posHub)}
                query={GET_POS_HUB}
                variables={{ id: idHub }}
                fetchPolicy={"cache-and-network"}
            >
                {({ data }: QueryResult<{ posHub: PosHub }>) => {
                    return (
                        <HubForm
                            loading={loading}
                            initialValues={data.posHub}
                            editMode={true}
                            onSubmit={values => updatePosHub({
                                variables: {
                                    input: JSON.parse(JSON.stringify({
                                        ...values,
                                        idHolding: Number(decodeEntityId(idHolding)),
                                        pos: {
                                            id: Number(decodeEntityId(values.pos.id))
                                        }
                                    }), function omitTypename(key, value) {
                                        return key === '__typename' ? undefined : value
                                    })
                                },
                            })}
                        />

                    )
                }}
            </QueryLoader>
        )}
    </Mutation>
};

export default withRouter(Edit);
