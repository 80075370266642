import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { appTheme } from 'theme';

interface IProps {
    children?: ReactElement | ReactElement[];
    content?: string | null;
    className?: string;
    color?: string;
    fontColor?: string;
    pill?: boolean;
    small?: boolean;
}

export default function Badge(props: IProps) {
    
    if(props.small) {
        return (
            <BadgeContainerSmall color={props.color} rounded={!!props.pill}>
                {props.children}
                <BadgeContentSmall 
                    fontColor={props.fontColor ? props.fontColor : appTheme.color.common.black} 
                    hasIcon={!!props.children}
                >
                    {props.content}
                </BadgeContentSmall>
            </BadgeContainerSmall>
        )
    } else {
        return (
            <BadgeContainer color={props.color} rounded={!!props.pill}>
                {props.children}
                <BadgeContent 
                    fontColor={props.fontColor ? props.fontColor : appTheme.color.common.black} 
                    hasIcon={!!props.children}
                >
                    {props.content}
                </BadgeContent>
            </BadgeContainer>
        )
    }
}

interface IBadgeContainerProps {
    rounded: boolean;
}

interface IBadgeContentProps {
    fontColor: string;
    hasIcon: boolean;
}

const BadgeContainer = styled.div<IBadgeContainerProps>`
    background-color: ${(props) => props.color};
    border-radius: ${({ rounded, theme }) => (rounded ? theme.borderRadius.xl : theme.borderRadius.s)}px; 
    width: fit-content;
    height: fit-content;
    padding-left: ${({ theme }) => theme.spacing.s}px; 
    padding-right: ${({ theme }) => theme.spacing.s}px; 
    padding-top: ${({ theme }) => theme.spacing.xxs}px;
    padding-bottom: ${({ theme }) => theme.spacing.xxs}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
`;

const BadgeContainerSmall = styled.div<IBadgeContainerProps>`
    background-color: ${(props) => props.color};
    border-radius: ${({ rounded, theme }) => (rounded ? theme.borderRadius.l : theme.borderRadius.s)}px; 
    width: fit-content;
    height: fit-content;
    padding-left: ${({ theme }) => theme.spacing.xs + 2}px; 
    padding-right: ${({ theme }) => theme.spacing.xs + 2}px;
    padding-bottom: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
`;

const BadgeContent = styled.span<IBadgeContentProps>`
    flex-shrink: 0;
    color: ${(props) => props.fontColor};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.size.M}px; 
    letter-spacing: 0;
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    text-align: center;
    margin-left: ${({hasIcon, theme}) => hasIcon ? theme.spacing.xs : 0}px;
`;

const BadgeContentSmall = styled.span<IBadgeContentProps>`
    flex-shrink: 0;
    color: ${(props) => props.fontColor};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.size.XS}px;
    letter-spacing: 0;
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    text-align: center;
    margin-left: ${({hasIcon, theme}) => hasIcon ? theme.spacing.xxs : 0}px;
`;
