import { Button } from 'components';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import { enqueueOscarImportVariables } from 'types/enqueueOscarImport';
import { ButtonType } from 'components/Button';

const ENQUEUE_OSCAR_IMPORT_MUTATION = loader('./enqueueOscarImport.gql');

interface IProps {
    posId: any;
}

function EnqueueOscarImportButton(props: IProps) {
    const [isImporting, setIsImporting] = useState(false);
    const { t } = useTranslation();
    //const [importButtonText, setimportButtonText] = useState(t('app:button.oscar-import-on'));
    return (
        <Mutation mutation={ENQUEUE_OSCAR_IMPORT_MUTATION}>
            {(
                enqueueOscarImport: (param: {
                    variables: enqueueOscarImportVariables;
                }) => Promise<any>
            ) => {
                const onClick = () => {
                    enqueueOscarImport({
                        variables: {
                            id: props.posId
                        }
                    });

                    setIsImporting(true);
                   // setimportButtonText(t('app:button.oscar-import-off'));
                };
                return (
                    <Button
                        display={ButtonType.SECONDARY}
                        disabled={isImporting}
                        onClick={onClick}
                        
                >{isImporting?t('app:button.oscar-import-off'):t('app:button.oscar-import-on')}</Button>
                );
            }}
        </Mutation>
    );
}

export default EnqueueOscarImportButton;
