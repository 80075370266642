import React, { useEffect, useMemo } from 'react';
import { Column, InfoLabel } from 'components';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { appTheme } from 'theme';
import { Field } from 'formik';
import * as Yup from 'yup';
import { RangeInputField, NewTimeRangeInputField, DaysInAdvanceInputField } from 'components/Inputs/FormikFields';
import TimeSlotInputField, { Time } from 'components/Inputs/FormikFields/TimeSlotInputField';
import { WarningMessage } from 'components/ErrorMessages';
import { OfferTemplateWithdrawalType } from 'types/globalTypes';
import PickupPointsSelector from '../../components/PickupPointsSelector';
import { isDurationStartBeforeEnd } from 'services/form/offerValidator';
import { TextLighter } from '../../../../components/ErrorMessages/styledComponents';
import { ToggleSwitchField } from 'components/Inputs/FormikFields';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';

const GET_CASH_SYSTEM = loader('../query/getCashSystemVersion.gql');

interface IStepProps {
    setStepSchema: Function;
    isModeEdit: boolean;
    validationSchema?: Yup.ObjectSchema<any>;
    values?: any;
    validate?: Function;
    setFieldValue?: Function;
    initialValues?: any;
    pickupPoints?: any[];
    errors?: any;
}

const StepTwo = ({
    values,
    setStepSchema,
    setFieldValue,
    initialValues,
    isModeEdit,
    pickupPoints,
    errors,
}: IStepProps) => {
    const { t } = useTranslation();

    const isSeatClickAndServe = values.withdrawalType === OfferTemplateWithdrawalType.POS_CLICK_SERVE;
    const isInstantClickAndCollect = values.withdrawalType === OfferTemplateWithdrawalType.INSTANT_CLICK_COLLECT;
    const isTableService = values.withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE;
    const clickServeOrTableServiceRule = isSeatClickAndServe || isTableService;
    const isClickAndPickup = values.withdrawalType === OfferTemplateWithdrawalType.CLICK_AND_PICK_UP;

    const isClickAndCollect = values.withdrawalType === OfferTemplateWithdrawalType.POS_TAKE_AWAY;
    const isRoomService = !!values.isRoomService;

    const { data: cashSystemVersion } = useQuery(GET_CASH_SYSTEM, {
        variables: { idHolding: values.holding },
        fetchPolicy: 'network-only',
    });

    const formatCashSystemVersion = () => {
        if (cashSystemVersion !== undefined && cashSystemVersion.getCashSystemVersion !== undefined) {
            let format = 'Inconnue';
            const { name, version } = cashSystemVersion.getCashSystemVersion;
            if (name !== null) {
                format = name + ' ';
                if (version !== null) {
                    format += version;
                } else {
                    format += '';
                }
            }
            return format;
        }
        return t('page:admin.cnc.apiVersionUnknown');
    };

    const handleMinOrderPreparationTimeChange = (value, unitValue) => {
        setFieldValue &&
            setFieldValue(
                'daysInAdvance.min',
                value && unitValue === Time.DAYS ? value : initialValues.daysInAdvance.min
            );
    };

    const handleDaysInAdvanceTimeInputChange = (field: string, value: string): void => {
        setFieldValue && setFieldValue(field, value);
    };

    const handleDaysInAdvanceLabelStart = (min: number, max: number) => {
        if (min === 0) {
            return max > 0
                ? `${t('page:admin.cnc.daysInAdvance')} ${t('page:admin.cnc.of')}`
                : `${t('page:admin.cnc.sameDay')} ${t(`page:admin.cnc.from`).toLowerCase()}`;
        } else {
            return t('page:admin.cnc.daysInAdvance') + ' ' + t(`page:admin.cnc.from`).toLowerCase();
        }
    };

    const handleDaysInAdvanceLabelEnd = (min: number) =>
        min === 0
            ? t('page:admin.cnc.sameDayTo')
            : `${t('page:admin.cnc.daysInAdvance')} ${t(`page:admin.cnc.endWith`)}`;

    useEffect(() => {
        setStepSchema(
            Yup.object().shape({
                orderPerTimeSlot: Yup.object({
                    max: Yup.number()
                        .integer(t('page:admin.cnc.form.error.invalidUnit') || undefined)
                        .min(1, t('page:admin.cnc.form.error.minimumUnit', { value: 1 }) || undefined)
                        .typeError(t('page:admin.cnc.required') || ''),
                }),
                daysInAdvance: Yup.object().shape({
                    min: Yup.number()
                        .integer(t('page:admin.cnc.form.error.invalidUnit') || undefined)
                        .typeError(t('page:admin.cnc.required') || ''),
                    max: Yup.number()
                        .integer(t('page:admin.cnc.form.error.invalidUnit') || undefined)
                        .max(30, t('page:admin.cnc.timeSlotNotConform') || undefined),
                    orderStartDefaultDaily: Yup.string(),
                    orderEndDefaultDaily: Yup.string(),
                }),
                withdrawSlotDuration: Yup.object().shape({
                    duration: Yup.number()
                        .integer(t('page:admin.cnc.form.error.invalidUnit') || undefined)
                        .when('unit', (unit, schema) => {
                            if (unit === Time.MINUTES)
                                return schema
                                    .min(
                                        timeUnitMinMax.minutesWithdraw.min,
                                        t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.minutesWithdraw)
                                    )
                                    .max(
                                        timeUnitMinMax.minutesWithdraw.max,
                                        t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.minutesWithdraw)
                                    );
                            if (unit === Time.HOURS)
                                return schema
                                    .min(
                                        timeUnitMinMax.hours.min,
                                        t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.hours)
                                    )
                                    .max(
                                        timeUnitMinMax.hours.max,
                                        t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.hours)
                                    );
                            return schema;
                        })
                        .typeError(t('page:admin.cnc.required') || ''),
                    unit: Yup.string(),
                }),
                minPreparationDelay: Yup.object().shape({
                    duration: Yup.number()
                        .integer(t('page:admin.cnc.form.error.invalidUnit') || undefined)
                        .when('unit', (unit, schema) => {
                            switch (unit) {
                                case Time.MINUTES:
                                    return schema
                                        .min(
                                            timeUnitMinMax.minutes.min,
                                            t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.minutes)
                                        )
                                        .max(
                                            timeUnitMinMax.minutes.max,
                                            t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.minutes)
                                        );
                                case Time.HOURS:
                                    return schema
                                        .min(
                                            timeUnitMinMax.hours.min,
                                            t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.hours)
                                        )
                                        .max(
                                            timeUnitMinMax.hours.max,
                                            t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.hours)
                                        );
                                case Time.DAYS:
                                    return schema
                                        .min(
                                            timeUnitMinMax.days.min,
                                            t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.days)
                                        )
                                        .max(
                                            timeUnitMinMax.days.max,
                                            t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.days)
                                        );
                                default:
                                    return schema;
                            }
                        })
                        .typeError(t('page:admin.cnc.required') || ''),
                }),
                minCancellationDelay: Yup.object().shape({
                    duration: Yup.number()
                        .integer(t('page:admin.cnc.form.error.invalidUnit') || undefined)
                        .when('unit', (unit, schema) => {
                            if (unit === Time.MINUTES)
                                return schema
                                    .min(
                                        timeUnitMinMax.minutes.min,
                                        t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.minutes)
                                    )
                                    .max(
                                        timeUnitMinMax.minutes.max,
                                        t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.minutes)
                                    );
                            if (unit === Time.HOURS)
                                return schema
                                    .min(
                                        timeUnitMinMax.hours.min,
                                        t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.hours)
                                    )
                                    .max(
                                        timeUnitMinMax.hours.max,
                                        t('page:admin.cnc.form.error.heartMealRange', timeUnitMinMax.hours)
                                    );
                            if (unit === Time.DAYS)
                                return schema
                                    .min(
                                        timeUnitMinMax.days.min,
                                        t('page:admin.cnc.form.error.heartMealRange', {
                                            min: timeUnitMinMax.days.min,
                                            max: timeUnitMinMax.days.max - 1,
                                        })
                                    )
                                    .max(
                                        timeUnitMinMax.days.max - 1,
                                        t('page:admin.cnc.form.error.heartMealRange', {
                                            min: timeUnitMinMax.days.min,
                                            max: timeUnitMinMax.days.max - 1,
                                        })
                                    );
                            return schema;
                        })
                        .typeError(t('page:admin.cnc.required') || ''),
                    unit: Yup.string(),
                }),
            })
        );
    }, [setStepSchema, t]);

    useEffect(() => {
        if ((isSeatClickAndServe || isInstantClickAndCollect) && values.orderStartDefaultDaily == null) {
            setFieldValue && setFieldValue('orderStartDefaultDaily', 'P0DT11H00M');
            setFieldValue && setFieldValue('orderEndDefaultDaily', 'P0DT15H00M');
            return;
        }
        if (
            values.withdrawalType === OfferTemplateWithdrawalType.TABLE_SERVICE &&
            values.orderStartDefaultDaily == null
        ) {
            setFieldValue && setFieldValue('orderStartDefaultDaily', 'P0DT12H00M');
            setFieldValue && setFieldValue('orderEndDefaultDaily', 'P0DT14H00M');
            return;
        }
        if (values.orderStartDefaultDaily == null) {
            setFieldValue && setFieldValue('orderStartDefaultDaily', 'P0DT9H0M');
            setFieldValue && setFieldValue('orderEndDefaultDaily', 'P0DT17H0M');
        }
        if (
            values.daysInAdvance.min === values.daysInAdvance.max &&
            values.orderEndDefaultDaily != null &&
            !isDurationStartBeforeEnd(values.daysInAdvance.orderStartDefaultDaily, values.orderEndDefaultDaily)
        ) {
            errors.daysInAdvance = {
                min: '',
                max: '',
                orderStartDefaultDaily: t('page:admin.cnc.form.error.newRange'),
                orderEndDefaultDaily: '',
            };
        }
    });

    useEffect(() => {
        if (isClickAndPickup) {
            const selectedPoints = pickupPoints?.filter((point) => point.selected) || [];
            if (values.pickupPoints.length === 0 && selectedPoints?.length > 0) {
                setFieldValue &&
                    setFieldValue(
                        'pickupPoints',
                        selectedPoints.map((point) => ({ id: point.id, label: point.name }))
                    );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * With useMemo it will avoid re-calculating the warning component message at every update.
     * It will compute the value, just once, at on mount.
     *
     */
    const displayCashSystemConfigWarning = useMemo(() => {
        const mailToSupportURI = `mailto:${window.config.SUPPORT_CONTACT}`;
        const subject = t('page:admin.cnc.cashSystemConfigWarningEmailSubject');

        return (
            <WarningWrapper>
                <WarningMessage renderChildrenAsIs={true}>
                    <TextLighter>
                        {t('page:admin.cnc.cashSystemConfigWarning')}&nbsp;
                        <a
                            style={{ color: appTheme.color.common.blue }}
                            href={`${mailToSupportURI}?subject=${subject}`}
                            title={mailToSupportURI}
                        >
                            {t('page:admin.cnc.serviceSupport')}
                        </a>
                        <InfoLabel
                            fontSize="S"
                            paddingBottom={8}
                            name={t('page:admin.cnc.apiVersionIdentified', { value: formatCashSystemVersion() })}
                        />
                    </TextLighter>
                </WarningMessage>
            </WarningWrapper>
        );
    }, [t, cashSystemVersion]);

    const renderManageGuestSection = () => {
        return (
            <Field
                name="enableComment"
                component={ToggleSwitchField}
                sideLabel={true}
                onLabel={t('page:admin.cnc.enableComments')}
                offLabel={t('page:admin.cnc.enableComments')}
            />
        );
    };

    if (isClickAndPickup) {
        return (
            <Wrapper>
                <SubWrapper>
                    <InfoLabel fontWeight="bold" name={`1. ${t(`page:admin.cnc.orderManagement`)}`} />
                    <InfoLabel fontSize="S" name={t(`page:admin.cnc.orderManagementDescription`)} />
                    {isModeEdit && (
                        <WarningWrapper>
                            <WarningMessage renderChildrenAsIs>
                                <TextLighter>
                                    <Trans
                                        i18nKey="page:admin.cnc.editMixedDisabledWarning"
                                        components={[<strong></strong>]}
                                    />
                                </TextLighter>
                            </WarningMessage>
                        </WarningWrapper>
                    )}
                    <InfoLabel
                        name={t('page:admin.cnc.allowOrdersInAdvance')}
                        fontSize="S"
                        paddingBottom={3}
                        withTooltip
                        tooltipContent={t('page:admin.cnc.allowOrdersInAdvanceTooltip')}
                    />
                    <Field
                        id="daysInAdvance-input"
                        label={t('page:admin.cnc.conviveCanOrder')}
                        width={50}
                        timeWidth={80}
                        labelEnd={t('page:admin.cnc.asSoonAsPossible')}
                        name="daysInAdvance"
                        labelStart={t('page:admin.cnc.atTheLatest')}
                        timeLabelStart={handleDaysInAdvanceLabelStart(
                            values.daysInAdvance.min,
                            values.daysInAdvance.max
                        )}
                        timeLabelEnd={handleDaysInAdvanceLabelEnd(values.daysInAdvance.min)}
                        fontWeight="normal"
                        validateFormOnChange
                        useDefaultErrorMessage={false}
                        component={DaysInAdvanceInputField}
                        handleInputChange={handleDaysInAdvanceTimeInputChange}
                        labelsFontSize="S"
                        fontSize="S"
                        marginBottom="xs"
                        disabledDaysFields={isModeEdit}
                    />
                    {!isTableService && (
                        <>
                            <InfoLabel fontWeight="bold" name={`2. ${t('page:admin.cnc.ManageGuestComment')}`} />
                            {renderManageGuestSection()}
                        </>
                    )}
                </SubWrapper>
                <VerticalLine />
                <SubWrapper>
                    <InfoLabel fontWeight="bold" name={`2. ${t('page:admin.cnc.deliveryManagement')}`} />
                    <InfoLabel
                        paddingBottom={1}
                        fontSize="S"
                        fontWeight="bold"
                        name={t('page:admin.cnc.pickupPoints')}
                    />
                    <InfoLabel fontSize="S" name={t('page:admin.cnc.selectPickupPoints')} />
                    <PickupPointsSelector
                        idHolding={values.holding}
                        idOfferTemplate={values?.id}
                        selected={values.pickupPoints.map(({ id }) => id)}
                        pickupPoints={pickupPoints || []}
                        setSelected={(values) => {
                            const newValues = pickupPoints?.flatMap(({ id, name }) =>
                                values.includes(id) ? { id, label: name } : []
                            );
                            setFieldValue && setFieldValue('pickupPoints', newValues);
                        }}
                    />
                    <InfoLabel fontWeight="bold" paddingBottom={1} name={`${t(`page:admin.cnc.cancellation`)}`} />
                    <InfoLabel fontSize="S" name={t(`page:admin.cnc.cancellationClickPickUpSubtittle`)} />
                </SubWrapper>
            </Wrapper>
        );
    }

    if (clickServeOrTableServiceRule) {
        return (
            <Wrapper>
                <SubWrapper>
                    <InfoLabel fontWeight="bold" name={`1. ${t(`page:admin.cnc.orderManagement`)}`} />
                    {isSeatClickAndServe && (
                        <InfoLabel
                            fontSize="S"
                            name={t(`page:admin.cnc.orderManagementDescriptionSeatClickAndServe`)}
                        />
                    )}
                    {isTableService && (
                        <InfoLabel fontSize="S" name={t(`page:admin.cnc.orderManagementDescriptionCommandeATable`)} />
                    )}
                    {isModeEdit && (
                        <WarningWrapper>
                            <WarningMessage renderChildrenAsIs>
                                <TextLighter>
                                    <Trans
                                        i18nKey="page:admin.cnc.editMixedDisabledWarning"
                                        components={[<strong></strong>]}
                                    />
                                </TextLighter>
                            </WarningMessage>
                            <InfoLabel
                                fontSize="S"
                                paddingBottom={8}
                                name={t('page:admin.cnc.apiVersionIdentified', { value: formatCashSystemVersion() })}
                            />
                        </WarningWrapper>
                    )}
                    <InfoLabel
                        fontWeight="bold"
                        fontSize="S"
                        paddingBottom={2}
                        name={t(`page:admin.cnc.orderRangeSeatClickAndServe`)}
                    />
                    {values.orderStartDefaultDaily !== null && (
                        <Field
                            id="orderRange-input"
                            label={t('page:admin.cnc.guestCanOrder')}
                            width={89}
                            labelStart={t(`page:admin.cnc.from`).toLowerCase()}
                            labelEnd={t(`page:admin.cnc.to`)}
                            labelEndInline={' '}
                            name="orderStartRange"
                            fontWeight="normal"
                            validateFormOnChange
                            useDefaultErrorMessage={false}
                            component={NewTimeRangeInputField}
                            labelsFontSize="S"
                            fontSize="S"
                            nameStart="orderStartDefaultDaily"
                            nameEnd="orderEndDefaultDaily"
                        />
                    )}
                    {!isTableService && (
                        <>
                            <InfoLabel fontWeight="bold" name={`2. ${t('page:admin.cnc.ManageGuestComment')}`} />
                            {renderManageGuestSection()}
                        </>
                    )}
                </SubWrapper>
                <VerticalLine />
                <SubWrapper />
            </Wrapper>
        );
    }

    if (isInstantClickAndCollect) {
        return (
            <Wrapper>
                <SubWrapper>
                    <InfoLabel fontWeight="bold" name={`1. ${t('page:admin.cnc.orderManagement')}`} />
                    <InfoLabel
                        fontSize="S"
                        name={t(`page:admin.cnc.orderManagementDescriptionInstantClickAndCollect`)}
                    />
                    {isModeEdit && (
                        <WarningWrapper>
                            <WarningMessage renderChildrenAsIs>
                                <TextLighter>
                                    <Trans
                                        i18nKey="page:admin.cnc.editMixedDisabledWarning"
                                        components={[<strong></strong>]}
                                    />
                                </TextLighter>
                            </WarningMessage>
                        </WarningWrapper>
                    )}
                    <InfoLabel
                        fontWeight="bold"
                        fontSize="S"
                        paddingBottom={2}
                        name={t(`page:admin.cnc.orderRangeInstantClickAndCollectOrder`)}
                    />
                    {values.orderStartDefaultDaily !== null && (
                        <Field
                            id="orderRange-input"
                            label={'\n'}
                            width={89}
                            labelEnd={t(`page:admin.cnc.to`)}
                            labelEndInline={' '}
                            name="orderStartRange"
                            fontWeight="normal"
                            validateFormOnChange
                            useDefaultErrorMessage={false}
                            component={NewTimeRangeInputField}
                            labelsFontSize="S"
                            fontSize="S"
                            nameStart="orderStartDefaultDaily"
                            nameEnd="orderEndDefaultDaily"
                        />
                    )}
                    {!isTableService && (
                        <>
                            <InfoLabel fontWeight="bold" name={`2. ${t('page:admin.cnc.ManageGuestComment')}`} />
                            {renderManageGuestSection()}
                        </>
                    )}
                </SubWrapper>
                <VerticalLine />
                <SubWrapper>
                    <>
                        <InfoLabel fontWeight="bold" name={`3. ${t(`page:admin.cnc.withdrawalManagement`)}`} />
                        <Field
                            name="enableWithdrawalTypeSelection"
                            component={ToggleSwitchField}
                            sideLabel={true}
                            onLabel={t('page:admin.cnc.enableWithdrawalTypeSelection')}
                            offLabel={t('page:admin.cnc.enableWithdrawalTypeSelection')}
                        />
                    </>
                </SubWrapper>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <SubWrapper>
                <InfoLabel fontWeight="bold" name={`1. ${t(`page:admin.cnc.orderManagement`)}`} />
                <InfoLabel fontSize="S" name={t(`page:admin.cnc.orderManagementDescription`)} />
                {isModeEdit && (
                    <WarningWrapper>
                        <WarningMessage renderChildrenAsIs>
                            <TextLighter>
                                <Trans
                                    i18nKey="page:admin.cnc.editMixedDisabledWarning"
                                    components={[<strong></strong>]}
                                />
                            </TextLighter>
                        </WarningMessage>
                    </WarningWrapper>
                )}
                <InfoLabel
                    fontWeight="bold"
                    fontSize="S"
                    paddingBottom={8}
                    name={t('page:admin.cnc.minOrderPreparationTime')}
                    withTooltip
                    tooltipContent={t('page:admin.cnc.minOrderPreparationTimeTooltip')}
                />
                <Field
                    id={'minPreparationDelay-input'}
                    name="minPreparationDelay"
                    validateFormOnChange
                    useDefaultErrorMessage={false}
                    component={TimeSlotInputField}
                    selectData={[Time.MINUTES, Time.HOURS, Time.DAYS]}
                    marginBottom="xxs"
                    handleChange={handleMinOrderPreparationTimeChange}
                    disabled={isModeEdit}
                />
                <InfoLabel
                    name={t('page:admin.cnc.allowOrdersInAdvance')}
                    fontSize="S"
                    paddingBottom={3}
                    withTooltip
                    tooltipContent={t('page:admin.cnc.allowOrdersInAdvanceTooltip')}
                />
                {isClickAndCollect && (
                    <Field
                        name="isRoomService"
                        component={ToggleSwitchField}
                        sideLabel={true}
                        onLabel={t('page:admin.cnc.enableRoomService')}
                        offLabel={t('page:admin.cnc.enableRoomService')}
                        disabled={isModeEdit}
                    />
                )}
                <Field
                    id="daysInAdvance-input"
                    label={t('page:admin.cnc.conviveCanOrder')}
                    width={50}
                    timeWidth={80}
                    labelEnd={t('page:admin.cnc.asSoonAsPossible')}
                    name="daysInAdvance"
                    labelStart={t('page:admin.cnc.atTheLatest')}
                    timeLabelStart={handleDaysInAdvanceLabelStart(values.daysInAdvance.min, values.daysInAdvance.max)}
                    timeLabelEnd={handleDaysInAdvanceLabelEnd(values.daysInAdvance.min)}
                    fontWeight="normal"
                    validateFormOnChange
                    useDefaultErrorMessage={false}
                    component={DaysInAdvanceInputField}
                    handleInputChange={handleDaysInAdvanceTimeInputChange}
                    labelsFontSize="S"
                    fontSize="S"
                    marginBottom="xs"
                    disabledDaysFields={isModeEdit || isRoomService}
                    disabledTimesField={isModeEdit && values.daysInAdvance.max > 0}
                />
                {values.daysInAdvance.max > 0 ? displayCashSystemConfigWarning : null}
                {!isTableService && (
                    <>
                        <InfoLabel fontWeight="bold" name={`2. ${t('page:admin.cnc.ManageGuestComment')}`} />
                        {renderManageGuestSection()}
                    </>
                )}
            </SubWrapper>
            <VerticalLine />
            <SubWrapper>
                <InfoLabel fontWeight="bold" name={`3. ${t(`page:admin.cnc.withdrawalManagement`)}`} />
                <InfoLabel
                    fontWeight="bold"
                    fontSize="S"
                    paddingBottom={2}
                    name={t(`page:admin.cnc.withdrawalRange`)}
                />
                <Field
                    id="guestCanGetOrder-input"
                    label={t('page:admin.cnc.guestCanGetOrder')}
                    width={89}
                    labelStart={t(`page:admin.cnc.from`).toLowerCase()}
                    labelEnd={t(`page:admin.cnc.to`)}
                    labelEndInline={' '}
                    name="withdrawalRange"
                    fontWeight="normal"
                    validateFormOnChange
                    useDefaultErrorMessage={false}
                    component={NewTimeRangeInputField}
                    labelsFontSize="S"
                    fontSize="S"
                    marginBottom="xs"
                    nameStart="withdrawStartDefault"
                    nameEnd="withdrawEndDefault"
                />
                <InfoLabel
                    fontWeight="bold"
                    fontSize="S"
                    name={t('page:admin.cnc.withdrawTimeSlotDuration')}
                    paddingBottom={3}
                />
                <Field
                    id="timeToCollectOrder-input"
                    label={t('page:admin.cnc.timeToCollectOrder')}
                    fontSize="S"
                    labelStart={t('page:admin.cnc.every')}
                    labelsFontSize="S"
                    name="withdrawSlotDuration"
                    validateFormOnChange
                    useDefaultErrorMessage={false}
                    component={TimeSlotInputField}
                    selectData={[Time.MINUTES, Time.HOURS]}
                    marginBottom="xxs"
                />
                <Field
                    id="maximumOrder-input"
                    label={t(`page:admin.cnc.maximumOrder`)}
                    width={60}
                    labelEnd={t(`page:admin.cnc.IAccept`)}
                    labelEndInline={t(`page:admin.cnc.orderPerTimeSlot`)}
                    fontWeight="bold"
                    name="orderPerTimeSlot"
                    validateFormOnChange
                    useDefaultErrorMessage={false}
                    component={RangeInputField}
                    labelsFontSize="S"
                    fontSize="S"
                    marginBottom="xs"
                />
                <InfoLabel
                    fontWeight="bold"
                    fontSize="S"
                    name={t('page:admin.cnc.cancellation')}
                    paddingBottom={3}
                    withTooltip
                    tooltipContent={t('page:admin.cnc.cancellationWhenPreparationReadyTooltip')}
                />
                <InfoLabel fontSize="S" name={t('page:admin.cnc.cancellationDelaySubtitle1')} paddingBottom={5} />
                <Field
                    id="cancellationDelaySubtitle2-input"
                    fontSize="S"
                    labelEnd={t(`page:admin.cnc.cancellationDelaySubtitle2`)}
                    labelsFontSize="S"
                    name="minCancellationDelay"
                    validateFormOnChange
                    useDefaultErrorMessage={false}
                    component={TimeSlotInputField}
                    selectData={[Time.MINUTES, Time.HOURS, Time.DAYS]}
                    marginBottom="xxs"
                />
            </SubWrapper>
        </Wrapper>
    );
};

const timeUnitMinMax = {
    minutes: {
        min: 1,
        max: 59,
    },
    hours: {
        min: 1,
        max: 23,
    },
    days: {
        min: 1,
        max: 30,
    },
    minutesWithdraw: {
        min: 1,
        max: 720,
    },
};

const Wrapper = styled.div`
    align-items: flex-start;
    padding-top: ${({ theme }) => theme.spacing.s}px;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    background-color: ${({ theme }) => theme.color.common.white};
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const LeftColumn = styled(Column)`
    align-items: flex-start;
`;

// @ts-ignore
const SubWrapper = styled(LeftColumn)`
    margin: 0 20px;
    width: 460px;
`;

const VerticalLine = styled.div`
    border-left: 2px solid ${() => appTheme.color.grey[0]};
    height: calc(100% - 40px);
    position: flex;
    left: 50%;
    margin-left: -3px;
    top: 0;
`;

const WarningWrapper = styled.div`
    margin-bottom: 10px;
`;

export default StepTwo;
