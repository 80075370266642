import I18n from 'services/i18n';
import { ArticleFamily } from 'types/globalTypes';
import { ITagOption } from 'components/Tag';

export const ID_MENU_SYSTEM_SEPARATOR = ':'

export function getArticleFamilyOptions(withEmptyOption?: boolean, emptyLabel = ''): ITagOption[] {
    const options = Object.values(ArticleFamily).map(family => ({
        id: family,
        label: I18n.t(`schema:article.familyOption.${family}`),
    }));
    return withEmptyOption ? options.concat([{ id: null as unknown as ArticleFamily, label: emptyLabel }]) : options;
}

//Formatting values from database to better user experience
export function formatIdMenuSystem(idMenuSystem : string | null): string |null {
    
    return idMenuSystem  ?  idMenuSystem.split(ID_MENU_SYSTEM_SEPARATOR)[1] : idMenuSystem ;
}
