import get from 'lodash/get';
import React, { FunctionComponent } from 'react';
import { FieldProps } from 'formik';
import { TextInput } from 'components';
import FieldContainer from './FieldContainer';
import { InputState } from '../common';

interface IProps {
    id?: string;
    className?: string;
    label?: string;
    disabled?: boolean;
    width?: number;
    maxLength?: number;
    style?: object;
    onChange?: (value) => void;
    useDefaultErrorMessage?: boolean;
    useDefaultErrorColoring?: boolean;
    onChangeField?: Function;
    value?: any;
    state?: InputState;
    color?: string;
    justify?: string;
    testID?: string;
}

const HourInputField: FunctionComponent<FieldProps & IProps> = (props) => {
    const {
        field,
        form,
        width,
        disabled,
        maxLength,
        style,
        useDefaultErrorColoring,
        onChangeField,
        onChange,
        value,
        state,
        color,
        id,
        testID
    } = props;
    let newField
    if (value || value === '') {
        newField = {...field, value}
    } else {
        newField = field
    }

    const formatter = new Intl.NumberFormat('fr-FR', { 
        minimumIntegerDigits: 2
    })
    
    const handleOnChange = (value: string) => {
        if (value.match(/^[0-9]{0,2}:[0-9]{0,2}$/)) {
            if (onChange) {
                onChange({ fieldName: field.name, value });
            } else {
                form.setFieldValue(field.name, value);
                form.setFieldTouched(field.name, true);
    
                if (onChangeField) {
                    onChangeField(value);
                }
            }
        }
    };

    const handleOnBlur = () => {
        let hourString = value?.split(':')[0];
        let minString = value?.split(':')[1];

        if (hourString > 23) hourString = 23;
        if (minString > 59) minString = 59;

        const newValue = `${formatter.format(hourString)}:${formatter.format(minString)}`;

        if (onChange) {
            onChange({ fieldName: field.name, value: newValue });
        } else {
            form.setFieldValue(field.name, newValue);
            form.setFieldTouched(field.name, true);

            if (onChangeField) {
                onChangeField(newValue);
            }
        }
    }
    
    return (
        <FieldContainer {...props}>
            <TextInput
                {...newField}
                id={id}
                width={width}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                hasError={!!get(form.errors, field.name) && !!get(form.touched, field.name)}
                disabled={disabled}
                maxLength={maxLength}
                style={style}
                useDefaultErrorColoring={useDefaultErrorColoring}
                state={state}
                color={color}
                {...(testID && {testID:`${testID}-input`})}
            />
        </FieldContainer>
    );
};

export default HourInputField;
