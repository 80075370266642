import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import styled from 'styled-components';
import { Duration } from 'luxon';
import { getBookingTemplateOffer_pos_Pos } from 'types/getBookingTemplateOffer';
import { appTheme } from 'theme';
import { Icon, Struct, Title, Loader, Column, Row, Text } from 'components';
import { getDates, getDisplayTime } from 'services/dateService';
import BookingOfferPublishToggle from 'feature/BookingOfferPublishToggle';
import OfferDatesCarrousel from 'components/OfferDatesCarousel';
import ReservationsInfo from '../components/ReservationsInfo';
import ReservationsCountInfo from '../components/ReservationsCountInfo';
import BookingOfferParameterFormPanel from './BookingOfferParameterFormPanel';
import { getPlannedAndUnavailableOffers } from '../../../services/offerService';
import { PaginationOptions } from 'localTypes';

const GET_BOOKING_OFFERS_QUERY = loader('../query/getBookingTemplateOffer.gql');
const GET_PLANNED_OFFERS_V2_QUERY = loader('../query/getPlannedOffersV2FromOfferTemplate.gql');
const DATE_COUNT = 5;

interface ISelectedOffer {
    id?: string;
    published?: boolean;
    disabled?: boolean;
    withdrawRange?: string;
    withdrawSlotDuration?: string;
    maxOrdersPerSlot?: string | number;
}

const DailyBookingServicePage = ({
                                     match: {
                                         params: { idPos, idHolding },
                                     },
                                     history,
                                 }: RouteComponentProps<{ idPos: string; idHolding: string }>) => {
    const { t } = useTranslation();
    const [isOfferPanelOpen, setOfferPanelOpen] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState<ISelectedOffer>({ id: '' });
    const [plannedOffersPagination, setPlannedOffersPagination] = useState<PaginationOptions>({
        first: DATE_COUNT,
    });

    const { loading, data, refetch } = useQuery(
        GET_BOOKING_OFFERS_QUERY,
        {
            variables: { idPos },
            fetchPolicy: 'network-only',
        }
    );

    const currentPos = data?.pos as getBookingTemplateOffer_pos_Pos;
    const offerTemplate = currentPos?.bookingOfferTemplate[0];

    const {
        loading: plannedOffersLoading,
        data: plannedOffersData,
        refetch: refetchOffers,
    } = useQuery(GET_PLANNED_OFFERS_V2_QUERY, {
        variables: { idOfferTemplate: offerTemplate?.id, ...plannedOffersPagination },
        fetchPolicy: 'network-only',
        skip: !offerTemplate?.id,
    });
    const formatedPLannedOffers = plannedOffersData?.offerTemplate?.plannedOffers_v2?.edges?.map(({ node }) => node);
    const firstOfferDisplayedDate =
        formatedPLannedOffers && new Date(getDates(formatedPLannedOffers[0].withdrawRange)[0]);

    let plannedOffers: any[] = [];
    if (formatedPLannedOffers && formatedPLannedOffers.length > DATE_COUNT - 2)
        formatedPLannedOffers.forEach((el) =>
            plannedOffers.push({
                ...el,
                disabled: false,
                isDaysInAdvance: false,
            })
        );
    else
        plannedOffers = getPlannedAndUnavailableOffers({
            plannedOffers: formatedPLannedOffers,
            fromDate: firstOfferDisplayedDate,
            isDaysInAdvance: false,
        });

    const hasNextPage = plannedOffersData?.offerTemplate?.plannedOffers_v2?.pageInfo?.hasNextPage;
    const hasPreviousPage = plannedOffersData?.offerTemplate?.plannedOffers_v2?.pageInfo?.hasPreviousPage;

    const selectedOfferDates = selectedOffer?.withdrawRange ? getDates(selectedOffer.withdrawRange) : ['', ''];

    useEffect(() => {
        if (!plannedOffersLoading && plannedOffers?.length > 0) {
            let offer = null;
            if (selectedOffer && selectedOffer.id) {
                offer = plannedOffers.find((offer) => offer.id === selectedOffer.id);
            }
            offer ? setSelectedOffer(offer) : setSelectedOffer(plannedOffers[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plannedOffersLoading, plannedOffersData]);

    useEffect(() => {
        if (currentPos?.bookingOfferTemplate?.length < 1) {
            history.push('/' + idHolding + '/bookingservice/' + idPos + '/admin');
        }
    }, [currentPos, history, idHolding, idPos]);

    const renderCarrouselHeader = () => {
        const dates = getDates(selectedOffer?.withdrawRange as string);
        if (offerTemplate?.id) refetchOffers();
        const offerDateStart = !!dates[0] && new Date(dates[0]);

        return (
            offerDateStart && (
                <>
                    <Row size={2}></Row>
                    <WrapperColumn size={8}>
                        <OfferDateTitleRow justify="center">
                            <OfferDateTitleText bold size="M">
                                {t(`app:reservation.dateTitle`)}
                            </OfferDateTitleText>
                            <OfferDateTitleText2 size="M">{t(`app:reservation.of`)}</OfferDateTitleText2>
                            <OfferDateTitleText size="M">
                                {t(`app:weekDays.${offerDateStart.getDay()}`)}
                            </OfferDateTitleText>
                            <OfferDateTitleText size="M">
                                {offerDateStart.toLocaleDateString(undefined, {
                                    month: 'numeric',
                                    day: 'numeric',
                                    year: 'numeric',
                                })}
                            </OfferDateTitleText>
                        </OfferDateTitleRow>
                    </WrapperColumn>
                    <Row size={2}>
                        {!!selectedOffer.id && (
                            <BookingOfferPublishToggle
                                testID='daily-booking-service-page-published'
                                offer={selectedOffer}
                                onChange={(published) => {
                                    setSelectedOffer({ ...selectedOffer, published });
                                }}
                            />
                        )}
                    </Row>
                </>
            )
        );
    };

    const toggleOfferModal = () => {
        refetch();
        setOfferPanelOpen(!isOfferPanelOpen);
    };

    const handlePlannedOffersPaginationClick = (click: '+' | '-') => {
        let newPlannedOffersPagination: PaginationOptions | undefined;
        if (click === '+') {
            newPlannedOffersPagination = {
                after: plannedOffersData?.offerTemplate?.plannedOffers_v2?.pageInfo?.endCursor,
                first: DATE_COUNT,
            };

            setPlannedOffersPagination(newPlannedOffersPagination);
        } else if (click === '-') {
            newPlannedOffersPagination = {
                before: plannedOffersData?.offerTemplate?.plannedOffers_v2?.pageInfo?.startCursor,
                last: DATE_COUNT,
            };
        }
        newPlannedOffersPagination && setPlannedOffersPagination(newPlannedOffersPagination);
    };

    return loading ? (
        <Loader />
    ) : (
        <>
            {selectedOffer && (
                <BookingOfferParameterFormPanel
                    offer={selectedOffer}
                    offerTemplate={offerTemplate}
                    closeModal={toggleOfferModal}
                    isOpen={isOfferPanelOpen}
                />
            )}
            <Struct.Section>
                <OfferDatesCarrousel
                    testID='daily-booking-service-page-carrousel'
                    renderHeader={renderCarrouselHeader}
                    loading={plannedOffersLoading}
                    activeOfferId={selectedOffer.id}
                    onSelectOffer={setSelectedOffer}
                    offers={plannedOffers}
                    paginationButtons={{
                        hasNextPage,
                        hasPreviousPage,
                        handleNextButtonClick: () => handlePlannedOffersPaginationClick('+'),
                        handlePreviousButtonClick: () => handlePlannedOffersPaginationClick('-'),
                    }}
                    isInAdvanceOfferTemplate={offerTemplate && offerTemplate.daysInAdvanceEnd > 0}
                    useOnlyOpenClosedState
                />
                {selectedOffer && (
                    <ParameterCard onClick={toggleOfferModal}>
                        <Title
                            testID='daily-booking-service-page-reservation-parameters'
                            mode="H3"
                            value={t('app:reservation.parameters')}
                            grow
                            icon={<Icon.Cog color={appTheme.color.grey[6]} />}
                        >
                            <Icon.SolidArrow />
                        </Title>
                        {plannedOffersLoading ? (
                            <Loader />
                        ) : (
                            <Infos>
                                <ReservationsInfo testID='daily-booking-service-page-reservation-withdrawRange' name={t('app:reservation.withdrawRange')}>
                                    {`${getDisplayTime(selectedOfferDates[0])} - ${getDisplayTime(
                                        selectedOfferDates[1]
                                    )}`}
                                </ReservationsInfo>
                                <ReservationsInfo testID='daily-booking-service-page-reservation-withdrawSlotDuration' name={t('app:reservation.withdrawSlotDuration')}>
                                    {`${Duration.fromISO(selectedOffer.withdrawSlotDuration as string).as('minutes')} ${t(
                                        'app:duration.minutes'
                                    )}`}
                                </ReservationsInfo>
                                <ReservationsInfo
                                    testID='daily-booking-service-page-reservation-places'
                                    name={t('app:reservation.maxPlaces')}
                                    nameItalic={t('app:reservation.max')}
                                >
                                    {selectedOffer.maxOrdersPerSlot && selectedOffer.maxOrdersPerSlot !== null ? selectedOffer.maxOrdersPerSlot : ''}
                                </ReservationsInfo>
                            </Infos>
                        )}
                    </ParameterCard>
                )}
            </Struct.Section>
            <br />
            {selectedOffer.id && (
                <Struct.Section>
                    <Struct.Card>
                        <ReservationsCountInfo testID='daily-booking-service-page-reservation-count' idOffer={selectedOffer.id} />
                    </Struct.Card>
                </Struct.Section>
            )}
        </>
    );
};

const Infos = styled.div`
    display: flex;
    flex-wrap: wrap;
    & > * {
        margin-right: ${({ theme }) => theme.spacing.s}px;
    }
`;

const ParameterCard = styled(Struct.Card as any)`
    margin-bottom: ${({ theme }) => theme.spacing.s}px;
    cursor: pointer;
`;

const OfferDateTitleRow = styled(Row)`
    margin-bottom: ${({ theme }) => theme.spacing.s}px;
`;

const OfferDateTitleText = styled(Text)`
    color: ${({ theme }) => theme.color.grey[6]};
    text-transform: capitalize;
    margin-right: ${({ theme }) => theme.spacing.xxs}px;
`;

const OfferDateTitleText2 = styled(Text)`
    color: ${({ theme }) => theme.color.grey[6]};
    margin-right: ${({ theme }) => theme.spacing.xxs}px;
`;

const WrapperColumn = styled(Column)`
    padding-top: ${({ theme }) => theme.spacing.xs}px;
`;

export default withRouter(DailyBookingServicePage);
