import { Icon } from 'components';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme, DefaultTheme } from 'styled-components';
import Configuration from './Configuration';
import Pos from './Pos/index';
import Personalization from './Personalization';
import appRoutes from 'Router/routes';
import { PageWithTabs } from 'components/Layout';
import Printing from "./Printing";

const getRoutes = (t: any) => [
    {
        tabKey: 'info',
        tabLabel: t('page:holding.tabs.info'),
        path: appRoutes.holding.info,
        component: Configuration,
    },
    {
        tabKey: 'pos',
        tabLabel: t('page:holding.tabs.pos'),
        path: appRoutes.holding.pos.index,
        component: Pos,
    },
    {
        tabKey: 'personalization',
        tabLabel: t('page:holding.tabs.personalization'),
        path: appRoutes.holding.personalization,
        component: Personalization,
    },
    {
        tabKey: 'printing',
        tabLabel: t('page:holding.tabs.printing'),
        path: appRoutes.holding.printing.index,
        component: Printing,
    },
];

interface IProps {
    theme: DefaultTheme;
}

const Holding = ({ theme }: IProps) => {
    const { t } = useTranslation();
    return (
        <PageWithTabs
            routes={getRoutes(t)}
            indexRoute={appRoutes.holding.index()}
            title={t(`app:app.holding`)}
            Icon={<Icon.Restaurant color={theme.color.grey[6]} />}
        />
    );
};

export default withTheme(Holding);
