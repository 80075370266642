import get from 'lodash/get';
import React, { FunctionComponent } from 'react';
import { FieldProps } from 'formik';
import { TextInput } from 'components';
import FieldContainer from './FieldContainer';
import { InputState } from '../common';

interface IProps {
    id?: string;
    className?: string;
    label?: string;
    disabled?: boolean;
    multiline?: boolean;
    width?: number;
    maxLength?: number;
    style?: object;
    placeholder?: string;
    onChange?: (value) => void;
    useDefaultErrorMessage?: boolean;
    useDefaultErrorColoring?: boolean;
    onChangeField?: Function;
    value?: any;
    state?: InputState;
    color?: string;
    justify?: string;
    isPlaceholderBold?: boolean;
    testID?: string;
}

const TextInputField: FunctionComponent<FieldProps & IProps> = (props) => {
    const {
        field,
        form,
        width,
        multiline,
        disabled,
        maxLength,
        style,
        placeholder,
        useDefaultErrorColoring,
        onChangeField,
        onChange,
        value,
        state,
        color,
        id,
        isPlaceholderBold,
        testID
    } = props;
    let newField
    if (value || value === '') {
        newField = {...field, value}
    } else {
        newField = field
    }
    const handleOnChange = (value: string) => {
        if (onChange) {
            onChange({ fieldName: field.name, value });
        } else {
            form.setFieldValue(field.name, value);
            form.setFieldTouched(field.name, true);

            if (onChangeField) {
                onChangeField(value);
            }
        }
    };

    const hasError = !!get(form.errors, field.name) && !!get(form.touched, field.name)
    return (
        <FieldContainer {...props}>
            <TextInput
                {...newField}
                id={id}
                placeholder={placeholder}
                width={width}
                onChange={handleOnChange}
                hasError={hasError}
                multiline={!!multiline}
                disabled={disabled}
                maxLength={maxLength}
                style={style}
                useDefaultErrorColoring={useDefaultErrorColoring}
                state={state}
                color={color}
                isPlaceholderBold={isPlaceholderBold}
                {...(testID && {testID:`${testID}-input`})}
            />
        </FieldContainer>
    );
};

export default TextInputField;
