import React, { FunctionComponent } from 'react';
import { FieldProps } from 'formik';
import { Icon } from 'components';
import FieldContainer from './FieldContainer';
import { appTheme } from 'theme';
import styled from 'styled-components';

interface IProps {
    id?: string;
    className?: string;
    label?: string;
    onChange?: (value) => void;
    useDefaultErrorMessage?: boolean;
    onChangeField?: Function;
    value: boolean;
    rounded?: boolean;
    justify?: string;
}

const CheckBoxInputField: FunctionComponent<FieldProps & IProps> = (props) => {
    const {
        field,
        form,
        onChangeField,
        onChange,
        value,
        rounded = false,
        id
    } = props;
    let newField
    if (value || value === false) {
        newField = {...field, value}
    } else {
        newField = field
    }

    const handleOnChange = (value: boolean) => {
        if (onChange) {
            onChange({ fieldName: field.name, value});
        } else {
            form.setFieldValue(field.name, value);
            form.setFieldTouched(field.name, true);

            if (onChangeField) {
                onChangeField(value);
            }
        }
    };


    return (
      // @ts-ignore
        <FieldContainer {...props}>
            <Check
                {...newField}
                id={id}
                checked={value}
                onClick={() => handleOnChange(!value)}
                rounded={rounded}
            >
                {value === true && <Icon.Check color={appTheme.color.common.white} />}
                {value === false && <Icon.Check color={appTheme.color.grey[4]} />}
                {value === null && <Icon.Less color={appTheme.color.grey[6]} />}
            </Check>
        </FieldContainer>
    );
};

interface ICheckProps {
    checked: boolean | null | undefined;
    rounded: boolean;
    disabled: boolean;
}

const Check = styled.span<ICheckProps>`
    display: inline-block;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    align-self: center;
    overflow: hidden;
    border-radius: ${({ theme, rounded }) => (rounded ? '50%' : `${theme.borderRadius.xs}px`)};

    border-width: ${({ rounded }) => (rounded ? 'thick' : 'none')};
    border-style: ${({ rounded }) => (rounded ? 'solid' : 'none')};
    border-color: ${({ checked }) => (checked ? appTheme.color.common.blue : appTheme.color.grey[4])};

    background-color: ${({ checked, rounded }) => {
        switch (checked) {
            case true:
                return rounded ? appTheme.color.common.white : appTheme.color.common.green;
            case false:
                return rounded ? appTheme.color.grey[2] : appTheme.color.grey[4];
            case null:
                return appTheme.color.grey[4];
        }
    }};
    color: ${({ checked, rounded }) => {
        switch (checked) {
            case true:
                return appTheme.color.common.white;
            case false:
                return rounded ? appTheme.color.grey[2] : appTheme.color.grey[1];
            case null:
                return appTheme.color.grey[6];
        }
    }};
`;

export default CheckBoxInputField;
