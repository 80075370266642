import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Tags, InfoLabel } from 'components';

type Props = {
  selected: string[];
  setSelected: (values: string[]) => void;
  idHolding: string;
  idOfferTemplate?: string;
  pickupPoints: any[];
}

const PickupPointsSelector = ({ selected = [], setSelected, pickupPoints }: Props) => {
  const { t } = useTranslation();

  const pickupPointsTags = useMemo(() => {
    const selectAll = {
      id: 'selectAll',
      label: selected.length === pickupPoints?.length
        ? t('app:button.unselectAll')
        : t('app:button.selectAll')
    }

    return !!pickupPoints ? [
      ...(pickupPoints?.length === 1 ? [] : [selectAll]),
      ...pickupPoints?.map(({ id, name }) => ({ id, label: name }))
    ] : [selectAll]
  }, [selected.length, t, pickupPoints])


  return (<TagWrapper>
    {!!pickupPoints.length ? <Tags
      value={selected}
      options={pickupPointsTags}
      onChange={(values, pressed) => {
        if (pressed === 'selectAll') {
          if (selected.length === pickupPoints.length) {
            return setSelected([]);
          }
          return setSelected(pickupPoints.map(({ id }) => id));
        }
        setSelected(values)
      }}
    /> : <InfoLabel
      fontSize="S"
      name={t('component:pickupPointSelector.emptyList')}
    />
    }
  </TagWrapper>)
}

const TagWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    max-width: 425px;
    margin-bottom: ${({ theme }) => theme.spacing.s}px;
`;

export default PickupPointsSelector;