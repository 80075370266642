import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import { loader } from 'graphql.macro';
import isEqual from 'lodash/isEqual';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Panel, TextInputField, SubmitButton, PriceInputField } from 'components';
import { PanelContent, PanelFooter } from 'components/Panel';
import { createContainerVariables } from 'types/createContainer';

const CREATE_CONTAINER_MUTATION = loader('../../query/createContainer.gql');
const UPDATE_CONTAINER_MUTATION = loader('../../query/updateContainer.gql');
const FORM_ID = 'container_add_edit_form';

const getSchema = (t: Function) =>
    Yup.object().shape({
        label: Yup.string().required(t('app:error.required')),
        description: Yup.string().notRequired().nullable(true),
        price: Yup.number().required(t('app:error.required')),
        cashSystemCode: Yup.string().required(t('app:error.required')),
    });

interface IProps {
    match: {
        params: { idHolding: string };
    };
    container: any | null;
    isOpen: boolean;
    closeModal: ({ shouldRefetch }: { shouldRefetch?: boolean | undefined }) => void;
    isEditMode: boolean;
}

function ContainerTablePanel({
    isOpen,
    closeModal,
    match: {
        params: { idHolding },
    },
    container,
    isEditMode,
}: 
IProps & RouteComponentProps) {
    const { t } = useTranslation();
    const panelTitle = isEditMode ? t('page:clickcollect.container.containerPanel.editTitle') : t('page:clickcollect.container.containerPanel.title');

    const initialValues: createContainerVariables = {
        idHolding: idHolding,
        label: container ? container.label : '',
        description: container ? container.description : '',
        price: container ? container.price.amount : undefined,
        cashSystemCode: container ? container.cashSystemCode : '',
    };

    return (
        <Panel onClose={() => closeModal({})} open={isOpen} title={panelTitle}>
            <Mutation mutation={isEditMode ? UPDATE_CONTAINER_MUTATION : CREATE_CONTAINER_MUTATION}>
                {(mutateContainer: (param: Record<'variables', createContainerVariables>) => Promise<any>) => (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={getSchema(t)}
                        validateOnBlur={false}
                        validateOnChange={true}
                        onSubmit={(values, { setSubmitting }) => {
                          mutateContainer({
                                variables: {
                                    idHolding: values.idHolding,
                                    label: values.label?.trim(),
                                    description: values.description?.trim(),
                                    price: values.price,
                                    cashSystemCode: values.cashSystemCode?.trim(),
                                    ...(isEditMode && {id: container.id})
                                },
                            })
                            .then(() => {
                                closeModal({shouldRefetch: true});
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                        }}
                    >
                        {({ errors, touched, values, initialValues }) => {
                            const { ...restInitialValues } = initialValues;
                            const { ...restValues } = values;
                            const formHasNoChange = isEqual(restInitialValues, restValues);
                            return (
                                <>
                                    <PanelContent>
                                      {
                                        // @ts-ignore
                                        <Form id={FORM_ID}>
                                            <Field
                                                label={t('schema:container.label')}
                                                name="label"
                                                component={TextInputField}
                                            />
                                            <Field
                                                label={t('schema:container.description')}
                                                subLabel={t('schema:container.subDescription')}
                                                name="description"
                                                component={TextInputField}
                                            />
                                            <Field
                                                label={t('schema:container.price.amount')}
                                                name="price"
                                                inline={true}
                                                multiline={false}
                                                component={PriceInputField}
                                                justify={'flex-start'}
                                            />
                                            <Field
                                                label={t('schema:container.cashSystemCode')}
                                                name="cashSystemCode"
                                                inline={true}
                                                multiline={false}
                                                component={TextInputField}
                                                justify={'flex-start'}
                                                width={150}
                                            />
                                        </Form>
                                      }
                                    </PanelContent>
                                    <PanelFooter>
                                        <SubmitButton
                                            formHasNoChange={formHasNoChange}
                                            form={FORM_ID}
                                            disabled={
                                                formHasNoChange ||
                                                Object.entries(touched).length === 0 ||
                                                Object.entries(errors).length !== 0
                                            }
                                        />
                                    </PanelFooter>
                                </>
                            );
                        }}
                    </Formik>
                )}
            </Mutation>
        </Panel>
    );
}

export default withRouter(ContainerTablePanel);
