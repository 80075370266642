import { convertFromRaw, Editor, EditorState } from 'draft-js';
// @ts-ignore
import { markdownToDraft } from 'markdown-draft-js';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IPropsText extends IPropsTextWrapper {
    children: ReactNode | string;
    markdown?: boolean;
}
const Text = ({ children, markdown, ...rest }: IPropsText) => {
    return (
        <TextWrapper {...rest}>
            {!markdown && children}
            {markdown && children && (
                <Editor
                    editorState={EditorState.createWithContent(convertFromRaw(markdownToDraft(children)))}
                    readOnly
                    onChange={() => {}}
                />
            )}
        </TextWrapper>
    );
};

interface IPropsTextWrapper {
    color?: any;
    bold?: boolean;
    size?: 'XXS' | 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';
    italic?: boolean;
    align?: 'center' | 'left' | 'right';
    textTransform?: 'capitalize' | 'uppercase' | 'lowercase';
    overflow?: 'hidden';
    wordBreak?: 'break-all' | 'break-word' | 'initial' | 'inherit' | 'keep-all' | 'normal' | 'revert' | 'unset';
    lineHeight?: 'XXS' | 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL';
    paddingTop?: number;
}

const TextWrapper = styled.span<IPropsTextWrapper>`
    font-size: ${({ size = 'S', theme }) => theme.typography.size[size]}px;
    line-height: ${({ lineHeight = 'S', theme }) => theme.typography.lineHeight[lineHeight]}px;
    color: ${({ theme, color = theme.color.grey[6] }) => color};
    font-weight: ${({ bold, theme }) =>
        bold ? theme.typography.fontWeight.bold : theme.typography.fontWeight.lighter};
    text-align: ${({ align }) => (align ? align : 'inherit')};
    text-transform: ${({ textTransform }) => textTransform || 'none'};
    font-style: ${({ italic }) => (italic ? 'italic' : 'inherit')};
    text-overflow: ${({ overflow }) => (overflow === 'hidden' ? 'ellipsis' : 'clip')};
    overflow: ${({ overflow }) => (overflow === 'hidden' ? 'hidden' : 'visible')};
    white-space: ${({ overflow }) => (overflow === 'hidden' ? 'nowrap' : 'normal')};
    word-break: ${({ wordBreak }) => wordBreak || 'inherit'};
    padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : 0)}px;
`;

export default Text;
