import { loader } from 'graphql.macro';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import AddOrEditServiceForm from './AddOrEditServiceForm';

const CREATE_SERVICE_MUTATION = loader('../query/createEPointageService.gql');

interface IPropsPanel extends RouteComponentProps<{ siteId: string }> {
    beforeClose: () => void;
}

const AddServicePage = ({ 
    history: { push },
    match: {
        params: { siteId },
    },
    beforeClose
}: IPropsPanel) => {
    const onClose = () => {
        beforeClose();
        push(`${routes.checkList.services.list(siteId)}`)
    };

    return (
        <Mutation mutation={CREATE_SERVICE_MUTATION}>
            {(createEPointageService: (param: Record<'variables', any>) => Promise<any>) => (
                <AddOrEditServiceForm
                    {...{
                        onSuccess: onClose,
                        updateEPointageService: createEPointageService,
                        isCreation: true,
                        siteId
                    }}
                />
            )}
        </Mutation>
    );
};

export default withRouter(AddServicePage);
