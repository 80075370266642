import { AddButton, DeleteButton, TableWithOptions, Icon } from 'components';
import { loader } from 'graphql.macro';
import { getArticleFamilyOptions } from 'services/articleService';

import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ArticleConceptList from 'components/Query/ArticleConceptList';
import { deleteLocalArticleVariables } from 'types/deleteLocalArticle';
import { importLocalArticleVariables } from 'types/importLocalArticle';
import {
    listArticlesForCnCCatalog,
    listArticlesForCnCCatalog_list_edges_node_Article,
    listArticlesForCnCCatalogVariables,
} from 'types/listArticlesForCnCCatalog';
import { Omit } from 'localTypes';
import { QueryLoader } from 'components';
import { QueryResult } from 'localTypes';
import { getHoldingWithBrand } from '../../types/getHoldingWithBrand';
import { ImportationType } from 'types/globalTypes';
import { LocalStorageKey, getItem } from 'services/persistentData';

const ORACLE_CASHSYSTEM_ID = '3';

const LIST_ARTICLES = loader('./query/listArticlesForCnCCatalog.gql');
const IMPORT_LOCAL_ARTICLE_MUTATION = loader('./query/importLocalArticle.gql');
const DELETE_LOCAL_ARTICLE_MUTATION = loader('./query/deleteLocalArticle.gql');
const GET_HOLDING_WITH_BRAND_QUERY = loader('./query/getHoldingWithBrand.gql');

export interface IGlobalArticle extends Omit<listArticlesForCnCCatalog_list_edges_node_Article, 'localArticles'> {
    localArticleId?: string;
    lastUse?: string;
}

enum QueryParamKeys {
    FAMILY = 'family',
    CONCEPT = 'articleConcept.id',
    SEARCH = 'search',
}

function Article({
    item,
    item: { label, family, lastUse, localArticleId, isActive, importationType },
    idPos,
    queryVariables,
}: {
    item: IGlobalArticle;
    idPos: string;
    queryVariables: listArticlesForCnCCatalogVariables;
}) {
    return (
        <>
            <td style={{
                width: '50%',
                maxWidth: '365px',
                overflow: 'hidden',
                overflowWrap: 'break-word'
            }}>{label}</td>
            <td style={{ width: '20%' }}>{family}</td>
            <td style={{ width: '10%' }}>
                {importationType === ImportationType.Oscar
                    ? <Icon.Check width={18} height={16} color="#383838" />
                    : <Icon.Cross width={16} color="#383838" />
                }
            </td>
            <td style={{ width: '30%' }}>{lastUse}</td>
            <td>
                {!!localArticleId ? (
                    <DeleteLocalArticle testID={`delete-button_${item.id}`} id={localArticleId} articleId={item.id} queryVariables={queryVariables} />
                ) : (
                    <AddLocalArticle
                        id={`add-button_${item.id}`}
                        article={item}
                        idPos={idPos}
                        queryVariables={queryVariables}
                        disabled={!isActive}
                    />
                )}
            </td>
        </>
    );
}

function AddLocalArticle({
    article,
    idPos,
    queryVariables,
    disabled,
    id
}: {
    article: IGlobalArticle;
    idPos: string;
    queryVariables: listArticlesForCnCCatalogVariables;
    disabled: boolean;
    id?: string;
}) {
    return (
        <Mutation
            // add local article id to list query cache
            update={(cache: any, { data: { importLocalArticle } }: any) => {
                const cachedGlobalData = cache.readQuery({
                    query: LIST_ARTICLES,
                    variables: queryVariables,
                }) as listArticlesForCnCCatalog;
                const updatedEdges = cachedGlobalData.list.edges.map((edge) => {
                    const article = edge.node as listArticlesForCnCCatalog_list_edges_node_Article;
                    if (article.id === importLocalArticle.article.id) {
                        return {
                            ...edge,
                            node: {
                                ...edge.node,
                                localArticles: [{ id: importLocalArticle.id, __typename: 'LocalArticle' }],
                            },
                        };
                    }

                    return edge;
                });
                cache.writeQuery({
                    query: LIST_ARTICLES,
                    variables: queryVariables,
                    data: { ...cachedGlobalData, list: { ...cachedGlobalData.list, edges: updatedEdges } },
                });
            }}
            mutation={IMPORT_LOCAL_ARTICLE_MUTATION}
        >
            {(importLocalArticle: (param: Record<'variables', importLocalArticleVariables>) => Promise<any>) => {
                const onAddClick = () => {
                    importLocalArticle({
                        variables: { idPos, idArticle: article.id },
                    });
                };
                return <AddButton id={id} onClick={onAddClick} disabled={disabled} />;
            }}
        </Mutation>
    );
}

function DeleteLocalArticle({
    id,
    articleId,
    queryVariables,
    testID
}: {
    id: string;
    articleId: string;
    queryVariables: listArticlesForCnCCatalogVariables;
    testID?: string;
}) {
    return (
        <Mutation
            // remove local article id from list query cache
            update={(cache: any) => {
                const cachedGlobalData = cache.readQuery({
                    query: LIST_ARTICLES,
                    variables: queryVariables,
                }) as listArticlesForCnCCatalog;
                const updatedEdges = cachedGlobalData.list.edges.map((edge) => {
                    const article = edge.node as listArticlesForCnCCatalog_list_edges_node_Article;
                    if (article.id === articleId) {
                        return {
                            ...edge,
                            node: {
                                ...edge.node,
                                localArticles: [],
                            },
                        };
                    }

                    return edge;
                });
                cache.writeQuery({
                    query: LIST_ARTICLES,
                    variables: queryVariables,
                    data: { ...cachedGlobalData, list: { ...cachedGlobalData.list, edges: updatedEdges } },
                });
            }}
            mutation={DELETE_LOCAL_ARTICLE_MUTATION}
        >
            {(deleteLocalArticle: (param: Record<'variables', deleteLocalArticleVariables>) => Promise<any>) => {
                const onDeleteClick = () => {
                    deleteLocalArticle({
                        variables: { id },
                    });
                };
                return <DeleteButton id={testID} onClick={onDeleteClick} />;
            }}
        </Mutation>
    );
}

interface IProps {
    match: {
        params: { idPos: string };
    };
}

const GlobalCatalogTable = ({
    match: {
        params: { 
          // @ts-ignore
          idHolding, 
          idPos 
        },
    },
}: IProps & RouteComponentProps) => {
    const { t } = useTranslation();

    const renderLine = (queryVariables: listArticlesForCnCCatalogVariables) => ({
        localArticles,
        ...rest
    }: listArticlesForCnCCatalog_list_edges_node_Article) => {
        const item = {
            ...rest,
            localArticleId: localArticles && localArticles.length > 0 ? localArticles[0].id : undefined,
        };
        return (
            <tr key={rest.id}>
                <Article item={item} idPos={idPos} queryVariables={queryVariables} />
            </tr>
        );
    };

    return (
        <QueryLoader
            hasData={(data: getHoldingWithBrand): boolean => !!(data && data.holding)}
            query={GET_HOLDING_WITH_BRAND_QUERY}
            variables={{ idHolding }}
        >
            {({ data }: QueryResult<getHoldingWithBrand>) => {

                return <ArticleConceptList>
                    {({ concepts }) => (
                        <TableWithOptions
                            renderLine={renderLine}
                            withQueryVariables
                            headers={getHeaders(t)}
                            query={LIST_ARTICLES}
                                variables={
                                    getItem(LocalStorageKey.CASH_SYSTEM_ID) === ORACLE_CASHSYSTEM_ID
                                        ? {
                                              idPos,
                                              querySearch: [
                                                  {
                                                      key: 'idElement',
                                                      operator: '!',
                                                      value: '0',
                                                  },
                                              ],
                                          }
                                        : {
                                              idPos,
                                          }
                                }
                            searchPlaceholder={t('app:placeholder.search.article')}
                            tags={{
                                [QueryParamKeys.FAMILY]: getArticleFamilyOptions(),
                                [QueryParamKeys.CONCEPT]: concepts,
                            }}
                        />
                    )}
                </ArticleConceptList>
            }}

        </QueryLoader>

    );
};

// @ts-ignore
const getHeaders = (t: any) => [
    {
        key: 'label',
        displayName: t('schema:article.label'),
    },
    {
        key: 'family',
        displayName: t('schema:article.family'),
    },
    {
        key: 'fromOscar',
        displayName: t('schema:article.fromOscar'),
    },
    {
        key: 'lastUse',
        displayName: t('schema:article.lastUse'),
    },
    {
        key: 'status',
        displayName: t('page:clickcollect.catalog.add-article.table.header.status'),
    },
];

export default withRouter(GlobalCatalogTable);
