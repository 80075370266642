import { ApolloQueryResult, NetworkStatus } from '@apollo/client';
import { GraphQLError } from 'graphql';
export type RefetchFunction<T> = (variables?: { [key: string]: any }) => Promise<ApolloQueryResult<T>>;

export type QueryResult<T> = {
    loading: boolean;
    errors?: GraphQLError[];
    data: T;
    refetch: RefetchFunction<T>;
    networkStatus: NetworkStatus;
};

export type PaginationOptions = {
    first?: number;
    after?: string;
    last?: number;
    before?: string;
    order?: string;
    limitDateISO?: string;
};

export enum OfferTemplateType {
    ClickCollect = 'CLICK_COLLECT',
    TableService = 'TABLE_SERVICE',
    BookingService = 'BOOKING_SERVICE',
}


export enum OfferTemplateRouteParam {
    ClickCollect = 'cnc',
    TableService = 'tableservice',
    BookingService = 'bookingservice',
}

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
