import { Toggle } from 'components';
import { loader } from 'graphql.macro';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import { getBookingOfferState, offerPalette, OfferState } from 'services/offerService';
import { changeOfferStatus as changeOfferStatusType, changeOfferStatusVariables } from 'types/changeOfferStatus';
import { State as OfferStateLabel } from 'components/Inputs/Toggle';
import styled from 'styled-components';
import { OfferTemplateType } from 'localTypes';

const CHANGE_OFFER_STATUS_MUTATION = loader('../OfferPublishToggle/changeOfferStatus.gql');

interface IProps {
    offer: any;
    className?: string;
    onChange?: (status: boolean) => void;
    testID?: string;
}

function BookingOfferPublishToggle(props: IProps) {
    const {
        offer: { id, published, withdrawRange },
        className,
        onChange,
        testID
    } = props;
    const { t } = useTranslation();
    const onState = getBookingOfferState({ withdrawRange, published: true });
    const offState = getBookingOfferState({ withdrawRange, published: false });

    if (onState === OfferState.EXPIRED)
        return <Expired {...(testID && {'data-test':`${testID}-expired`})} color={offerPalette[onState].highlighted}>{t(`schema:offer.state.${onState}`)}</Expired>;
    return (
        <Mutation mutation={CHANGE_OFFER_STATUS_MUTATION}>
            {(
                changeOfferStatus: (param: {
                    variables: changeOfferStatusVariables;
                    optimisticResponse: changeOfferStatusType;
                }) => Promise<any>
            ) => {
                const onToggle = () => {
                    const status = !published;
                    onChange && onChange(status);
                    changeOfferStatus({
                        variables: {
                            id,
                            published: status,
                            offerTemplateType: OfferTemplateType.BookingService,
                        },
                        optimisticResponse: {
                            changeOfferStatus: {
                                id,
                                __typename: 'Offer',
                                published: status,
                            },
                        },
                    });
                };
                return (
                    <Toggle
                        testID={testID}
                        className={className}
                        checked={!!published}
                        onChange={onToggle}
                        onColor={offerPalette[onState].highlighted}
                        offColor={offerPalette[offState].highlighted}
                        onLabel={t(`schema:offer.state.${onState}`)}
                        offLabel={t(`schema:offer.state.${offState}`)}
                    />
                );
            }}
        </Mutation>
    );
}

const Expired = styled(OfferStateLabel as any)`
    max-width: 160px;
    height: 40px;
    background-color: ${({ color }) => color};
    color: ${({ theme }) => theme.color.common.white};
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
`;

export default BookingOfferPublishToggle;
