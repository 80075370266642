import isEqual from 'lodash/isEqual';
import { getDates } from 'services/dateService';
import { OpeningHourInput } from 'types/globalTypes';

export function areRangesEqual(range1: string, range2: string) {
    const [range1StartDate, range1EndDate] = getDates(range1);
    const [range2StartDate, range2EndDate] = getDates(range2);
    return (
        new Date(range1StartDate).getTime() === new Date(range2StartDate).getTime() &&
        new Date(range1EndDate).getTime() === new Date(range2EndDate).getTime()
    );
}

export function areSchedulesEqual(schedule1: OpeningHourInput[], schedule2: OpeningHourInput[]): boolean {
    const schedule2IncludsAllSchedule1 = schedule1.every(input =>
        input.days.every(day => {
            const schedule2Input = schedule2.find(input => input.days.includes(day));
            if (!schedule2Input) return false;
            return isEqual(schedule2Input.hours, input.hours);
        })
    );
    const schedule1IncludsAllSchedule2 = schedule2.every(input =>
        input.days.every(day => {
            const schedule1Input = schedule1.find(input => input.days.includes(day));
            if (!schedule1Input) return false;
            return isEqual(schedule1Input.hours, input.hours);
        })
    );
    return schedule2IncludsAllSchedule1 && schedule1IncludsAllSchedule2;
}
