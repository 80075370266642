import React from 'react';
import { Route } from 'react-router-dom';
import appRoutes from 'Router/routes';
import ServicesPage from './ServicesPage';
import EditServicePage from './ServicesPage/AddOrEditService/EditServicePage';
import AddServicePage from './ServicesPage/AddOrEditService/AddServicePage';

function RoutingServicesPage() { 
    return (
        <>
            <Route exact path={appRoutes.checkList.services.list()} component={ServicesPage} />
            <Route exact path={appRoutes.checkList.services.edit()}
                component={() => 
                    <EditServicePage beforeClose={() => {}} />
                }
            />
            <Route exact path={appRoutes.checkList.services.add()}
                component={() => 
                    <AddServicePage beforeClose={() => {}}/>
                }
            />
        </>
    );
}

export default RoutingServicesPage;
