import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Icon, InfoModal } from 'components';
import { ButtonType } from 'components/Button';
import { toast } from 'react-toastify';
import { ErrorToast } from 'components/ErrorToast';

const APPLY_OFFER_TO_ALL_DATES = loader('../../DailyOffersPage/query/applyOfferToAllDates.gql');

type Props = {
    idOfferTemplate: string;
    idOffer: string;
    refreshItemsAndPlannedOffers: () => void;
};

const ApplyOfferItemsToAll = ({ idOffer, refreshItemsAndPlannedOffers }: Props) => {
    const { t } = useTranslation();
    
    const applyToAllDatesLabel = t('app:button.applyToAllDates');

    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

    const [applyOfferToAllDates] = useMutation(APPLY_OFFER_TO_ALL_DATES);


    const handleApply = async () => {
        const {
            data: {
                applyOfferToAllDates: { wasApplied },
            },
        } = await applyOfferToAllDates({
            variables: {
                idOffer,
            },
        }) as any;

        refreshItemsAndPlannedOffers();

        if (!wasApplied) {
            toast(<ErrorToast errorMessage="APPLY_TO_ALL_DATES_ERROR" />);
        }
        
        handleCloseInfoModal();
    };


    const handleCloseInfoModal = () => {
        setIsInfoModalOpen(false);
    };

    const handleOpenInfoModal = () => {
        setIsInfoModalOpen(true);
    };

    return (
        <>
            <Button onClick={handleOpenInfoModal} display={ButtonType.SECONDARY} styleSvg>
                <DuplicateIcon title={applyToAllDatesLabel} />
                {applyToAllDatesLabel}
            </Button>
            <InfoModal
                message={t('page:clickcollect.daily-offers.clonerModal.applyToAll')}
                isOpen={isInfoModalOpen}
                onClose={handleCloseInfoModal}
                btnText={applyToAllDatesLabel}
                btnOnClick={handleApply}
                showBtn
                showClose
            />
        </>
    );
};

const DuplicateIcon = styled(Icon.DuplicateClean)`
    margin-right: 3px;
`;

export default ApplyOfferItemsToAll;
