import get from 'lodash/get';
import React, { FunctionComponent } from 'react';
import { FieldProps } from 'formik';

import { PriceInput } from 'components';
import FieldContainer from './FieldContainer';

interface IProps {
    className?: string;
    label?: string;
    disabled?: boolean;
    testID?: string;
}

const PriceInputField: FunctionComponent<FieldProps & IProps> = (props) => {
    const { field, form, disabled, testID } = props;
    const onChange = (value: string) => {
        form.setFieldValue(field.name, value);
        form.setFieldTouched(field.name);
    };

    return (
        <FieldContainer {...props}>
            <PriceInput
                {...field}
                disabled={disabled}
                onChange={onChange}
                hasError={!!get(form.errors, field.name) && !!get(form.touched, field.name)}
                {...(testID && {testID:`${testID}-input`})}
            />
        </FieldContainer>
    );
};

export default PriceInputField;
