import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IProps {
    address: string;
    city: string;
    zip: string;
    className?: string;
}

const Address = ({ address, city, zip, className }: IProps) => {
    const [t] = useTranslation();
    return (
        <Wrapper className={className}>
            <Item>
                <Label>{t('component:address.address')}</Label>
                <Info>{address}</Info>
            </Item>
            <CityLine>
                <Item>
                    <Label>{t('component:address.zip')}</Label>
                    <Info>{zip}</Info>
                </Item>
                <Item>
                    <Label>{t('component:address.city')}</Label>
                    <Info>{city}</Info>
                </Item>
            </CityLine>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    padding: ${({ theme }) => theme.spacing.xs}px;
    background-color: ${({ theme }) => theme.color.grey[0]};
    margin-bottom: ${({ theme }) => theme.spacing.m}px;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
`;

const Item = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.spacing.xs}px;
`;

const Label = styled.label`
    color: ${({ theme }) => theme.color.grey[6]};
    font-size: ${({ theme }) => theme.typography.fontSizeXXS}px;
    text-transform: uppercase;
`;
const Info = styled.span`
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    color: ${({ theme }) => theme.color.common.navy};
`;

const CityLine = styled.div`
    display: flex;
    & > :first-child {
        flex-basis: 33%;
    }
`;

export default Address;
