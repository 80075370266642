import React, { FunctionComponent } from 'react';
import { MessageBuilderProps } from './types';
import {
    Wrapper,
    WrapperLighter,
    WrapperWarning,
    StyledIcon,
    StyledIconLighter,
    StyledIconWarning,
    Text,
    TextLighter,
    MultiErrorsWrapper,
    IconWrapper
} from './styledComponents';

const MessageCompByType = (theme, type) =>{
    const comps = {
        ERROR: {
            Wrapper: Wrapper,
            Icon: StyledIcon,
            iconColor: theme.color.common.darkRed,
            Text: Text,
            hasMultiWrapper: false
        },
        LIGHTER: {
            Wrapper: WrapperLighter,
            Icon: StyledIconLighter,
            iconColor: theme.color.common.darkRed,
            Text: TextLighter,
            hasMultiWrapper: true
        },
        WARNING: {
            Wrapper: WrapperWarning,
            Icon: StyledIconWarning,
            iconColor: theme.color.common.mediumLightOrange,
            Text: TextLighter,
            hasMultiWrapper: true
        }
    };

    return comps[type];
};

export const MessageBuilder: FunctionComponent<MessageBuilderProps> = ({ children, theme, className, type, renderChildrenAsIs }) => {
    const Comps = MessageCompByType(theme, type);

    const wrapChildrenAroundComponents = () => {
        if(children == null){
          return <Comps.Text />;
        }
        
        if (typeof children === 'string') {
            return <Comps.Text>{children}</Comps.Text>;
        }
        else if (typeof children === 'object') {
            return Comps.hasMultiWrapper ?
                <MultiErrorsWrapper>
                    {Object.keys(children).map((key: string) => !!children[key] && <Comps.Text key={key}>{children[key] as string}</Comps.Text>)}
                </MultiErrorsWrapper>
                :
                Object.keys(children).map((key: string) => <Comps.Text key={key}>{children[key] as string}</Comps.Text>)
        }
    };

    return(
        <Comps.Wrapper className={className} >
            <IconWrapper>
                <Comps.Icon color={Comps.iconColor} />
            </IconWrapper>

            {renderChildrenAsIs ? children : wrapChildrenAroundComponents()}
        </Comps.Wrapper>
    )
};