import React from 'react';
import styled from 'styled-components';
import { loader } from 'graphql.macro';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import { Button, QueryLoader, Struct, Tags, Icon, Paginator } from 'components';
import { getBooleanOptions } from 'components/Tag';
import useRoutingForTableOptions from 'components/Table/useRoutingForTableOptions';
import { rowPerPageOptions, PaginationWrapper, DEFAULT_ROW_PER_PAGE } from 'components/Table/Paginator';
import { QueryResult } from 'localTypes';
import { getNodes } from 'services/queryService';
import { listOfferTemplate, listOfferTemplate_list_edges_node_OfferTemplate } from 'types/listOfferTemplate';
import OfferTemplateTable from './OfferTemplateTable';

interface IPropsEmptyStatePage {
    message?: string;
}

const EmptyStatePage = ({ message } : IPropsEmptyStatePage) => {
    const { t } = useTranslation();
    const defaultMessage = t('page:clickcollect.offers.emptyStateMessage')
    return (
        <EmptyStateWrapper>
            <Icon.BlankPage />
            <MessageWrapper>{message || defaultMessage }</MessageWrapper>
        </EmptyStateWrapper>
    )
}
interface IPropsPanel extends RouteComponentProps<{ idHolding: string, idPos: string, idOfferTemplate: string }> {
    beforeClose: () => void;
}

export enum FilterKey {
    PUBLISHED = 'published',
}

const ClickCollectPage = ({ 
        location, 
        history, 
        match: {
            params: { idHolding, idPos },
        }
    }: IPropsPanel) => {

    const { t } = useTranslation();
    const {
        queryParams,
        onSearchValueChange,
        querySearch,
        onPaginationChange,
        paginationOptions,
    } = useRoutingForTableOptions<FilterKey>(location, history, DEFAULT_ROW_PER_PAGE);

    const getFilterValue = (key: FilterKey): string[] => {
        return queryParams[key] ? queryParams[key]!.value.split(',') : ([] as string[]);
    };

    const onFilterChange = (key: FilterKey) => (value: string[]) => {
        onSearchValueChange({
            [key]: value.length > 0 ? { key, value: value.join(), operator: '*' } : undefined,
        });
    };
    const redirectToTemplateCreatePage = () => {
        history.push(`${routes.cnc.offers.offerTemplate.add(idHolding, idPos)}`);
    };

    const optionsPublished = getBooleanOptions(t('page:admin.cnc.published'), t('page:admin.cnc.unpublished'));

    const queryVariables = {
        querySearch: [
            ...querySearch,
            { key: "period", value: "DAILY" },
        ],
        ...paginationOptions,
    };


    if(idHolding){
        queryVariables.querySearch.push({
            key: "holding.id",
            operator: "=",
            value: idHolding
        });    
    }

    return (
        <QueryLoader
            fetchPolicy="no-cache"
            query={loader('./query/listOfferTemplate.gql')}
            variables={queryVariables}
            hasData={(data: listOfferTemplate): boolean => !!(data && data.list)}
        >
            {({ data: { list } }: QueryResult<listOfferTemplate>) => {
                const data = getNodes(list) as listOfferTemplate_list_edges_node_OfferTemplate[]
                const offerTemplatesWithPlannedOffers = data.filter(({ plannedOffers_v2 }) => plannedOffers_v2.edges.length > 0);
                return (
                    <Struct.Section>
                        <HeaderWrapper>
                            <Tags
                                onChange={onFilterChange(FilterKey.PUBLISHED)}
                                value={getFilterValue(FilterKey.PUBLISHED)}
                                options={optionsPublished}
                            />
                            <Button id='createOffer-button' onClick={redirectToTemplateCreatePage}>
                                {t('app:button.add-offerClick')}
                            </Button>
                        </HeaderWrapper>
                        {offerTemplatesWithPlannedOffers.length === 0 ? (
                            <EmptyStatePage />
                        ) : (
                            <Struct.Card>
                                <PaginationWrapper>
                                    <Paginator
                                        pageInfo={list.pageInfo}
                                        onPaginate={onPaginationChange}
                                        rowPerPage={
                                            (paginationOptions.first ||
                                                paginationOptions.last) as rowPerPageOptions
                                        }
                                    />
                                </PaginationWrapper>
                                <OfferTemplateTable data={offerTemplatesWithPlannedOffers} idHolding={idHolding} idPos={idPos} />
                            </Struct.Card>
                        )}
                    </Struct.Section>
                );
            }}
        </QueryLoader>
    );
};

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: ${({ theme }) => theme.spacing.s}px; 
`;

const EmptyStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 600px;
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.color.common.white};
`;

const MessageWrapper = styled.div`
    font-size: ${({ theme }) => theme.typography.size.M}px;
    line-height: ${({ theme }) => theme.typography.lineHeight.S}px;
    margin-top: ${({ theme }) => theme.spacing.s}px;
    color: ${({ theme }) => theme.color.grey[6]};
    font-family: Helvetica;
    letter-spacing: 0;
    text-align: center;
`;

export default withRouter(ClickCollectPage);
