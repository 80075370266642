import React, { ReactElement, PureComponent } from 'react';
import styled from 'styled-components';
import { ITagOption } from 'components/Tag';

export interface IGroupBy {
    key: string;
    options: ITagOption[];
}

interface ITableProps<T> {
    headers?: ITableHeader[];
    headersWithoutPadding?: boolean;
    data: T[];
    renderLine: (item: T, index: number) => ReactElement;
    groupBy?: IGroupBy;
    testID?: string;
    noSeparator?: boolean;
    subHeaderComponent?: () => ReactElement | null;
}

export interface ITableHeader {
    displayName: string | ReactElement;
    withoutPadding?: boolean;
    center?: boolean;
    required?: boolean;
    key: string;
    hide?: boolean;
}

function renderTBody<T>(data: T[], renderLine: (item: T, index: number) => ReactElement, groupBy?: IGroupBy) {
    /*
        The groupby function works with backend sorting.
        You need only add order field to the associated gql
    */
    if (!groupBy) return data.map(renderLine);
    let currentGroup: string | null;
    return data.map((item: any, key) => {
        const keys = groupBy.key.split('.');
        const newGroup = keys.reduce((result, key) => result[key], item);
        if (key === 0 || currentGroup !== newGroup) {
            currentGroup = newGroup;
            const groupInfo = groupBy.options.find(option => option.id === newGroup);
            return (
                <>
                    <tr key={key} style={{ height: 40 }}>
                        {/*colSpan is an arbitrary number to make sure that it covers the whole line*/}
                        {// @ts-ignore
                          <GroupTh scope="colgroup" id={newGroup} colSpan={100} {...(groupInfo?.greyVariation && {greyVariation : groupInfo.greyVariation})}>
                            {groupInfo && groupInfo.label}
                          </GroupTh>
                        }
                        
                    </tr>
                    {renderLine(item, key)}
                </>
            );
        } else {
            return renderLine(item, key);
        }
    });
}

export default class Table<T> extends PureComponent<ITableProps<T>> {
    render() {
        const { headers, headersWithoutPadding, renderLine, groupBy, data, testID, noSeparator, subHeaderComponent } = this.props;

        // Remove hidden headers
        const finalHeaders = headers?.filter((header) => !header.hide);

        return (
            <MainTable hasHeader={!!finalHeaders} data-test={testID} noSeparator={noSeparator}>
                {!!finalHeaders && (
                    <thead>
                        <tr>
                            {finalHeaders.map((head, index) => (
                                <Th required={head.required} center={head.center} headersWithoutPadding={!!headersWithoutPadding || !!head.withoutPadding} key={`h${index}`}>{head.displayName}</Th>
                            ))}
                        </tr>
                        {!!subHeaderComponent &&
                          <tr>
                            <td colSpan={headers ? headers.length : 0}>
                                {subHeaderComponent}
                            </td>
                          </tr>
                        }
                    </thead>
                )}

                <tbody>{renderTBody(data, renderLine, groupBy)}</tbody>
            </MainTable>
        );
    }
}

const GroupTh = styled.th<{ greyVariation: boolean }>`
    height: 40px;
    padding-left: ${({ theme }) => theme.spacing.s}px;
    border-top: 1px solid ${props => props.theme.color.grey[2]};
    background-color: ${props => props.greyVariation ? `rgba(95, 112, 129, 0.2)`: props.theme.color.grey[0]};
    text-align: left;
    font-weight: bold;
`;

const MainTable = styled.table<{ hasHeader: boolean, noSeparator?: boolean }>`
    width: 100%;
    border-spacing: 0;
    color: ${({ theme }) => theme.color.grey[7]};
    & tr {
        height: ${({ theme }) => theme.dimension.height.tr}px;
    }
    tbody > tr > td {
        padding-left: ${({ theme }) => theme.spacing.s}px;
        border-top: ${props => props.noSeparator ? '0' : '1'}px solid ${props => props.theme.color.grey[2]};
    }
    tbody > tr:first-child > td {
        border-top: ${({ hasHeader, theme, noSeparator }) => (hasHeader ? `${noSeparator ? '0' : '1'}px solid ${theme.color.grey[1]}` : 'none')};
    }
`;

const Th = styled.th<{ headersWithoutPadding: boolean, center?: boolean, required?: boolean }>`
    padding-left: ${({ theme, headersWithoutPadding }) => headersWithoutPadding ? 0 : theme.spacing.s}px;
    font-size: ${({ theme }) => theme.typography.fontSizeXS}px;
    color: ${({ theme }) => theme.color.grey[4]};
    text-transform: uppercase;
    text-align: ${({ center }) => center ? 'center' : 'left'};
    font-weight: normal;

    ${({ required }) => required && 
        `&:after {
        content: "*";
        color: red;
        }`};
    
    &:last-child {
        text-align: ${({ headersWithoutPadding, center }) => headersWithoutPadding ? 'right' : 
            center ? 'center' : 'inherit'};
    }
`;
