import React, { MouseEvent, ReactNode } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { rgba } from 'polished';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import auth0Client from 'services/auth0';
import { Icon, Text } from 'components';
import { getItem, LocalStorageKey } from 'services/persistentData';
import routes from 'Router/routes';
import logo from 'resources/img/logo.png';
import { version as projectVersion } from '../../services/projectVersion';
import { RouteWithRoles } from 'Router';
import { Role } from 'types/globalTypes';

interface ISideNavProps {
    theme: DefaultTheme;
    isOpen: boolean;
    onClose: (event: MouseEvent<any>) => void;
}

interface INavItemProps {
    to: string;
    title: string;
    menuIcon: ReactNode;
    theme: DefaultTheme;
}

const NavItem = withTheme(({ to, menuIcon, title, theme }: INavItemProps) => (
    <MenuItem to={to} activeClassName="selected">
        {menuIcon}
        <MenuItemTitle>{title}</MenuItemTitle>
        <Indicator>
            <Icon.ArrowRight color={theme.color.common.white} width={7} height={12} />
        </Indicator>
    </MenuItem>
));

const SideNavComponent = ({ isOpen, onClose, theme, idHolding }: ISideNavProps & { idHolding: string }) => {
    const { t } = useTranslation();
    return (
        <Wrapper isOpen={isOpen}>
            <Header>
                <NavLink to="/">
                    <img src={logo} width={114} alt="Foodi Services" />
                </NavLink>
                <Close onClick={onClose}>
                    <Icon.Close color={theme.color.common.white} />
                </Close>
            </Header>
            <Menu>
                <RouteWithRoles roles={[Role.Admin]}>
                    <NavItem
                        to={routes.holding.index(idHolding)}
                        menuIcon={<Icon.Restaurant />}
                        title={t('app:app.holding')}
                    />
                </RouteWithRoles>
                <RouteWithRoles roles={[Role.Employee]}>
                    <NavItem to={routes.cnc.posSelect(idHolding)} menuIcon={<Icon.CnC />} title={t('app:app.cnc')} />
                </RouteWithRoles>
                <RouteWithRoles roles={[Role.Employee]}>
                    <NavItem
                        to={routes.tableservice.posSelect(idHolding)}
                        menuIcon={<Icon.TableService />}
                        title={t('app:app.tableService')}
                    />
                </RouteWithRoles>
                <RouteWithRoles roles={[Role.Admin, Role.ManagerAdmin]}>
                    <NavItem
                        to={routes.globalCatalog.list()}
                        menuIcon={<Icon.GlobalCatalog />}
                        title={t('app:app.globalCatalog')}
                    />
                </RouteWithRoles>
                <RouteWithRoles roles={[Role.Employee]}>
                    <NavItem
                        to={routes.bookingservice.posSelect(idHolding)}
                        menuIcon={<Icon.Booking />}
                        title={t('app:app.bookingService')}
                    />
                </RouteWithRoles>
                <RouteWithRoles roles={[Role.Admin, Role.Marketer]}>
                    <NavItem to={routes.communication.list()} menuIcon={<Icon.Communication />} title={t('app:app.communication')} />
                </RouteWithRoles>
                <RouteWithRoles roles={[Role.Admin]}>
                    <NavItem to={routes.admin.index()} menuIcon={<Icon.Admin />} title={t('app:app.admin')} />
                </RouteWithRoles>
                <RouteWithRoles roles={[Role.Admin, Role.ChecklistAdmin]}>
                    <NavItem
                        to={routes.checkList.index(idHolding)}
                        menuIcon={<Icon.CheckList />}
                        title={t('app:app.checkList')}
                    />
                </RouteWithRoles>
            </Menu>
            <Footer>
                <MenuItem to="" onClick={auth0Client.signOut}>
                    <Icon.Off />
                    <MenuItemTitle>{t('component:sideNav.logout')}</MenuItemTitle>
                </MenuItem>
                <AppVersionWrapper>
                    <Text>v{projectVersion}</Text>
                </AppVersionWrapper>
            </Footer>
        </Wrapper>
    );
};

const SideNav = (props: ISideNavProps) => {
    const persistedIdHolding = getItem(LocalStorageKey.HOLDING_ID);
    if (persistedIdHolding) return <SideNavComponent {...props} idHolding={persistedIdHolding} />;
    return null;
};

interface IStyledProps {
    isOpen: boolean;
}

const Wrapper = styled.div<IStyledProps>`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: ${({ theme }) => theme.dimension.width.sideNav}px;
    transform: translateX(${({ theme, isOpen }) => (isOpen ? 0 : -theme.dimension.width.sideNav)}px);
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.common.navy};
    font-size: ${({ theme }) => theme.typography.fontSizeM}px;
    z-index: 1;
    transition: transform ${({ theme }) => theme.transition.duration[2]}ms;
`;

const Header = styled.div`
    padding: ${({ theme }) => theme.spacing.m}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }) => theme.color.common.white};
    background-color: ${({ theme }) => rgba(theme.color.grey[6], 0.1)};
`;

const Menu = styled.div`
    padding: ${({ theme }) => theme.spacing.s}px 0;
    padding-left: ${({ theme }) => theme.spacing.xs}px;
    flex: 1;
    display: flex;
    flex-direction: column;
`;
const Footer = styled.div`
    padding: ${({ theme }) => theme.spacing.s}px 0;
    padding-left: ${({ theme }) => theme.spacing.xs}px;
`;

const MenuItem = styled(NavLink)`
    padding: ${({ theme }) => theme.spacing.s}px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4.8px 0 0 4.8px;
    color: ${({ theme }) => theme.color.grey[3]};
    &:hover,
    &:active {
        background-color: ${({ theme }) => rgba(theme.color.grey[6], 0.3)};
    }
    &.selected {
        background-color: ${({ theme }) => rgba(theme.color.grey[6], 0.5)};
        font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
        color: white;
    }
`;

const MenuItemTitle = styled.span`
    display: inline-box;
    margin-left: ${({ theme }) => theme.spacing.s}px;
    flex: 1;
`;

const Close = styled.button`
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.common.transparent};
`;

const Indicator = styled.span`
    display: none;
    .selected & {
        display: block;
    }
`;

const AppVersionWrapper = styled.span`
    padding: ${({ theme }) => theme.spacing.s}px;
    color: ${({ theme }) => theme.color.grey[3]};
`;

export default withTheme(SideNav);
