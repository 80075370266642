import { Button, ErrorMessageLighter, Struct, TextInputField, ToggleSwitchField, } from 'components';
import SelectPOS from '../../../feature/SelectPOS';
import { Field, FieldArray, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Select from 'react-select';
import * as Yup from 'yup';
import PrintersEdit from "./PrintersEdit";
import { PosHub } from "../../../types/posHub";
import { ArticleFamily, PrintingPriority } from "../../../types/globalTypes";
import { ISelectOption } from "../../ClickCollect/ClickCollectPage/AddOrEditForm/StepOne";
import { appTheme } from "../../../theme";
import { isEmpty, uniq, xor } from "lodash";
import { ErrorMessageWrapper } from "../../Communication/AddOrEditForm/StepTwo";
import appRoutes from "../../../Router/routes";
import { ButtonType } from "../../../components/Button";

interface IProps extends RouteComponentProps<{ idHolding: string }> {
    initialValues: PosHub;
    loading: boolean;
    editMode: boolean;
    onSubmit: (values: any) => void;
}


const getSchema = (t: any) =>
    Yup.object().shape({
        printingPriority: Yup.string().required(t('app:error.required')),
        hub: Yup.object().shape({
            name: Yup.string().required(t('app:error.required')),
        }),
        pos: Yup.object().shape({
            id: Yup.string().required(t('app:error.required')),
        }),
    });

const validate = (t: any) => (values: any) => {
    let errors: any = {};
    if (values.posPrinters && values.posPrinters.length > 0) {
        if (values.printingPriority === PrintingPriority.articleFamily) {
            if (!isEmpty(xor(
                Object.values(ArticleFamily),
                uniq(values.posPrinters.map(({ scopes }) => scopes).flat())
            ))) {
                errors.posPrinters = t('error:posHub.printing.COVER_ALL_DISH_GROUPS');
            }
        }
        if (values.printingPriority === PrintingPriority.mealHeart) {
            const scopes = values.posPrinters.map(({ scopes }) => scopes).flat();
            if (scopes.length !== new Set(scopes).size) {
                errors.posPrinters = t('error:posHub.printing.DISH_GROUP_MUST_BE_SELECTED_ONCE');
            }
        }
        const masterTrue = values.posPrinters.filter(({ master }) => master === true);
        if (masterTrue.length > 1) {
            errors.posPrinters = t('error:posHub.printing.MASTER_TRUE');
        }
        const defaultTrue = values.posPrinters.filter(({ default: defaultPrinter }) => defaultPrinter === true);
        if (defaultTrue.length > 1) {
            errors.posPrinters = t('error:posHub.printing.DEFAULT_TRUE');
        }
    }
    return errors;
};


const costumStyles = {
    container: (base) => ({
        ...base,
        marginBottom: '32px',
    }),
    control: (base) => ({
        ...base,
        border: `1px solid ${appTheme.color.grey[2]}`,
    }),
    clearIndicator: (prevStyle) => ({
        ...prevStyle,
        color: appTheme.color.common.red,
        ':hover': { color: appTheme.color.common.red },
        backgroundColor: appTheme.color.grey[0],
    }),
    option: (base) => ({
        ...base,
        ':hover': {
            color: appTheme.color.common.blue,
            fontWeight: appTheme.typography.fontWeight.bold,
            backgroundColor: appTheme.color.grey[0]
        },
        backgroundColor: 'transparent',
        color: appTheme.color.common.black,
    }),
    placeholder: (base) => ({
        ...base,
        color: appTheme.color.grey[5],
        fontSize: appTheme.typography.fontSizeS,
        fontWeight: appTheme.typography.fontWeight.bold,
    }),
    indicatorSeparator: (styles) => ({ display: 'none' }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
        color: appTheme.color.common.navy
    })
}

const PrintingPriorityInput = ({ field, form, t }: { field: any; form: any, t: any }) => (
    <Select
        styles={costumStyles}
        options={Object.values(PrintingPriority).map((value) => ({
            value,
            label: t(`schema:posHub.printingPriorityLabels.${value}`),
        }))}
        value={field.value && { value: field.value, label: t(`schema:posHub.printingPriorityLabels.${field.value}`) }}
        onChange={(value) => {
            form.setFieldValue(field.name, value.value);
            form.setFieldTouched(field.name, true);
            if (value != field.value && form.values.posPrinters && form.values.posPrinters.length > 0){
                form.setFieldValue('posPrinters', form.values.posPrinters.map((printer: any) => ({
                    ...printer,
                    scopes: []
                })));
            }
        }}
    />
);

const Configuration = ({
                           match: {
                               params: { idHolding },
                           },
                           // @ts-ignore
                           initialValues = {},
                           onSubmit,
                           loading,
                           editMode,
                           history

                       }: IProps) => {
    const [t] = useTranslation();
    return (
        <Formik
            validationSchema={getSchema(t)}
            validate={validate(t)}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {({ setFieldValue, values, submitForm, errors }) => {
                return (
                    <Form>
                        <Struct.Section>
                            <Title>
                                <SelectPOS
                                    omitLoader={false}
                                    // @ts-ignore
                                    selectedPosId={values.pos?.id}
                                    idHolding={idHolding}
                                    width={400}
                                    name="pos.id"
                                    onChange={(pos: ISelectOption) => {
                                        setFieldValue && setFieldValue('pos.id', pos.id);
                                    }}
                                    backgroundColor={'white'}

                                />
                                {!editMode && <Button onClick={submitForm} disabled={loading}>
                                        {t('schema:posHub.add')}
                                    </Button>}
                                {editMode && <Actions>
                                    <Button display={ButtonType.GHOST} onClick={() => history.push(appRoutes.holding.printing.index(idHolding))} disabled={loading}>
                                        {t('schema:posHub.cancel')}
                                    </Button>
                                    <Button
                                        display={ButtonType.VALIDATION}
                                        onClick={submitForm} disabled={loading}>
                                        {t('schema:posHub.submit')}
                                    </Button>
                                </Actions> }
                            </Title>
                            <StyledCard>
                                <StyledColumn>
                                    {
                                        !isEmpty(errors) && (<ErrorMessageWrapper>
                                            {Object.values(errors)
                                                .map((error: any) => <ErrorMessageLighter>{error}</ErrorMessageLighter>)}
                                        </ErrorMessageWrapper>)
                                    }
                                    <Field
                                        name="enabled"
                                        component={ToggleSwitchField}
                                        sideLabel={true}
                                        onLabel={t('schema:posHub.enabled')}
                                        offLabel={t('schema:posHub.enabled')}
                                    />
                                    <Field
                                        label={t('schema:posHub.name')}
                                        name="hub.name"
                                        component={TextInputField}
                                    />
                                    <Field
                                        label={t('schema:posHub.username')}
                                        name="hub.user.username"
                                        component={TextInputField}
                                    />
                                    <Field
                                        label={t('schema:posHub.password')}
                                        name="hub.user.password"
                                        component={TextInputField}
                                    />
                                    <Field
                                        label={t('schema:posHub.printingPriority')}
                                        name="printingPriority"
                                        component={PrintingPriorityInput}
                                        t={t}
                                    />
                                    <FieldArray
                                        name="posPrinters"
                                        render={arrayHelpers => <PrintersEdit values={values}
                                        // @ts-ignore
                                        setFieldValue={setFieldValue}
                                        arrayHelpers={arrayHelpers}/>}
                                    />
                                </StyledColumn>
                            </StyledCard>
                        </Struct.Section>
                    </Form>
                );
            }}
        </Formik>
    );
};

// @ts-ignore
const StyledCard = styled(Struct.Card)`
  display: flex;
  justify-content: space-between;
`;

const StyledColumn = styled.div`
  flex-basis: 48%;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;


export default withRouter(Configuration);
