import auth0, { Auth0DecodedHash } from 'auth0-js';

import { setItem, removeItem, LocalStorageKey } from 'services/persistentData';

class Auth {
    auth0: auth0.WebAuth;
    accessToken?: string | null = null;
    expiresAt: number | null = null;

    constructor() {
        this.auth0 = new auth0.WebAuth({
            domain: window.config.AUTH0.DOMAIN || '',
            audience: window.config.AUTH0.AUDIENCE,
            clientID: window.config.AUTH0.CLIENT_ID || '',
            redirectUri: `${window.config.WEB_BASE_URL}/callback`,
            responseType: 'token',
        });
    }

    getAccessToken() {
        return this.accessToken;
    }

    isAuthenticated = () => {
        if (!this.expiresAt) return false;
        else {
            return new Date().getTime() < this.expiresAt;
        }
    };

    signIn = () => this.auth0.authorize();

    /**
     * Gets called by the main Router
     */
    silentAuth = () => {
        return new Promise<void>((resolve, reject) => {
            this.auth0.checkSession({ domain: window.config.AUTH0.DOMAIN }, (err, authResult) => {
                if (err) return reject(err);

                this.setSession(authResult).then(() => resolve());
            });
        });
    };

    /**
     * This gets called from CallbackComponent when we come back
     * from auth0, with the tokens in the query.
     */
    handleAuthentication = () => {
        return new Promise<void>((resolve, reject) => {
            this.auth0.parseHash((err, authResult) => {
                if (err) return reject(err);
                if (!authResult || !authResult.accessToken) {
                    return reject(err);
                }

                this.setSession(authResult).then(() => resolve());
            });
        });
    };

    protected async setSession(authResult: Auth0DecodedHash) {
        this.accessToken = authResult.accessToken;
        if (this.accessToken) setItem(LocalStorageKey.ACCESS_TOKEN, this.accessToken);
        this.expiresAt = new Date().getTime() + (authResult.expiresIn || 0) * 1000;
    }

    signOut = () => {
        removeItem(LocalStorageKey.ACCESS_TOKEN);
        this.auth0.logout({
            returnTo: window.config.WEB_BASE_URL,
            clientID: window.config.AUTH0.CLIENT_ID,
        });
    };

    signOutToPasswordExpired = ({ email }) => {
        if (email) setItem(LocalStorageKey.EXPIRED_PASSWORD_EMAIL, email);
        this.auth0.logout({
            returnTo: `${window.config.WEB_BASE_URL}/password-expired`,
            clientID: window.config.AUTH0.CLIENT_ID,
        });
    };

    sendResetPasswordEmail = async ({ email }) => {
        const baseURL = `https://${window.config.AUTH0.DOMAIN}/dbconnections/change_password`;
        const body = {
            client_id: window.config.AUTH0.CLIENT_ID,
            email: email,
            connection: 'Username-Password-Authentication',
        };
        try {
            const response = await window.fetch(baseURL, {
                method: 'post',
                headers: {
                    Accept: 'application/json, text/json, */*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });
            if (response.status !== 200) {
                throw Error;
            }
            return Promise.resolve(response.status);
        } catch (error) {
            throw Error;
        }
    };
}

const auth0Client = new Auth();

export default auth0Client;
