import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from 'Router/routes';
import { Route } from 'react-router-dom';

import { TableWithOptions, ImageIconWithTooltip, Icon, PriceInput } from 'components';
import { getBooleanOptions } from 'components/Tag';
import ArticleConceptList from 'components/Query/ArticleConceptList';
import { loader } from 'graphql.macro';
import { QueryResult } from 'localTypes';
import { TransitionGroup } from 'react-transition-group';
import { getArticleFamilyOptions } from 'services/articleService';
import { listArticles, listArticles_list_edges_node_Article } from 'types/listArticles';
import AddGlobalArticlePanel from './AddGlobalArticlePanel';
import EditGlobalArticlePanel from './EditGlobalArticlePanel';
import { ImportationType } from 'types/globalTypes';

export enum FilterKey {
    FAMILY = 'family',
    CONCEPT = 'articleConcept.id',
    ACTIVE = 'isActive',
}

const ARTICLE_LIST_QUERY = loader('./query/listArticles.gql');

const Article = ({ item: { label, family, priceHint, image, importationType } }: { item: listArticles_list_edges_node_Article }) => {
    const { t } = useTranslation();
    return (
        <>
            <td style={{
                width: '40%',
                maxWidth: '320px',
                overflow: 'hidden',
                overflowWrap: 'break-word'
            }}>{label}</td>
            <td style={{ width: '20%' }}>{family ? t(`schema:article.familyOption.${family}`) : ''}</td>
            <td style={{ width: '10%' }}>
                {importationType === ImportationType.Oscar
                    ? <Icon.Check width={18} height={16} color="#383838" />
                    : <Icon.Cross width={16} color="#383838" />
                }
            </td>
            <td style={{ width: '10%' }}>
                <ImageIconWithTooltip image={image} />
            </td>
            <td style={{ width: '15%' }}>
                <PriceInput onChange={() => {}} value={priceHint.amount} disabled />
            </td>
            <td style={{ width: '5%' }}>
                <Icon.SolidArrow />
            </td>
        </>
    );
};
const GlobalCatalogTable = ({ history, location }: RouteComponentProps) => {
    const { t } = useTranslation();

    const optionsActive = getBooleanOptions(
        t('page:globalCatalog.edit.form.isActive'),
        t('page:globalCatalog.edit.form.inActive')
    );
    const renderLine = (item: listArticles_list_edges_node_Article) => {
        return (
            <StyledTR
                onClick={() => {
                    history.push(`${routes.globalCatalog.article.edit(item.id)}${location.search}`);
                }}
                key={item.id}
            >
                <Article item={item} />
            </StyledTR>
        );
    };

    return (
        <ArticleConceptList>
            {({ concepts }) => (
                <TableWithOptions
                    parentPage='administration-global-catalog'
                    renderLine={renderLine}
                    headers={getHeaders(t)}
                    fetchPolicy="cache-and-network"
                    query={ARTICLE_LIST_QUERY}
                    searchPlaceholder={t('app:placeholder.search.article')}
                    tags={{
                        [FilterKey.FAMILY]: getArticleFamilyOptions(),
                        [FilterKey.CONCEPT]: concepts,
                        [FilterKey.ACTIVE]: optionsActive,
                    }}
                >
                    {({ refetch }: QueryResult<listArticles>) => {
                        return (
                            <TransitionGroup>
                                <Route
                                    path={routes.globalCatalog.article.edit()}
                                    render={() => <EditGlobalArticlePanel beforeClose={refetch} parentPage="administration-global-catalog"/>}
                                />
                                <Route
                                    path={routes.globalCatalog.article.add()}
                                    render={() => <AddGlobalArticlePanel beforeClose={refetch} parentPage="administration-global-catalog"/>}
                                />
                            </TransitionGroup>
                        );
                    }}
                </TableWithOptions>
            )}
        </ArticleConceptList>
    );
};

const getHeaders = (t: any) => [
    {
        key: 'label',
        displayName: t('schema:article.label'),
    },
    {
        key: 'family',
        displayName: t('schema:article.family'),
    },
    {
        key: 'fromOscar',
        displayName: t('schema:article.fromOscar'),
    },
];

const StyledTR = styled.tr`
    cursor: pointer;
`;

export default withRouter(GlobalCatalogTable);
