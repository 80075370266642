import { Field } from 'formik';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { appTheme } from 'theme';
import * as Yup from 'yup';
import { Column, InfoLabel, InputLabel } from 'components';
import { Tags } from '../../../components';
import DropdownHolding from 'feature/DropdownHolding';
import LanguageTextInput from 'components/LanguageTextInput';
import { Option } from 'react-select';
import { InfoNews, PageArticle, Vignette } from 'components/Icons';
import CheckBoxCard from 'components/CheckBoxCard';
import { MarketingTypeNew } from 'types/globalTypes';
import PublicationDates from './components/PublicationDates';

interface IStepProps {
    values?: any;
    validate?: Function;
    validationSchema?: Yup.ObjectSchema<any>;
    isModeEdit: boolean;
    setFieldValue?: Function;
    setMarketingCardType?: Function;
}

const langs = [
    { id: 'fr', label: 'Français' },
    { id: 'en', label: 'Anglais' },
];


const StepOne = ({ values, setFieldValue, setMarketingCardType }: IStepProps) => {
    const { t } = useTranslation();

    //Being used to change dynamically the number of steps that the user will be going through
    useEffect(() => {
        setMarketingCardType && setMarketingCardType(values.type)
    }, [setMarketingCardType, values.type])

    //If we change the marketingCardType, the values from the next steps should all be cleared
    const clearValuesFromNextSteps = () =>{
        if(setFieldValue) {
            setFieldValue('frArticleTitle', '');
            setFieldValue('frArticleResume', '');
            setFieldValue('enArticleTitle', '');
            setFieldValue('enArticleResume', '');
            setFieldValue('image', [{ id: '' }]);
            setFieldValue('contentFR', []);
            setFieldValue('contentEN', []);
            setFieldValue('linkToExternalPage', null);
        }
    };

    const setLangFields = (lang: string, values: any, setFieldValue: Function) => {
        const langLowerCase = lang.toLowerCase();

        // Is this a existing language?
        if (values.languages.includes(lang)) {
            return;
        }

        copyContentsToNewLanguage(lang.toUpperCase(), values, setFieldValue);

        setFieldValue(`${langLowerCase}ArticleTitle`, '');
        setFieldValue(`${langLowerCase}ArticleResume`, '');
    };

    /**
     * Iterates over the list of available languages and check if in values object is there any content for each
     * language in the list.
     * If there is any content then it will copy the existent content to the new language and stops the loop iteration.
     *
     * Note: if the content contains any image, it will keep the same image, otherwise it will clear the content.
     */
    const copyContentsToNewLanguage = (langUpperCase, values: any, setFieldValue: Function) => {

        let contentComponents = [];

        // Find for another lang that have contents to copy the keys
        for (const langLoop of langs) {
            const contentArr = values[`content${langLoop.id.toUpperCase()}`];
            if (contentArr && contentArr.length > 0) {
                contentComponents = contentArr.map(content => ({
                    key: content.key,
                    content: content.key === 'image' ? content.content : ''
                }));
                break;
            }
        }

        setFieldValue(`content${langUpperCase}`, contentComponents);
    };

    return (
        <>
            <Wrapper>
                <MainSubWrapper>
                    <FieldWrapper>
                        <LanguagesWrapper>
                            <InfoLabel inline fontWeight={'bold'} paddingRight={4} name={t('page:communication.addMarketingCard.languages')} />
                            <InfoLabel inline fontColor={appTheme.color.common.darkRed} name="*" />
                            <Tags
                                options={langs}
                                value={values.languages}
                                onChange={(option: string | string[]) => {
                                    if (setFieldValue) {
                                        if (typeof option === 'string') {
                                            setLangFields(option, values, setFieldValue);
                                        } else {
                                            for (const lang of option) {
                                                setLangFields(lang, values, setFieldValue);
                                            }
                                        }
                                        setFieldValue('languages', option);
                                    };
                                }}
                            />
                        </LanguagesWrapper>
                    </FieldWrapper>
                    <LanguageTextInput
                        placeholder={t(`page:communication.addMarketingCard.titlePlaceholder`)}
                        label={
                            <Trans
                                i18nKey="page:communication.addMarketingCard.titleNews"
                                components={[<strong />, <i />]}
                            />
                        }
                        name="title"
                        maxLength={80}
                        multiline
                        value={values.title}
                    />
                    <FieldWrapper>
                        <Field
                            label={
                                <Trans
                                    i18nKey="page:communication.addMarketingCard.chooseHoldingTitle"
                                    components={[<strong />]}
                                />
                            }
                            name="holdings"
                            isMultiSelect
                            component={DropdownHolding}
                            onChange={(option: Option | Option[]) => {
                                setFieldValue && setFieldValue('holdings', option);
                            }}
                            initialValues={values.holdings}
                            overrideShowRequiredAsterisk
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <InputLabel
                            fontWeight={'bold'}
                            showRequiredAsterisk={true}
                            name={t(`page:communication.addMarketingCard.publicationDates`)}
                        />
                        <DatePickerSection>
                            <PublicationDates values={values} setFieldValue={setFieldValue} />
                        </DatePickerSection>
                    </FieldWrapper>
                </MainSubWrapper>
                <VerticalLine />
                <SecondSubWrapper>
                    <InfoLabel
                        fontWeight={'bold'}
                        name={t('page:communication.addMarketingCard.chooseMarketingCardType')}
                    />
                    <FieldWrapper>
                        <CheckBoxCard
                            label={'Article'}
                            checked={values.type === MarketingTypeNew.ARTICLE}
                            onChange={() => {
                                setFieldValue && setFieldValue('type', MarketingTypeNew.ARTICLE)
                                if(values.type !== MarketingTypeNew.ARTICLE){
                                    clearValuesFromNextSteps()
                                }
                            }}
                            renderBody={
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <StyledColumn>
                                        <Label>{t('page:communication.addMarketingCard.thumbnail')}</Label>
                                        <Vignette />
                                    </StyledColumn>
                                    <StyledPlus>{'+'}</StyledPlus>
                                    <StyledColumn>
                                        <Label>{t('page:communication.addMarketingCard.articlePage')}</Label>
                                        <PageArticle />
                                    </StyledColumn>
                                </div>
                            }
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <CheckBoxCard
                            label={'Info News'}
                            checked={values.type === MarketingTypeNew.IMPORTANT_MESSAGE}
                            onChange={() => {
                                setFieldValue && setFieldValue('type', MarketingTypeNew.IMPORTANT_MESSAGE)
                                if(values.type !== MarketingTypeNew.IMPORTANT_MESSAGE){
                                    clearValuesFromNextSteps()
                                }
                            }}
                            renderBody={
                                <StyledColumn>
                                    <Label>{t('page:communication.addMarketingCard.infoNews')}</Label>
                                    <InfoNews />
                                </StyledColumn>
                            }
                        />
                    </FieldWrapper>
                </SecondSubWrapper>
            </Wrapper>
            <RequiredFieldsExplanation>
                <RequiredFieldsLabel>( * ) {t('app:error.requiredFields')}</RequiredFieldsLabel>
            </RequiredFieldsExplanation>
        </>
    );
};

const Wrapper = styled.div`
    align-items: flex-start;
    padding: ${({ theme }) => theme.spacing.s}px;
    border-radius: ${({ theme }) => theme.borderRadius.s}px;
    background-color: ${({ theme }) => theme.color.common.white};
    flex-grow: 0;
    justify-content: center;
    display: flex;
    flex-direction: row;
    flex: 1;
    width: 940px;
`;

const MainSubWrapper = styled(Column)`
    margin: 15px;
    align-items: flex-start;
    flex: 5;
`;

const SecondSubWrapper = styled(Column)`
    margin: 15px;
    align-items: flex-start;
    flex: 4;
`;

const Label = styled.label`
    font-size: ${({ theme }) => theme.typography.fontSizeS}px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    margin-bottom: ${({ theme }) => theme.spacing.xxs}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.lighter};
    color: ${() => appTheme.color.grey[6]};
`;

const StyledColumn = styled(Column)`
    flex-direction: column;
    align-items: flex-start;
    margin-left: ${({ theme }) => theme.spacing.m}px;
`;

const StyledPlus = styled.div`
    display: flex;
    align-items: center;
    color: ${() => appTheme.color.grey[6]};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.fontSizeL}px;
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
`;

const VerticalLine = styled.div`
    border-left: 2px solid ${() => appTheme.color.grey[0]};
    height: calc(100% - 40px);
    margin-left: -3px;
`;

const LanguagesWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const FieldWrapper = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.s}px;
    width: inherit;
`;

const DatePickerSection = styled.div`
    display: flex;
    margin-top: 10px;
    width: 85%;
`;

const RequiredFieldsExplanation = styled.div`
    align-items: flex-start;
    padding: ${({ theme }) => theme.spacing.s}px;
    padding-left: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    width: 900px;
`;

const RequiredFieldsLabel = styled.span`
    color: ${({ theme }) => theme.color.common.darkRed};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.fontSizeM}px;
    font-style: italic;
`;

export default StepOne;
