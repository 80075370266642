import React, { useState } from 'react';
import { RouteComponentProps, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OfferTemplateRouteParam } from 'localTypes';
import { Struct, Title, Button } from 'components';
import PickUpPointsTable from './PickUpPointsTable';
import appRoutes from 'Router/routes';

interface IProps extends RouteComponentProps<{ idHolding: string; idPos: string }> {
    offerType: OfferTemplateRouteParam;
}

const PickUpPoints = ({
    match: {
        params: { idHolding },
    },
    offerType,
}: IProps) => {
    const { t } = useTranslation();
    const [isPickUpPointsPanelOpen, setPickUpPointsPanelOpen] = useState(false);

    const togglePickUpPointsModal = () => {
      setPickUpPointsPanelOpen(!isPickUpPointsPanelOpen);
    };

    return (
        <Route
              exact
              path={appRoutes[offerType].pickUpPoints.get()}
              render={() => (
                  <Struct.Section>
                      <Title grow mode="H2" value={t('page:clickcollect.pickUpPoints.title') || ''}>
                          <Button id='add-pickUpPoints-button' onClick={togglePickUpPointsModal}>{t('app:button.add-pickUpPoint')}</Button>
                      </Title>
                      <Struct.Card>
                          <PickUpPointsTable 
                            idHolding={idHolding}
                            togglePickUpPointsModal={togglePickUpPointsModal}
                            isPickUpPointsPanelOpen={isPickUpPointsPanelOpen}
                          />
                      </Struct.Card>
                  </Struct.Section>
              )}
          />
    );
};

export default PickUpPoints;
